<div *ngIf="!details">
    <div *ngFor="let item of list; index as i; trackBy: trackCount" class="rest-container">
        <div class="d-none d-md-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center ">
                <div *ngIf="icon" class="bg-slick-carbon text-center text-success font-size-xl d-30 min-width-circle rounded-circle">
                    <img class="restIcon" [alt]="item[identProp]" [src]="icon">
                </div>
                <div class="d-flex mx-1 mx-md-4 flex-column">
                    <b class="" [innerText]="item[identProp]"></b>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <div *ngIf="dataProp !== 'lastUse'; else lastUse" class="d-flex align-items-center">
                    <div [countUp]="item[dataProp]" class="font-weight-bold text-success user-select-none"></div>
                    &nbsp;&nbsp;
                    <div class="font-weight-bold text-success ">{{appendValue}}</div>
                </div>
                <ng-template #lastUse>
                    <div [innerText]="formatDate(item[dataProp])" class="font-weight-bold text-success user-select-none"></div>
                </ng-template>
                <button (click)="handleInfo(item['id'], item[identProp])"
                  class="btn btn-sm m-2 btn-primary text-light"
                  type="button"
                >Info</button>
            </div>
        </div>
        <!-- small 360px width view -->
        <div class="d-flex d-md-none justify-content-start align-items-center">
            <div class="d-flex align-items-center ">
                <div *ngIf="icon" class="bg-slick-carbon text-center text-success font-size-xl d-30 min-width-circle rounded-circle">
                    <img class="restIcon" [alt]="item[identProp]" [src]="icon">
                </div>
            </div>
            <div class="d-flex ml-2 flex-column align-items-start w-100">
                <div class="justify-content-start">
                    <b class="" [innerText]="item[identProp]"></b>
                    <div *ngIf="i == 0 && subtitle != ''" class="text-black-50 d-none d-sm-block">{{subtitle}}</div>
                </div>
                <div class="d-flex justify-content-between w-100">
                    <div *ngIf="dataProp !== 'lastUse'; else lastUse" class="d-flex align-items-center">
                        <div [countUp]="item[dataProp]" class="font-weight-bold text-success user-select-none"></div>
                        &nbsp;&nbsp;
                        <div class="font-weight-bold text-success ">{{appendValue}}</div>
                    </div>
                    <ng-template #lastUse>
                        <div class="d-flex align-items-center">
                            <div [innerText]="formatDate(item[dataProp])" class="font-weight-bold text-success user-select-none"></div>
                        </div>
                    </ng-template>
                    <button (click)="handleInfo(item['id'], item[identProp])"
                        class="btn btn-sm m-2 btn-primary text-light"
                        type="button"
                    >MAS</button>
                </div>
            </div>
        </div>
        <div class="divider my-1"></div>
    </div>
</div>
<div *ngIf="details && key === 'comerciales'">
    <div class="d-flex justify-content-between align-items-center pl-2">
        <div class="font-size-md mb-0 py-2 font-weight-bold">Actividad de: {{singleName}} este mes</div>
        <button (click)="closeDetail()" class="btn btn-sm m-2 btn-outline-primary" type="button">Volver</button>
    </div>
    <div class="divider my-1"></div>
    <div *ngFor="let item of restsCom; index as i; trackBy: trackCountVisits" class="rest-container">
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <div class="bg-slick-carbon text-center text-success font-size-xl d-30 min-width-circle rounded-circle">
                    <img class="restIcon" [alt]="item[identProp]" src="assets/images/rest-icon.png">
                </div>
                <div class="d-flex mx-4 flex-column">
                    <b class="" [innerText]="item['nombre']"></b>
                    <div *ngIf="i == 0" class="text-black-50">Restaurante con mas visitas este mes</div>
                </div>
            </div>
            <div class="d-flex">
                <div [countUp]="item['visits']" class="font-weight-bold text-success user-select-none"></div>
                &nbsp;&nbsp;
                <div class="font-weight-bold text-success">Visitas</div>
            </div>
        </div>
        <div class="divider my-1"></div>
    </div>
</div>

<div *ngIf="details && key === 'restaurantes'">
    <div class="d-flex justify-content-between align-items-center pl-2">
        <div class="d-flex align-items-center">
            <fa-icon [icon]="['fas', 'arrow-left']" class="font-size-lg mouse-action-back-arrow mr-2" (click)="closeDetail()"></fa-icon>
            <div class="d-flex flex-column flex-md-row">
                <div class="font-size-md mb-0 mx-2 py-2 font-weight-bold">Vinos del restaurant: {{singleName}}</div>
                <div class="d-flex align-items-center">
                    <div class="btn-group m-2 ml-md-3 dropdown-index" dropdown>
                        <button dropdownToggle type="button" class="btn btn-primary btn-sm dropdown-toggle text-light w-50">
                            Filtrar
                            <span class="caret"></span>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li role="menuitem"><a class="dropdown-item" (click)="showAllWines()">Vinos</a></li>
                            <li role="menuitem"><a class="dropdown-item" (click)="filterType()">Tipo</a></li>
                            <li role="menuitem"><a class="dropdown-item" (click)="filterCategory()">Categoria</a></li>
                            <li role="menuitem"><a class="dropdown-item" (click)="filterHiddenWines()" >Vinos Desactivados</a></li>
                            <li role="menuitem"><a class="dropdown-item" (click)="filterRecommendedWines()">Recomendado</a></li>
                            <li role="menuitem"><a class="dropdown-item" (click)="filterEntryChannel()">Ingresos</a></li>
                        </ul>   
                    </div>
                    <b *ngIf="filterStatus !== ''">{{filterStatus}}</b>
                </div>
            </div>
        </div>
    </div>
    <div class="divider my-1"></div>
    <div *ngIf="!showWines">
        <div *ngFor="let item of statList; index as i; trackBy: trackCount" class="rest-container">
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                    <div class="bg-slick-carbon text-center text-success font-size-xl d-30 min-width-circle rounded-circle">
                        <img class="restIcon" alt="Vino" src="assets/images/MenuBottle.png">
                    </div>
                    <div class="d-flex mx-2 mx-md-4 flex-column">
                        <b class="" [innerText]="item['name']"></b>
                    </div>
                </div>
                <div class="d-flex">
                    <div [countUp]="item['count']" class="font-weight-bold text-success user-select-none"></div>
                    &nbsp;&nbsp;
                    <div class="font-weight-bold text-success">Vistas</div>
                </div>
            </div>
            <div class="divider my-1"></div>
        </div>
    </div>
    <div *ngIf="showWines">
        <div *ngFor="let item of wineFromRestList; index as i; trackBy: trackCount" class="rest-container">
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                    <div class="bg-slick-carbon text-center text-success font-size-xl d-30 min-width-circle rounded-circle">
                        <img class="restIcon" [alt]="item[identProp]" src="assets/images/MenuBottle.png">
                    </div>
                    <div class="d-flex mx-1 mx-md-4 flex-column">
                        <b class="" [innerText]="item['name']"></b>
                        <div *ngIf="i == 0 && !details" class="text-black-50">Vino con mas vistas este mes</div>
                    </div>
                </div>
                <div class="d-flex">
                    <div [countUp]="item['count'] || 0" class="font-weight-bold text-success user-select-none"></div>
                    &nbsp;&nbsp;
                    <div class="font-weight-bold text-success">Vistas</div>
                </div>
            </div>
            <div class="divider my-1"></div>
        </div>
    </div>
    
</div>