<app-page-title titleDescription='' titleHeading='Vinos'></app-page-title>



<div class="card card-box mb-5 border-0">
  <div class="card-header pr-2 ">
    <div class="card-header--title">
      <div class="search-wrapper">
        <span class="icon-wrapper text-black">
          <fa-icon [icon]="['fas', 'search']"></fa-icon>
        </span>
        <input (keyup)="buscador($event)" class="form-control form-control-sm rounded-pill" placeholder="Buscar..."
          type="search">

      </div>
    </div>
    <div class="card-header--actions">

      <button (click)='downloadCSV();' class="btn  btn-sm m-2 btn-success" type="button">
        <span class="btn-wrapper--icon">
          <fa-icon [icon]="['fas', 'plus']"></fa-icon>
        </span>
        <span class="btn-wrapper--label ">EXPORTAR VINOS</span>
      </button>


      <button [routerLink]="['/anadir-vino']" class="btn  btn-sm m-2 btn-success" type="button">
        <span class="btn-wrapper--icon">
          <fa-icon [icon]="['fas', 'plus']"></fa-icon>
        </span>
        <span class="btn-wrapper--label ">AÑADIR VINO</span>
      </button>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-hover text-nowrap mb-0 table-borderless">
      <thead>
        <tr>
          <th class="text-center bg-primary text-light">#</th>
          <th class="text-center bg-primary text-light">Nombre</th>
          <th class="text-center bg-primary text-light">Añada</th>
          <th class="text-center bg-primary text-light">Informacion</th>

          <th class="text-center bg-primary text-light">Fecha de creación </th>
          <th class="text-center bg-primary text-light">Activo en</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let vino of vinos | paginate: { itemsPerPage: 10, currentPage: page } ; index as i">
          <td [routerLink]="['/editar-vino', vino.id]" class="text-center">
            {{((i+1)+(page*10)-10)}}
          </td>
          <td [routerLink]="['/editar-vino', vino.id]">
            <div class="d-flex align-items-center ">
              <div class="avatar-icon-wrapper mr-2">
                <div [ngStyle]="{'background-image': 'url(' + vino.img + ')'}" class="avatar-icon itemImg"></div>
              </div>
              <div>
                <a class="font-weight-bold text-black" title="...">
                  {{vino.name}}
                </a>
              </div>
            </div>
          </td>
          <td [routerLink]="['/editar-vino', vino.id]" class="text-center">
            <div class=" text-black-50 d-block">{{vino.year}}</div>
          </td>
          <td [routerLink]="['/editar-vino', vino.id]">
            <progressbar [value]="vino.info" class="progress-bar-rounded" type="info">{{vino.info}}%</progressbar>
          </td>
          <td [routerLink]="['/editar-vino', vino.id]" class="text-center">
            <div class=" text-black-50 d-block">{{vino.creado}}</div>
          </td>
          <td (click)="openModal(bodegaModal, vino.id, vino.name)" class="text-center wineRests">
            <button class="btn btn-sm btn-link " type="button">
              <fa-icon [icon]="['fas', 'ellipsis-h']" class="font-size-lg"></fa-icon>
            </button>
          </td>



          <ng-template #bodegaModal>
            <div class="text-center p-5">
              <div class="avatar-icon-wrapper rounded-circle m-0">
                <div
                  class="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-primary m-0 d-130">
                  <fa-icon [icon]="['fas', 'cog']" class="d-flex align-self-center display-3"></fa-icon>
                </div>
              </div>
              <h4 class="font-weight-bold mt-4">{{wineName}}</h4>
              <p class="mb-0 font-size-lg text-muted">Se encuentra en los siguientes restaurantes:</p>
              <div class="table-responsive">
                <table class="table table-hover table-striped table-bordered mb-5">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nombre</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="rests2.length  == 0 && finished">
                      <th scope="row">0</th>
                      <td> No hay restaurantes</td>
                    </tr>
                    <tr *ngIf="rests2.length  == 0 && !finished">
                      <th scope="row">0</th>
                      <td> Cargando</td>
                    </tr>
                    <tr *ngFor="let rest of rests2; index as e">
                      <th scope="row">{{e+1}}</th>
                      <td>{{rest}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>


            </div>
          </ng-template>



        </tr>

      </tbody>
    </table>
  </div>
  <div class="card-footer py-3 d-flex justify-content-between">
    <pagination-controls class="pagination" (pageChange)="page = $event"></pagination-controls>
  </div>
</div>
