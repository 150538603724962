<div class="app-main">
    <!-- <a class="btn btn-lg btn-go-back btn-outline-first" href="javascript:history.back()">
            <span class="btn-wrapper--icon">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
            </span>
        <span class="btn-wrapper--label">Back to previous page</span>
    </a> -->
    <div class="app-content p-0">
        <div class=" overflow-hidden h-100vh">
            <!-- <div class="flex-grow-1 w-100 d-flex align-items-center">
                <div class="bg-composed-wrapper--content py-5"> -->
                    <!-- <div class="container h-100vh"> -->
                        <div class="row h-100vh d-flex align-items-center justify-content-center">
                            <div class="col-lg-5 d-none d-lg-flex align-items-center h-100 overflow-hidden ">
                                <img alt="..." class="w-100 mx-auto d-block img-fluid  image" src="assets/images/iPad-Pro-11.png">
                            </div>
                            <div class="col-lg-4 col-sm-12 ml-5  d-flex align-items-center">
                                <div class="pl-0 pl-lg-7  w-100">
                                    <div class="text-light mt-3">
                                        <span class="text-left  text-sm-center">
                                            <h1 class="display-3 text-lg-left text-center  font-weight-bold text-uppercase">
                                                Acceso Dvinum
                                            </h1>
                                            <p class="font-size-lg text-lg-left text-center mb-1 text-uppercase">
                                                Carta de vino digital
                                            </p>
                                            <div [ngClass]="{'wrong-login--show': wrongLogin}" class="wrong-login">
                                                <p class="font-size-lg text-lg-left text-danger text-center mb-3 text-uppercase font-weight-bold">
                                                    Datos Incorrectos
                                                </p>
                                            </div>
                                        </span>

                                        <div>
                                            <form [formGroup]="login" (ngSubmit)="onSubmit()" >
                                                <div class="form-group">
                                                    <label > Email </label>
                                                    <input formControlName="email" class="text-light form-control col-lg-12 rounded-pill" placeholder="Introduce tu email" type="email">
                                                </div>
                                                <div class="form-group mb-4">
                                                    <div class="d-flex text-light justify-content-between">
                                                        <label >Contraseña</label>
                                                        <!-- <a class="text-light" [routerLink]="['/pages-recover-password']">Recuperar contraseña</a> -->
                                                    </div>
                                                    <input formControlName="password" class="text-light form-control rounded-pill" placeholder="Introduce tu contraseña" type="password">
                                                </div>

                                                <button type="submit" class="btn btn-lg btn-second btn-block rounded-pill">
                                                    <div class="d-flex justify-content-center align-items-center">
                                                        <span class="text-uppercase align-middle font-weight-bold">acceder</span>
                                                        <span *ngIf="loading" aria-hidden="true" class="spinner-border spinner-border-sm ml-3 "role="status"></span>
                                                    </div>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <!-- </div> -->
                <!-- </div>
            </div> -->
        </div>
    </div>
</div>
