import { Component, OnInit, TemplateRef } from "@angular/core";
import { AngularFirestore, validateEventsArray } from "@angular/fire/firestore";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AngularFireFunctions } from "@angular/fire/functions";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FirestoreProviderService } from "src/app/shared/firestore-provider.service";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

@Component({
  selector: "app-distribuidor",
  templateUrl: "./distribuidor.component.html",
  styleUrls: ["./distribuidor.component.scss"]
})
export class DistribuidorComponent implements OnInit {
  isSubmitted: boolean;

  nuevoDistribuidor = new FormGroup({
    nombre: new FormControl("", Validators.required),
    email: new FormControl(
      "",
      Validators.compose([Validators.email, Validators.required])
    ),
    contrasena: new FormControl("", Validators.minLength(6)),
    numeroContacto: new FormControl(""),
    direccion: new FormControl(""),
    localidad: new FormControl(""),
    correoContacto: new FormControl(
      "",
      Validators.compose([Validators.email])
    ),
    nombreResponsable: new FormControl("")
  });
  loading: boolean;

  public selectedProvincia: string = "";
  public provinciaSelectorKey: string = "Nombre";

  constructor(
    private _firestore: AngularFirestore,
    private _firestoreProvider: FirestoreProviderService,
    private _functions: AngularFireFunctions,
    private _router: Router,
    private notifier: NotifierService,
    private _route: ActivatedRoute,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.getParams();
  }
  id = null;
  getParams() {
    this._route.paramMap.subscribe(params => {
      this.id = params.get("id");
      this.getUsuario();
    });
  }
  usuarioToEdit = {
    email: "",
    contrasena: "",
    nombre: ""
  };

  getUsuario() {
    if (this.id) {
      this._firestoreProvider
        .getCollection("Distribuidores")
        .doc(this.id)
        .valueChanges()
        .subscribe((usuario: any) => {
          this.usuarioToEdit = {
            email: usuario.email,
            contrasena: usuario.password,
            nombre: usuario.displayName
          };

          this.selectedProvincia = usuario.provincia;
          this.nuevoDistribuidor.patchValue({ email: usuario.email });
          this.nuevoDistribuidor.patchValue({ nombre: usuario.displayName });
          this.nuevoDistribuidor.patchValue({
            numeroContacto: usuario.numeroContacto
          });
          this.nuevoDistribuidor.patchValue({ localidad: usuario.localidad });
          this.nuevoDistribuidor.patchValue({ direccion: usuario.direccion });
          this.nuevoDistribuidor.patchValue({
            correoContacto: usuario.correoContacto
          });
          this.nuevoDistribuidor.patchValue({
            nombreResponsable: usuario.nombreResponsable
          });
        });
    }
  }

  generatePassword() {
    let generated = this._firestore.createId();
    let password = generated.substring(0, 7);
    this.nuevoDistribuidor.patchValue({ contrasena: password });
  }

  get formControls() {
    return this.nuevoDistribuidor["controls"];
  }

  showNotification(type, message) {
    this.notifier.notify(type, message);
  }

  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered"
    });
  }
  closeModal() {
    this.modalRef.hide();
  }

  loadingDelete: boolean;
  deleteUsuario() {
    this.loadingDelete = true;
    let deleteUser = this._functions.httpsCallable("deleteDistribuidor");
    deleteUser({
      distUID: this.id
    })
      .toPromise()
      .then(response => {
        if (response.errorInfo) {
          this.loading = false;
          this.showNotification("danger", response.errorInfo.code);
        } else {
          this.loadingDelete = false;
          this.showNotification("warning", `Se ha borrado el Distribuidor `);
          this.closeModal();
          this._router.navigateByUrl("/distribuidores");
        }
      }).catch(err=>{
        this.showNotification(
          "warning",
          `Ha ocurrido un error, por favor vuelva a intentar`
        );
        this.closeModal();
        this._router.navigateByUrl("/distribuidores");
      });
  }

  onSubmit(formValue) {
    this.isSubmitted = true;

    if (!this.id) {
      let createDistribudor = this._functions.httpsCallable(
        "createDistribuidor"
      );
      if (this.nuevoDistribuidor.valid) {
        this.loading = true;
        const user = {
          email: formValue.email,
          password: formValue.contrasena,
          displayName: formValue.nombre
        };
        const info = {
          numeroContacto: formValue.numeroContacto,
          localidad: formValue.localidad,
          provincia: this.selectedProvincia,
          direccion: formValue.direccion,
          correoContacto: formValue.correoContacto,
          nombreResponsable: formValue.nombreResponsable
        };
        console.log(info);
        createDistribudor({
          user,
          info
        })
          .toPromise()
          .then(response => {
            console.log(response);
            if (response.errorInfo) {
              let msg = response.errorInfo.message;
              if (response.errorInfo.code === "auth/email-already-exists") {
                msg = "Error, este email ya esta usado por otra cuenta"
              }
              this.showNotification("warning", `${msg}`);
              this._router.navigateByUrl("/distribuidores");
              this.loading = false;
            } else {
              this.showNotification(
                "success",
                `El usuario se ha creado correctamente.`
              );
              this.loading = false;
              this._router.navigateByUrl("/distribuidores");
            }
          }).catch(err=>{
            this.showNotification(
              "warning",
              `Ha ocurrido un error, por favor vuelva a intentar`
            );
            this.closeModal();
            this._router.navigateByUrl("/distribuidores");
          });
      } else {
        this.showNotification(
          "warning",
          `Por favor, rellene todos los campos requeridos`
        );
      }
    } else {
      let updateUser = this._functions.httpsCallable("updateDistribuidor");
      if (this.nuevoDistribuidor.valid) {
        this.loading = true;
        const user = {
          id: this.id
        };
        const info = {
          nombre: formValue.nombre,
          contrasena: formValue.contrasena || this.usuarioToEdit.contrasena,
          numeroContacto: formValue.numeroContacto,
          localidad: formValue.localidad,
          provincia: this.selectedProvincia,
          email: formValue.email,
          direccion: formValue.direccion,
          correoContacto: formValue.correoContacto,
          nombreResponsable: formValue.nombreResponsable
        };
        updateUser({
          user,
          info
        })
          .toPromise()
          .then(response => {
            if (response.errorInfo) {
              let msg = response.errorInfo.message;
              if (response.errorInfo.code === "auth/email-already-exists") {
                msg = "Error, este email ya esta usado por otra cuenta";
              }
              this.showNotification("warning", `${msg}`);
              this._router.navigateByUrl("/distribuidores");
              this.loading = false;
            } else {
              this.loading = false;
              this._router.navigateByUrl("/distribuidores");
              this.showNotification(
                "success",
                `El distribuidor ha sido actualizado correctamente.`
              );
            }
          }).catch(err=>{
            this.showNotification(
              "warning",
              `Ha ocurrido un error, por favor vuelta a intentar`
            );
            this.closeModal();
            this._router.navigateByUrl("/distribuidores");
          });
      }
    }
  }

  agregarProvinciaADistribuidor(event) {
    if (this.selectedProvincia === "") {
      this.selectedProvincia = event[this.provinciaSelectorKey];
    }
  }

  eliminarProvinciaDeDistribuidor() {
    this.selectedProvincia = "";
  }
}
