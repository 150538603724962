<app-page-title titleDescription='' titleHeading='Descarga de archivos'></app-page-title>

<div class="card card-box mx-4 mb-5">
  <div class="card-header pr-2">
    <div class="card-header--title ">
      <h5 *ngIf='!director' class="mb-0 py-2 font-weight-bold">
        {{restauranteToEdit.nombre}}
      </h5>
      <h5 *ngIf='director' class="mb-0 py-2 font-weight-bold">
        {{restName}}
      </h5>
    </div>
  </div>
  <div class="card-body ">

    <div id="qrcode-container">


      <div class="big">
        <div class="image-bg">
          <div id="card-image2"> 
            <div class="qrContainer">
              <div id="qrcode2">
                <qrcode [elementType]="'img'" [qrdata]="qrcode" [width]="500" [errorCorrectionLevel]="'M'"></qrcode>
              </div>
            </div>
            <img src="/assets/images/qrTemplate.png" alt="">
          </div>
        </div>
      </div>

      
      <div class="big">
        <div class="image-bg">
          <div id="card-image3">
            <div class="qrContainer">
              <div id="qrcode3">
                <qrcode [elementType]="'img'" [qrdata]="qrcode" [width]="620" [errorCorrectionLevel]="'M'"></qrcode>
              </div>
            </div>
            <img src="/assets/images/qrTemplateVertical.png" alt="">
          </div>
        </div>
      </div>


      <div id="qrcode">
        <qrcode [elementType]="'img'" [qrdata]="qrcode" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
      </div>
    </div>



    <div class="table-responsive">
      <table class="table table-hover text-nowrap mb-0 table-borderless">
        <thead class="bg-primary">
          <tr class="bg-primary">
            <th class="text-center bg-primary text-light">Carta</th>
            <th class="text-center bg-primary text-light"></th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr>
            <td class="text-center">
              <div class=" text-black-50 d-block">Carta rapida</div>
            </td>
            <td class="text-center">
              <button (click)="getWineList(1)" class="btn btn-sm m-2 btn-success" type="button">
                <span class="btn-wrapper--icon">
                  <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                </span>
                <span *ngIf="!generating" class="btn-wrapper--label ">DESCARGAR CARTA</span>
                <span *ngIf="generating" class="btn-wrapper--label ">GENERANDO</span>
              </button>
            </td>
          </tr> -->
          <tr>
            <td class="text-center">
              <div class=" text-black-50 d-block">Carta personalizada</div>
            </td>
            <td  *ngIf='!director' class="text-center">
              <button [routerLink]="['/configurar-carta', id]"  class="btn btn-sm m-2 btn-success" type="button">
                <span class="btn-wrapper--icon">
                  <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                </span>
                <span class="btn-wrapper--label ">CONFIGURAR CARTA</span>
              </button>
            </td>
            <td  *ngIf='director' class="text-center">
              <button [routerLink]="['/configurar-carta', id, restName]"  class="btn btn-sm m-2 btn-success" type="button">
                <span class="btn-wrapper--icon">
                  <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                </span>
                <span class="btn-wrapper--label ">CONFIGURAR CARTA</span>
              </button>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <div class="table-responsive">
      <table class="table table-hover text-nowrap mb-0 table-borderless">
        <thead class="bg-primary">
          <tr class="bg-primary">
            <th class="text-center bg-primary text-light">FICHAS QR</th>
            <th class="text-center bg-primary text-light"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">
              <div class=" text-black-50 d-block">Ficha QR horizontal</div>
            </td>
            <td class="text-center">
              <button (click)="getTxt(1)" class="btn btn-sm m-2 btn-success" type="button">
                <span class="btn-wrapper--icon">
                  <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                </span>
                <span *ngIf="!generatingqr" class="btn-wrapper--label ">DESCARGAR FICHA</span>
                <span *ngIf="generatingqr" class="btn-wrapper--label ">GENERANDO</span>
              </button>
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <div class=" text-black-50 d-block">Ficha QR vertical</div>
            </td>
            <td class="text-center">
              <button (click)="getTxt(2)" class="btn btn-sm m-2 btn-success" type="button">
                <span class="btn-wrapper--icon">
                  <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                </span>
                <span *ngIf="!generatingqr2" class="btn-wrapper--label ">DESCARGAR FICHA</span>
                <span *ngIf="generatingqr2" class="btn-wrapper--label ">GENERANDO</span>
              </button>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <div class="table-responsive">
      <table class="table table-hover text-nowrap mb-0 table-borderless">
        <thead class="bg-primary">
          <tr class="bg-primary">
            <th class="text-center bg-primary text-light">CSV vinos activos</th>
            <th class="text-center bg-primary text-light"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">
              <div class=" text-black-50 d-block">Ordenado por DO</div>
            </td>
            <td class="text-center">
              <button (click)="this.getWineList(2,1)" class="btn btn-sm m-2 btn-success" type="button">
                <span class="btn-wrapper--icon">
                  <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                </span>
                <span *ngIf="!generating21" class="btn-wrapper--label ">DESCARGAR ARCHIVO</span>
                <span *ngIf="generating21" class="btn-wrapper--label ">GENERANDO</span>
              </button>
            </td>

          </tr>
          <tr>
            <td class="text-center">
              <div class=" text-black-50 d-block">Ordenado por Tipo</div>
            </td>
            <td class="text-center">
              <button (click)="this.getWineList(2,2)" class="btn btn-sm m-2 btn-success" type="button">
                <span class="btn-wrapper--icon">
                  <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                </span>
                <span *ngIf="!generating22" class="btn-wrapper--label ">DESCARGAR ARCHIVO</span>
                <span *ngIf="generating22" class="btn-wrapper--label ">GENERANDO</span>
              </button>
            </td>

          </tr>

        </tbody>
      </table>
    </div>

  </div>
</div>
