import { Component, OnInit, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FirestoreProviderService } from 'src/app/shared/firestore-provider.service';

@Component({
  selector: 'app-vinos',
  templateUrl: './vinos.component.html',
  styleUrls: ['./vinos.component.scss']
})
export class VinosComponent implements OnInit {
  isCollapsed = true;
  rotate = true;
  vinos

  page: number = 1;
  vinosDisplay: any;
  query: any;
  modalRef: BsModalRef;

  file: { Nombre: any; Bodega: any; Tipo: any; Variedad: any; Contiente: any; País: any; Propio: any; Volumen: any; Añada: any; Registrado: any; }[];
  wineName: any;
  rests2: any[] = [];

  convertArrayOfObjectsToCSV(args) {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ';';
    lineDelimiter = args.lineDelimiter || '\n';

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  downloadCSV() {
    var data, filename, link;
    var csv = this.convertArrayOfObjectsToCSV({
      data: this.file
    });
    var date = new Date();
    let day = date.getDay();
    let month = date.getMonth();
    let year = date.getFullYear();
    let hour = date.getHours();
    let minutes = date.getMinutes();

    if (csv == null) return;

    filename = `Vinos ${year + '/' + month + '/' + day + '-' + hour + ':' + minutes}.csv`;
    // if (!csv.match(/^data:text\/csv/i)) {
    //   csv =  "data:text/csv;charset=utf-8,%EF%BB%BF," + csv;
    // }
    data = encodeURI(csv);

    link = document.createElement('a');
    // link.setAttribute('href', data);
    link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }


  constructor(
    private _firestore: AngularFirestore,
    private modalService: BsModalService,
    private _firestoreProvider: FirestoreProviderService
    //changeDetection: ChangeDetectionStrategy.OnPush
  ) {
  }

  ngOnInit() {

    this.getVinos();

  }

  buscador(event) {
    
    this.vinos = this.query
    const searchKeyword = event.target.value
      .toLowerCase()
      .replace(/á/, "a")
      .replace(/é/, "e")
      .replace(/í/, "i")
      .replace(/ó/, "o")
      .replace(/ú/, "u");

    let string_norm

    let busqueda = this.vinos.filter((vino) => {
      string_norm = vino.name
      // .normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return string_norm.toLowerCase()
        .replace(/á/, 'a')
        .replace(/é/, 'e')
        .replace(/í/, 'i')
        .replace(/ó/, 'o')
        .replace(/ú/, 'u')
        .includes(searchKeyword)
    })
    this.vinos = busqueda
    // this.vinos = busqueda 
  }

  wineRests = ''
  rests = []
  finished = false
  openModal(template: TemplateRef<any>, id, name) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });

    this.finished = false
    this.wineName = name
    this.rests = []
    this.rests2 = []

    this._firestoreProvider.getCollection('restaurantes').get().toPromise().then((doc) => {
      let docs = doc.docs
      docs.forEach((elementi, i) => {
        let document = elementi.data()
        let restId = document.id
        let restName = document.nombre
        this._firestoreProvider.getOneDoc(restId, 'vinos').toPromise().then((docE) => {
          let wines = docE.data().vinos
          if (wines.length > 0) {
            let filtered = wines.filter((item) => {
              return item.id == id
            })[0]
            if (filtered) {

              this.rests.push(restName)

            }
          }
          if (i == (docs.length - 1)) {
            this.rests2 = this.rests
            this.finished = true
          }
        })
      })
    })

  }
  closeModal() {
    this.modalRef.hide()
  }


  getVinos() {
    // this._firestore.collection('vinosStock', ref => ref.orderBy("name", "asc")).valueChanges().subscribe((doc) => {
    this._firestoreProvider.getCollectionAndSort('vinosStock', "name").valueChanges().subscribe((doc) => {
      this.query = []
      this.query = doc.map((element) => element)
      this.vinos = this.query
      this.file = doc.map((element: any) => ({
        Nombre: this.converter(element.name),
        Bodega: element.bodega,
        Tipo: element.color,
        Variedad: element.uva,
        Contiente: element.continente,
        País: element.pais,
        Ecologico: this.converter(element.eco),
        Propio: this.converter(element.own),
        Provincias: this.provinciasToArray(element.provincias),
        Volumen: this.converter(element.volumen),
        Añada: element.year,
        Registrado: element.creado,
      }))
    })
  }

  provinciasToArray(data) {
    if (data) {
      return data.join(', ')
    }else {
      return ''
    }
  }

  converter(string) {
    if (string == true) {
      return 'Si'
    } else if (string == false || string == undefined) {
      return 'No'
    } else if (string.includes(",")) {

      return string.replace(",", ".")
    } else {
      return string
    }
  }
}
