import { Component, ElementRef, Input, Output, OnInit, OnChanges, ViewChild, EventEmitter } from '@angular/core';
import { FirestoreProviderService } from 'src/app/shared/firestore-provider.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class SelectorComponent implements OnInit, OnChanges {

  @Input('key') keyValue: string;
  @Input('collection') collection: string;
  @Input('tag') tag: string;
  @Input('maxInput') maxInput: number = 10;
  @Input('selectedFields') selectedFields: Array<any> = [];
  @Input('isAdminOperation') isAdminOperation: boolean = false;

  @Output() addNewItem = new EventEmitter<{}>();
  @Output() removeItem = new EventEmitter<{}>();

  @ViewChild('acceptModal', { static: true }) acceptModal: ElementRef<any>;
  @ViewChild('successEditModal', { static: true }) successEditModal: ElementRef<any>;
  @ViewChild('successDeleteModal', { static: true }) successDeleteModal: ElementRef<any>;
  @ViewChild('mainInput', { static: true }) mainInput: ElementRef<HTMLInputElement>;

  public modalRef: BsModalRef;

  public data: Array<any> = []; // original data
  public displayData: Array<any> = []; // data to morph with filters.

  // TODO rework on component state
  // This is the component visual state props
  public displayDataBox: boolean = false;
  public isUserSelecting: boolean = false;
  public inputValue: string = '';
  public indexSelected: number = -1;
  public submitData: any = [];

  public indexEditing: number = -1;
  // end component visual state props

  constructor(
    private firestoreService: FirestoreProviderService,
    private modalService: BsModalService,
    private notifier: NotifierService,) { }

  ngOnInit() {
    this.loadData();
  }
  
  ngOnChanges() {
    if (this.selectedFields.length === 1) this.inputValue = this.selectedFields[0];
  }

  async loadData() {
    this.data = await this.firestoreService.getCollectionDocuments(this.collection, this.keyValue);
    this.data.push({Nombre: `Agregar Item`})
    this.displayData = this.data;
  }

  //helpers

  showNotification(type, message) {
    this.notifier.notify(type, message);
  }

  stopPropagationHelper(event) {
    event.stopPropagation();
  }

  filterDocuments(inputFilter: string, param: string, documentArray: Array<any>): Array<any> {
    return documentArray.filter(document => new RegExp(`^${inputFilter}`, 'i').test(document[param]));
  }

  isInputTextGoingToChange(): boolean {
    if (this.indexSelected > -1 && this.indexSelected < this.displayData.length) {
      return true
    };
    return false;
  }

  getNextOption(currentOptionIndex: number, up: boolean = true, displayedData: Array<any> = this.displayData): number {
    if (up && currentOptionIndex - 1 < -1) return currentOptionIndex;
    if (!up && currentOptionIndex + 1 >= displayedData.length) return currentOptionIndex;
    return up ? currentOptionIndex - 1 : currentOptionIndex + 1;
  }

  isObjectInArray(object: any, param: string, array: Array<any>) {
    for (const item of array) {
      if (item[param] === object[param]) {
        return true;
      }
    }
    return false;
  }

  emitAdd(submitData) {
    this.addNewItem.emit(submitData);
  }

  //event handlers

  async onAddNewItem(item) {
    if (this.selectedFields.length < this.maxInput) {
      if (this.displayDataBox) {
        this.displayDataBox = false;
        this.displayData = this.data;
      }
      this.submitData = ({ [this.keyValue]: item });
      if (this.isObjectInArray(this.submitData, this.keyValue, this.data)) {
        this.addNewItem.emit(this.submitData);
      } else {
        await this.openModal(this.acceptModal);
      }
    this.inputValue = '';
    } 
  }

  async onInputChange(event) {
    event.stopPropagation();
    event.preventDefault();
    if (event.target.value) {
      this.displayData = this.filterDocuments(event.target.value, this.keyValue, this.data)
      this.displayData.push(this.data[this.data.length-1]);
      if (!this.displayData.length) this.displayDataBox = false;
      else this.displayDataBox = true;
      this.inputValue = event.target.value;
    }
    if (event.target.value === '') {
      if (!this.isUserSelecting) this.displayDataBox = false;
      this.displayData = this.data;
      this.inputValue = event.target.value;
    }
  }

  onBlur() {
    setTimeout(() => {
      if (this.indexEditing === -1) {
        this.displayData = this.data;
        this.indexSelected = -1;
        this.displayDataBox = false;
      }
    }, 200)
  }

  onDataNavigation(event) {
    
  }

  onInputClick() {
    if (!this.displayDataBox) this.displayDataBox = true;
  }

  async onSelectOptionClick(i) {
    if (this.selectedFields.length < this.maxInput) {
      const itemName = i === this.displayData.length-1 ? this.inputValue : this.displayData[i].Nombre; 
      this.submitData = { [this.keyValue]: itemName };
      if (this.isObjectInArray(this.submitData, this.keyValue, this.data)) {
        this.addNewItem.emit(this.submitData);
      } else {
        await this.openModal(this.acceptModal);
      }
      this.inputValue = '';
    }
  }

  async onOptionClick(data) {
    this.removeItem.emit(data);
  }

  //actions

  setEditing(event, index) {
    this.stopPropagationHelper(event);
    this.displayDataBox;
    if (this.indexEditing !== index) {
      this.indexEditing = index;
    } else {
      this.indexEditing = -1;
      this.mainInput.nativeElement.focus();
    }
  }

  async setDeleting(event, index) {
    this.stopPropagationHelper(event);
    await this.deleteDoc(this.displayData[index]._id);
    this.displayDataBox = false;
    this.indexEditing = -1;
    this.openModal(this.successDeleteModal);
  }

  async setFavorite(event, index) {
    if (this.isAdminOperation) {
      this.stopPropagationHelper(event);
      const docId = this.displayData[index]._id;
      const favStatus = this.displayData[index].favorito;
      await this.toggleItemFavorite(docId, favStatus);
      if (favStatus) {
        this.showNotification('success', `${this.tag} dejo de ser exclusiva`)
      } else {
        this.showNotification('success', `${this.tag} marcada como exclusiva`)
      }
    }
  }

  async onEditInputEnter(event) {
    const isEnter = event.key === "Enter";
    if (isEnter) {
      const editedField = event.target.value;
      const newEditDoc = {
        docId: this.displayData[this.indexEditing]._id,
        newValue: { [this.keyValue]: editedField },
      }
      await this.editDoc(newEditDoc);
      this.displayDataBox = false;
      this.indexEditing = -1;
      this.openModal(this.successEditModal);
    }
  }

  openModal(ref: ElementRef<any>) {
    this.modalRef = this.modalService.show(ref, { class: 'modal-dialog-centered' });
  }

  async editDoc(data) {
    await this.firestoreService.updateOne(this.collection, data.docId, data.newValue);
    await this.loadData();
  }

  async deleteDoc(docId) {
    await this.firestoreService.deleteOneDoc(this.collection, docId);
    await this.loadData();
  }

  async addDoc(data) {
    if (!this.data.includes(data)) {
      await this.firestoreService.createOneDoc(this.collection, { ...data });
      await this.loadData();
    }
  }

  async toggleItemFavorite(docId, favStatus) {
    await this.firestoreService.updateOne(this.collection, docId, {favorito: !favStatus});
    await this.loadData();
  }

  async modalUploadAcceptButton() {
    this.submitData.favorite = false;
    await this.addDoc(this.submitData);
    this.addNewItem.emit(this.submitData);
    this.modalRef.hide();
  }

  modalSubmittedAcceptButton() {
    this.modalRef.hide();
  }

  modalUploadDecline() {
    this.modalRef.hide();
  }
}
