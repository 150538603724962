import { Component, OnInit, TemplateRef, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { finalize } from "rxjs/operators";

import { ViewChild, EventEmitter, Output } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { async } from 'rxjs/internal/scheduler/async';
import { FirestoreProviderService } from 'src/app/shared/firestore-provider.service';
import { NotifierService } from 'angular-notifier';
// import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-anadir-vino',
  templateUrl: './anadir-vino.component.html',
  styleUrls: ['./anadir-vino.component.scss']
})
export class AnadirVinoComponent implements OnInit {

  @ViewChild('bodega', { static: true }) descInput: ElementRef;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  isSubmitted: boolean;
  modalRef: BsModalRef;
  image
  showCropper = false;
  message////////////////

  showAddBodega = false;
  showDeleteBodega = false

  showAddDo = false;
  showDeleteDo = false

  bodegas: any;
  dos: any;

  variedades: any = [];

  nuevoVino = new FormGroup({
    nombre: new FormControl('', Validators.required),
    bodega: new FormControl('', Validators.required),
    do: new FormControl('', Validators.required),
    anada: new FormControl('Sin añada'),
    volumen: new FormControl('750 ml', Validators.required),
    tipo: new FormControl('', Validators.required),
    continente: new FormControl('Europa', Validators.required),
    pais: new FormControl('España', Validators.required), 
    //uva: new FormControl('', Validators.required),
    desc: new FormControl(''),
    image: new FormControl('', Validators.required)
  })

  nuevaBodega = new FormGroup({
    nombre: new FormControl('', Validators.required)
  })

  nuevoDo = new FormGroup({
    nombre: new FormControl('', Validators.required)
  })

  nuevoVolumen = new FormGroup({
    nombre: new FormControl('', Validators.required)
  })

  nuevaUva = new FormGroup({
    nombre: new FormControl('', Validators.required)
  })

  nuevoPais = new FormGroup({
    nombre: new FormControl('', Validators.required)
  })


  showDeleteVolumen: boolean;
  showAddVolumen: boolean;
  volumenes: any;
  showEditDo: boolean;
  itemToEdit: any;
  itemToEditId: any;
  showEditBodega: boolean;
  showEditVolumen: boolean;
  showDeleteUva: boolean;
  showAddUva: boolean;
  showEditUva: boolean;
  uvas: any;
  showDeletePais: boolean;
  showAddPais: boolean;
  showEditPais: boolean;
  paises: any;
  ownCheck: any;
  ecoCheck:any

  public provinciaSelectorKey = "Nombre";
  public showProvinciaExclusiva: boolean = false;
  public selectedProvincias: Array<string> = [];
  public loading: boolean = false;

  constructor(
    private modalService: BsModalService,
    private _storage: AngularFireStorage,
    private _firestore: AngularFirestore,
    private _firestoreProvider: FirestoreProviderService,
    private _router: Router,
    private _route: ActivatedRoute,
    private notification: NotifierService
    //private _toastr: ToastrService
  ) { }


  ngOnInit() {
    this.getBodegas();
    this.getDos();
    this.getVolumen();
    this.getUvas();
    this.getUvasPromise()
    this.getPais();
  }

  openModal(template) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }

  openEditModal(template, edit, id) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
    this.itemToEdit = edit
    this.itemToEditId = id
  }

  closeModal() {
    this.modalRef.hide()
  }

  checkType(element) {

    if (typeof (element) == 'object') {
      return true
    } else {
      return false
    }
  }

  saveVariedades() {
    let table = Array.from(document.querySelectorAll(".variedad"))
    // let tableChild = table.map((element: any) => element.value)

    table.forEach((element: any) => {
      if (element.checked) {
        this.variedades.push(element.value)
      }
    });
    if (this.variedades.length > 0) {
      if (this.variedades.length == 1) {
        this.variedades = this.variedades[0]
      }
    } else {
      this.variedades = ''
    }

    this.closeModal()

  }

  availableList = []
  selectedList = []
  
  openVariedadModal(template: TemplateRef<any>, available, selected) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
    this.availableList = available
  }
  uvaspromise
  getUvasPromise() {
    this.uvaspromise = []
    this._firestoreProvider.getCollectionAndSort('uvas', 'name').get().toPromise().then((doc) => {
      doc.forEach((element) => {
        this.uvaspromise.push(element.data().name)
      })
    })
  }

//////////////////////////////////////////////////////////////////////////////////////////

addPais(): void {
  this.showDeletePais = false
  this.showAddPais = true
  this.showEditPais = false
}
editPais(): void {
  this.showEditPais = true
  this.showDeletePais = false
  this.showAddPais = false
}
deletePais(): void {
  this.showDeletePais = true
  this.showAddPais = false
  this.showEditPais = false
}
getPais() {
  this.paises = this._firestoreProvider.getCollectionAndSort('paises', 'name').valueChanges();
}
onPaisSubmit(formValue: { nombre: any; }) {
  if (this.nuevoPais.valid) {
    let id = this._firestore.createId();
    this._firestoreProvider.getCollection('paises').doc(id).set({
      name: formValue.nombre,
      id: id
    }).then(() => {
      this.nuevoVino.patchValue({ pais: formValue.nombre });
      this.closeModal();
      this.nuevoPais.setValue({ nombre: '' })
    });
  }
}
onPaisDelete(id) {
  this._firestoreProvider.getCollection('paises').doc(id).delete(); 
}

queryWinesByPais(item) {
  let newPais: any
  newPais = document.getElementById('paisToEdit')

  this._firestoreProvider.getCollection('paises').doc(this.itemToEditId).update({ name: newPais.value }).then(() => {

    this._firestoreProvider.getCollectionWhere('vinosStock', `paises == ${item}`).get().toPromise().then((doc) => {
      doc.forEach(element => {
        let id = element.data().id
        this._firestoreProvider.getCollection('vinosStock').doc(id).update({ pais: newPais.value })
      });
    })
  }).then(()=>{
    this.closeModal();
  })

}


//////////////////////////////////////////////////////////////////////////////////////////

  addVolumen(): void {
    this.showDeleteVolumen = false
    this.showAddVolumen = true
    this.showEditVolumen = false
  }
  editVolumen(): void {
    this.showEditVolumen = true
    this.showDeleteVolumen = false
    this.showAddVolumen = false
  }
  deleteVolumen(): void {
    this.showDeleteVolumen = true
    this.showAddVolumen = false
    this.showEditVolumen = false
  }

  getVolumen() {
    this.volumenes = this._firestoreProvider.getCollectionAndSort('volumenes', 'name').valueChanges();
  }
  onVolumenSubmit(formValue: { nombre: any; }) {
    if (this.nuevoVolumen.valid) {
      let id = this._firestore.createId();
      this._firestoreProvider.getCollection('volumenes').doc(id).set({
        name: formValue.nombre,
        id: id
      }).then(() => {
        this.nuevoVino.patchValue({ volumen: formValue.nombre });
        this.closeModal();
        this.nuevoVolumen.setValue({ nombre: '' })
      });
    }
  }
  onVolumenDelete(id) {
    this._firestoreProvider.getCollection('volumenes').doc(id).delete();
  }
  
  queryWinesByVolumen(item) {
    let newVolumen: any
    newVolumen = document.getElementById('volumenToEdit')

    this._firestoreProvider.getCollection('volumenes').doc(this.itemToEditId).update({ name: newVolumen.value }).then(() => {

      this._firestoreProvider.getCollectionWhere('vinosStock', `volumenes == ${item}`).get().toPromise().then((doc) => {
        doc.forEach(element => {
          let id = element.data().id
          this._firestoreProvider.getCollection('vinosStock').doc(id).update({ volumen: newVolumen.value })
        });
      })
    }).then(()=>{
      this.closeModal();
    })

  }

  ////////////////////////////////////////////////////////////////////////////////////////

  
  addUva(): void {
    this.showDeleteUva = false
    this.showAddUva = true
    this.showEditUva = false
  }
  
  editUva(): void {
    this.showEditUva = true
    this.showDeleteUva = false
    this.showAddUva = false
  }
  deleteUva(): void {
    this.showAddUva = false
    this.showDeleteUva = true
    this.showEditUva = false
  }

  
  onUvaSubmit(formValue: { nombre: any; }) {
    if (this.nuevaUva.valid) {
      let id = this._firestore.createId();
      this._firestoreProvider.getCollection('uvas').doc(id).set({
        name: formValue.nombre,
        id: id
      }).then(() => {
        this.nuevoVino.patchValue({ uva: formValue.nombre });
        this.closeModal();
        this.nuevaUva.setValue({ nombre: '' })
        this.getUvasPromise() 
      });
    }
  }

  onUvaDelete(id) {
    this._firestoreProvider.getCollection('uvas').doc(id).delete();
  }

  
  getUvas() {
    this.uvas = this._firestoreProvider.getCollectionAndSort('uvas', 'name').valueChanges();
  }


  queryWinesByUva(item) {
    let newUva: any
    newUva = document.getElementById('uvaToEdit')

    this._firestoreProvider.getCollection('uvas').doc(this.itemToEditId).update({ name: newUva.value }).then(() => {

      this._firestoreProvider.getCollectionWhere('vinosStock', `uva == ${item}`).get().toPromise().then((doc) => {
        doc.forEach(element => {
          let id = element.data().id
          this._firestoreProvider.getCollection('vinosStock').doc(id).update({ uva: newUva.value })
        });
      })
    }).then(()=>{
      this.closeModal();
    })

  }

  ////////////////////////////////////////////////////////////////////////////////////////

  addBodega(): void {
    this.showDeleteBodega = false
    this.showAddBodega = true
    this.showEditBodega = false
  }
  
  editBodega(): void {
    this.showEditBodega = true
    this.showDeleteBodega = false
    this.showAddBodega = false
  }
  deleteBodega(): void {
    this.showAddBodega = false
    this.showDeleteBodega = true
    this.showEditBodega = false
  }
  getBodegas() {
    this.bodegas = this._firestoreProvider.getCollectionAndSort('bodegas', 'name').valueChanges();
  }
  onBodegaSubmit(formValue) {
    if (this.nuevaBodega.valid) {
      let id = this._firestore.createId();
      this._firestoreProvider.getCollection('bodegas').doc(id).set({
        name: formValue.nombre,
        id: id
      }).then(() => {
        this.nuevoVino.patchValue({ bodega: formValue.nombre });
        this.closeModal();
        this.nuevaBodega.setValue({ nombre: '' })
      });
    }
  }
  onBodegaDelete(id) {
    this._firestoreProvider.getCollection('bodegas').doc(id).delete();
  }

  

  queryWinesByBodega(item) {
    let newBodega: any
    newBodega = document.getElementById('bodegaToEdit')

    this._firestoreProvider.getCollection('bodegas').doc(this.itemToEditId).update({ name: newBodega.value }).then(() => {

      this._firestoreProvider.getCollectionWhere('vinosStock', `bodega == ${item}`).get().toPromise().then((doc) => {
        doc.forEach(element => {
          let id = element.data().id
          this._firestoreProvider.getCollection('vinosStock').doc(id).update({ bodega: newBodega.value })
        });
      })
    }).then(()=>{
      this.closeModal();
    })
  }

  //////////////////////////////////////////////////////////////////////////////////////////

  addDo(): void {
    this.showAddDo = true
    this.showDeleteDo = false
    this.showEditDo = false
  }
  editDo(): void {
    this.showEditDo = true
    this.showAddDo = false
    this.showDeleteDo = false
  }
  deleteDo(): void {
    this.showDeleteDo = true
    this.showAddDo = false
    this.showEditDo = false
  }
  getDos() {
    this.dos = this._firestoreProvider.getCollectionAndSort('DOs', 'name').valueChanges();
  }
  onDoSubmit(formValue) {
    if (this.nuevoDo.valid) {
      let id = this._firestore.createId();
      this._firestoreProvider.getCollection('DOs').doc(id).set({
        name: formValue.nombre,
        id: id
      }).then(() => {
        this.nuevoVino.patchValue({ do: formValue.nombre });
        this.closeModal();
        this.nuevoDo.setValue({ nombre: '' })
      });
    }
  }
  onDoDelete(id) {
    this._firestoreProvider.getCollection('DOs').doc(id).delete();
  }



  queryWinesByDO(item) {
    let newDo: any
    newDo = document.getElementById('doToEdit')

    this._firestoreProvider.getCollection('DOs').doc(this.itemToEditId).update({ name: newDo.value }).then(() => {

      this._firestoreProvider.getCollectionWhere('vinosStock', `DO == ${item}`).get().toPromise().then((doc) => {
        doc.forEach(element => {
          let id = element.data().id
          this._firestoreProvider.getCollection('vinosStock').doc(id).update({ DO: newDo.value })
        });
      })
    }).then(()=>{
      this.closeModal();
    })

  }

  getDate() {

    var today = new Date();
    var dd = today.getDate()
    var mm = today.getMonth() + 1
    var yyyy = today.getFullYear()

    let date: any = {}

    date['day'] = dd
    date['month'] = mm
    date['year'] = yyyy

    return date
  }

  formatMonth(month) {
    return month < 10 ? '0'+month : month.toString();
  }

  async addWineCount(){
    const {year, month, day} = this.getDate();
    const formattedMonth = this.formatMonth(month);
    const wineAmmount = await this._firestoreProvider.getCollection('stats').doc('wineAmmount').get().toPromise();
    const wineAmmountX = await this._firestoreProvider.getCollection('vinosStock').get().toPromise();
    const wineObj = wineAmmount.data();
    if (!wineObj[year]) {
      wineObj[year] = {};
      if (!wineObj[year][formattedMonth]) {
        wineObj[year][formattedMonth] = 0
      }
    }
    const previousAmmount = wineObj[year][formattedMonth] ? wineObj[year][formattedMonth] : wineObj[year][this.formatMonth(month-1)]
    wineObj[year][formattedMonth] = wineAmmountX.docs.length;
    await this._firestoreProvider.getCollection('stats').doc('wineAmmount').set({...wineObj},{merge: true});
  }

  //////////////////////////////////////////////////////////////////////////////////////////////

  onSubmit(formValue) {
    this.isSubmitted = true
      
    if (this.nuevoVino.valid) {
      this.loading = true;
      this.ownCheck = document.getElementById('ownCheck');

      this.ecoCheck = document.getElementById('ecoWine');
      const uvaVariedad = this.variedades || '--';
      let id = this._firestore.createId();
      let filePath = `vinosStock/${id}/${formValue.nombre}.jpg`;
      let fileRef = this._storage.ref(filePath);
      this.image = this.croppedImage.replace('data:image/png;base64,', '');
      let progress = 100
      let inactivo: boolean = false
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0');
      let yyyy = today.getFullYear();
      let todayDate = dd + '/' + mm + '/' + yyyy;

      if (formValue.desc == '') {
        progress = progress - 12.5
      }
      if (formValue.anada == '') {
        progress = progress - 12.5
      }

      this._storage.ref(filePath).putString(this.image, "base64", { contentType: "image/jpg", cacheControl: 'private, max-age=86400' }).snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((url) => {
            this._firestoreProvider.getCollection('vinosStock').doc(id).set({
              name: formValue.nombre,
              DO: formValue.do,
              bodega: formValue.bodega,
              color: formValue.tipo,
              creado: todayDate,
              volumen: formValue.volumen,
              desc: formValue.desc,
              uva: uvaVariedad,
              pais:formValue.pais,
              continente:formValue.continente,
              own:this.ownCheck.checked,
              provincias:this.selectedProvincias,
              id: id,
              img: url,
              year: formValue.anada,
              info: progress,
              active: inactivo,
              eco: this.ecoCheck.checked
            }).then(() => {
              this.addWineCount();
              this.loading = false;
              this._router.navigateByUrl('/vinos');
            })
          })
        })
      ).subscribe();
    } else {
      this.notification.notify('danger', 'Falto rellenar un campo')
    }
  }

  get formControls() {
    return this.nuevoVino['controls'];
  }

  resetForm() {
    this.nuevoVino.reset();
    this.nuevoVino.setValue({
      nombre: '',
      bodega: '',
      do: '',
      anada: '',
      volumen: '',
      tipo: '',
      desc: '',
      image: '',
    })
    //this.imgSrc = "/assets/images/sample-1.jpg";
    this.isSubmitted = false;
    this.fileChangeEvent(Event);
    this.showCropper = false;
  }


  ////////////////////////////////////////////////////////////////////////////////////////////

  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  rotateLeft() {
    this.imageCropper.rotateLeft();
  }

  rotateRight() {
    this.imageCropper.rotateRight();
  }

  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }

  flipVertical() {
    this.imageCropper.flipVertical();
  }

  toggleOwn(event) {
    this.showProvinciaExclusiva = event.target.checked;
  }
  
  agregarProvinciaAVino(event) {
    if(!this.selectedProvincias.includes(event.Nombre)) {
      this.selectedProvincias.push(event.Nombre);
    }
  }

  eliminarProvinciaDeVino(provincaToDelete) {
    const newProvincias = this.selectedProvincias.filter(prov => provincaToDelete !== prov);
    this.selectedProvincias = newProvincias;
  }
}