import { Injectable } from '@angular/core';

interface MenuItem {
    title: string;
    type: string;
    badge?: {
        class: string;
        text: string;
    };
    link?: string;
    active?: boolean;
    icon?: string;
    submenus?: MenuItem[];
}

@Injectable({
    providedIn: 'root'
})
export class SidebarMenuService {

    adminMenus: MenuItem[] = [
      {
        "title": "",
        "type": "header"
      },
      {
        "title": "Dashboard",
            "type": "simple", 
            "link": "/dashboard",
        "icon": "<i class=\"pe-7s-safe\"></i>",
      },
      {
        "title": "Restaurantes",
            "type": "simple", 
            "link": "/restaurantes",
        "icon": "<i class=\"pe-7s-network\"></i>",
      },
      {
        "title": "Vinos",
            "type": "simple", 
            "link": "/vinos",
        "icon": "<i class=\"pe-7s-portfolio\"></i>",
      },
      {
        "title": "Usuarios",
            "type": "simple", 
            "link": "/usuarios",
        "icon": "<i class=\"pe-7s-box2\"></i>",
      },
      {
        "title": "Comerciales",
            "type": "simple", 
            "link": "/comerciales",
        "icon": "<i class=\"pe-7s-box2\"></i>",
      },
      {
        "title": "CCO",
            "type": "simple", 
            "link": "/directores",
        "icon": "<i class=\"pe-7s-box2\"></i>",
      },
      {
        "title": "Distribuidores",
            "type": "simple", 
            "link": "/distribuidores",
        "icon": "<i class=\"pe-7s-users\"></i>",
      },
    ];

    distAdminMenus: MenuItem[] = [
      {
        "title": "",
        "type": "header"
      },
      {
        "title": "Dashboard",
            "type": "simple", 
            "link": "/dashboard",
        "icon": "<i class=\"pe-7s-safe\"></i>",
      },
      {
        "title": "Restaurantes",
            "type": "simple", 
            "link": "/restaurantes",
        "icon": "<i class=\"pe-7s-network\"></i>",
      },
      {
        "title": "Vinos",
            "type": "simple", 
            "link": "/vinos",
        "icon": "<i class=\"pe-7s-portfolio\"></i>",
      },
      {
        "title": "Usuarios",
            "type": "simple", 
            "link": "/usuarios",
        "icon": "<i class=\"pe-7s-box2\"></i>",
      },
      {
        "title": "Comerciales",
            "type": "simple", 
            "link": "/comerciales",
        "icon": "<i class=\"pe-7s-box2\"></i>",
      },
      {
        "title": "CCO",
            "type": "simple", 
            "link": "/directores",
        "icon": "<i class=\"pe-7s-box2\"></i>",
      },
    ]

comercialMenus: MenuItem[] = [
    {
      "title": "",
      "type": "header"
    },
    {
      "title": "Dashboard",
          "type": "simple", 
          "link": "/dashboard",
      "icon": "<i class=\"pe-7s-safe\"></i>",
    },
    {
      "title": "Restaurantes",
          "type": "simple", 
          "link": "/restaurantes",
      "icon": "<i class=\"pe-7s-network\"></i>",
    }
  ];
  
editorMenus: MenuItem[] = [
    {
        "title": "",
        "type": "header"
      },
      {
        "title": "Dashboard",
            "type": "simple", 
            "link": "/dashboard",
        "icon": "<i class=\"pe-7s-safe\"></i>",
      },
      {
        "title": "Restaurantes",
            "type": "simple", 
            "link": "/restaurantes",
        "icon": "<i class=\"pe-7s-network\"></i>",
      },
      {
        "title": "Vinos",
            "type": "simple", 
            "link": "/vinos",
        "icon": "<i class=\"pe-7s-portfolio\"></i>",
      },
      {
        "title": "Comerciales",
            "type": "simple", 
            "link": "/comerciales",
        "icon": "<i class=\"pe-7s-box2\"></i>",
      },
      {
        "title": "CCO",
            "type": "simple", 
            "link": "/directores",
        "icon": "<i class=\"pe-7s-box2\"></i>",
      },
  ];

    constructor() { }

    getMenuList() {
        let allMenus = {
            adminMenus: this.adminMenus,
            editorMenus: this.editorMenus,
            comercialMenus: this.comercialMenus,
            distAdminMenus: this.distAdminMenus
        }
        return allMenus;
    }

    getMenuItemByUrl(aMenus: MenuItem[], aUrl: string): MenuItem {
        for (const theMenu of aMenus) {
            if (theMenu.link && theMenu.link === aUrl) {
                return theMenu;
            }

            if (theMenu.submenus && theMenu.submenus.length > 0) {
                const foundItem = this.getMenuItemByUrl(theMenu.submenus, aUrl);
                if (foundItem) {
                    return foundItem;
                }
            }
        }

        return undefined;
    }

    toggleMenuItem(aMenus: MenuItem[], aCurrentMenu: MenuItem): MenuItem[] {
        return aMenus.map((aMenu: MenuItem) => {
            if (aMenu === aCurrentMenu) {
                aMenu.active = !aMenu.active;
            } else {
                aMenu.active = false;
            }

            if (aMenu.submenus && aMenu.submenus.length > 0) {
                aMenu.submenus = this.toggleMenuItem(aMenu.submenus, aCurrentMenu);

                if (aMenu.submenus.find(aChild => aChild.active)) {
                    aMenu.active = true;
                }
            }

            return aMenu;
        });
    }
}
