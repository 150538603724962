import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AngularFireFunctions } from "@angular/fire/functions";
import { Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { FirestoreProviderService } from "src/app/shared/firestore-provider.service";

@Component({
  selector: "app-anadir-usuario",
  templateUrl: "./anadir-usuario.component.html",
  styleUrls: ["./anadir-usuario.component.scss"]
})
export class AnadirUsuarioComponent implements OnInit {
  isSubmitted: boolean;

  nuevoUsuario = new FormGroup({
    nombre: new FormControl("", Validators.required),
    email: new FormControl(
      "",
      Validators.compose([Validators.email, Validators.required])
    ),
    contrasena: new FormControl(
      "",
      Validators.compose([Validators.minLength(6), Validators.required])
    )
  });
  loading: boolean;

  constructor(
    private _firestore: AngularFirestore,
    private _functions: AngularFireFunctions,
    private _firestoreProvider: FirestoreProviderService,
    private _router: Router,
    private notifier: NotifierService
  ) {}

  ngOnInit() {}

  generatePassword() {
    let generated = this._firestore.createId();
    let password = generated.substring(0, 7);
    this.nuevoUsuario.patchValue({ contrasena: password });
  }

  get formControls() {
    return this.nuevoUsuario["controls"];
  }

  showNotification(type, message) {
    this.notifier.notify(type, message);
  }

  onSubmit(formValue) {
    this.isSubmitted = true;
    let createAccount = this._functions.httpsCallable("createUserAccount");

    if (this.nuevoUsuario.valid) {
      this.loading = true;
      createAccount({
        userDetails: {
          email: formValue.email,
          password: formValue.contrasena,
          name: formValue.nombre
        },
        userClaim: this._firestoreProvider.isExtDist() ? "editorExt" : "editor"
      })
        .toPromise()
        .then(response => {
          if (response.errorInfo) {
            let msg = response.errorInfo.message;
            if (response.errorInfo.code === "auth/email-already-exists") {
              msg = "Error, email ya esta en uso por otra cuenta";
            }
            this.loading = false;
            this.showNotification("warning", `${msg}`);
            this._router.navigateByUrl("/usuarios");
          } else {
            this.loading = false;
            this._router.navigateByUrl("/usuarios");
            this.showNotification(
              "success",
              `El usuario ${formValue.email} fue creado exitosamente.`
            );
          }
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
          this._router.navigateByUrl("/usuarios");
          this.showNotification("danger", `${err}`);
        });
    }
  }
}
