import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';
import { FirestoreProviderService } from 'src/app/shared/firestore-provider.service';

@Component({
  selector: 'app-asignar-comerciales',
  templateUrl: './asignar-comerciales.component.html',
  styleUrls: ['./asignar-comerciales.component.scss']
})
export class AsignarComercialesComponent implements OnInit {


  id: string;
  directorToEdit = {
    nombre: ''
  };
  subs = new Subscription();
  public loading: boolean = false;
  constructor(
    private _router: Router,
    private _firestore: AngularFirestore,
    private _firestoreProvider: FirestoreProviderService,
    private _route: ActivatedRoute,
    private notify: NotifierService) { }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.subs.add(
      this._route.paramMap.subscribe(params => {
        this.id = params.get('id')
      })
    )
    this.getDirector();
    this.getVinos();
  }


  // coleccionVinos = []
  source = []
  target = []

  display = 'nombre'
  key = 'id'
  format = { add: 'Añadir', remove: 'Remover', all: 'Todos', none: 'Ninguno' };

  getDirector() {
    this._firestoreProvider.getCollection('directores').doc(this.id).get().toPromise().then((director: any) => {

      this.directorToEdit = {
        nombre: director.data().nombre,
      }
    })
  }

  getVinosRest() {
    this._firestoreProvider.getCollection('directores').doc(this.id).get().toPromise().then((doc: any) => {

      this.target = doc.data().comerciales ? doc.data().comerciales : [];

    })
  }

  getVinos() {
    this._firestoreProvider.getCollection('comerciales').get().toPromise().then((doc) => {

      let comercialesQuery = doc.docs.map((comerciales) => comerciales.data())
      comercialesQuery.forEach(ele => {

        let comerciales = {
          nombre: ele.nombre,
          id: ele.id,
          email: ele.email
        }
        this.source.push(comerciales)
      });
      this.getVinosRest();

    })
  }

  updateVinos() {
    this.loading = true;
    this._firestoreProvider.getCollection('directores').doc(this.id).set({ comerciales: this.target }, { merge: true }).then(() => {
      this._firestoreProvider.getCollection('directoresIndex').doc(this.id).set({ comerciales: this.target }, { merge: true }).then(() => {
        this.loading = false;
        this._router.navigateByUrl('/directores');
      })
    }).then(()=>{
      this.notify.notify('success', 'Comerciales actualizados')
      this._router.navigateByUrl('/directores');
    })
    .catch((err)=>{
      this.notify.notify('danger', 'Hubo un problema')
    })
  }

  showResult() {
    this.updateVinos()
  }

}
