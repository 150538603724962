<perfect-scrollbar [autoPropagation]="true">
  <app-sidebar-userbox *ngIf="globals.sidebarUserbox"></app-sidebar-userbox>
  <div class="sidebar-navigation">
    <ul>
      <li *ngFor="let menu of menus"
        [ngClass]="{'active': menu.active, 'sidebar-dropdown':menu.type === 'dropdown' , 'sidebar-header':menu.type === 'header'}">
        <span *ngIf="menu.type === 'header'">{{menu.title}}</span>
        <a *ngIf="menu.type === 'dropdown'" [routerLink]="" (click)='toggle(menu)'>
          <span>
            <span class="sidebar-icon-wrapper" [innerHTML]="menu.icon"></span>
            <span>{{menu.title}}</span>
          </span>
          <span>
            <span *ngIf="menu.badge" class="badge badge-pill" [ngClass]="menu.badge.class">{{menu.badge.text}}</span>
            <span class="sidebar-icon-indicator">
              <fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
            </span>
          </span>
        </a>
        <a *ngIf="menu.type === 'simple'" routerLink="{{menu.link}}" (click)="toggle(menu); toggleSidebarMobileOpen()"
          routerLinkActive="active">
          <span>
            <span class="sidebar-icon-wrapper" [innerHTML]="menu.icon"></span>
            <span>{{menu.title}}</span>
            <span *ngIf="menu.badge" class="badge badge-pill" [ngClass]="menu.badge.class">{{menu.badge.text}}</span>
          </span>
        </a>
        <div *ngIf="menu.type === 'dropdown'" class="sidebar-submenu" [@slide]="getState(menu)">
          <ul>
            <li *ngFor="let submenu of menu.submenus">
              <a routerLink="{{submenu.link}}" (click)="toggleSidebarMobileOpen()" routerLinkActive="active">
                <span>
                  {{submenu.title}}
                  <span *ngIf="submenu.badge" class="badge badge-pill"
                    [ngClass]="submenu.badge.class">{{submenu.badge.text}}</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</perfect-scrollbar>
