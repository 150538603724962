import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PdfFontsService } from "src/app/shared/pdf-fonts.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { toPng } from "html-to-image";
import * as jsPDF from "jspdf";
import download from "downloadjs";
import { TimepickerStore } from "ngx-bootstrap/timepicker";
import { AuthService } from "src/app/shared/auth.service";
import { FirestoreProviderService } from "src/app/shared/firestore-provider.service";

@Component({
  selector: "app-configurar-carta",
  templateUrl: "./configurar-carta.component.html",
  styleUrls: ["./configurar-carta.component.scss"]
})
export class ConfigurarCartaComponent implements OnInit {
  admin;
  editor;
  comercial;
  director;
  ComercialId;
  restName;
  constructor(
    private _firestore: AngularFirestore,
    private _firestoreProvider: FirestoreProviderService,
    private _route: ActivatedRoute,
    private _router: Router,
    private pdfFonts: PdfFontsService,
    private _auth: AuthService
  ) {
    this.subs.add(
      this._route.paramMap.subscribe(params => {
        this.id = params.get("id");
        this.restName = params.get("name");
      })
    );
    this.qrcode = `https://app.dvinum.es/id/${this.id}`;

    this.admin = false;
    this.editor = false;
    this.comercial = false;
    this.director = false;
    this._auth
      .currentUser()
      .getIdTokenResult(true)
      .then(result => {
        this.ComercialId = result.claims.user_id;

        if (result.claims.admin) {
          this.admin = result.claims.admin;
        }
        if (result.claims.editor) {
          this.editor = result.claims.editor;
        }
        if (result.claims.comercial) {
          this.comercial = result.claims.comercial;
        }
        if (result.claims.director) {
          this.director = result.claims.director;
        }

        if (this.admin) {
          // this.getRestaurantes();
          this.getRestaurante();
        }
        if (this.comercial) {
          this.getRestauranteComercial();
          // this.id = result.claims.user_id
        }
        if (this.director) {
          this.getRestDirector();
        }
      });
  }

  qrcode: string;
  ngOnInit() {
    this.getLangs();
    // this.moveQR()
    // this.getWineList(true)
  }
  moveQR() {
    let element = document.getElementById("qrcode");

    element.style.position = "absolute";
    element.style.right = "1000px";
  }

  loading = false;
  subs = new Subscription();
  id: string;

  winesArray = [];

  carta = new FormGroup({
    language: new FormControl("espanol"),
    type: new FormControl("1"),
    size: new FormControl("1"),
    font: new FormControl("1"),
    pagination: new FormControl("1"),
    spacing: new FormControl("1"),
    cover: new FormControl("1"),
    coverColor: new FormControl("1"),
    branding: new FormControl("1"),
    restName: new FormControl("1"),
    qrCode: new FormControl("1"),
    varietyColumn: new FormControl("1"),
    formatColumn: new FormControl("1"),
    alergenColumn: new FormControl("1")
  });
  restauranteToEdit = {
    nombre: ""
  };

  onSubmit(value) {
    this.getWineList(value);
    this.loading = true;
  }

  getRestaurante() {
    this._firestoreProvider
      .getCollection("restaurantes")
      .doc(this.id)
      .get()
      .toPromise()
      .then((restaurante: any) => {
        this.restauranteToEdit = {
          nombre: restaurante.data().nombre
        };
      });
  }
  getRestauranteComercial() {
    this._firestoreProvider
      .getCollection("comerciales")
      .doc(this.ComercialId)
      .get()
      .toPromise()
      .then((rests: any) => {
        let restComercial = rests.data().restaurantes.filter(rest => {
          return rest.id == this.id;
        });

        this.restauranteToEdit = restComercial[0];
      });
  }

  getRestDirector() {
    this.restauranteToEdit.nombre = this.restName;
  }
  langs = [];
  getLangs() {
    this._firestoreProvider
      .getCollection(this.id)
      .doc("configs")
      .get()
      .toPromise()
      .then((docConfig: any) => {
        if (docConfig.data().langEnglish) {
          this.langs.push("Inglés");
        }
        if (docConfig.data().langFrench) {
          this.langs.push("Francés");
        }
        if (docConfig.data().langPortugesse) {
          this.langs.push("Portugués");
        }
        if (docConfig.data().langGalician) {
          this.langs.push("Galego");
        }
      });
  }

  getWineList(value) {
    this.winesArray = [];

    this._firestoreProvider
      .getCollection(this.id)
      .doc("vinos")
      .get()
      .toPromise()
      .then((doc: any) => {
        let wines = doc.data().vinos.map(wine => wine.id);

        this._firestoreProvider
          .getCollection(this.id)
          .get()
          .toPromise()
          .then((restWines: any) => {
            let restWineData = [];
            restWines.docs.forEach(element => {
              restWineData.push(element.data());
            });
            let filteredWineData = [];

            wines.forEach(element => {
              let item = restWineData.filter(wineData => {
                return wineData.id == element;
              });
              filteredWineData.push(item[0]);
            });

            this._firestoreProvider
              .getCollection("vinosStock")
              .get()
              .toPromise()
              .then(doc2 => {
                let wineStock = [];
                doc2.docs.forEach(element => {
                  wineStock.push(element.data());
                });
                let wineRest = [];
                wines.forEach(element => {
                  let wine = wineStock.filter(currentwineRest => {
                    return currentwineRest.id == element;
                  });
                  wineRest.push(wine[0]);
                });

                let doSorted = wineRest.sort((a, b) =>
                  a.DO.localeCompare(b.DO)
                );

                //////////////////////////////////////////////////////////////////////

                //////////////////////////////////////////////////////////////////

                let types = [
                  "Espumoso",
                  "Blanco",
                  "Rosado",
                  "Tinto",
                  "Generoso",
                  "Vermouth"
                ];
                let type;

                types.forEach((element, i) => {
                  switch (element) {
                    case "Tinto":
                      type = "Vinos Tintos";
                      break;
                    case "Blanco":
                      type = "Vinos Blancos";
                      break;
                    case "Rosado":
                      type = "Vinos Rosados";
                      break;
                    case "Espumoso":
                      type = "Vinos Espumosos";
                      break;
                    case "Generoso":
                      type = "Vinos Generosos";
                      break;
                    case "Vermouth":
                      type = "Vinos Vermouth";
                      break;
                    default:
                      break;
                  }

                  let typecheck = doSorted.filter(wineData => {
                    return wineData.color == element;
                  });
                  if (typecheck[0]) {
                    let lastDO = null;
                    let DOIndex = 0;
                    let typeObj;
                    typeObj = {};
                    typeObj.nombre = type;
                    typeObj.dos = [];

                    let typeSorted = doSorted.filter(wineData => {
                      return wineData.color == element;
                    });

                    typeSorted.forEach(element => {
                      let currentWineData: any = filteredWineData.filter(
                        wine => {
                          if (wine) {
                            return wine.id == element.id;
                          } else {
                            return undefined;
                          }
                        }
                      );
                      if (currentWineData[0] && currentWineData[0].active) {
                        if (currentWineData[0]) {
                          if (currentWineData[0].precioBotella == 0) {
                            element.price = "";
                          } else {
                            if (currentWineData[0].precioBotella > 100) {
                              element.price = currentWineData[0].precioBotella.toFixed(
                                1
                              );
                            } else {
                              element.price = currentWineData[0].precioBotella.toFixed(
                                2
                              );
                            }
                          }
                        } else {
                          element.price = "";
                        }
                        element.active =
                          typeof currentWineData[0] === "undefined"
                            ? false
                            : currentWineData[0].active;

                        let currentDO = element.DO;

                        if (lastDO == null) {
                          typeObj.dos[DOIndex] = {};
                          typeObj.dos[DOIndex].nombre = currentDO;
                          typeObj.dos[DOIndex].vinos = [];
                          typeObj.dos[DOIndex].vinos.push(element);
                          //DOIndex++
                          lastDO = currentDO;
                        } else if (currentDO == lastDO) {
                          typeObj.dos[DOIndex].vinos.push(element);
                          lastDO = currentDO;
                        } else {
                          DOIndex++;
                          typeObj.dos[DOIndex] = {};
                          typeObj.dos[DOIndex].nombre = currentDO;
                          typeObj.dos[DOIndex].vinos = [];
                          typeObj.dos[DOIndex].vinos.push(element);
                          lastDO = currentDO;
                        }
                      }
                    });

                    this.winesArray.push(typeObj);
                  }
                });

                const typeOrder = [
                  "Vinos Blancos",
                  "Vinos Rosados",
                  "Vinos Espumosos",
                  "Vinos Tintos",
                  "Vinos Generosos",
                  "Vinos Vermouth"
                ];
                this.winesArray.sort(
                  (typeA, typeB) =>
                    typeOrder.indexOf(typeA.nombre) -
                    typeOrder.indexOf(typeB.nombre)
                );

                this._firestoreProvider
                  .getCollection(this.id)
                  .doc("configs")
                  .get()
                  .toPromise()
                  .then((docConfig: any) => {
                    this._firestoreProvider
                      .getCollection(this.id)
                      .doc("configs")
                      .collection("order")
                      .doc("cartas")
                      .get()
                      .toPromise()
                      .then((docCartas: any) => {
                        this._firestoreProvider
                          .getCollection(this.id)
                          .doc("configs")
                          .collection("carta")
                          .get()
                          .toPromise()
                          .then((itemDocCarta: any) => {
                            this._firestoreProvider
                              .getCollection(this.id)
                              .doc("configs")
                              .collection("order")
                              .doc("menus")
                              .get()
                              .toPromise()
                              .then((docMenus: any) => {
                                this._firestoreProvider
                                  .getCollection(this.id)
                                  .doc("configs")
                                  .collection("menus")
                                  .get()
                                  .toPromise()
                                  .then((itemDocMenus: any) => {
                                    let foodMsg = false;
                                    if (docConfig.data().foodMsg) {
                                      foodMsg = docConfig.data().foodMsg;
                                    }

                                    let sortedCart = [];
                                    if (docCartas.data()) {
                                      let indexCarta = docCartas.data().order;

                                      let cartasArray = itemDocCarta.docs.map(
                                        item => {
                                          return item.data();
                                        }
                                      );

                                      indexCarta.forEach(element => {
                                        let filteredItem = cartasArray.filter(
                                          item => {
                                            return item.id == element;
                                          }
                                        )[0];
                                        sortedCart.push(filteredItem);
                                      });
                                    }

                                    let sortedMenu = [];
                                    if (docMenus.data()) {
                                      let indexMenu = docMenus.data().order;

                                      let MenusArray = itemDocMenus.docs.map(
                                        item => {
                                          return item.data();
                                        }
                                      );

                                      indexMenu.forEach(element => {
                                        let filteredItem = MenusArray.filter(
                                          item => {
                                            return item.id == element;
                                          }
                                        )[0];
                                        sortedMenu.push(filteredItem);
                                      });
                                    }
                                    toPng(document.getElementById("qrcode"))
                                      .then(dataUrl => {
                                        dataUrl.replace(
                                          "data:image/png;base64,",
                                          ""
                                        );

                                        this.getDesign(
                                          sortedCart,
                                          sortedMenu,
                                          this.winesArray,
                                          foodMsg,
                                          dataUrl,
                                          value
                                        );
                                      })
                                      .catch(err => console.log(err));
                                  });
                              });
                          });
                      });
                  });
              });
          });
      })
      .catch(err => console.log(err));
  }

  getDesign(cartData, menuData, winesData, foodMsg, QR, formValue) {
    let doc: jsPDF;
    let text;
    let text2;
    let lastHeight;
    let stringWidth;
    let textOffset;
    let leftMargin;
    let rightMargin;
    let footerHeight;
    let columnOne;
    let columnTwo;
    let columnThree;
    let columnFour;
    let columnOneSpace;
    let columnTwoSpace;
    let columnThreeSpace;
    let rows: number = 0;
    let specials;
    let totalHeight;
    let heightOffset;
    let startHeight;
    let maxRows;
    let orientated;
    let innerSpace;
    let startRows;
    let verticalHeight;
    let typeText;
    let firstDO = true;
    let firstCarta = true;

    ///////////////////////////////////
    ////////// OPTION PARAMS //////////
    ///////////////////////////////////

    //DOCUMENT FORMAT
    let docFormat;
    switch (formValue.size) {
      case "1":
        docFormat = [592, 842];
        break;

      case "2":
        docFormat = [481, 980];
        break;

      case "3":
        docFormat = [583, 565];
        break;

      default:
        break;
    }
    doc = new jsPDF({
      unit: "px",
      format: docFormat
    });
    this.pdfFonts.AddFonts(doc);
    //ROW LIMITS
    switch (formValue.size) {
      case "1":
        maxRows = 450;
        break;

      case "2":
        maxRows = 550;
        break;

      case "3":
        maxRows = 250;
        break;

      default:
        break;
    }

    //DOCUMENT SPACINGS
    let wineSpacingNormal = {
      columnOneSpace: 185,
      columnTwoSpace: 50,
      columnThreeSpace: 68
    };
    let wineSpacingNarrow = {
      columnOneSpace: 105,
      columnTwoSpace: 50,
      columnThreeSpace: 68
    };
    let wineSpacingSquare = {
      columnOneSpace: 130,
      columnTwoSpace: 50,
      columnThreeSpace: 68
    };

    //DOCUMENT MARGINS
    //item -> food, wine
    let margins = (item: string, odd) => {
      switch (formValue.size) {
        case "1":
          innerSpace = doc.internal.pageSize.width - 110;

          if (item == "wine") {
            columnOneSpace = wineSpacingNormal.columnOneSpace;
            columnTwoSpace = wineSpacingNormal.columnTwoSpace;
            columnThreeSpace = wineSpacingNormal.columnThreeSpace;
          }
          if (formValue.spacing == "1") {
            if (formValue.pagination == "1") {
              if (!odd) {
                leftMargin = 80;
                rightMargin = doc.internal.pageSize.width - 30;
              } else {
                leftMargin = 30;
                rightMargin = doc.internal.pageSize.width - 80;
              }
            } else {
              leftMargin = 80;
              rightMargin = doc.internal.pageSize.width - 30;
            }
          } else {
            leftMargin = 55;
            rightMargin = doc.internal.pageSize.width - 55;
          }
          footerHeight = doc.internal.pageSize.height - 30;

          break;

        case "2":
          innerSpace = doc.internal.pageSize.width - 110;

          if (item == "wine") {
            columnOneSpace = wineSpacingNarrow.columnOneSpace;
            columnTwoSpace = wineSpacingNarrow.columnTwoSpace;
            columnThreeSpace = wineSpacingNarrow.columnThreeSpace;
          }
          if (formValue.spacing == "1") {
            if (formValue.pagination == "1") {
              if (!odd) {
                leftMargin = 80;
                rightMargin = doc.internal.pageSize.width - 30;
              } else {
                leftMargin = 30;
                rightMargin = doc.internal.pageSize.width - 80;
              }
            } else {
              leftMargin = 80;
              rightMargin = doc.internal.pageSize.width - 30;
            }
          } else {
            leftMargin = 55;
            rightMargin = doc.internal.pageSize.width - 55;
          }
          footerHeight = doc.internal.pageSize.height - 30;

          break;

        case "3":
          innerSpace = doc.internal.pageSize.width - 160;

          if (item == "wine") {
            columnOneSpace = wineSpacingSquare.columnOneSpace;
            columnTwoSpace = wineSpacingSquare.columnTwoSpace;
            columnThreeSpace = wineSpacingSquare.columnThreeSpace;
          }

          if (formValue.spacing == "1") {
            if (formValue.pagination == "1") {
              if (!odd) {
                leftMargin = 110;
                rightMargin = doc.internal.pageSize.width - 50;
              } else {
                leftMargin = 50;
                rightMargin = doc.internal.pageSize.width - 110;
              }
            } else {
              leftMargin = 110;
              rightMargin = doc.internal.pageSize.width - 50;
            }
          } else {
            leftMargin = 80;
            rightMargin = doc.internal.pageSize.width - 80;
          }
          footerHeight = doc.internal.pageSize.height - 50;

          break;

        default:
          break;
      }

      columnOne = leftMargin;
      columnTwo = columnOne + columnOneSpace;
      columnThree = columnTwo + columnTwoSpace;
      columnFour = columnThree + columnThreeSpace;
    };

    //LAST PAGE IMG SIZES

    let sizeA = {
      height: 300,
      width: 120
    };

    //////////////////////////////////////
    ////////// STRUCTURE BLOCKS //////////
    //////////////////////////////////////

    //DOCUMENT COVERS
    // pos => first, wine
    let docCover = pos => {
      let pageInit = () => {
        if (pos == "first") {
          switch (formValue.size) {
            case "1":
              orientated = "portrait";
              break;

            case "2":
              orientated = "portrait";
              break;

            case "3":
              orientated = "landscape";
              break;

            default:
              break;
          }
          // doc.orientated = orientated;
        } else {
          doc.addPage(
            docFormat
            // ,'p'
          );
        }
      };
      let bgColor = () => {
        switch (formValue.coverColor) {
          case "1":
            doc.setFillColor(40, 40, 40);
            break;

          case "2":
            doc.setFillColor(224, 224, 224);
            break;

          case "3":
            doc.setFillColor(255, 72, 103);
            break;

          default:
            break;
        }

        doc.rect(
          0,
          0,
          doc.internal.pageSize.width,
          doc.internal.pageSize.height,
          "F"
        );
      };
      let title = () => {
        if (pos == "first") {
          switch (formValue.language) {
            case "espanol":
              switch (formValue.type) {
                case "1":
                  text = "CARTA DE COMIDA";
                  break;

                case "2":
                  text = "CARTA DE COMIDA";
                  break;

                case "3":
                  text = "CARTA DE VINOS";
                  break;

                default:
                  break;
              }

              break;

            case "Inglés":
              switch (formValue.type) {
                case "1":
                  text = "MENU";
                  break;

                case "2":
                  text = "MENU";
                  break;

                case "3":
                  text = "WINES";
                  break;

                default:
                  break;
              }

              break;

            case "Francés":
              switch (formValue.type) {
                case "1":
                  text = "CARTE";
                  break;

                case "2":
                  text = "CARTE";
                  break;

                case "3":
                  text = "VINS";
                  break;

                default:
                  break;
              }

              break;

            case "Portugués":
              switch (formValue.type) {
                case "1":
                  text = "CARTA";
                  break;

                case "2":
                  text = "CARTA";
                  break;

                case "3":
                  text = "VINHOS";
                  break;

                default:
                  break;
              }
              break;

            case "Galego":
              switch (formValue.type) {
                case "1":
                  text = "CARTA";
                  break;

                case "2":
                  text = "CARTA";
                  break;

                case "3":
                  text = "VIÑOS";
                  break;

                default:
                  break;
              }
              break;

            default:
              break;
          }
        } else if (pos == "wine") {
          switch (formValue.language) {
            case "espanol":
              text = "CARTA DE VINOS";
              break;

            case "Inglés":
              text = "WINES";
              break;

            case "Francés":
              text = "VINS";
              break;

            case "Portugués":
              text = "VINHOS";
              break;

            case "Galego":
              text = "VIÑOS";
              break;

            default:
              break;
          }
        }

        switch (formValue.coverColor) {
          case "1":
            doc.setTextColor(234, 234, 234);
            break;

          case "2":
            doc.setTextColor(40, 40, 40);
            break;

          case "3":
            doc.setTextColor(234, 234, 234);
            break;

          default:
            break;
        }

        switch (formValue.font) {
          case "1":
            doc.setFont("DrukTextWide", "Medium");
            break;

          case "2":
            doc.setFont("trajanpro", "bold");
            break;

          default:
            break;
        }

        doc.setFontSize(17);
        stringWidth =
          doc.getStringUnitWidth(text) * doc.internal.getFontSize() * 0.75;
        textOffset = (doc.internal.pageSize.width - stringWidth) / 2;
        lastHeight = (doc.internal.pageSize.height - 10) / 2;
        doc.text(text, textOffset, lastHeight);
      };
      let subTitle = () => {
        if (formValue.restName == "1") {
          switch (formValue.coverColor) {
            case "1":
              doc.setTextColor(255, 72, 103);
              break;

            case "2":
              doc.setTextColor(255, 72, 103);
              break;

            case "3":
              doc.setTextColor(40, 40, 40);
              break;

            default:
              break;
          }

          switch (formValue.font) {
            case "1":
              doc.setFont("Monserrat", "SemiBold");
              break;

            case "2":
              doc.setFont("trajanpro", "bold");
              break;

            default:
              break;
          }
          doc.setFontSize(12);
          stringWidth =
            doc.getStringUnitWidth(
              this.restauranteToEdit.nombre.toUpperCase()
            ) *
            doc.internal.getFontSize() *
            0.75;
          textOffset = (doc.internal.pageSize.width - stringWidth) / 2;

          doc.text(
            this.restauranteToEdit.nombre.toUpperCase(),
            textOffset - 2,
            lastHeight + 10
          );
        }
      };
      let branding = () => {
        if (formValue.branding == "1") {
          doc.setFont("Monserrat", "SemiBold");
          lastHeight = doc.internal.pageSize.height - 60;
          doc.addImage(
            this.pdfFonts.logo,
            "pdf",
            (doc.internal.pageSize.width - 20) / 2,
            lastHeight,
            20,
            20
          );
          text = "dvinum.es";

          switch (formValue.coverColor) {
            case "1":
              doc.setTextColor(234, 234, 234);
              break;

            case "2":
              doc.setTextColor(40, 40, 40);
              break;

            case "3":
              doc.setTextColor(234, 234, 234);
              break;

            default:
              break;
          }
          doc.setFontSize(7);

          (stringWidth =
            doc.getStringUnitWidth(text.toUpperCase()) *
            doc.internal.getFontSize() *
            0.75),
            (textOffset = (doc.internal.pageSize.width - stringWidth - 2) / 2);
          lastHeight = lastHeight + 30;
          doc.text(text.toUpperCase(), textOffset, lastHeight);
        }
      };

      pageInit();

      bgColor();

      title();

      subTitle();

      branding();
    };

    let wines = () => {
      let pageTemplate = (tipoData?, samePage?) => {
        if (firstDO && formValue.type === "3" && formValue.cover === "2") {
          firstDO = false;
          lastHeight = 50;
          rows = 50;
        } else if (samePage && !firstDO) {
          lastHeight = lastHeight + 30;
          rows = rows + 30;
        } else {
          firstDO = false;
          lastHeight = 50;
          rows = 50;
          doc.addPage(docFormat);
        }

        let odd = this.isOdd(doc.internal.getCurrentPageInfo().pageNumber);

        margins("wine", odd);

        doc.setFontSize(7);

        switch (formValue.font) {
          case "1":
            doc.setFont("Monserrat", "SemiBold");
            break;

          case "2":
            doc.setFont("trajanpro", "bold");
            break;

          default:
            break;
        }
        doc.setTextColor(255, 72, 103);
        if (formValue.branding == "1") {
          doc.text("DVINUM.ES", leftMargin, footerHeight);
        }

        switch (formValue.language) {
          case "espanol":
            text = "IVA INCLUIDO";
            break;

          case "Inglés":
            text = "VAT INCLUDED";
            break;

          case "Francés":
            text = "TVA incluse";
            break;

          case "Portugués":
            text = "IVA incluído";
            break;

          case "Galego":
            text = "IVE incluído";
            break;

          default:
            break;
        }

        stringWidth =
          doc.getStringUnitWidth(text) * doc.internal.getFontSize() * 0.75;
        doc.text(text, rightMargin - stringWidth, footerHeight);

        if (foodMsg) {
          stringWidth =
            doc.getStringUnitWidth(foodMsg) * doc.internal.getFontSize() * 0.75;
          doc.text(foodMsg, rightMargin - stringWidth, footerHeight - 10);
        }

        doc.setFontSize(12);
        doc.setTextColor(255, 72, 103);
        if (formValue.restName == "1") {
          if (!samePage) {
            doc.text(
              this.restauranteToEdit.nombre.toUpperCase(),
              leftMargin,
              lastHeight
            );
          }
        }

        if (rows < 420) {
          doc.setFontSize(21);
          doc.setTextColor(56, 56, 56);
          if (tipoData) {
            switch (tipoData.nombre) {
              case "Vinos Tintos":
                switch (formValue.language) {
                  case "espanol":
                    typeText = "Vinos Tintos";
                    break;
                  case "Inglés":
                    typeText = "Red Wines";
                    break;

                  case "Francés":
                    typeText = "Vins Rouges";
                    break;

                  case "Portugués":
                    typeText = "Vinhos Tintos";
                    break;

                  case "Galego":
                    typeText = "Viños Tintos";
                    break;
                  default:
                    break;
                }

                break;

              case "Vinos Blancos":
                switch (formValue.language) {
                  case "espanol":
                    typeText = "Vinos Blancos";
                    break;
                  case "Inglés":
                    typeText = "White Wines";
                    break;

                  case "Francés":
                    typeText = "Vins Blancs";
                    break;

                  case "Portugués":
                    typeText = "Vinhos Brancos";
                    break;

                  case "Galego":
                    typeText = "Viños Brancos";
                    break;
                  default:
                    break;
                }

                break;

              case "Vinos Generosos":
                switch (formValue.language) {
                  case "espanol":
                    typeText = "Vinos Generosos";
                    break;
                  case "Inglés":
                    typeText = "Fortified Wines";
                    break;

                  case "Francés":
                    typeText = "Vins Fortifiés";
                    break;

                  case "Portugués":
                    typeText = "Vinhos Generosos";
                    break;

                  case "Galego":
                    typeText = "Viños Xenerosos";
                    break;
                  default:
                    break;
                }

                break;

              case "Vinos Espumosos":
                switch (formValue.language) {
                  case "espanol":
                    typeText = "Vinos Espumosos";
                    break;
                  case "Inglés":
                    typeText = "Sparkling Wines";
                    break;
                  case "Francés":
                    typeText = "Vins Effervescents";
                    break;

                  case "Portugués":
                    typeText = "Vinhos Espumantes";
                    break;

                  case "Galego":
                    typeText = "Viños Xenerosos";
                    break;
                  default:
                    break;
                }

                break;
              case "Vinos Rosados":
                switch (formValue.language) {
                  case "espanol":
                    typeText = "Vinos Rosados";
                    break;
                  case "Inglés":
                    typeText = "Rosé Wines";
                    break;

                  case "Francés":
                    typeText = "Vins Rosés";
                    break;

                  case "Portugués":
                    typeText = "Vinhos Rosés";
                    break;

                  case "Galego":
                    typeText = "Viños Rosados";
                    break;
                  default:
                    break;
                }

                break;

              case "Vinos Vermouth":
                switch (formValue.language) {
                  case "espanol":
                    typeText = "Vinos Vermouth";
                    break;
                  case "Inglés":
                    typeText = "Vermouth Wines";
                    break;

                  case "Francés":
                    typeText = "Vins Vermouth";
                    break;

                  case "Portugués":
                    typeText = "Vinhos Vermouth";
                    break;

                  case "Galego":
                    typeText = "Viños Vermouth";
                    break;
                  default:
                    break;
                }
                break;
              default:
                break;
            }

            stringWidth =
              doc.getStringUnitWidth(typeText.toUpperCase()) *
              doc.internal.getFontSize() *
              0.75;

            doc.text(
              typeText.toUpperCase(),
              rightMargin - stringWidth,
              lastHeight
            );
          }

          lastHeight = lastHeight + 5;
          doc.line(leftMargin, lastHeight, rightMargin, lastHeight);

          doc.setTextColor(255, 72, 103);
          doc.setFontSize(7);
          lastHeight = lastHeight + 15;

          switch (formValue.language) {
            case "espanol":
              text = "REFERENCIA";
              break;

            case "Inglés":
              text = "REFERENCE";
              break;

            case "Francés":
              text = "RÉFÉRENCE";
              break;

            case "Portugués":
              text = "REFERÊNCIA";
              break;

            case "Galego":
              text = "REFERENCIA";
              break;

            default:
              break;
          }

          doc.text(text, columnOne, lastHeight);

          if (formValue.formatColumn == "1") {
            switch (formValue.language) {
              case "espanol":
                text = "FORMATO";
                break;

              case "Inglés":
                text = "FORMAT";
                break;

              case "Francés":
                text = "FORMAT";
                break;

              case "Portugués":
                text = "FORMATO";
                break;

              case "Galego":
                text = "FORMATO";
                break;

              default:
                break;
            }

            doc.text(text, columnTwo, lastHeight);
          }

          if (formValue.varietyColumn == "1") {
            switch (formValue.language) {
              case "espanol":
                text = "VARIEDADES";
                break;

              case "Inglés":
                text = "VARIETIES";
                break;

              case "Francés":
                text = "VARIÉTÉS";
                break;

              case "Portugués":
                text = "VARIEDADES";
                break;

              case "Galego":
                text = "VARIEDADES";
                break;

              default:
                break;
            }

            doc.text(text, columnThree, lastHeight);
          }

          switch (formValue.language) {
            case "espanol":
              text = "PVP";
              break;

            case "Inglés":
              text = "PRICE";
              break;

            case "Francés":
              text = "prix";
              break;

            case "Portugués":
              text = "preço";
              break;

            case "Galego":
              text = "prezo";
              break;

            default:
              break;
          }

          doc.text(text, columnFour + 8, lastHeight);
        }
      };

      let mainLoop = () => {
        let doLoop = () => {
          specials = [];

          let singleWineLoop = (tipoData, vinoData) => {
            vinoData.forEach(vino => {
              if (vino.active) {
                if (vino.volumen == "750 ml") {
                  if (rows > maxRows) {
                    pageTemplate(tipoData);

                    lastHeight = lastHeight + 8;
                    rows = rows + 8;
                  }

                  //NAME

                  doc.setTextColor(56, 56, 56);
                  doc.setFontSize(9);
                  totalHeight = 8;
                  startHeight = lastHeight;
                  lastHeight = lastHeight + 8;
                  rows = rows + 8;

                  switch (formValue.font) {
                    case "1":
                      doc.setFont("Monserrat", "SemiBold");
                      break;

                    case "2":
                      doc.setFont("acaslonpro", "bold");
                      break;

                    default:
                      break;
                  }
                  // doc.text(vino.name.toUpperCase(), columnOne + 2, lastHeight);

                  let nameArray = doc.splitTextToSize(
                    vino.name.toUpperCase(),
                    columnOneSpace - 20
                  );

                  nameArray.forEach((element, index) => {
                    if (index != 0) {
                      lastHeight = lastHeight + 8;
                      rows = rows + 8;
                      totalHeight = totalHeight + 8;
                    }
                    doc.text(element, columnOne + 2, lastHeight);
                  });
                  //ECO ICON

                  if (vino.eco) {
                    if (totalHeight > 14) {
                      heightOffset = startHeight + totalHeight / 2 + 4;
                      doc.addImage(
                        this.pdfFonts.eco,
                        "png",
                        columnTwo - 10,
                        heightOffset - 6,
                        7,
                        7
                      );
                    } else {
                      doc.addImage(
                        this.pdfFonts.eco,
                        "png",
                        columnTwo - 10,
                        lastHeight - 6,
                        7,
                        7
                      );
                    }
                  }

                  //FORMAT

                  switch (formValue.font) {
                    case "1":
                      doc.setFont("Monserrat", "Regular");
                      break;

                    case "2":
                      doc.setFont("acaslonpro", "Regular");
                      break;

                    default:
                      break;
                  }

                  doc.setFontSize(7);

                  if (formValue.formatColumn == "1") {
                    if (totalHeight > 14) {
                      heightOffset = startHeight + totalHeight / 2 + 4;
                      doc.text(vino.volumen, columnTwo + 2, heightOffset);
                    } else {
                      doc.text(vino.volumen, columnTwo + 2, lastHeight);
                    }
                  }

                  //VARIETIES

                  doc.setFontSize(7);

                  if (formValue.varietyColumn == "1") {
                    if (typeof vino.uva == "string") {
                      if (totalHeight > 14) {
                        heightOffset = startHeight + totalHeight / 2 + 4;
                        doc.text(
                          vino.uva.toUpperCase(),
                          columnThree + 2,
                          heightOffset
                        );
                      } else {
                        doc.text(
                          vino.uva.toUpperCase(),
                          columnThree + 2,
                          lastHeight
                        );
                      }
                    } else {
                      let array2string = "";
                      vino.uva.forEach((element, i) => {
                        if (i < 4) {
                          let res = element.slice(0, 5);
                          if (i == 0) {
                            array2string = res;
                          } else {
                            array2string = array2string + ", " + res;
                          }
                        }
                      });

                      if (totalHeight > 14) {
                        heightOffset = startHeight + totalHeight / 2 + 4;
                        doc.text(
                          array2string.toUpperCase(),
                          columnThree + 2,
                          heightOffset
                        );
                      } else {
                        doc.text(
                          array2string.toUpperCase(),
                          columnThree + 2,
                          lastHeight
                        );
                      }
                    }
                  }

                  //PRICE

                  switch (formValue.font) {
                    case "1":
                      doc.setFont("Monserrat", "SemiBold");
                      break;

                    case "2":
                      doc.setFont("acaslonpro", "bold");
                      break;

                    default:
                      break;
                  }
                  doc.setFontSize(10);

                  if (totalHeight > 14) {
                    heightOffset = startHeight + totalHeight / 2 + 4;
                    doc.text(
                      vino.price.toString(),
                      columnFour + 20,
                      heightOffset,
                      0,
                      0,
                      "right"
                    );
                  } else {
                    doc.text(
                      vino.price.toString(),
                      columnFour + 20,
                      lastHeight,
                      0,
                      0,
                      "right"
                    );
                  }

                  lastHeight = lastHeight + 3;
                  rows = rows + 1;
                  doc.line(leftMargin, lastHeight, rightMargin, lastHeight);
                } else {
                  specials.push(vino);
                }
              }
            });
          };

          winesData.forEach(tipo => {
            let sortBy = [
              "Rias Baixas",
              "Ribeiro",
              "Monterrei",
              "Valdeorras",
              "Ribeira Sacra",
              "Ribera del Duero",
              "Rioja"
            ];

            let newDoArray;
            let sortedArray = [];

            newDoArray = tipo.dos.map(e => {
              return e;
            });

            sortBy.forEach((sortElement, i) => {
              tipo.dos.forEach(doElement => {
                if (doElement.nombre == sortElement) {
                  sortedArray.push(doElement);

                  newDoArray.splice(
                    newDoArray
                      .map(e => {
                        return e.nombre;
                      })
                      .indexOf(doElement.nombre),
                    1
                  );
                }
              });
            });

            newDoArray.sort(compare);

            function compare(a, b) {
              if (a.vinos.length < b.vinos.length) {
                return 1;
              }
              if (a.vinos.length > b.vinos.length) {
                return -1;
              }
              return 0;
            }

            let finalArray = sortedArray.concat(newDoArray);

            if (
              rows > maxRows - 100 ||
              tipo.nombre == "Vinos Tintos" ||
              tipo.nombre == "Vinos Generosos"
            ) {
              pageTemplate(tipo, true);
            } else {
              pageTemplate(tipo, true);
            }

            finalArray.forEach(denominacion => {
              let wines = denominacion.vinos;

              let checkEmptyDO = wines.every((element, index) => {
                return element.volumen != "750 ml";
              });

              if (!checkEmptyDO) {
                if (rows > maxRows - 40) {
                  pageTemplate(tipo);
                }

                doc.setFontSize(18);
                doc.setTextColor(56, 56, 56);
                switch (formValue.font) {
                  case "1":
                    doc.setFont("DrukTextWide", "Medium");
                    break;

                  case "2":
                    doc.setFont("trajanpro", "bold");
                    break;

                  default:
                    break;
                }

                lastHeight = lastHeight + 20;
                doc.text(denominacion.nombre, columnOne, lastHeight);
                lastHeight = lastHeight + 10;
                rows = rows + 30;
              }
              singleWineLoop(tipo, wines);
            });
          });
        };

        doLoop();
      };

      let specialWines = () => {
        if (specials.length >= 1) {
          if (rows > maxRows - 100) {
            pageTemplate();
          }

          doc.setFontSize(18);
          doc.setTextColor(56, 56, 56);
          switch (formValue.font) {
            case "1":
              doc.setFont("DrukTextWide", "Medium");
              break;

            case "2":
              doc.setFont("trajanpro", "bold");
              break;

            default:
              break;
          }
          lastHeight = lastHeight + 20;

          switch (formValue.language) {
            case "espanol":
              text = "Formatos Especiales";
              break;

            case "Inglés":
              text = "Special Formats";
              break;

            case "Francés":
              text = "Formats spéciaux";
              break;

            case "Portugués":
              text = "Formatos Especiais";
              break;

            case "Galego":
              text = "Formatos especiais";
              break;

            default:
              break;
          }

          doc.text(text, columnOne, lastHeight);
          lastHeight = lastHeight + 10;
          rows = rows + 30;
        }

        specials.forEach(vino => {
          if (rows > maxRows) {
            pageTemplate();

            lastHeight = lastHeight + 8;
            rows = rows + 8;
          }

          doc.setTextColor(56, 56, 56);
          doc.setFontSize(9);
          startHeight = lastHeight;
          lastHeight = lastHeight + 8;
          rows = rows + 8;
          totalHeight = 8;
          switch (formValue.font) {
            case "1":
              doc.setFont("Monserrat", "SemiBold");
              break;

            case "2":
              doc.setFont("acaslonpro", "bold");
              break;

            default:
              break;
          }
          // doc.text(vino.name.toUpperCase(), columnOne + 2, lastHeight);

          let nameArray = doc.splitTextToSize(
            vino.name.toUpperCase(),
            columnOneSpace - 5
          );

          nameArray.forEach((element, index) => {
            if (index != 0) {
              lastHeight = lastHeight + 8;
              rows = rows + 8;
              totalHeight = totalHeight + 8;
              rows = rows + 8;
            }
            doc.text(element, columnOne + 2, lastHeight);
          });

          switch (formValue.font) {
            case "1":
              doc.setFont("Monserrat", "Regular");
              break;

            case "2":
              doc.setFont("acaslonpro", "Regular");
              break;

            default:
              break;
          }
          doc.setFontSize(7);
          // doc.text(vino.volumen, columnTwo + 2, lastHeight);

          if (formValue.formatColumn == "1") {
            if (totalHeight > 14) {
              heightOffset = startHeight + totalHeight / 2 + 4;
              doc.text(vino.volumen, columnTwo + 2, heightOffset);
            } else {
              doc.text(vino.volumen, columnTwo + 2, lastHeight);
            }
          }

          doc.setFontSize(7);

          if (formValue.varietyColumn == "1") {
            if (typeof vino.uva == "string") {
              // doc.text(vino.uva.toUpperCase(), columnThree + 2, lastHeight);

              if (totalHeight > 14) {
                heightOffset = startHeight + totalHeight / 2 + 4;
                doc.text(vino.uva.toUpperCase(), columnThree + 2, heightOffset);
              } else {
                doc.text(vino.uva.toUpperCase(), columnThree + 2, lastHeight);
              }
            } else {
              let array2string = "";
              vino.uva.forEach((element, i) => {
                if (i < 4) {
                  let res = element.slice(0, 5);
                  if (i == 0) {
                    array2string = res;
                  } else {
                    array2string = array2string + ", " + res;
                  }
                }
              });

              if (totalHeight > 14) {
                heightOffset = startHeight + totalHeight / 2 + 4;
                doc.text(
                  array2string.toUpperCase(),
                  columnThree + 2,
                  heightOffset
                );
              } else {
                doc.text(
                  array2string.toUpperCase(),
                  columnThree + 2,
                  lastHeight
                );
              }
            }
          }

          switch (formValue.font) {
            case "1":
              doc.setFont("Monserrat", "SemiBold");
              break;

            case "2":
              doc.setFont("acaslonpro", "bold");
              break;

            default:
              break;
          }
          doc.setFontSize(10);
          // doc.text(vino.price.toString(), columnFour + 2, lastHeight);

          if (totalHeight > 14) {
            heightOffset = startHeight + totalHeight / 2 + 4;
            doc.text(vino.price.toUpperCase(), columnFour + 2, heightOffset);
          } else {
            doc.text(vino.price.toUpperCase(), columnFour + 2, lastHeight);
          }

          lastHeight = lastHeight + 3;
          doc.line(leftMargin, lastHeight, rightMargin, lastHeight);
        });
      };

      mainLoop();

      specialWines();
    };

    let foods = () => {
      // tipo => carta, menus
      let pageTemplate = (tipo?) => {
        lastHeight = 50;
        rows = 50;

        if (
          firstCarta &&
          formValue.cover === "2" &&
          (formValue.type === "2" || formValue.type === "1")
        ) {
          firstCarta = false;
          lastHeight = 50;
          rows = 50;
        } else {
          doc.addPage(docFormat);
        }

        let odd = this.isOdd(doc.internal.getCurrentPageInfo().pageNumber);

        margins("wine", odd);

        doc.setFontSize(7);
        switch (formValue.font) {
          case "1":
            doc.setFont("Monserrat", "SemiBold");
            break;

          case "2":
            doc.setFont("trajanpro", "bold");
            break;

          default:
            break;
        }
        doc.setTextColor(255, 72, 103);

        if (formValue.branding == "1") {
          doc.text("DVINUM.ES", leftMargin, footerHeight);
        }

        switch (formValue.language) {
          case "espanol":
            text = "IVA INCLUIDO";
            break;

          case "Inglés":
            text = "VAT INCLUDED";
            break;

          case "Francés":
            text = "TVA incluse";
            break;

          case "Portugués":
            text = "IVA incluído";
            break;

          case "Galego":
            text = "IVE incluído";
            break;

          default:
            break;
        }

        stringWidth =
          doc.getStringUnitWidth(text) * doc.internal.getFontSize() * 0.75;
        doc.text(text, rightMargin - stringWidth, footerHeight);

        if (foodMsg) {
          stringWidth =
            doc.getStringUnitWidth(foodMsg) * doc.internal.getFontSize() * 0.75;
          doc.text(foodMsg, rightMargin - stringWidth, footerHeight - 10);
        }

        doc.setFontSize(12);
        doc.setTextColor(255, 72, 103);
        if (formValue.restName == "1") {
          doc.text(
            this.restauranteToEdit.nombre.toUpperCase(),
            leftMargin,
            lastHeight
          );
        }

        doc.setFontSize(21);
        doc.setTextColor(56, 56, 56);

        if (tipo == "carta") {
          switch (formValue.language) {
            case "espanol":
              text = "CARTA";
              break;

            case "Inglés":
              text = "MENU";
              break;

            case "Francés":
              text = "CARTE";
              break;

            case "Portugués":
              text = "CARTA";
              break;

            case "Galego":
              text = "CARTA";
              break;

            default:
              break;
          }

          stringWidth =
            doc.getStringUnitWidth("CARTA") * doc.internal.getFontSize() * 0.75;
          doc.text("CARTA", rightMargin - stringWidth, lastHeight);
        } else if (tipo == "menus") {
          switch (formValue.language) {
            case "espanol":
              text = "MENÚS";
              break;

            case "Inglés":
            case "Francés":
            case "Portugués":
            case "Galego":
              text = "MENUS";
              break;

            default:
              break;
          }

          stringWidth =
            doc.getStringUnitWidth("MENÚS") * doc.internal.getFontSize() * 0.75;
          doc.text("MENÚS", rightMargin - stringWidth, lastHeight);
        }

        lastHeight = lastHeight + 5;
        doc.line(leftMargin, lastHeight, rightMargin, lastHeight);

        doc.setTextColor(255, 72, 103);
        doc.setFontSize(7);
        lastHeight = lastHeight + 15;

        switch (formValue.language) {
          case "espanol":
            text = "REFERENCIA";
            break;

          case "Inglés":
            text = "REFERENCE";
            break;

          case "Francés":
            text = "RÉFÉRENCE";
            break;

          case "Portugués":
            text = "REFERÊNCIA";
            break;

          case "Galego":
            text = "REFERENCIA";
            break;

          default:
            break;
        }

        doc.text(text, columnOne, lastHeight);

        if (tipo != "menus") {
          if (formValue.formatColumn == "1") {
            switch (formValue.language) {
              case "espanol":
                text = "FORMATO";
                break;

              case "Inglés":
                text = "FORMAT";
                break;

              case "Francés":
                text = "FORMAT";
                break;

              case "Portugués":
                text = "FORMATO";
                break;

              case "Galego":
                text = "FORMATO";
                break;

              default:
                break;
            }

            doc.text(text, columnTwo, lastHeight);
          }
        } else {
          if (formValue.formatColumn == "1") {
            switch (formValue.language) {
              case "espanol":
                text = "ALÉRGENOS";
                break;

              case "Inglés":
                text = "ALERGENS";
                break;

              case "Francés":
                text = "ALLERGÈNES";
                break;

              case "Portugués":
                text = "ALÉRGENOS";
                break;

              case "Galego":
                text = "ALÉRXENOS";
                break;

              default:
                break;
            }

            doc.text(text, columnTwo, lastHeight);
          }
        }

        if (tipo != "menus") {
          if (formValue.alergenColumn == "1") {
            switch (formValue.language) {
              case "espanol":
                text = "ALÉRGENOS";
                break;

              case "Inglés":
                text = "ALERGENS";
                break;

              case "Francés":
                text = "ALLERGÈNES";
                break;

              case "Portugués":
                text = "ALÉRGENOS";
                break;

              case "Galego":
                text = "ALÉRXENOS";
                break;

              default:
                break;
            }

            doc.text(text, columnThree, lastHeight);
          }
        }
        switch (formValue.language) {
          case "espanol":
            text = "PVP";
            break;

          case "Inglés":
            text = "PRICE";
            break;

          case "Francés":
            text = "prix";
            break;

          case "Portugués":
            text = "preço";
            break;

          case "Galego":
            text = "prezo";
            break;

          default:
            break;
        }

        doc.text(text, columnFour + 8, lastHeight);
      };

      let cartaLoop = () => {
        let categoryName = categoryData => {
          if (rows > maxRows - 80) {
            pageTemplate("carta");
          }

          doc.setFontSize(18);
          doc.setTextColor(56, 56, 56);

          switch (formValue.font) {
            case "1":
              doc.setFont("DrukTextWide", "Medium");
              break;

            case "2":
              doc.setFont("trajanpro", "bold");
              break;

            default:
              break;
          }

          lastHeight = lastHeight + 20;

          let nameArray;
          switch (formValue.language) {
            case "espanol":
              nameArray = doc.splitTextToSize(
                categoryData.name.toUpperCase(),
                innerSpace
              );
              break;

            case "Inglés":
              if (categoryData.nameEnglish != "") {
                nameArray = doc.splitTextToSize(
                  categoryData.nameEnglish.toUpperCase(),
                  innerSpace
                );
              } else {
                nameArray = doc.splitTextToSize(
                  categoryData.name.toUpperCase(),
                  innerSpace
                );
              }
              break;

            case "Francés":
              if (categoryData.nameFrench != "") {
                nameArray = doc.splitTextToSize(
                  categoryData.nameFrench.toUpperCase(),
                  innerSpace
                );
              } else {
                nameArray = doc.splitTextToSize(
                  categoryData.name.toUpperCase(),
                  innerSpace
                );
              }
              break;

            case "Portugués":
              if (categoryData.namePortuguese != "") {
                nameArray = doc.splitTextToSize(
                  categoryData.namePortuguese.toUpperCase(),
                  innerSpace
                );
              } else {
                nameArray = doc.splitTextToSize(
                  categoryData.name.toUpperCase(),
                  innerSpace
                );
              }
              break;

            case "Galego":
              if (categoryData.nameGalician != "") {
                nameArray = doc.splitTextToSize(
                  categoryData.nameGalician.toUpperCase(),
                  innerSpace
                );
              } else {
                nameArray = doc.splitTextToSize(
                  categoryData.name.toUpperCase(),
                  innerSpace
                );
              }
              break;

            default:
              break;
          }

          nameArray.forEach((element, index) => {
            if (index != 0) {
              lastHeight = lastHeight + 14;

              rows = rows + 14;
            }
            doc.text(element, columnOne, lastHeight);
          });

          lastHeight = lastHeight + 10;
          rows = rows + 30;
        };

        let cartName = foodData => {
          if (rows > maxRows) {
            pageTemplate("carta");

            lastHeight = lastHeight + 12;
            rows = rows + 12;
          }

          doc.setTextColor(56, 56, 56);
          doc.setFontSize(9);
          totalHeight = 8;
          startHeight = lastHeight;
          lastHeight = lastHeight + 8;
          startRows = rows;
          rows = rows + 8;
          switch (formValue.font) {
            case "1":
              doc.setFont("Monserrat", "SemiBold");
              break;

            case "2":
              doc.setFont("acaslonpro", "bold");
              break;

            default:
              break;
          }

          let nameArray;
          switch (formValue.language) {
            case "espanol":
              nameArray = doc.splitTextToSize(
                foodData.name.toUpperCase(),
                columnOneSpace
              );
              break;

            case "Inglés":
              if (foodData.nameEnglish != "") {
                nameArray = doc.splitTextToSize(
                  foodData.nameEnglish.toUpperCase(),
                  columnOneSpace
                );
              } else {
                nameArray = doc.splitTextToSize(
                  foodData.name.toUpperCase(),
                  columnOneSpace
                );
              }
              break;

            case "Francés":
              if (foodData.nameFrench != "") {
                nameArray = doc.splitTextToSize(
                  foodData.nameFrench.toUpperCase(),
                  columnOneSpace
                );
              } else {
                nameArray = doc.splitTextToSize(
                  foodData.name.toUpperCase(),
                  columnOneSpace
                );
              }
              break;

            case "Portugués":
              if (foodData.namePortuguese != "") {
                nameArray = doc.splitTextToSize(
                  foodData.namePortuguese.toUpperCase(),
                  columnOneSpace
                );
              } else {
                nameArray = doc.splitTextToSize(
                  foodData.name.toUpperCase(),
                  columnOneSpace
                );
              }
              break;

            case "Galego":
              if (foodData.nameGalician != "") {
                nameArray = doc.splitTextToSize(
                  foodData.nameGalician.toUpperCase(),
                  columnOneSpace
                );
              } else {
                nameArray = doc.splitTextToSize(
                  foodData.name.toUpperCase(),
                  columnOneSpace
                );
              }
              break;

            default:
              break;
          }

          nameArray.forEach((element, index) => {
            if (index != 0) {
              lastHeight = lastHeight + 8;
              rows = rows + 8;
              totalHeight = totalHeight + 8;
            }
            doc.text(element, columnOne + 2, lastHeight);
          });
        };

        let cartDesc = foodData => {
          switch (formValue.font) {
            case "1":
              doc.setFont("Monserrat", "Regular");
              break;

            case "2":
              doc.setFont("acaslonpro", "Regular");
              break;

            default:
              break;
          }
          doc.setFontSize(7);

          let descArray;

          switch (formValue.language) {
            case "espanol":
              descArray = doc.splitTextToSize(
                foodData.description,
                columnOneSpace
              );
              break;

            case "Inglés":
              if (foodData.descEnglish != "") {
                descArray = doc.splitTextToSize(
                  foodData.descEnglish,
                  columnOneSpace
                );
              } else {
                descArray = doc.splitTextToSize(
                  foodData.description,
                  columnOneSpace
                );
              }
              break;

            case "Francés":
              if (foodData.descFrench != "") {
                descArray = doc.splitTextToSize(
                  foodData.descFrench,
                  columnOneSpace
                );
              } else {
                descArray = doc.splitTextToSize(
                  foodData.description,
                  columnOneSpace
                );
              }
              break;

            case "Portugués":
              if (foodData.descPortuguese != "") {
                descArray = doc.splitTextToSize(
                  foodData.descPortuguese,
                  columnOneSpace
                );
              } else {
                descArray = doc.splitTextToSize(
                  foodData.description,
                  columnOneSpace
                );
              }
              break;

            case "Galego":
              if (foodData.descGalician != "") {
                descArray = doc.splitTextToSize(
                  foodData.descGalician,
                  columnOneSpace
                );
              } else {
                descArray = doc.splitTextToSize(
                  foodData.description,
                  columnOneSpace
                );
              }
              break;

            default:
              break;
          }

          if (descArray[0] != "") {
            lastHeight = lastHeight + 6;
            totalHeight = totalHeight + 6;
            rows = rows + 6;
          }

          descArray.forEach((element, index) => {
            if (element != "") {
              doc.text(element, columnOne + 2, lastHeight);

              if (index != descArray.length - 1) {
                totalHeight = totalHeight + 5;
                lastHeight = lastHeight + 5;
                rows = rows + 5;
              }
            }
          });
        };

        let allergens = foodData => {
          let allergenPosition;
          let allergenDimention = 7;
          let allergenSingleLineOffset;

          if (formValue.alergenColumn == "1") {
            // allergenSingleLineOffset = heightOffset - 6

            if (foodData.allergens.length > 12) {
              if (totalHeight < 35) {
                totalHeight = 35;
                lastHeight = startHeight + 35;
                rows = startRows + 35;
              }
            } else if (foodData.allergens.length > 6) {
              if (totalHeight < 20) {
                totalHeight = 20;
                lastHeight = startHeight + 20;
                rows = startRows + 20;
              }
            }
          }

          heightOffset = startHeight + totalHeight / 2 + 4;

          if (formValue.alergenColumn == "1") {
            if (totalHeight >= 35) {
              if (foodData.allergens.length > 12) {
                allergenSingleLineOffset = heightOffset - 14;
              } else {
                allergenSingleLineOffset = heightOffset - 6;
              }
            } else if (totalHeight >= 14) {
              if (foodData.allergens.length > 6) {
                allergenSingleLineOffset = heightOffset - 10;
              } else {
                allergenSingleLineOffset = heightOffset - 6;
              }
            } else {
              allergenSingleLineOffset = lastHeight - 6;
            }

            foodData.allergens.forEach((element, index) => {
              if (index == 0) {
                allergenPosition = columnThree + 2;
              } else {
                allergenPosition = allergenPosition + allergenDimention + 2;
              }
              if (index == 6 || index == 12) {
                allergenPosition = columnThree + 2;
                allergenSingleLineOffset =
                  allergenSingleLineOffset + allergenDimention + 1;
              }

              let offset = allergenSingleLineOffset;

              switch (element) {
                case 1:
                  doc.addImage(
                    this.pdfFonts.icons.icon1,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                case 2:
                  doc.addImage(
                    this.pdfFonts.icons.icon2,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                case 3:
                  doc.addImage(
                    this.pdfFonts.icons.icon3,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                case 4:
                  doc.addImage(
                    this.pdfFonts.icons.icon4,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                case 5:
                  doc.addImage(
                    this.pdfFonts.icons.icon5,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                case 6:
                  doc.addImage(
                    this.pdfFonts.icons.icon6,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                case 7:
                  doc.addImage(
                    this.pdfFonts.icons.icon7,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                case 8:
                  doc.addImage(
                    this.pdfFonts.icons.icon8,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                case 9:
                  doc.addImage(
                    this.pdfFonts.icons.icon9,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                case 10:
                  doc.addImage(
                    this.pdfFonts.icons.icon10,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                case 11:
                  doc.addImage(
                    this.pdfFonts.icons.icon11,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                case 12:
                  doc.addImage(
                    this.pdfFonts.icons.icon12,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                case 13:
                  doc.addImage(
                    this.pdfFonts.icons.icon13,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                case 14:
                  doc.addImage(
                    this.pdfFonts.icons.icon14,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                case 15:
                  doc.addImage(
                    this.pdfFonts.icons.icon15,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                case 16:
                  doc.addImage(
                    this.pdfFonts.icons.icon16,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                case 17:
                  doc.addImage(
                    this.pdfFonts.icons.icon17,
                    "png",
                    allergenPosition,
                    offset,
                    allergenDimention,
                    allergenDimention
                  );

                  break;
                default:
                  break;
              }
            });
          }
        };

        let cartFormat = foodData => {
          switch (formValue.font) {
            case "1":
              doc.setFont("Monserrat", "Regular");
              break;

            case "2":
              doc.setFont("acaslonpro", "Regular");
              break;

            default:
              break;
          }
          doc.setFontSize(7);
          let formatArray;

          switch (formValue.language) {
            case "espanol":
              formatArray = doc.splitTextToSize(
                foodData.comment,
                columnTwoSpace
              );
              break;

            case "Inglés":
              if (foodData.commentEnglish != "") {
                formatArray = doc.splitTextToSize(
                  foodData.commentEnglish,
                  columnTwoSpace
                );
              } else {
                formatArray = doc.splitTextToSize(
                  foodData.comment,
                  columnTwoSpace
                );
              }
              break;

            case "Francés":
              if (foodData.commentFrench != "") {
                formatArray = doc.splitTextToSize(
                  foodData.commentFrench,
                  columnTwoSpace
                );
              } else {
                formatArray = doc.splitTextToSize(
                  foodData.comment,
                  columnTwoSpace
                );
              }
              break;

            case "Portugués":
              if (foodData.commentPortuguese != "") {
                formatArray = doc.splitTextToSize(
                  foodData.commentPortuguese,
                  columnTwoSpace
                );
              } else {
                formatArray = doc.splitTextToSize(
                  foodData.comment,
                  columnTwoSpace
                );
              }
              break;

            case "Galego":
              if (foodData.commentGalician != "") {
                formatArray = doc.splitTextToSize(
                  foodData.commentGalician,
                  columnTwoSpace
                );
              } else {
                formatArray = doc.splitTextToSize(
                  foodData.comment,
                  columnTwoSpace
                );
              }
              break;

            default:
              break;
          }

          let formatOffset;

          if (totalHeight >= 14) {
            formatOffset = heightOffset;
          } else {
            formatOffset = lastHeight;
          }

          if (formatArray.length == 1) {
            formatArray.forEach((element, index) => {
              if (index != 0) {
                formatOffset = formatOffset + 4;
              }
              doc.text(element, columnTwo + 2, formatOffset);
            });
          } else if (formatArray.length < 3) {
            formatArray.forEach((element, index) => {
              if (index != 0) {
                formatOffset = formatOffset + 4;
              }
              doc.text(element, columnTwo + 2, formatOffset - 3);
            });
          }
        };

        let cartPrice = foodData => {
          switch (formValue.font) {
            case "1":
              doc.setFont("Monserrat", "SemiBold");
              break;

            case "2":
              doc.setFont("acaslonpro", "bold");
              break;

            default:
              break;
          }
          doc.setFontSize(10);
          if (
            foodData.price == "" ||
            foodData.price == null ||
            foodData.price == "0"
          ) {
          } else {
            let price = foodData.price.toFixed(2);
            if (totalHeight >= 14) {
              doc.text(price, columnFour + 20, heightOffset, 0, 0, "right");
            } else {
              doc.text(price, columnFour + 20, lastHeight, 0, 0, "right");
            }
          }
          lastHeight = lastHeight + 3;
          doc.line(leftMargin, lastHeight, rightMargin, lastHeight);
        };

        pageTemplate("carta");

        cartData.forEach(categoria => {
          categoryName(categoria);

          categoria.optionsA.forEach(comida => {
            if (comida.active === true) {
              //NAME
              cartName(comida);

              //DESC
              cartDesc(comida);

              //ALLERGENS
              allergens(comida);

              //FORMAT
              cartFormat(comida);

              //PRICE
              cartPrice(comida);
            }
          });
        });
      };

      let menusLoop = () => {
        let categoryName = categoryData => {
          if (rows > maxRows - 40) {
            pageTemplate("menus");
          }

          doc.setFontSize(18);
          doc.setTextColor(56, 56, 56);
          switch (formValue.font) {
            case "1":
              doc.setFont("DrukTextWide", "Medium");
              break;

            case "2":
              doc.setFont("trajanpro", "bold");
              break;

            default:
              break;
          }

          lastHeight = lastHeight + 20;
          let nameArray;

          switch (formValue.language) {
            case "espanol":
              nameArray = doc.splitTextToSize(
                categoryData.name.toUpperCase(),
                innerSpace
              );
              break;

            case "Inglés":
              if (categoryData.nameEnglish != "") {
                nameArray = doc.splitTextToSize(
                  categoryData.nameEnglish.toUpperCase(),
                  innerSpace
                );
              } else {
                nameArray = doc.splitTextToSize(
                  categoryData.name.toUpperCase(),
                  innerSpace
                );
              }
              break;

            case "Francés":
              if (categoryData.nameFrench != "") {
                nameArray = doc.splitTextToSize(
                  categoryData.nameFrench.toUpperCase(),
                  innerSpace
                );
              } else {
                nameArray = doc.splitTextToSize(
                  categoryData.name.toUpperCase(),
                  innerSpace
                );
              }
              break;

            case "Portugués":
              if (categoryData.namePortuguese != "") {
                nameArray = doc.splitTextToSize(
                  categoryData.namePortuguese.toUpperCase(),
                  innerSpace
                );
              } else {
                nameArray = doc.splitTextToSize(
                  categoryData.name.toUpperCase(),
                  innerSpace
                );
              }
              break;

            case "Galego":
              if (categoryData.nameGalician != "") {
                nameArray = doc.splitTextToSize(
                  categoryData.nameGalician.toUpperCase(),
                  innerSpace
                );
              } else {
                nameArray = doc.splitTextToSize(
                  categoryData.name.toUpperCase(),
                  innerSpace
                );
              }
              break;

            default:
              break;
          }

          nameArray.forEach((element, index) => {
            if (index != 0) {
              lastHeight = lastHeight + 14;

              rows = rows + 14;
            }
            doc.text(element, columnOne, lastHeight);
          });

          lastHeight = lastHeight + 10;
          rows = rows + 20;
        };

        let categoryLoop = (menuData, category: string) => {
          if (rows > maxRows) {
            pageTemplate("menus");

            lastHeight = lastHeight + 12;
            rows = rows + 12;
          }

          if (menuData.optionsA[category]) {
            doc.setTextColor(56, 56, 56);
            doc.setFontSize(9);
            let totalHeight = 8;
            let startHeight = lastHeight;
            lastHeight = lastHeight + 10;
            rows = rows + 10;
            switch (formValue.font) {
              case "1":
                doc.setFont("Monserrat", "SemiBold");
                break;

              case "2":
                doc.setFont("acaslonpro", "bold");
                break;

              default:
                break;
            }
            let categoria;

            switch (category) {
              case "Entrantes":
                switch (formValue.language) {
                  case "espanol":
                    categoria = "Entrantes";
                    break;
                  case "Inglés":
                    categoria = "Starters";
                    break;

                  case "Francés":
                    categoria = "Entrées";
                    break;

                  case "Portugués":
                    categoria = "Iniciantes";
                    break;

                  case "Galego":
                    categoria = "Entrantes";
                    break;
                  default:
                    break;
                }

                break;

              case "Plato principal":
                switch (formValue.language) {
                  case "espanol":
                    categoria = "Plato principal";
                    break;
                  case "Inglés":
                    categoria = "Main dish";
                    break;

                  case "Francés":
                    categoria = "Plat principal";
                    break;

                  case "Portugués":
                    categoria = "Prato principal";
                    break;

                  case "Galego":
                    categoria = "Prato principal";
                    break;
                  default:
                    break;
                }

                break;

              case "Postre":
                switch (formValue.language) {
                  case "espanol":
                    categoria = "Postre";
                    break;
                  case "Inglés":
                    categoria = "Dessert";
                    break;

                  case "Francés":
                    categoria = "Dessert";
                    break;

                  case "Portugués":
                    categoria = "Sobremesa";
                    break;

                  case "Galego":
                    categoria = "Sobremesa";
                    break;
                  default:
                    break;
                }

                break;

              default:
                break;
            }

            doc.text(categoria.toUpperCase(), columnOne + 2, lastHeight);

            menuData.optionsA[category].forEach(comida => {
              switch (formValue.font) {
                case "1":
                  doc.setFont("Monserrat", "Regular");
                  break;

                case "2":
                  doc.setFont("acaslonpro", "Regular");
                  break;

                default:
                  break;
              }
              doc.setFontSize(9);

              lastHeight = lastHeight + 9;
              rows = rows + 9;

              let capitalize = (string: string) => {
                return string.charAt(0).toUpperCase() + string.slice(1);
              };
              let nameArray;

              switch (formValue.language) {
                case "espanol":
                  nameArray = doc.splitTextToSize(
                    capitalize(comida.name.toLowerCase()),
                    columnOneSpace
                  );
                  break;

                case "Inglés":
                  if (comida.nameEnglish != "") {
                    nameArray = doc.splitTextToSize(
                      capitalize(comida.nameEnglish.toLowerCase()),
                      columnOneSpace
                    );
                  } else {
                    nameArray = doc.splitTextToSize(
                      capitalize(comida.name.toLowerCase()),
                      columnOneSpace
                    );
                  }
                  break;

                case "Francés":
                  if (comida.nameFrench != "") {
                    nameArray = doc.splitTextToSize(
                      capitalize(comida.nameFrench.toLowerCase()),
                      columnOneSpace
                    );
                  } else {
                    nameArray = doc.splitTextToSize(
                      capitalize(comida.name.toLowerCase()),
                      columnOneSpace
                    );
                  }
                  break;

                case "Portugués":
                  if (comida.namePortuguese != "") {
                    nameArray = doc.splitTextToSize(
                      capitalize(comida.namePortuguese.toLowerCase()),
                      columnOneSpace
                    );
                  } else {
                    nameArray = doc.splitTextToSize(
                      capitalize(comida.name.toLowerCase()),
                      columnOneSpace
                    );
                  }
                  break;

                case "Galego":
                  if (comida.nameGalician != "") {
                    nameArray = doc.splitTextToSize(
                      capitalize(comida.nameGalician.toLowerCase()),
                      columnOneSpace
                    );
                  } else {
                    nameArray = doc.splitTextToSize(
                      capitalize(comida.name.toLowerCase()),
                      columnOneSpace
                    );
                  }
                  break;

                default:
                  break;
              }

              doc.splitTextToSize(
                capitalize(comida.name.toLowerCase()),
                columnOneSpace
              );

              nameArray.forEach((element, index) => {
                if (index != 0) {
                  lastHeight = lastHeight + 7;
                  rows = rows + 7;
                }

                doc.text(element, columnOne + 2, lastHeight);
              });
              // let heightOffset

              // if (totalHeight >= 14) {
              //   heightOffset = startHeight + (totalHeight / 2) + 4
              // }

              //doc.text(comida.name.toLowerCase(), columnOne + 2, lastHeight, { maxWidth: columnOneSpace });

              let allergenPosition;
              let allergenSingleLineOffset;
              let allergenDimention = 7;

              if (nameArray.length > 1) {
                allergenSingleLineOffset = lastHeight - nameArray.length * 4.5;
              } else {
                allergenSingleLineOffset = lastHeight - 6;
              }

              comida.allergens.forEach((element, index) => {
                if (index == 0) {
                  allergenPosition = columnTwo + 2;
                } else {
                  allergenPosition = allergenPosition + allergenDimention + 2;
                }
                if (index == 12) {
                  allergenPosition = columnTwo + 2;
                  allergenSingleLineOffset =
                    allergenSingleLineOffset + allergenDimention + 1;

                  lastHeight = lastHeight + 10;
                  rows = rows + 10;
                }

                let offset = allergenSingleLineOffset;

                switch (element) {
                  case 1:
                    doc.addImage(
                      this.pdfFonts.icons.icon1,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  case 2:
                    doc.addImage(
                      this.pdfFonts.icons.icon2,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  case 3:
                    doc.addImage(
                      this.pdfFonts.icons.icon3,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  case 4:
                    doc.addImage(
                      this.pdfFonts.icons.icon4,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  case 5:
                    doc.addImage(
                      this.pdfFonts.icons.icon5,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  case 6:
                    doc.addImage(
                      this.pdfFonts.icons.icon6,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  case 7:
                    doc.addImage(
                      this.pdfFonts.icons.icon7,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  case 8:
                    doc.addImage(
                      this.pdfFonts.icons.icon8,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  case 9:
                    doc.addImage(
                      this.pdfFonts.icons.icon9,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  case 10:
                    doc.addImage(
                      this.pdfFonts.icons.icon10,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  case 11:
                    doc.addImage(
                      this.pdfFonts.icons.icon11,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  case 12:
                    doc.addImage(
                      this.pdfFonts.icons.icon12,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  case 13:
                    doc.addImage(
                      this.pdfFonts.icons.icon13,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  case 14:
                    doc.addImage(
                      this.pdfFonts.icons.icon14,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  case 15:
                    doc.addImage(
                      this.pdfFonts.icons.icon15,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  case 16:
                    doc.addImage(
                      this.pdfFonts.icons.icon16,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  case 17:
                    doc.addImage(
                      this.pdfFonts.icons.icon17,
                      "png",
                      allergenPosition,
                      offset,
                      allergenDimention,
                      allergenDimention
                    );

                    break;
                  default:
                    break;
                }
              });
            });
          }
        };

        let commentPrice = menuData => {
          lastHeight = lastHeight + 3;

          doc.line(leftMargin, lastHeight, rightMargin, lastHeight);

          lastHeight = lastHeight + 8;

          rows = rows + 11;

          if (menuData.comment != "") {
            switch (formValue.font) {
              case "1":
                doc.setFont("Monserrat", "SemiBold");
                break;

              case "2":
                doc.setFont("acaslonpro", "bold");
                break;

              default:
                break;
            }
            doc.setFontSize(9);

            switch (formValue.language) {
              case "espanol":
                text = "INCLUIDO";
                break;

              case "Inglés":
                text = "INCLUDED";
                break;

              case "Francés":
                text = "incluse";
                break;

              case "Portugués":
                text = "incluído";
                break;

              case "Galego":
                text = "incluído";
                break;

              default:
                break;
            }

            doc.text(text, columnOne + 2, lastHeight);
            switch (formValue.font) {
              case "1":
                doc.setFont("Monserrat", "Regular");
                break;

              case "2":
                doc.setFont("acaslonpro", "Regular");
                break;

              default:
                break;
            }
            doc.setFontSize(9);

            switch (formValue.language) {
              case "espanol":
                doc.text(menuData.comment, columnOne + 40, lastHeight);
                break;

              case "Inglés":
                if (menuData.commentEnglish != "") {
                  doc.text(menuData.commentEnglish, columnOne + 40, lastHeight);
                } else {
                  doc.text(menuData.comment, columnOne + 40, lastHeight);
                }
                break;

              case "Francés":
                if (menuData.commentFrench != "") {
                  doc.text(menuData.comment, columnOne + 40, lastHeight);
                } else {
                  doc.text(menuData.comment, columnOne + 40, lastHeight);
                }
                break;

              case "Portugués":
                if (menuData.commentPortuguese != "") {
                  doc.text(menuData.comment, columnOne + 40, lastHeight);
                } else {
                  doc.text(menuData.comment, columnOne + 40, lastHeight);
                }
                break;

              case "Galego":
                if (menuData.commentGalician != "") {
                  doc.text(menuData.comment, columnOne + 40, lastHeight);
                } else {
                  doc.text(menuData.comment, columnOne + 40, lastHeight);
                }
                break;

              default:
                break;
            }
          }

          switch (formValue.font) {
            case "1":
              doc.setFont("Monserrat", "SemiBold");
              break;

            case "2":
              doc.setFont("acaslonpro", "bold");
              break;

            default:
              break;
          }
          doc.setFontSize(10);
          if (menuData.price == "" || menuData.price == null) {
          } else {
            let price = parseFloat(menuData.price).toFixed(2);
            doc.text(price, columnFour + 2, lastHeight);
          }
        };

        if (menuData.length > 0) {
          pageTemplate("menus");

          menuData.forEach(categoria => {
            categoryName(categoria);

            categoryLoop(categoria, "Entrantes");

            categoryLoop(categoria, "Plato principal");

            categoryLoop(categoria, "Postre");

            commentPrice(categoria);
          });
        }
      };

      cartaLoop();

      menusLoop();
    };

    let lastPage = () => {
      let bgColor = () => {
        doc.addPage(docFormat);

        switch (formValue.coverColor) {
          case "1":
            doc.setFillColor(40, 40, 40);
            break;

          case "2":
            doc.setFillColor(224, 224, 224);
            break;

          case "3":
            doc.setFillColor(255, 72, 103);
            break;

          default:
            break;
        }

        doc.rect(
          0,
          0,
          doc.internal.pageSize.width,
          doc.internal.pageSize.height,
          "F"
        );
      };
      // inputText => ALERGENOS, CÓDIGO QR
      let title = inputText => {
        text = inputText;

        switch (formValue.coverColor) {
          case "1":
            doc.setTextColor(234, 234, 234);
            break;

          case "2":
            doc.setTextColor(40, 40, 40);
            break;

          case "3":
            doc.setTextColor(234, 234, 234);
            break;

          default:
            break;
        }

        switch (formValue.font) {
          case "1":
            doc.setFont("DrukTextWide", "Medium");
            break;

          case "2":
            doc.setFont("trajanpro", "bold");
            break;

          default:
            break;
        }

        doc.setFontSize(17);
        stringWidth =
          doc.getStringUnitWidth(text) * doc.internal.getFontSize() * 0.75;
        textOffset = (doc.internal.pageSize.width - stringWidth) / 2;

        if (inputText == "ALÉRGENOS") {
          if (formValue.qrCode == "1") {
            verticalHeight = (doc.internal.pageSize.height - 120) / 4 - 20;
          } else {
            verticalHeight = (doc.internal.pageSize.height - 120) / 2 - 20;
          }
          doc.text(text, textOffset, verticalHeight);
        } else if (inputText == "CÓDIGO QR") {
          if (formValue.qrCode == "1") {
            verticalHeight = (doc.internal.pageSize.height - 120) / 4 + 155;
            doc.text(text, textOffset, verticalHeight);
          }
        }
      };

      let image = () => {
        if (formValue.qrCode == "1") {
          verticalHeight = (doc.internal.pageSize.height - 120) / 4;
        } else {
          verticalHeight = (doc.internal.pageSize.height - 120) / 2;
        }

        switch (formValue.coverColor) {
          case "1":
            doc.addImage(
              this.pdfFonts.darkBgAllergens,
              "jpg",
              (doc.internal.pageSize.width - 300) / 2,
              verticalHeight,
              300,
              120
            );
            break;

          case "2":
            doc.addImage(
              this.pdfFonts.lightBgAllergens,
              "jpg",
              (doc.internal.pageSize.width - 300) / 2,
              verticalHeight,
              300,
              120
            );
            break;

          case "3":
            doc.addImage(
              this.pdfFonts.darkBgAllergens,
              "jpg",
              (doc.internal.pageSize.width - 300) / 2,
              verticalHeight,
              300,
              120
            );
            break;

          default:
            break;
        }
      };

      let qr = () => {
        if (formValue.qrCode == "1") {
          verticalHeight = (doc.internal.pageSize.height - 120) / 4 + 150;

          switch (formValue.coverColor) {
            case "1":
              doc.addImage(
                this.pdfFonts.darkBgQR,
                "jpg",
                (doc.internal.pageSize.width - 304) / 2,
                verticalHeight,
                304,
                135,
                3
              );
              break;

            case "2":
              doc.addImage(
                this.pdfFonts.lightBgQR,
                "jpg",
                (doc.internal.pageSize.width - 304) / 2,
                verticalHeight,
                304,
                135,
                3
              );
              break;

            case "3":
              doc.addImage(
                this.pdfFonts.darkBgQR,
                "jpg",
                (doc.internal.pageSize.width - 304) / 2,
                verticalHeight,
                304,
                135,
                3
              );
              break;

            default:
              break;
          }

          switch (formValue.size) {
            case "1":
              doc.addImage(QR, "png", 255, 315, 85, 85);
              break;

            case "2":
              doc.addImage(QR, "png", 213, 342, 85, 85);
              break;

            case "3":
              doc.addImage(QR, "png", 252, 264, 85, 85);
              break;

            default:
              break;
          }
        }
      };

      let branding = () => {
        let stringWidth2;

        if (formValue.branding == "1") {
          // ((doc.internal.pageSize.width) - 20) / 2
          text = "DVINUM.ES";
          text2 = " CARTA DIGITAL PARA RESTAURANTES";

          switch (formValue.coverColor) {
            case "1":
              doc.setTextColor(234, 234, 234);
              break;

            case "2":
              doc.setTextColor(40, 40, 40);
              break;

            case "3":
              doc.setTextColor(234, 234, 234);
              break;

            default:
              break;
          }
          doc.setFontSize(9);

          doc.setFont("DrukTextWide", "Medium");
          stringWidth =
            doc.getStringUnitWidth(text) * doc.internal.getFontSize() * 0.75;

          doc.setFont("Monserrat", "Regular");
          stringWidth2 =
            doc.getStringUnitWidth(text2) * doc.internal.getFontSize() * 0.75;

          textOffset =
            (doc.internal.pageSize.width - (stringWidth + stringWidth2)) / 2;

          lastHeight = doc.internal.pageSize.height - 30;

          let imgX = doc.internal.pageSize.width / 2 - 120;
          let text1X = imgX + 20 + 5;
          let text2X = text1X + stringWidth + 3;

          // lastHeight = lastHeight + 30
          doc.addImage(
            this.pdfFonts.logo,
            "pdf",
            imgX,
            lastHeight - 12,
            20,
            20
          );
          doc.setFont("DrukTextWide", "Medium");
          doc.text(text, text1X, lastHeight);
          doc.setFont("Monserrat", "Regular");
          doc.text(text2, text2X, lastHeight, { charSpace: 1 });
        }
      };

      bgColor();

      title("ALÉRGENOS");

      image();

      title("CÓDIGO QR");

      qr();

      branding();
    };

    /////////////////////////////////////
    ////////////// BUILDER //////////////
    /////////////////////////////////////

    if (formValue.cover == "1") {
      docCover("first");
    }
    if (formValue.type == "1" || formValue.type == "2") {
      foods();
    }

    if (formValue.cover == "1" && formValue.type == "1") {
      docCover("wine");
    }

    if (
      formValue.cover == "1" ||
      formValue.type == "3" ||
      formValue.type == "1"
    ) {
      wines();
    }

    lastPage();

    doc
      .save(`Restaurant ${this.restauranteToEdit.nombre}.pdf`, {
        returnPromise: true
      })
      .then(() => {
        this.loading = false;

        // this._router.routeReuseStrategy.shouldReuseRoute = () => false;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  isOdd(num) {
    return num % 2;
  }
}
