import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';
import { FirestoreProviderService } from 'src/app/shared/firestore-provider.service';

@Component({
  selector: 'app-asignar-vinos',
  templateUrl: './asignar-vinos.component.html',
  styleUrls: ['./asignar-vinos.component.scss']
})
export class AsignarVinosComponent implements OnInit {
  id: string;
  restauranteToEdit = {
    nombre: ''
  };
  subs = new Subscription();
  public loading: boolean = false;
  constructor(
    private _router: Router,
    private _firestore: AngularFirestore,
    private _functions: AngularFireFunctions,
    private _firestoreProvider: FirestoreProviderService,
    private _route: ActivatedRoute,
    private notify: NotifierService
    ) { }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.subs.add(
      this._route.paramMap.subscribe(params => {
        this.id = params.get('id')
      })
    )
    this.getRestaurante();
    this.getVinos();
    this.getVinosRest();
  }


  coleccionVinos = []
  source = this.coleccionVinos;
  target = []

  display = 'nombre'
  key = 'id'
  format = { add: 'Añadir', remove: 'Remover', all: 'Todos', none: 'Ninguno' };

  getRestaurante() {
    this._firestoreProvider.getCollection('restaurantes').doc(this.id).get().toPromise().then((restaurante: any) => {

      this.restauranteToEdit = {
        nombre: restaurante.data().nombre,
      }
    })
  }

  getVinosRest() {
    this._firestoreProvider.getCollection(this.id).doc('vinos').get().toPromise().then((doc: any) => {

      this.target = doc.data().vinos;
    })
  }

  getVinos() {
    this._firestoreProvider.getCollection('vinosStock').get().toPromise().then((doc) => {

      let wineQuery = doc.docs.map((wine) => wine.data())
      wineQuery.forEach(ele => {

        let vino = {
          nombre: ele.name,
          id: ele.id
        }
        this.coleccionVinos.push(vino)
      });
    })
  }

  updateVinos() { 
    this.loading = true;
    this._firestoreProvider.getCollection(this.id).doc('vinos').set(
      { vinos: this.target }, { merge: true }
    ).then(() => {
      let updateStats = this._functions.httpsCallable('generateStatsForWine');
      return updateStats({
        restaurantUID: this.id
      })
    })
    .then(() => {
      this.notify.notify('success', 'Vinos actualizados')
      this._router.navigateByUrl('/restaurantes');
    })
    .catch((err)=>{
      this.notify.notify('danger', 'Hubo un problema')
    })
  }

  showResult() {
    this.updateVinos()
  }

}
