<div class="div card card-box">
    <div class="card-header d-flex justify-content-between pr-5">
        <h4 class="font-size-md mb-0 py-2 font-weight-bold">
            {{cardTitle}}
        </h4>
        <div *ngIf="chart==='area'">
            <button 
                (click)="changeAreaSelected('views')"
                [ngClass]="{'btn-primary': selectedArea === 'views', 'text-light': selectedArea === 'views'}"
                class="btn btn-headerbar btn-sm btn-sm-m btn-outline-primary"
                type="button"
            >Visitas</button>
            <button 
            (click)="changeAreaSelected('wines')"
                [ngClass]="{'btn-primary': selectedArea === 'wines', 'text-light': selectedArea === 'wines'}"
                class="btn btn-headerbar btn-sm btn-sm-m btn-outline-primary"
                type="button"
            >Vinos</button>
            <button 
                (click)="changeAreaSelected('rest')"
                [ngClass]="{'btn-primary': selectedArea === 'rest', 'text-light': selectedArea === 'rest'}"
                class="btn btn-headerbar btn-sm btn-sm-m btn-outline-primary"
                type="button"
            >Restaurantes</button>
        </div>
        <div *ngIf="chart==='bar'">
            <button 
                (click)="changeBarSelected('comercial')"
                [ngClass]="{'btn-primary': selectedBar === 'comercial', 'text-light': selectedBar === 'comercial'}"
                class="btn btn-headerbar btn-sm btn-sm-m btn-outline-primary"
                type="button"
                >Comerciales</button>
            <button 
                (click)="changeBarSelected('wines')"
                [ngClass]="{'btn-wine': selectedBar === 'wines', 'text-light': selectedBar === 'wines'}"
                class="btn btn-headerbar btn-sm btn-sm-m btn-outline-wine"
                type="button"
            >Vinos</button>
            <div *ngIf="selectedBar === 'wines'" class="btn-group m-2 ml-md-3 dropdown-index" dropdown>
                <div dropdownToggle>
                    <fa-icon [icon]="['fas', 'bars']"></fa-icon>
                </div>
                <ul *dropdownMenu class="dropdown-menu dropdown-to-left bring-to-front " role="menu">
                    <li *ngFor="let month of (months|async); index as i; trackBy: trackMonthList" role="menuitem">
                        <a class="dropdown-item" (click)="changeBarSelected('wines','month',i+1)">
                            {{month}}
                        </a>
                    </li>
                </ul>   
            </div>
        </div>
    </div>
    <div class="card-body card-spacing item-container card--height overflow-hidden">
        <div *ngIf="chart==='bar' else areaChart" class="mt-3">
            <apx-chart
                [chart]="{
                    type: 'bar',
                    height: '380',
                    toolbar: {
                        show: false
                    }
                }"
                [colors]="barData.colors || ['#ff7955', '#f83245']"
                [plotOptions]="{
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            position: 'top'
                            }
                        }
                }"
                [dataLabels]="{
                    offsetX: -10
                }"
                [fill]="{
                    opacity: 0.85,
                    colors: barData.colors || ['#ff7955', '#D23078']
                }"
                [grid]="{
                    strokeDashArray: '5',
                    borderColor: 'rgba(125, 138, 156, 0.3)'
                }"
                [series]="[
                    {
                        name: barData.dataName,
                        data: barData.data || []
                    }
                ]"
                [xaxis]="{
                    categories: barData.names || []
                }"
            ></apx-chart>
        </div>
        <ng-template #areaChart>
            <apx-chart
                [chart]="{
                    type: 'area',
                    height: '380',
                    toolbar: {
                        show: false
                    }
                }"
                [colors]="['#d23078', '#ff7955']"
                [dataLabels]="{
                    enabled: false
                }"
                [fill]="{
                    opacity: 1,
                    colors: ['#d23078', '#ff7955']
                }"
                [grid]="{
                    strokeDashArray: '5',
                    borderColor: 'rgba(125, 138, 156, 0.3)'
                }"
                [series]="areaData.data"
                [stroke]="{
                    curve: 'smooth'
                }"
                [xaxis]="{
                    categories: areaData.months
                }"
                [yaxis]="{
                    min: 0,
                    tickAmount: 6,
                    forceNiceScale: true
                }"
            ></apx-chart>
        </ng-template>
    </div>
  </div>