import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Input() list: Array<unknown>;
  @Input() identProp: string;
  @Input() valueProp: string;
  @Input() appendValue: string = '';
  @Input() subtitle: string = '';
  @Input() icon: string;

  constructor() { }
  ngOnInit() { }

  trackValueProp(index, item) {
    return item.valueProp
  }

}
