// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    // apiKey: "AIzaSyApLT0jH8ALmsjTk39901rCtvE8JCJtw80",
    // authDomain: "dvinum-backup.firebaseapp.com",
    // databaseURL: "https://dvinum-backup.firebaseio.com",
    // projectId: "dvinum-backup",
    // storageBucket: "dvinum-backup.appspot.com",
    // messagingSenderId: "33959501347",
    // appId: "1:33959501347:web:ed6bd33f196b847e039b96",
    apiKey: "AIzaSyDE9PoRLbR_OgOkmmDwM6wt6pWOPM3dcSA",
    authDomain: "dvinum-ec91c.firebaseapp.com",
    databaseURL: "https://dvinum-ec91c.firebaseio.com",
    projectId: "dvinum-ec91c",
    storageBucket: "dvinum-ec91c.appspot.com",
    messagingSenderId: "894350003351",
    appId: "1:894350003351:web:51d570e171c5bfe04d2dd2",
    measurementId: "G-VE9T3MHMHF"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
