<app-page-title titleDescription='' titleHeading='Configurar carta'></app-page-title>
<div id="qrcode-container">

  <div id="qrcode">
    <qrcode [elementType]="'img'" [qrdata]="qrcode" [width]="120" [errorCorrectionLevel]="'M'"></qrcode>
  </div>
</div>

<div class="mx-4">
  <form [formGroup]="carta" (submit)="onSubmit(carta.value)">

    <div class="card card-box mb-5 border-0">
      <div class="card-header border-0">
        <div class="card-header--title">
          <h5 *ngIf='!director' class="mb-0 py-2 font-weight-bold">
            {{restauranteToEdit.nombre}}
          </h5>
          <h5 *ngIf='director' class="mb-0 py-2 font-weight-bold">
            {{restName}}
          </h5>
        </div>
        <div [formGroup]="carta" class="card-header--actions">
          <button *ngIf="loading" class="btn btn-sm m-2 btn-success d-flex  align-items-center" disabled type="button">
            <span aria-hidden="true" class="spinner-border spinner-border-sm " role="status"></span>
            <span class="ml-2">PROCESANDO...</span>
          </button>
          <button *ngIf="!loading" class="btn btn-sm m-2 btn-success" type="submit">
            <span class="btn-wrapper--icon">
              <fa-icon [icon]="['fas', 'plus']"></fa-icon>
            </span>
            <span class="btn-wrapper--label ">GENERAR CARTA.</span>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-4">
            <div class="position-relative form-group">
              <label for="language">Idioma</label>
              <!-- <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.nombre.errors?.required"> *</span> -->

              <select formControlName="language" class="form-control" id="language" name="language">
                <option value="espanol">Español</option>
                <option *ngFor="let lang of langs; index as i" value="{{lang}}">{{lang}}</option>
              </select>

            </div>
          </div>
          <div class="col-md-4">
            <div class="position-relative form-group">
              <label for="type">Tipo</label>
              <!-- <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.provincia.errors?.required"> *</span> -->
              <select formControlName="type" class="form-control" id="type" name="type">
                <option value="1">Carta y Vinos</option>
                <option value="2">Solo Carta</option>
                <option value="3">Solo Vinos</option>
              </select>

            </div>
          </div>
          <div class="col-md-4">
            <div class="position-relative form-group">
              <label for="size">Tamaño</label>
              <select formControlName="size" class="form-control" id="size" name="size">
                <option value="1">Normal</option>
                <option value="2">Estrecha</option>
                <option value="3">Cuadrada</option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-4">
            <div class="position-relative form-group">
              <label for="pagination">Paginación</label>
              <select formControlName="pagination" class="form-control" id="pagination" name="pagination">
                <option value="1">2 caras </option>
                <option value="2">1 cara</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="position-relative form-group">
              <label for="spacing">margen</label>
              <!-- <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.email.errors?.required"> *</span> -->
              <select formControlName="spacing" class="form-control" id="spacing" name="spacing">
                <option value="1">Con Sangría</option>
                <option value="2">Sin Sangría</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="position-relative form-group">
              <label for='font'>Tipografía</label>
              <!-- <span *ngIf="!isSubmitted"> *</span>
                  <span class="text-danger" *ngIf="isSubmitted && formControls.nombreResponsable.errors?.required"> *</span> -->
              <select formControlName="font" class="form-control" id="font" name="font">
                <option value="1">Moderna</option>
                <option value="2">Clasica</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label for="cover">Portadas</label>
              <!-- <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.numeroRestaurante.errors?.required">
                  *</span> -->
              <select formControlName="cover" class="form-control" id="cover" name="cover">
                <option value="1">Con portada</option>
                <option value="2">Sin portada</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label for='coverColor'>Color de portada</label>
              <!-- <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.nombreResponsable.errors?.required"> *</span> -->
              <select formControlName="coverColor" class="form-control" id="coverColor" name="coverColor">
                <option value="1">Oscuro</option>
                <option value="2">Claro</option>
                <option value="3">Color</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label for='branding'>Marca Dvinum</label>
              <!-- <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.numeroResponsable.errors?.required"> *</span> -->
              <select formControlName="branding" class="form-control" id="branding" name="branding">
                <option value="1">Con Marca</option>
                <option value="2">Sin Marca</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label for='restName'>Nombre de Restaurante</label>
              <!-- <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.comercial.errors?.required"> *</span> -->
              <select formControlName="restName" class="form-control" id="restName" name="restName">
                <option value="1">Con Nombre</option>
                <option value="2">Sin Nombre</option>
              </select>
            </div>
          </div>

        </div>
        <div class="form-row">
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label for='qrCode'>Codigo QR</label>
              <!-- <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.comercial.errors?.required"> *</span> -->
              <select formControlName="qrCode" class="form-control" id="qrCode" name="qrCode">
                <option value="1">Con Codigo</option>
                <option value="2">Sin Codigo</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label for='varietyColumn'>Columna de variedad</label>
              <!-- <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.comercial.errors?.required"> *</span> -->
              <select formControlName="varietyColumn" class="form-control" id="varietyColumn" name="varietyColumn">
                <option value="1">Con Columna</option>
                <option value="2">Sin Columna</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label for='formatColumn'>Columna de formato</label>
              <!-- <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.comercial.errors?.required"> *</span> -->
              <select formControlName="formatColumn" class="form-control" id="formatColumn" name="formatColumn">
                <option value="1">Con Columna</option>
                <option value="2">Sin Columna</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label for='alergenColumn'>Columna de alergenos</label>
              <!-- <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.comercial.errors?.required"> *</span> -->
              <select formControlName="alergenColumn" class="form-control" id="alergenColumn" name="alergenColumn">
                <option value="1">Con Columna</option>
                <option value="2">Sin Columna</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

</div>
