<app-page-title titleDescription='' titleHeading='Distribuidores'></app-page-title>

<div class="card card-box mx-4 mb-5 border-0">
  <div class="card-header pr-2 border-0">
    <div class="card-header--title ">
      <div class="search-wrapper">
        <span class="icon-wrapper text-black">
          <fa-icon [icon]="['fas', 'search']"></fa-icon>
        </span>
        <input (keyup)="buscador($event)" class="form-control form-control-sm rounded-pill" placeholder="Buscar..." type="search">
      </div>
    </div>
    <div class="card-header--actions">

      <button [routerLink]="['/distribuidor']" class="btn btn-sm m-2 btn-success" type="button">
        <span class="btn-wrapper--icon">
          <fa-icon [icon]="['fas', 'plus']"></fa-icon>
        </span>
        <span class="btn-wrapper--label ">AÑADIR DISTRIBUIDOR</span>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="table-responsive col-md-12">
      <table class="table table-hover text-nowrap mb-0 table-borderless">
        <thead class="bg-primary">
          <tr class="bg-primary">
            <th class="text-center bg-primary text-light">Nombre</th>
            <th class="text-center bg-primary text-light">Email</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            *ngFor="let director of distribuidores | paginate: { itemsPerPage: 10, currentPage: page } ; index as i">
            <!-- <td>{{director.id}}</td> -->
            <td [routerLink]="['/distribuidor', director.id]">
              <div class="d-flex align-items-center ">
                <div class="avatar-icon-wrapper mx-4">
                  <div class="avatar-icon p-2 bg-slick-carbon"><img alt="..." src="assets/images/user-icon.png" /></div>
                </div>
                <div>
                  <a class="font-weight-bold text-black" title="...">
                    {{director.name}}
                  </a>
                </div>
              </div>
            </td>
            <td [routerLink]="['/distribuidor', director.id]" class="text-center">
              <div class=" text-black-50 d-block">{{director.email}}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="card-footer py-3 d-flex justify-content-between">
    <pagination-controls class="pagination" (pageChange)="page = $event"></pagination-controls>

  </div>
</div>