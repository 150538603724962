import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import { NotifierService } from 'angular-notifier';


@Component({
  selector: 'app-pages-login',
  templateUrl: './pages-login.component.html',
  host: {'class': 'w-100 d-flex align-items-center'}
})
export class PagesLoginComponent implements OnInit  {


  ngOnInit(){
  }


  login = new FormGroup({
    email: new FormControl(''),
    password: new FormControl('')
  });

  constructor(
    public _authService:AuthService,
    public _auth: AngularFireAuth,
    private _router: Router,
    private notifier: NotifierService,
  ){

  }

  onSubmit() {

    let email = this.login.value.email + "@kore.com"
    let password = this.login.value.password

    this._auth.auth.signInWithEmailAndPassword(email,password).then(()=> {
      this._router.navigate(['/vinos']);
    }).catch((error)=> {
    });

  }

}
