import { Component, OnInit, TemplateRef, ElementRef, OnDestroy, NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirestoreProviderService } from 'src/app/shared/firestore-provider.service';
import { finalize, first } from "rxjs/operators";
import { Router } from '@angular/router';

import { ViewChild, EventEmitter, Output } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';
import { async } from '@angular/core/testing';
import { AuthService } from 'src/app/shared/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { Subscription } from 'rxjs';
//import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-editar-vino',
  templateUrl: './editar-vino.component.html',
  styleUrls: ['./editar-vino.component.scss']
})
export class EditarVinoComponent implements OnInit, OnDestroy {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  isSubmitted: boolean;
  modalRef: BsModalRef;
  image
  showCropper = false;
  message////////////////

  showAddBodega = false;
  showDeleteBodega = false

  showAddDo = false;
  showDeleteDo = false

  bodegas: any;
  dos: any;

  id: any;
  vinoToEdit = {
    nombre: '',
    bodega: '',
    do: '',
    anada: '',
    volumen: '',
    tipo: '',
    continente: '',
    pais: '',
    desc: '',
    uva: '',
    image: '',
    active: '',
    owned: '',
    info: '',
    creado: '',
    eco: '',
    provincias: [],
  }

  public provinciaSelectorKey = "Nombre";
  public selectedProvincias = [];
  public showProvinciaExclusiva: boolean = false;
  public loading: boolean = false;

  @ViewChild('desc', { static: true }) descInput: ElementRef;
  @ViewChild('nombre', { static: true }) nombreInput: ElementRef;
  @ViewChild('bodega', { static: true }) bodegaInput: ElementRef;
  @ViewChild('do', { static: true }) doInput: ElementRef;
  @ViewChild('anada', { static: true }) anadaInput: ElementRef;
  @ViewChild('volumen', { static: true }) volumenInput: ElementRef;
  @ViewChild('tipo', { static: true }) tipoInput: ElementRef;
  @ViewChild('continente', { static: true }) continenteInput: ElementRef;
  @ViewChild('pais', { static: true }) paisInput: ElementRef;
  @ViewChild('uva', { static: true }) uvaInput: ElementRef;
  @ViewChild('successProvinciaModal', { static: true }) successProvinciaModal: TemplateRef<any>;
  @ViewChild('duplicateProvinciaModal', { static: true }) duplicateProvinciaModal: TemplateRef<any>;

  @ViewChild("uvaTable", { static: true })
  public _uvaTable: ElementRef;
  public get uvaTable(): HTMLElement {
    return this._uvaTable.nativeElement;
  }

  variedades: any = ''

  checkType(element) {

    if (typeof (element) == 'object') {
      return true
    } else {
      return false
    }
  }

  saveVariedades() {
    let table = Array.from(document.querySelectorAll(".variedad"))
    this.variedades = []

    // let tableChild = table.map((element: any) => element.value)

    table.forEach((element: any) => {
      if (element.checked) {
        this.variedades.push(element.value)
      }
    });
    if (this.variedades.length > 0) {
      if (this.variedades.length == 1) {
        this.variedades = this.variedades[0]
      }
    } else {
      this.variedades = ''
    }

    this.closeModal()

  }


  nuevoVino = new FormGroup({
    nombre: new FormControl(''),
    bodega: new FormControl(''),
    do: new FormControl(''),
    anada: new FormControl(''),
    volumen: new FormControl(''),
    tipo: new FormControl(''),
    continente: new FormControl('', Validators.required),
    uva: new FormControl(''),
    pais: new FormControl('', Validators.required),
    desc: new FormControl,
    image: new FormControl(''),
  })

  nuevaBodega = new FormGroup({
    nombre: new FormControl('', Validators.required)
  })

  nuevoDo = new FormGroup({
    nombre: new FormControl('', Validators.required)
  })

  nuevoVolumen = new FormGroup({
    nombre: new FormControl('', Validators.required)
  })

  nuevaUva = new FormGroup({
    nombre: new FormControl('', Validators.required)
  })

  nuevoPais = new FormGroup({
    nombre: new FormControl('', Validators.required)
  })

  showDeleteVolumen: boolean;
  showAddVolumen: boolean;
  volumenes: any;
  showEditBodega: boolean;
  itemToEditId: string;
  itemToEdit: any;
  showEditDo: boolean;
  showEditVolumen: boolean;
  showDeleteUva: boolean;
  showAddUva: boolean;
  showEditUva: boolean;
  uvas: any;
  showDeletePais: boolean;
  showAddPais: boolean;
  showEditPais: boolean;
  paises: any;
  restFilter: Array<any>;
  restaurantsWineActive: boolean = false;
  subs = new Subscription();
  
  //activeRestInfo: any;

  constructor(
    private _router: Router,
    private modalService: BsModalService,
    private _route: ActivatedRoute,
    private _storage: AngularFireStorage,
    private _firestoreProvider: FirestoreProviderService,
    private _firestore: AngularFirestore,
    private _auth: AuthService,
    private _afAuth: AngularFireAuth,
    private ngZone: NgZone,
  ) { }


  ngOnInit() {

    this.subs.add(
      this._route.paramMap.subscribe(params => {
        this.id = params.get('id');
      }))
    //this.deleteVino(this.id)
    this.getBodegas();
    this.getDos();
    this.getVino();
    this.getVolumen();
    this.getUvas();
    this.getPais();
    this.getUvasPromise()
    // this.isLoggedIn();
    // this.doSomething();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  duplicate() {

    this.subs.unsubscribe();

    let id = this._firestore.createId();
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    let todayDate = dd + '/' + mm + '/' + yyyy;

    let owned

    if (this.vinoToEdit.owned) {
      owned = this.vinoToEdit.owned
    } else {
      owned = ''
    }

    let duplicated = {
      name: this.vinoToEdit.nombre + " COPIA",
      DO: this.vinoToEdit.do,
      bodega: this.vinoToEdit.bodega,
      color: this.vinoToEdit.tipo,
      creado: todayDate,
      volumen: this.vinoToEdit.volumen,
      desc: this.vinoToEdit.desc,
      uva: this.vinoToEdit.uva,
      pais: this.vinoToEdit.pais,
      continente: this.vinoToEdit.continente,
      own: owned,
      id: id,
      img: this.vinoToEdit.image,
      year: this.vinoToEdit.anada,
      info: this.vinoToEdit.info,
      active: false,
      provincias: this.selectedProvincias
    }







    this._firestoreProvider.getCollection('vinosStock').doc(id).set(duplicated, { merge: true }).then(() => {

      this.id = id
      // this.getVino();



      this._router.routeReuseStrategy.shouldReuseRoute = () => false;

      this._router.navigate(["/editar-vino", this.id]);

      // this.ngZone.run(() => this._router.navigateByUrl(`editar-vino/${this.id}`))
      // this.ngOnInit()


    })
  }

  availableList = []
  selectedList = []

  openVariedadModal(template: TemplateRef<any>, available, selected) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
    this.availableList = available
    this.selectedList = selected

    setTimeout(() => {

      if (typeof (this.selectedList) == 'object') {

        this.selectedList.forEach((element, index) => {
          let a: any = document.getElementById(element)

          a.checked = true
        })
      } else {

        let a: any = document.getElementById(this.selectedList)
        a.checked = true
      }

    }, 100);


  }
  // HERE #2
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }

  openEditModal(template, edit, id) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
    this.itemToEdit = edit
    this.itemToEditId = id
  }

  closeModal() {
    this.modalRef.hide()
  }
  // HERE #2 -


  getVino() {
    this._firestoreProvider.getCollection('vinosStock').doc(this.id).get().toPromise().then((vinoData) => {
      let vino = vinoData.data();
      this.selectedProvincias = vino.provincias || [];
      this.vinoToEdit = {
        nombre: vino.name,
        bodega: vino.bodega,
        do: vino.DO,
        anada: vino.year,
        volumen: vino.volumen,
        tipo: vino.color,
        continente: vino.continente,
        pais: vino.pais,
        desc: vino.desc,
        uva: vino.uva,
        owned: vino.own,
        eco: vino.eco,
        image: vino.img,
        info: vino.info,
        active: vino.active,
        creado: vino.creado,
        provincias: vino.provincias || [],
      }

      if (vino.own) {
        let ownCheck: any = document.getElementById('ownCheck');
        this.toggleOwn();
        ownCheck.checked = vino.own
      }
      if (vino.eco) {
        let ecoCheck: any = document.getElementById('ecoWine');
        ecoCheck.checked = vino.eco

      }
      if (vino.uva) {
        //let ecoCheck: any = document.getElementById('ecoWine');
        this.variedades = vino.uva

      }
    })
  }



  deleteVino(id) {
    let stop = false
    this.restFilter = []
    this._firestoreProvider.getCollection('restaurantes').get().toPromise().then((doc) => {
      let docs = doc.docs
      docs.forEach((elementi, i) => {
        let document = elementi.data()
        let restId = document.id
        let restName = document.nombre
        // if (i) {

        this._firestoreProvider.getCollection(restId).doc('vinos').get().toPromise().then((docE) => {
          let wines = docE.data().vinos
          if (wines.length > 0) {
            let filtered = wines.filter((item) => {
              return item.id == id
            })[0]
            if (filtered) {
              this._firestoreProvider.getCollection(restId).doc('vinos').update({
                'vinos': firebase.firestore.FieldValue.arrayRemove(filtered)
              })
            }
          }
          // wines.forEach((elemente, e) => {
          // })
        })
        // }
        if (i == (docs.length - 1)) {
          this._firestoreProvider.getCollection('vinosStock').doc(id).delete().then(() => {
            this.closeModal()
            this._router.navigateByUrl('/vinos');
          })
        }
      })
    })

  }
  ///////////////////////////////////////////////////////////////////////////////////////////



  addPais(): void {
    this.showDeletePais = false
    this.showAddPais = true
    this.showEditPais = false
  }
  editPais(): void {
    this.showEditPais = true
    this.showDeletePais = false
    this.showAddPais = false
  }
  deletePais(): void {
    this.showDeletePais = true
    this.showAddPais = false
    this.showEditPais = false
  }
  getPais() {
    this.paises = this._firestoreProvider.getCollectionAndSort('paises', 'name').valueChanges();
  }
  onPaisSubmit(formValue: { nombre: any; }) {
    if (this.nuevoPais.valid) {
      let id = this._firestore.createId();
      this._firestoreProvider.getCollection('paises').doc(id).set({
        name: formValue.nombre,
        id: id
      }).then(() => {
        this.nuevoVino.patchValue({ pais: formValue.nombre });
        this.closeModal();
        this.nuevoPais.setValue({ nombre: '' })
      });
    }
  }
  onPaisDelete(id) {
    this._firestoreProvider.getCollection('paises').doc(id).delete();
  }

  queryWinesByPais(item) {
    let newPais: any
    newPais = document.getElementById('paisToEdit')

    this._firestoreProvider.getCollection('paises').doc(this.itemToEditId).update({ name: newPais.value }).then(() => {

      this._firestoreProvider.getCollectionWhere('vinosStock', `paises == ${item}`).get().toPromise().then((doc) => {
        doc.forEach(element => {
          let id = element.data().id
          this._firestoreProvider.getCollection('vinosStock').doc(id).update({ pais: newPais.value })
        });
      })
    }).then(() => {
      this.closeModal();
    })

  }

  ////////////////////////////////////////////////////////////////////////////////////////

  // HERE #1
  addUva(): void {
    this.showDeleteUva = false
    this.showAddUva = true
    this.showEditUva = false
  }

  editUva(): void {
    this.showEditUva = true
    this.showDeleteUva = false
    this.showAddUva = false
  }
  deleteUva(): void {
    this.showAddUva = false
    this.showDeleteUva = true
    this.showEditUva = false
  }


  onUvaSubmit(formValue: { nombre: any; }) {
    if (this.nuevaUva.valid) {
      let id = this._firestore.createId();
      this._firestoreProvider.getCollection('uvas').doc(id).set({
        name: formValue.nombre,
        id: id
      }).then(() => {
        this.nuevoVino.patchValue({ uva: formValue.nombre });
        this.closeModal();
        this.nuevaUva.setValue({ nombre: '' })
        this.getUvasPromise()
      });
    }
  }

  onUvaDelete(id) {
    this._firestoreProvider.getCollection('uvas').doc(id).delete()
  }


  getUvas() {
    this.uvas = this._firestoreProvider.getCollectionAndSort('uvas', 'name').valueChanges();
  }
  uvaspromise
  getUvasPromise() {
    this.uvaspromise = []
    this._firestoreProvider.getCollectionAndSort('uvas', 'name').get().toPromise().then((doc) => {
      doc.forEach((element) => {
        this.uvaspromise.push(element.data().name)
      })
    })
  }


  queryWinesByUva(item) {
    let newUva: any
    newUva = document.getElementById('uvaToEdit')

    this._firestoreProvider.getCollection('uvas').doc(this.itemToEditId).update({ name: newUva.value }).then(() => {

      this._firestoreProvider.getCollectionWhere('vinosStock', `uva == ${item}`).get().toPromise().then((doc) => {
        doc.forEach(element => {
          let id = element.data().id
          this._firestoreProvider.getCollection('vinosStock').doc(id).update({ uva: newUva.value })
        });
      })
    }).then(() => {
      this.closeModal();
    })

  }
  // HERE #1 -FIN
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////



  addVolumen(): void {
    this.showDeleteVolumen = false
    this.showAddVolumen = true
    this.showEditVolumen = false
  }
  editVolumen(): void {
    this.showEditVolumen = true
    this.showDeleteVolumen = false
    this.showAddVolumen = false
  }
  deleteVolumen(): void {
    this.showDeleteVolumen = true
    this.showAddVolumen = false
    this.showEditVolumen = false
  }
  getVolumen() {
    this.volumenes = this._firestoreProvider.getCollection('volumenes').valueChanges();
  }
  onVolumenSubmit(formValue: { nombre: any; }) {
    if (this.nuevoVolumen.valid) {
      let id = this._firestore.createId();
      this._firestoreProvider.getCollection('volumenes').doc(id).set({
        name: formValue.nombre,
        id: id
      }).then(() => {
        this.nuevoVino.patchValue({ volumen: formValue.nombre });
        this.closeModal();
        this.nuevoVolumen.setValue({ nombre: '' })
      });
    }
  }
  onVolumenDelete(id) {
    this._firestoreProvider.getCollection('volumenes').doc(id).delete();
  }


  queryWinesByVolumen(item) {
    let newVolumen: any
    newVolumen = document.getElementById('volumenToEdit')

    this._firestoreProvider.getCollection('volumenes').doc(this.itemToEditId).update({ name: newVolumen.value }).then(() => {

      this._firestoreProvider.getCollectionWhere('vinosStock', `volumenes == ${item}`).get().toPromise().then((doc) => {
        doc.forEach(element => {
          let id = element.data().id
          this._firestoreProvider.getCollection('vinosStock').doc(id).update({ volumen: newVolumen.value })
        });
      })
    }).then(() => {
      this.closeModal();
    })

  }


  ///////////////////////////////////////////////////////////////////////////



  addBodega(): void {
    this.showDeleteBodega = false
    this.showAddBodega = true
    this.showEditBodega = false
  }

  editBodega(): void {
    this.showEditBodega = true
    this.showDeleteBodega = false
    this.showAddBodega = false
  }
  deleteBodega(): void {
    this.showAddBodega = false
    this.showDeleteBodega = true
    this.showEditBodega = false
  }
  getBodegas() {
    this.bodegas = this._firestoreProvider.getCollectionAndSort('bodegas', 'name').valueChanges();
  }
  onBodegaSubmit(formValue) {
    if (this.nuevaBodega.valid) {
      let id = this._firestore.createId();
      this._firestoreProvider.getCollection('bodegas').doc(id).set({
        name: formValue.nombre,
        id: id
      }).then(() => {
        this.nuevoVino.patchValue({ bodega: formValue.nombre });
        this.closeModal();
        this.nuevaBodega.setValue({ nombre: '' })
      });
    }
  }
  onBodegaDelete(id) {
    this._firestoreProvider.getCollection('bodegas').doc(id).delete();
  }


  queryWinesByBodega(item) {
    let newBodega: any
    newBodega = document.getElementById('bodegaToEdit')

    this._firestoreProvider.getCollection('bodegas').doc(this.itemToEditId).update({ name: newBodega.value }).then(() => {

      this._firestoreProvider.getCollectionWhere('vinosStock', `bodega == ${item}`).get().toPromise().then((doc) => {
        doc.forEach(element => {
          let id = element.data().id
          this._firestoreProvider.getCollection('vinosStock').doc(id).update({ bodega: newBodega.value })
        });
      })
    }).then(() => {
      this.closeModal();
    })

  }


  ///////////////////////////////////////////////////////////////////////////


  addDo(): void {
    this.showAddDo = true
    this.showDeleteDo = false
    this.showEditDo = false
  }
  editDo(): void {
    this.showEditDo = true
    this.showAddDo = false
    this.showDeleteDo = false
  }
  deleteDo(): void {
    this.showDeleteDo = true
    this.showAddDo = false
    this.showEditDo = false
  }
  getDos() {
    this.dos = this._firestoreProvider.getCollectionAndSort('DOs', 'name').valueChanges();
  }
  onDoSubmit(formValue) {
    if (this.nuevoDo.valid) {
      let id = this._firestore.createId();
      this._firestoreProvider.getCollection('DOs').doc(id).set({
        name: formValue.nombre,
        id: id
      }).then(() => {
        this.nuevoVino.patchValue({ do: formValue.nombre });
        this.closeModal();
        this.nuevoDo.setValue({ nombre: '' })
      });
    }
  }
  onDoDelete(id) {
    this._firestoreProvider.getCollection('DOs').doc(id).delete();
  }


  queryWinesByDO(item) {
    let newDo: any
    newDo = document.getElementById('doToEdit')

    this._firestoreProvider.getCollection('DOs').doc(this.itemToEditId).update({ name: newDo.value }).then(() => {

      this._firestoreProvider.getCollectionWhere('vinosStock', `DO == ${item}`).get().toPromise().then((doc) => {
        doc.forEach(element => {
          let id = element.data().id
          this._firestoreProvider.getCollection('vinosStock').doc(id).update({ DO: newDo.value })
        });
      })
    }).then(() => {
      this.closeModal();
    })

  }




  public inputValue = {

    nombre: "",
    bodega: "",
    do: "",
    anada: "",
    volumen: "",
    tipo: "",
    continente: '',
    uva: '',
    pais: '',
    desc: "",
    provincias:[]
  }


  getInput() {
    // this.descValue = this.descInput.nativeElement.value

    this.inputValue = {

      nombre: this.nombreInput.nativeElement.value,
      bodega: this.bodegaInput.nativeElement.value,
      do: this.doInput.nativeElement.value,
      anada: this.anadaInput.nativeElement.value,
      volumen: this.volumenInput.nativeElement.value,
      tipo: this.tipoInput.nativeElement.value,
      continente: this.continenteInput.nativeElement.value,
      uva: this.variedades,
      pais: this.paisInput.nativeElement.value,
      desc: this.descInput.nativeElement.value,
      provincias: this.selectedProvincias

    }

  }

  //////////////////////////////////////////////////////////////////////////////////////////////

  onSubmit(formValue) {
    this.isSubmitted = true
    this.getInput()
    let ownCheck: any = document.getElementById('ownCheck');
    let ecoCheck: any = document.getElementById('ecoWine');
    let uva: any = document.getElementById('uva');

    let progress = 100

    if (this.inputValue.desc == '') {
      progress = progress - 8.3
    }
    if (this.inputValue.uva == '') {
      progress = progress - 8.3
    }
    if (this.inputValue.pais == '') {
      progress = progress - 8.3
    }

    if (formValue.image == '') {

      this.loading = true;
      this._firestoreProvider.getCollection('vinosStock').doc(this.id).update({
        name: this.inputValue.nombre,
        DO: this.inputValue.do,
        bodega: this.inputValue.bodega,
        color: this.inputValue.tipo,
        volumen: this.inputValue.volumen,
        desc: this.inputValue.desc,
        year: this.inputValue.anada,
        uva: this.inputValue.uva,
        continente: this.inputValue.continente,
        own: ownCheck.checked,
        eco: ecoCheck.checked,
        pais: this.inputValue.pais,
        provincias: this.selectedProvincias,
        info: progress
      }).then(() => {
        this.loading = false;
        this._router.navigateByUrl('/vinos');
      });
      //this._toastr.success('Hello world!', 'Toastr fun!');

    } else {

      let filePath = `vinosStock/${this.id}/${formValue.nombre}.jpg`;
      let fileRef = this._storage.ref(filePath);
      this.image = this.croppedImage.replace('data:image/png;base64,', '');

      this.loading = true;
      this.subs.add(
        this._storage.ref(filePath).putString(this.image, "base64", { contentType: "image/jpg", cacheControl: 'private, max-age=86400' }).snapshotChanges().pipe(
          finalize(() => {
            this.subs.add(
              fileRef.getDownloadURL().subscribe((url) => {
                this._firestore.collection('vinosStock').doc(this.id).update({
                  name: this.inputValue.nombre,
                  DO: this.inputValue.do,
                  bodega: this.inputValue.bodega,
                  color: this.inputValue.tipo,
                  volumen: this.inputValue.volumen,
                  desc: this.inputValue.desc,
                  year: this.inputValue.anada,
                  uva: this.inputValue.uva,
                  continente: this.inputValue.continente,
                  own: ownCheck.checked,
                  eco: ecoCheck.checked,
                  pais: this.inputValue.pais,
                  provincias: this.selectedProvincias,
                  info: progress,
                  img: url,
                }).then(() => {
                  this.loading = false;
                  this._router.navigateByUrl('/vinos');
                })
                //this._toastr.success('Hello world!', 'Toastr fun!');
              })

            )
          })
        ).subscribe()

      )

    }
  }

  get formControls() {
    return this.nuevoVino['controls'];
  }

  resetForm() {
    this.nuevoVino.reset();
    this.nuevoVino.setValue({
      nombre: '',
      bodega: '',
      do: '',
      anada: '',
      volumen: '',
      tipo: '',
      desc: '',
      image: '',
    })
    //this.imgSrc = "/assets/images/sample-1.jpg";
    this.isSubmitted = false;
    this.fileChangeEvent(Event);
    this.showCropper = false;
  }


  ////////////////////////////////////////////////////////////////////////////////////////////

  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  rotateLeft() {
    this.imageCropper.rotateLeft();
  }

  rotateRight() {
    this.imageCropper.rotateRight();
  }

  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }

  flipVertical() {
    this.imageCropper.flipVertical();
  }


  // PROVINCIAS
  toggleOwn(event?) {
    this.showProvinciaExclusiva = event ? event.target.checked : this.vinoToEdit.owned;
  }
  modalProvinciaCloseButton() {
    this.modalRef.hide();
  }

  agregarProvinciaAVino(event) {
    this.selectedProvincias.push(event.Nombre);
  }

  eliminarProvinciaDeVino(proviciaToDelete) {
    const provinciasNuevas = this.selectedProvincias.filter(provincia => provincia !== proviciaToDelete);
    this.selectedProvincias = provinciasNuevas;
  }

}

