import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirestoreProviderService } from './shared/firestore-provider.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet><ng-progress></ng-progress>'
})
export class AppComponent {

  constructor() { }

}
