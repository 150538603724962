import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ThemeOptions } from "./theme-options";

// Widgets

// NGX Bootstrap Core

// NGX Bootstrap Buttons

import { ButtonsModule } from "ngx-bootstrap";

// NGX Bootstrap Collapse

import { CollapseModule } from "ngx-bootstrap";

// NGX Bootstrap Timepicker

import { TimepickerModule } from "ngx-bootstrap";

// NGX Bootstrap Typeahead

import { TypeaheadModule } from "ngx-bootstrap";

// NGX Bootstrap Carousel

import { CarouselModule } from "ngx-bootstrap";

// NGX Bootstrap Dropdown

import { BsDropdownModule } from "ngx-bootstrap";

// NGX Bootstrap Datepicker

import { BsDatepickerModule } from "ngx-bootstrap";

// NGX Bootstrap Modal

import { ModalModule } from "ngx-bootstrap";

// NGX Bootstrap Pagination

import { PaginationModule } from "ngx-bootstrap";

// NGX Bootstrap Progress bar

import { ProgressbarModule } from "ngx-bootstrap";

// NGX Bootstrap Tabs

import { TabsModule } from "ngx-bootstrap";

// NGX Bootstrap Popover

import { PopoverModule } from "ngx-bootstrap";

// NGX Bootstrap Tooltip

import { TooltipModule } from "ngx-bootstrap";

// NGX Bootstrap Accordion

import { AccordionModule } from "ngx-bootstrap";

// NGX Bootstrap Alert

import { AlertModule } from "ngx-bootstrap";

// Bootstrap Core

// ApexCharts for Angular

import { NgApexchartsModule } from "ng-apexcharts";

// Apex Charts

// Perfect Scrollbar

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: false
};

// Ngx UI Switch

import { UiSwitchModule } from "ngx-ui-switch";

// NG2 File Upload

import { FileUploadModule } from "ng2-file-upload";

// NGX Dropzone for Angular

import { DropzoneModule } from "ngx-dropzone-wrapper";
import { DROPZONE_CONFIG } from "ngx-dropzone-wrapper";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: "https://httpbin.org/post",
  maxFilesize: 50,
  acceptedFiles: "image/*"
};

// Formly Core

import { FormlyModule } from "@ngx-formly/core";

// Formly Bootstrap

import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";

// Archwizard

import { ArchwizardModule } from "angular-archwizard";

// AngularEditor

import { AngularEditorModule } from "@kolkov/angular-editor";

// Angular Circle Progress

import { NgCircleProgressModule } from "ng-circle-progress";

// Angular Gauge Wrapper

import { GaugeModule } from "angular-gauge";

// Angular Circle Progress

import { NgxGaugeModule } from "ngx-gauge";

// Angular Ladda2 Buttons Loader

import { LaddaModule } from "angular2-ladda";

// Angular FullWidth Calendar

import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";

// Date-fns

// NGX Slick Carousel

import { SlickCarouselModule } from "ngx-slick-carousel";

// jQuery

// Slick Carousel

// NG2 Charts

import { ChartsModule } from "ng2-charts";

// Chart.js Annotations

// Chart.js Datalabels

// Chart.js

// NGX Context Menu

import { ContextMenuModule } from "ngx-contextmenu";

// Angular Component Development Kit

// Angular CountUp

import { CountUpModule } from "countup.js-angular2";

// Dragula for Angular

import { DragulaModule } from "ng2-dragula";

// NG2 Table

import { Ng2TableModule } from "ng2-table";

// NG2 Smart Table

import { Ng2SmartTableModule } from "ng2-smart-table";

// NG2 Completer

import { Ng2CompleterModule } from "ng2-completer";

// Angular Colorpicker

import { ColorPickerModule } from "ngx-color-picker";

// NGX Clipboard

import { ClipboardModule } from "ngx-clipboard";

// NG Select

import { NgSelectModule } from "@ng-select/ng-select";

// NGX Autosize

import { AutosizeModule } from "ngx-autosize";

// Angular noUiSlider

import { NouisliderModule } from "ng2-nouislider";

// noUiSlider

// Angular Flags Icons

//import { NgxFlagIconCssModule } from 'ngx-flag-icon-css';

// SVG Flags Icons

// Angular Feather Icons

import { FeatherModule } from "angular-feather";
import {
  Calendar,
  Activity,
  Bell,
  Settings,
  Search,
  Grid,
  Users,
  LifeBuoy,
  CloudDrizzle,
  Coffee,
  Box,
  Briefcase,
  Layers,
  Printer
} from "angular-feather/icons";
const icons = {
  Calendar,
  Activity,
  Bell,
  Settings,
  Search,
  Grid,
  Users,
  LifeBuoy,
  CloudDrizzle,
  Coffee,
  Box,
  Briefcase,
  Layers,
  Printer
};

// FontAwesome Regular SVG Icons

import {
  faSquare,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faEdit,
  faLifeRing,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
} from "@fortawesome/free-regular-svg-icons";

// FontAwesome Solid SVG Icons

import {
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkerAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
} from "@fortawesome/free-solid-svg-icons";

// FontAwesome Brand SVG Icons

import {
  faFacebook,
  faTwitter,
  faAngular,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
} from "@fortawesome/free-brands-svg-icons";

// Angular FontAwesome Icons

import {
  FontAwesomeModule,
  FaIconLibrary
} from "@fortawesome/angular-fontawesome";

// Angular FontAwesome Icons Core

// Ionicons

// Pe7 Icons

// Socicons Icons

// NG Spin Kit

import { NgSpinKitModule } from "ng-spin-kit";

// NGX Skeleton Loader

import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

// Angular Progressbar Core

import { NgProgressModule } from "@ngx-progressbar/core";

// Angular Progressbar router module

import { NgProgressRouterModule } from "@ngx-progressbar/router";

// NGX Spinner

import { NgxSpinnerModule } from "ngx-spinner";

// Angular Google Maps

import { AgmCoreModule } from "@agm/core";

// Angular SweetAlerts2 Notifications

import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";

// SweetAlerts2 Notifications

// Angular Notifier

import { NotifierModule, NotifierOptions } from "angular-notifier";
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: "right",
      distance: 12
    },
    vertical: {
      position: "top",
      distance: 12,
      gap: 10
    }
  },
  theme: "uifort",
  behaviour: {
    autoHide: 5000,
    onClick: "hide",
    onMouseover: "pauseAutoHide",
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: "slide",
      speed: 300,
      easing: "ease"
    },
    hide: {
      preset: "fade",
      speed: 300,
      easing: "ease",
      offset: 50
    },
    shift: {
      speed: 300,
      easing: "ease"
    },
    overlap: 150
  }
};

// NGX Pagination for Angular

import { NgxPaginationModule } from "ngx-pagination";

// NGX Ratings for Angular

import { BarRatingModule } from "ngx-bar-rating";

// Angular Infinite Scroll

import { InfiniteScrollModule } from "ngx-infinite-scroll";

// Angular Elegant Trends Graphs

import { TrendModule } from "ngx-trend";

// Angular Tree Component

import { TreeModule } from "angular-tree-component";

// UUID

// Angular Tree Grid

import { AngularTreeGridModule } from "angular-tree-grid";

// NGX Joyride

import { JoyrideModule } from "ngx-joyride";

// Hamburgers navigation buttons

// NGX Image Cropper

import { ImageCropperModule } from "ngx-image-cropper";

// Dual listbox

import { AngularDualListBoxModule } from "angular-dual-listbox";

// Input Mask

import { TextMaskModule } from "angular2-text-mask";

// Angular Leaflet maps

import { LeafletModule } from "@asymmetrik/ngx-leaflet";

// Leaflet Maps

import { tileLayer, latLng } from "leaflet";

// Layouts

import { LeftSidebarComponent } from "./layout-blueprints/left-sidebar/left-sidebar.component";
import { CollapsedSidebarComponent } from "./layout-blueprints/collapsed-sidebar/collapsed-sidebar.component";
import { MinimalLayoutComponent } from "./layout-blueprints/minimal-layout/minimal-layout.component";
import { PresentationLayoutComponent } from "./layout-blueprints/presentation-layout/presentation-layout.component";

// Layout components

import { HeaderComponent } from "./layout-components/header/header.component";
import { HeaderDotsComponent } from "./layout-components/header-dots/header-dots.component";
import { HeaderDrawerComponent } from "./layout-components/header-drawer/header-drawer.component";
import { HeaderUserboxComponent } from "./layout-components/header-userbox/header-userbox.component";
import { HeaderSearchComponent } from "./layout-components/header-search/header-search.component";
import { HeaderMenuComponent } from "./layout-components/header-menu/header-menu.component";
import { SidebarCollapsedComponent } from "./layout-components/sidebar-collapsed/sidebar-collapsed.component";
import { SidebarComponent } from "./layout-components/sidebar/sidebar.component";
import { SidebarHeaderComponent } from "./layout-components/sidebar-header/sidebar-header.component";
import { SidebarUserboxComponent } from "./layout-components/sidebar-userbox/sidebar-userbox.component";
import { SidebarMenuComponent } from "./layout-components/sidebar-menu/sidebar-menu.component";
import { SidebarFooterComponent } from "./layout-components/sidebar-footer/sidebar-footer.component";
import { PageTitleComponent } from "./layout-components/page-title/page-title.component";
import { FooterComponent } from "./layout-components/footer/footer.component";
import { ThemeConfiguratorComponent } from "./layout-components/theme-configurator/theme-configurator.component";
import { PromoSectionComponent } from "./layout-components/promo-section/promo-section.component";
import { ExampleWrapperSimpleComponent } from "./layout-components/example-wrapper-simple/example-wrapper-simple.component";
import { ExampleWrapperSeamlessComponent } from "./layout-components/example-wrapper-seamless/example-wrapper-seamless.component";
import { ExampleWrapperContainerComponent } from "./layout-components/example-wrapper-container/example-wrapper-container.component";

// Example components

//import { DashboardDefaultSection1Component } from './example-components/DashboardDefault/dashboard-default-section-1/dashboard-default-section-1.component';
// import { DashboardDefaultSection2Component } from './example-components/DashboardDefault/dashboard-default-section-2/dashboard-default-section-2.component';
// import { DashboardDefaultSection3Component } from './example-components/DashboardDefault/dashboard-default-section-3/dashboard-default-section-3.component';
// import { DashboardDefaultSection4Component } from './example-components/DashboardDefault/dashboard-default-section-4/dashboard-default-section-4.component';
// import { DashboardDefaultSection5Component } from './example-components/DashboardDefault/dashboard-default-section-5/dashboard-default-section-5.component';
// import { DashboardDefaultSection6Component } from './example-components/DashboardDefault/dashboard-default-section-6/dashboard-default-section-6.component';
// import { DashboardDefaultSection7Component } from './example-components/DashboardDefault/dashboard-default-section-7/dashboard-default-section-7.component';
// import { DashboardDefaultSection8Component } from './example-components/DashboardDefault/dashboard-default-section-8/dashboard-default-section-8.component';
// import { DashboardAnalyticsSection1Component } from './example-components/DashboardAnalytics/dashboard-analytics-section-1/dashboard-analytics-section-1.component';
// import { DashboardAnalyticsSection2Component } from './example-components/DashboardAnalytics/dashboard-analytics-section-2/dashboard-analytics-section-2.component';
// import { DashboardAnalyticsSection3Component } from './example-components/DashboardAnalytics/dashboard-analytics-section-3/dashboard-analytics-section-3.component';
// import { DashboardAnalyticsSection4Component } from './example-components/DashboardAnalytics/dashboard-analytics-section-4/dashboard-analytics-section-4.component';
// import { DashboardAnalyticsSection5Component } from './example-components/DashboardAnalytics/dashboard-analytics-section-5/dashboard-analytics-section-5.component';
// import { DashboardAnalyticsSection6Component } from './example-components/DashboardAnalytics/dashboard-analytics-section-6/dashboard-analytics-section-6.component';
// import { DashboardAnalyticsSection7Component } from './example-components/DashboardAnalytics/dashboard-analytics-section-7/dashboard-analytics-section-7.component';
// import { DashboardAnalyticsSection8Component } from './example-components/DashboardAnalytics/dashboard-analytics-section-8/dashboard-analytics-section-8.component';
// import { DashboardSalesSection1Component } from './example-components/DashboardSales/dashboard-sales-section-1/dashboard-sales-section-1.component';
// import { DashboardSalesSection2Component } from './example-components/DashboardSales/dashboard-sales-section-2/dashboard-sales-section-2.component';
// import { DashboardSalesSection3Component } from './example-components/DashboardSales/dashboard-sales-section-3/dashboard-sales-section-3.component';
// import { DashboardSalesSection4Component } from './example-components/DashboardSales/dashboard-sales-section-4/dashboard-sales-section-4.component';
// import { DashboardSalesSection5Component } from './example-components/DashboardSales/dashboard-sales-section-5/dashboard-sales-section-5.component';
// import { DashboardSalesSection6Component } from './example-components/DashboardSales/dashboard-sales-section-6/dashboard-sales-section-6.component';
// import { DashboardSalesSection7Component } from './example-components/DashboardSales/dashboard-sales-section-7/dashboard-sales-section-7.component';
// import { DashboardSalesSection8Component } from './example-components/DashboardSales/dashboard-sales-section-8/dashboard-sales-section-8.component';
// import { DashboardSalesSection9Component } from './example-components/DashboardSales/dashboard-sales-section-9/dashboard-sales-section-9.component';
// import { DashboardReportsSection1Component } from './example-components/DashboardReports/dashboard-reports-section-1/dashboard-reports-section-1.component';
// import { DashboardReportsSection2Component } from './example-components/DashboardReports/dashboard-reports-section-2/dashboard-reports-section-2.component';
// import { DashboardReportsSection3Component } from './example-components/DashboardReports/dashboard-reports-section-3/dashboard-reports-section-3.component';
// import { DashboardReportsSection4Component } from './example-components/DashboardReports/dashboard-reports-section-4/dashboard-reports-section-4.component';
// import { DashboardReportsSection5Component } from './example-components/DashboardReports/dashboard-reports-section-5/dashboard-reports-section-5.component';
// import { DashboardReportsSection6Component } from './example-components/DashboardReports/dashboard-reports-section-6/dashboard-reports-section-6.component';
// import { DashboardReportsSection7Component } from './example-components/DashboardReports/dashboard-reports-section-7/dashboard-reports-section-7.component';
// import { DashboardReportsSection8Component } from './example-components/DashboardReports/dashboard-reports-section-8/dashboard-reports-section-8.component';
// import { DashboardRealEstateSection1Component } from './example-components/DashboardRealEstate/dashboard-real-estate-section-1/dashboard-real-estate-section-1.component';
// import { DashboardRealEstateSection2Component } from './example-components/DashboardRealEstate/dashboard-real-estate-section-2/dashboard-real-estate-section-2.component';
// import { DashboardRealEstateSection3Component } from './example-components/DashboardRealEstate/dashboard-real-estate-section-3/dashboard-real-estate-section-3.component';
// import { DashboardRealEstateSection4Component } from './example-components/DashboardRealEstate/dashboard-real-estate-section-4/dashboard-real-estate-section-4.component';
// import { DashboardRealEstateSection5Component } from './example-components/DashboardRealEstate/dashboard-real-estate-section-5/dashboard-real-estate-section-5.component';
// import { DashboardRealEstateSection6Component } from './example-components/DashboardRealEstate/dashboard-real-estate-section-6/dashboard-real-estate-section-6.component';
// import { DashboardRealEstateSection7Component } from './example-components/DashboardRealEstate/dashboard-real-estate-section-7/dashboard-real-estate-section-7.component';
// import { DashboardRealEstateSection8Component } from './example-components/DashboardRealEstate/dashboard-real-estate-section-8/dashboard-real-estate-section-8.component';
// import { DashboardServerStatusSection1Component } from './example-components/DashboardServerStatus/dashboard-server-status-section-1/dashboard-server-status-section-1.component';
// import { DashboardServerStatusSection2Component } from './example-components/DashboardServerStatus/dashboard-server-status-section-2/dashboard-server-status-section-2.component';
// import { DashboardServerStatusSection3Component } from './example-components/DashboardServerStatus/dashboard-server-status-section-3/dashboard-server-status-section-3.component';
// import { DashboardServerStatusSection4Component } from './example-components/DashboardServerStatus/dashboard-server-status-section-4/dashboard-server-status-section-4.component';
// import { DashboardServerStatusSection5Component } from './example-components/DashboardServerStatus/dashboard-server-status-section-5/dashboard-server-status-section-5.component';
// import { DashboardServerStatusSection6Component } from './example-components/DashboardServerStatus/dashboard-server-status-section-6/dashboard-server-status-section-6.component';
// import { DashboardServerStatusSection7Component } from './example-components/DashboardServerStatus/dashboard-server-status-section-7/dashboard-server-status-section-7.component';
// import { DashboardServerStatusSection8Component } from './example-components/DashboardServerStatus/dashboard-server-status-section-8/dashboard-server-status-section-8.component';
// import { DashboardServerStatusSection9Component } from './example-components/DashboardServerStatus/dashboard-server-status-section-9/dashboard-server-status-section-9.component';
// import { DashboardProjectsSection1Component } from './example-components/DashboardProjects/dashboard-projects-section-1/dashboard-projects-section-1.component';
// import { DashboardProjectsSection2Component } from './example-components/DashboardProjects/dashboard-projects-section-2/dashboard-projects-section-2.component';
// import { DashboardProjectsSection3Component } from './example-components/DashboardProjects/dashboard-projects-section-3/dashboard-projects-section-3.component';
// import { DashboardProjectsSection4Component } from './example-components/DashboardProjects/dashboard-projects-section-4/dashboard-projects-section-4.component';
// import { DashboardProjectsSection5Component } from './example-components/DashboardProjects/dashboard-projects-section-5/dashboard-projects-section-5.component';
// import { DashboardProjectsSection6Component } from './example-components/DashboardProjects/dashboard-projects-section-6/dashboard-projects-section-6.component';
// import { DashboardProjectsSection7Component } from './example-components/DashboardProjects/dashboard-projects-section-7/dashboard-projects-section-7.component';
// import { DashboardProjectsSection8Component } from './example-components/DashboardProjects/dashboard-projects-section-8/dashboard-projects-section-8.component';
// import { DashboardProjectsSection9Component } from './example-components/DashboardProjects/dashboard-projects-section-9/dashboard-projects-section-9.component';
// import { DashboardHelpdeskSection1Component } from './example-components/DashboardHelpdesk/dashboard-helpdesk-section-1/dashboard-helpdesk-section-1.component';
// import { DashboardHelpdeskSection2Component } from './example-components/DashboardHelpdesk/dashboard-helpdesk-section-2/dashboard-helpdesk-section-2.component';
// import { DashboardHelpdeskSection3Component } from './example-components/DashboardHelpdesk/dashboard-helpdesk-section-3/dashboard-helpdesk-section-3.component';
// import { DashboardHelpdeskSection4Component } from './example-components/DashboardHelpdesk/dashboard-helpdesk-section-4/dashboard-helpdesk-section-4.component';
// import { DashboardHelpdeskSection5Component } from './example-components/DashboardHelpdesk/dashboard-helpdesk-section-5/dashboard-helpdesk-section-5.component';
// import { DashboardCustomersSection1Component } from './example-components/DashboardCustomers/dashboard-customers-section-1/dashboard-customers-section-1.component';
// import { DashboardCustomersSection2Component } from './example-components/DashboardCustomers/dashboard-customers-section-2/dashboard-customers-section-2.component';
// import { DashboardCustomersSection3Component } from './example-components/DashboardCustomers/dashboard-customers-section-3/dashboard-customers-section-3.component';
// import { DashboardCustomersSection4Component } from './example-components/DashboardCustomers/dashboard-customers-section-4/dashboard-customers-section-4.component';
// import { DashboardCustomersSection5Component } from './example-components/DashboardCustomers/dashboard-customers-section-5/dashboard-customers-section-5.component';
// import { DashboardCustomersSection6Component } from './example-components/DashboardCustomers/dashboard-customers-section-6/dashboard-customers-section-6.component';
// import { DashboardCustomersSection7Component } from './example-components/DashboardCustomers/dashboard-customers-section-7/dashboard-customers-section-7.component';
// import { DashboardCryptocurrencySection1Component } from './example-components/DashboardCryptocurrency/dashboard-cryptocurrency-section-1/dashboard-cryptocurrency-section-1.component';
// import { DashboardCryptocurrencySection2Component } from './example-components/DashboardCryptocurrency/dashboard-cryptocurrency-section-2/dashboard-cryptocurrency-section-2.component';
// import { DashboardCryptocurrencySection3Component } from './example-components/DashboardCryptocurrency/dashboard-cryptocurrency-section-3/dashboard-cryptocurrency-section-3.component';
// import { DashboardCryptocurrencySection4Component } from './example-components/DashboardCryptocurrency/dashboard-cryptocurrency-section-4/dashboard-cryptocurrency-section-4.component';
// import { DashboardCryptocurrencySection5Component } from './example-components/DashboardCryptocurrency/dashboard-cryptocurrency-section-5/dashboard-cryptocurrency-section-5.component';
// import { DashboardCryptocurrencySection6Component } from './example-components/DashboardCryptocurrency/dashboard-cryptocurrency-section-6/dashboard-cryptocurrency-section-6.component';
// import { DashboardCryptocurrencySection7Component } from './example-components/DashboardCryptocurrency/dashboard-cryptocurrency-section-7/dashboard-cryptocurrency-section-7.component';
// import { DashboardCrmManagerSection1Component } from './example-components/DashboardCrmManager/dashboard-crm-manager-section-1/dashboard-crm-manager-section-1.component';
// import { DashboardCrmManagerSection2Component } from './example-components/DashboardCrmManager/dashboard-crm-manager-section-2/dashboard-crm-manager-section-2.component';
// import { DashboardCrmManagerSection3Component } from './example-components/DashboardCrmManager/dashboard-crm-manager-section-3/dashboard-crm-manager-section-3.component';
// import { DashboardCrmManagerSection4Component } from './example-components/DashboardCrmManager/dashboard-crm-manager-section-4/dashboard-crm-manager-section-4.component';
// import { DashboardCrmManagerSection5Component } from './example-components/DashboardCrmManager/dashboard-crm-manager-section-5/dashboard-crm-manager-section-5.component';
// import { DashboardCrmManagerSection6Component } from './example-components/DashboardCrmManager/dashboard-crm-manager-section-6/dashboard-crm-manager-section-6.component';
// import { DashboardCrmManagerSection7Component } from './example-components/DashboardCrmManager/dashboard-crm-manager-section-7/dashboard-crm-manager-section-7.component';
// import { DashboardCrmManagerSection8Component } from './example-components/DashboardCrmManager/dashboard-crm-manager-section-8/dashboard-crm-manager-section-8.component';
// import { DashboardContentSection1Component } from './example-components/DashboardContent/dashboard-content-section-1/dashboard-content-section-1.component';
// import { DashboardContentSection2Component } from './example-components/DashboardContent/dashboard-content-section-2/dashboard-content-section-2.component';
// import { DashboardContentSection3Component } from './example-components/DashboardContent/dashboard-content-section-3/dashboard-content-section-3.component';
// import { DashboardContentSection4Component } from './example-components/DashboardContent/dashboard-content-section-4/dashboard-content-section-4.component';
// import { DashboardContentSection5Component } from './example-components/DashboardContent/dashboard-content-section-5/dashboard-content-section-5.component';
// import { DashboardContentSection6Component } from './example-components/DashboardContent/dashboard-content-section-6/dashboard-content-section-6.component';
// import { DashboardContentSection7Component } from './example-components/DashboardContent/dashboard-content-section-7/dashboard-content-section-7.component';
// import { ApplicationsCalendarContentComponent } from './example-components/ApplicationsCalendar/applications-calendar-content/applications-calendar-content.component';
// import { ApplicationsChatContentComponent } from './example-components/ApplicationsChat/applications-chat-content/applications-chat-content.component';
// import { ApplicationsContactsContentComponent } from './example-components/ApplicationsContacts/applications-contacts-content/applications-contacts-content.component';
// import { ApplicationsFileManagerContentComponent } from './example-components/ApplicationsFileManager/applications-file-manager-content/applications-file-manager-content.component';
// import { ApplicationsMailContentComponent } from './example-components/ApplicationsMail/applications-mail-content/applications-mail-content.component';
// import { ApplicationsProjectsContentComponent } from './example-components/ApplicationsProjects/applications-projects-content/applications-projects-content.component';
// import { ApplicationsWidgetsChatComponent } from './example-components/ApplicationsWidgets/applications-widgets-chat/applications-widgets-chat.component';
// import { ApplicationsWidgetsFilesComponent } from './example-components/ApplicationsWidgets/applications-widgets-files/applications-widgets-files.component';
// import { ApplicationsWidgetsTimelineComponent } from './example-components/ApplicationsWidgets/applications-widgets-timeline/applications-widgets-timeline.component';
import { PagesError404ContentComponent } from "./example-components/PagesError404/pages-error-404-content/pages-error-404-content.component";
// import { PagesError500ContentComponent } from './example-components/PagesError500/pages-error-500-content/pages-error-500-content.component';
// import { PagesError505ContentComponent } from './example-components/PagesError505/pages-error-505-content/pages-error-505-content.component';
// import { PagesInvoiceContentComponent } from './example-components/PagesInvoice/pages-invoice-content/pages-invoice-content.component';
import { PagesLoginContentComponent } from "./example-components/PagesLogin/pages-login-content/pages-login-content.component";
import { PagesProfileContentComponent } from "./example-components/PagesProfile/pages-profile-content/pages-profile-content.component";
import { PagesRecoverPasswordContentComponent } from "./example-components/PagesRecoverPassword/pages-recover-password-content/pages-recover-password-content.component";
import { PagesRegisterContentComponent } from "./example-components/PagesRegister/pages-register-content/pages-register-content.component";

import { ButtonsBasicComponent } from "./example-components/Buttons/buttons-basic/buttons-basic.component";
import { ButtonsGroupsComponent } from "./example-components/Buttons/buttons-groups/buttons-groups.component";
import { ButtonsGroupsSizingComponent } from "./example-components/Buttons/buttons-groups-sizing/buttons-groups-sizing.component";
import { ButtonsColorsComponent } from "./example-components/Buttons/buttons-colors/buttons-colors.component";
import { ButtonsLinksComponent } from "./example-components/Buttons/buttons-links/buttons-links.component";
import { ButtonsOutlineComponent } from "./example-components/Buttons/buttons-outline/buttons-outline.component";
import { ButtonsSizingComponent } from "./example-components/Buttons/buttons-sizing/buttons-sizing.component";
import { ButtonsPillsComponent } from "./example-components/Buttons/buttons-pills/buttons-pills.component";
import { ButtonsSquareComponent } from "./example-components/Buttons/buttons-square/buttons-square.component";
import { ButtonsGradientsComponent } from "./example-components/Buttons/buttons-gradients/buttons-gradients.component";
import { ButtonsLoadingComponent } from "./example-components/Buttons/buttons-loading/buttons-loading.component";
import { ButtonsSocialComponent } from "./example-components/Buttons/buttons-social/buttons-social.component";
import { ButtonsIconsComponent } from "./example-components/Buttons/buttons-icons/buttons-icons.component";

import { ImageCropBasicComponent } from "./example-components/ImageCrop/image-crop-basic/image-crop-basic.component";

import { UtilitiesHelpersPrimaryColorsComponent } from "./example-components/UtilitiesHelpers/utilities-helpers-primary-colors/utilities-helpers-primary-colors.component";
import { UtilitiesHelpersNeutralColorsComponent } from "./example-components/UtilitiesHelpers/utilities-helpers-neutral-colors/utilities-helpers-neutral-colors.component";
import { UtilitiesHelpersGradientsComponent } from "./example-components/UtilitiesHelpers/utilities-helpers-gradients/utilities-helpers-gradients.component";
import { UtilitiesHelpersBrandColorsComponent } from "./example-components/UtilitiesHelpers/utilities-helpers-brand-colors/utilities-helpers-brand-colors.component";
import { UtilitiesHelpersComposedBackgroundsComponent } from "./example-components/UtilitiesHelpers/utilities-helpers-composed-backgrounds/utilities-helpers-composed-backgrounds.component";

// Example pages

import { DashboardDefaultComponent } from "./example-pages/dashboard-default/dashboard-default.component";
// import { ApplicationsChatComponent } from './example-pages/applications-chat/applications-chat.component';
import { PagesLoginComponent } from "./example-pages/pages-login/pages-login.component";
import { PagesRegisterComponent } from "./example-pages/pages-register/pages-register.component";
import { PagesRecoverPasswordComponent } from "./example-pages/pages-recover-password/pages-recover-password.component";
import { PagesError404Component } from "./example-pages/pages-error-404/pages-error-404.component";
import { ButtonsComponent } from "./example-pages/buttons/buttons.component";
import { UtilitiesHelpersComponent } from "./example-pages/utilities-helpers/utilities-helpers.component";
import { VinosComponent } from "./dvinum/vinos/vinos.component";
import { AnadirVinoComponent } from "./dvinum/anadir-vino/anadir-vino.component";
import { RestaurantesComponent } from "./dvinum/restaurantes/restaurantes.component";
import { DashboardComponent } from "./dvinum/dashboard/dashboard.component";

import { AngularFireModule } from "@angular/fire";
import { environment } from "../environments/environment";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAnalyticsModule } from "@angular/fire/analytics";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { EditarVinoComponent } from "./dvinum/editar-vino/editar-vino.component";
import { AnadirRestauranteComponent } from "./dvinum/anadir-restaurante/anadir-restaurante.component";
import { AuthService } from "./shared/auth.service";

import { PdfFontsService } from "./shared/pdf-fonts.service";
import { AngularFireAuthGuard } from "@angular/fire/auth-guard";
import { EditarRestauranteComponent } from "./dvinum/editar-restaurante/editar-restaurante.component";
import { AsignarVinosComponent } from "./dvinum/asignar-vinos/asignar-vinos.component";
import { AngularFireFunctionsModule, FunctionsRegionToken } from "@angular/fire/functions";
import { UsuariosComponent } from "./dvinum/usuarios/usuarios.component";
import { AnadirUsuarioComponent } from "./dvinum/anadir-usuario/anadir-usuario.component";
import { EditarUsuarioComponent } from "./dvinum/editar-usuario/editar-usuario.component";
import { MenusComponent } from "./config/menus/menus.component";
import { OrdenarVinosComponent } from "./dvinum/ordenar-vinos/ordenar-vinos.component";
import { FichasVinosComponent } from "./dvinum/fichas-vinos/fichas-vinos.component";
import { VinoRestsComponent } from "./dvinum/vino-rests/vino-rests.component";
import { QRCodeModule } from "angularx-qrcode";
import { ConfigurarCartaComponent } from "./dvinum/configurar-carta/configurar-carta.component";
import { ComercialesComponent } from "./dvinum/comerciales/comerciales.component";
import { ComercialComponent } from "./dvinum/comercial/comercial.component";
import { AsignarRestaurantesComponent } from "./dvinum/asignar-restaurantes/asignar-restaurantes.component";
import { DirectoresComponent } from "./dvinum/directores/directores.component";
import { DirectorComponent } from "./dvinum/director/director.component";
import { AsignarComercialesComponent } from "./dvinum/asignar-comerciales/asignar-comerciales.component";
import { SelectorComponent } from "./dvinum/_componentes/selector/selector.component";

import { FirestoreProviderService } from "./shared/firestore-provider.service";
import { DistribuidoresComponent } from "./dvinum/distribuidores/distribuidores.component";
import { DistribuidorComponent } from "./dvinum/distribuidor/distribuidor.component";
import { AuthGuardGuard } from "./shared/auth-guard.guard";
import { CardComponent } from './dvinum/dashboard/card/card.component';
import { ListComponent } from './dvinum/dashboard/card/list/list.component';
import { DetailListComponent } from './dvinum/dashboard/card/detail-list/detail-list.component';
import { GraphCardComponent } from './dvinum/dashboard/graph-card/graph-card.component';

//import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,

    // Layout components

    HeaderComponent,
    HeaderDotsComponent,
    HeaderDrawerComponent,
    HeaderUserboxComponent,
    HeaderSearchComponent,
    HeaderMenuComponent,
    SidebarCollapsedComponent,
    SidebarComponent,
    SidebarHeaderComponent,
    SidebarUserboxComponent,
    SidebarMenuComponent,
    SidebarFooterComponent,
    PageTitleComponent,
    FooterComponent,
    ThemeConfiguratorComponent,
    PromoSectionComponent,
    ExampleWrapperSimpleComponent,
    ExampleWrapperSeamlessComponent,
    ExampleWrapperContainerComponent,

    // Layouts

    LeftSidebarComponent,
    CollapsedSidebarComponent,
    MinimalLayoutComponent,
    PresentationLayoutComponent,

    // Example components

    PagesError404ContentComponent,
    // PagesError500ContentComponent,
    // PagesError505ContentComponent,
    // PagesInvoiceContentComponent,
    PagesLoginContentComponent,
    PagesProfileContentComponent,
    PagesRecoverPasswordContentComponent,
    PagesRegisterContentComponent,

    ButtonsBasicComponent,
    ButtonsGroupsComponent,
    ButtonsGroupsSizingComponent,
    ButtonsColorsComponent,
    ButtonsLinksComponent,
    ButtonsOutlineComponent,
    ButtonsSizingComponent,
    ButtonsPillsComponent,
    ButtonsSquareComponent,
    ButtonsGradientsComponent,
    ButtonsLoadingComponent,
    ButtonsSocialComponent,
    ButtonsIconsComponent,

    UtilitiesHelpersPrimaryColorsComponent,
    UtilitiesHelpersNeutralColorsComponent,
    UtilitiesHelpersGradientsComponent,
    UtilitiesHelpersBrandColorsComponent,
    UtilitiesHelpersComposedBackgroundsComponent,

    // Example pages

    ImageCropBasicComponent,

    DashboardDefaultComponent,
    // ApplicationsChatComponent,
    PagesLoginComponent,
    PagesRegisterComponent,
    PagesRecoverPasswordComponent,
    PagesError404Component,
    ButtonsComponent,
    UtilitiesHelpersComponent,
    VinosComponent,
    AnadirVinoComponent,
    RestaurantesComponent,
    DashboardComponent,
    EditarVinoComponent,
    AnadirRestauranteComponent,
    EditarRestauranteComponent,
    AsignarVinosComponent,
    UsuariosComponent,
    AnadirUsuarioComponent,
    EditarUsuarioComponent,
    MenusComponent,
    OrdenarVinosComponent,
    FichasVinosComponent,
    VinoRestsComponent,
    ConfigurarCartaComponent,
    ComercialesComponent,
    ComercialComponent,
    AsignarRestaurantesComponent,
    DirectoresComponent,
    DirectorComponent,
    DistribuidoresComponent,
    DistribuidorComponent,
    AsignarComercialesComponent,
    SelectorComponent,
    CardComponent,
    ListComponent,
    DetailListComponent,
    GraphCardComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    //ToastrModule.forRoot(),
    QRCodeModule,

    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    TimepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    NgApexchartsModule,
    PerfectScrollbarModule,
    UiSwitchModule,
    FileUploadModule,
    DropzoneModule,
    FormlyModule.forRoot(),
    FormlyBootstrapModule,
    ArchwizardModule,
    AngularEditorModule,
    NgCircleProgressModule.forRoot(),
    GaugeModule.forRoot(),
    NgxGaugeModule,
    LaddaModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    SlickCarouselModule,
    ChartsModule,
    ContextMenuModule.forRoot(),
    CountUpModule,
    DragulaModule.forRoot(),
    Ng2TableModule,
    Ng2SmartTableModule,
    Ng2CompleterModule,
    ColorPickerModule,
    ClipboardModule,
    NgSelectModule,
    AutosizeModule,
    NouisliderModule,
    //NgxFlagIconCssModule,
    FeatherModule.pick(icons),
    FontAwesomeModule,
    NgSpinKitModule,
    NgxSkeletonLoaderModule,
    NgProgressModule,
    NgProgressRouterModule,
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCoVRykl39EigHTQ0wnI0ISVGR3zpV4dDM"
    }),
    SweetAlert2Module.forRoot(),
    NotifierModule.withConfig(customNotifierOptions),
    NgxPaginationModule,
    BarRatingModule,
    InfiniteScrollModule,
    TrendModule,
    TreeModule.forRoot(),
    AngularTreeGridModule,
    JoyrideModule.forRoot(),
    ImageCropperModule,
    AngularDualListBoxModule,
    TextMaskModule,
    LeafletModule.forRoot(),

    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    AngularFireStorageModule,

    AngularFireFunctionsModule
  ],
  providers: [
    AuthService,
    PdfFontsService,
    AngularFireAuthGuard,
    AuthGuardGuard,
    FirestoreProviderService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    { provide: FunctionsRegionToken, useValue: 'us-central1' },
    ThemeOptions
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faFacebook,
      faPrint,
      faAlignCenter,
      faMapMarkerAlt,
      faTachometerAlt,
      faEdit,
      faExternalLinkAlt,
      faShareSquare,
      faSitemap,
      faInfoCircle,
      faLifeRing,
      faTwitter,
      faQuoteRight,
      faStarHalfAlt,
      faSync,
      faShapes,
      faCarBattery,
      faTable,
      faCubes,
      faPager,
      faAngular,
      faVuejs,
      faReact,
      faHtml5,
      faCheckCircle,
      faTimesCircle,
      faBomb,
      faNetworkWired,
      faBusAlt,
      faBirthdayCake,
      faEyeDropper,
      faThumbsUp,
      faCameraRetro,
      faUnlockAlt,
      faDownload,
      faUpload,
      faReply,
      faGoogle,
      faFileImage,
      faFolderOpen,
      faBars,
      faTrashAlt,
      faSave,
      faPlayCircle,
      faEllipsisV,
      faEllipsisH,
      faSlidersH,
      faFileArchive,
      faAward,
      faCaretRight,
      faInstagram,
      faPinterest,
      faYoutube,
      faDiscord,
      faSlack,
      faDribbble,
      faGithub,
      faPlus,
      faFolder,
      faTimes,
      faEnvelope,
      faAddressCard,
      faMap,
      faCalendarAlt,
      faImages,
      faFilm,
      faClock,
      faSearch,
      faChevronRight,
      faChevronUp,
      faChevronLeft,
      faChevronDown,
      faLink,
      faLightbulb,
      faGem,
      faCog,
      faDotCircle,
      faArrowsAltH,
      faComments,
      faCommentDots,
      faKeyboard,
      faObjectGroup,
      faUser,
      faUserCircle,
      faQuestionCircle,
      faBuilding,
      faBell,
      faFileExcel,
      faFileAudio,
      faFileVideo,
      faFileWord,
      faFilePdf,
      faFileCode,
      faFileAlt,
      faEye,
      faChartBar,
      faPlusCircle,
      faAngleRight,
      faAngleUp,
      faAngleLeft,
      faAngleDown,
      faArrowUp,
      faArrowDown,
      faArrowRight,
      faArrowLeft,
      faStar,
      faSignOutAlt,
      faLemon
    );
  } 
}
