import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreProviderService } from "src/app/shared/firestore-provider.service";

@Component({
  selector: "app-directores",
  templateUrl: "./directores.component.html",
  styleUrls: ["./directores.component.scss"]
})
export class DirectoresComponent implements OnInit {
  isCollapsed = true;
  rotate = true;

  page: number = 1;
  directores: any;
  query: any;

  constructor(
    private _firestore: AngularFirestore,
    private _firestoreProvider: FirestoreProviderService
  ) {}

  ngOnInit() {
    this.getComerciales();
  }

  buscador(event) {
    const searchKeyword = event.target.value
      .toLowerCase()
      .replace(/á/, "a")
      .replace(/é/, "e")
      .replace(/í/, "i")
      .replace(/ó/, "o")
      .replace(/ú/, "u");

    this.directores = this.query;
    let busqueda = this.directores.filter(director => {
      return director.nombre
        .toLowerCase()
        .replace(/á/, "a")
        .replace(/é/, "e")
        .replace(/í/, "i")
        .replace(/ó/, "o")
        .replace(/ú/, "u")
        .includes(searchKeyword);
    });
    this.directores = busqueda;
  }
  getComerciales() {
    this._firestoreProvider
      .getCollection("directores")
      .snapshotChanges()
      .subscribe(doc => {
        this.query = [];
        doc.forEach((doc, i) => {
          this.query.push(doc.payload.doc.data());
        });
        this.directores = this.query;
      });
  }
}
