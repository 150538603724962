import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-sidebar-userbox',
  templateUrl: './sidebar-userbox.component.html'
})
export class SidebarUserboxComponent implements OnInit  {
  
  constructor(
    public _afAuth: AngularFireAuth,
    private _fb: AngularFirestore,
    private _router: Router,
    private _auth: AuthService) 
    { }

  user:any
  ngOnInit(){
    
    this.user = this._auth.currentUser()
    
  }
  

logout(){
  this._auth.logout()
}

}
