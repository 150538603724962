<div *ngFor="let item of list; index as i; trackBy: trackValueProp" class="rest-container">
    <div [ngClass]="{'py-2' : !icon && !subtitle}" class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
            <div *ngIf="icon" class="bg-slick-carbon text-center text-success font-size-xl d-30 min-width-circle rounded-circle">
                <img class="restIcon" [alt]="item[identProp]" [src]="icon">
            </div>
            <div class="d-flex mx-4 flex-column">
                <b class="" [innerText]="item[identProp]"></b>
            </div>
        </div>
        <div class="d-flex">
            <div [countUp]="item[valueProp]" class="font-weight-bold text-success user-select-none"></div>
            &nbsp;&nbsp;
            <div class="font-weight-bold text-success ">{{appendValue}}</div>
        </div>
    </div>
    <div class="divider my-1"></div>
</div>