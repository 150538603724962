<app-page-title titleDescription='' titleHeading='Editar Restaurante'></app-page-title>

<div class="">
  <form [formGroup]="nuevoRestaurante" (submit)="onSubmit(nuevoRestaurante.value)">

    <div class="card card-box mb-5 border-0">
      <div class="card-header border-0">
        <div class="card-header--title">
          <h5  class="mb-0 py-2 font-weight-bold">
            {{restauranteToEdit.nombre}}
          </h5>
        </div>
        <div [formGroup]="nuevoRestaurante" class="card-header--actions">
          <button *ngIf="loading" class="btn btn-sm m-2 btn-success d-flex  align-items-center" disabled type="button">
            <span aria-hidden="true" class="spinner-border spinner-border-sm " role="status"></span>
            <span class="ml-2">PROCESANDO...</span>
          </button>
          <button *ngIf="!loading" class="btn btn-sm m-2 btn-success" type="submit">
            <span class="btn-wrapper--icon">
              <fa-icon [icon]="['fas', 'plus']"></fa-icon>
            </span>
            <span class="btn-wrapper--label ">ACTUALIZAR RESTAURANTE</span>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label>Nombre del Restaurante</label>
              <span *ngIf="!isSubmitted"> *</span>
              <span class="text-danger" *ngIf="isSubmitted && formControls.nombre.errors?.required"> *</span>
              <input #nombre formControlName="nombre" class="form-control" value="{{restauranteToEdit.nombre}}" placeholder="Introduce el nombre" type="text">
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label>Provincia</label>
              <app-selector collection="Provincias" [selectedFields]="selectedProvincia ? [selectedProvincia]: []" [maxInput]="1"
                [key]="provinciaSelectorKey" (addNewItem)="onAgregarProvincia($event)"
                (removeItem)="onDeleteProvincia()" [isAdminOperation]="true" tag="Provincia">
              </app-selector>
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label>Localidad</label>
              <input #localidad formControlName="localidad" class="form-control" value="{{restauranteToEdit.localidad}}" placeholder="Introduce la localidad" type="text">
            </div>  
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label>Dirección</label>
              <input #direccion formControlName="direccion" class="form-control" value="{{restauranteToEdit.direccion}}" placeholder="Introduce la direccion" type="text">
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label for="exampleEmail11">Email</label>
              <span *ngIf="!isSubmitted"> *</span>
              <span class="text-danger" *ngIf="isSubmitted && formControls.email.errors?.required"> *</span>
              <input  #email formControlName="email" class="form-control" value="{{restauranteToEdit.email}}" placeholder="Introduce el email" type="email">
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label>Número del Restaurante</label>
              <span *ngIf="!isSubmitted"> *</span>
              <span class="text-danger" *ngIf="isSubmitted && formControls.numeroRestaurante.errors?.required">
                *</span>
              <input #numeroRestaurante formControlName="numeroRestaurante" value="{{restauranteToEdit.numeroRestaurante}}" class="form-control" placeholder="Introduce el número"
                type="number">
            </div>
          </div>
          <div class="col-md-6">
            <div class="position-relative form-group">
              <label>Contraseña</label>
              <span *ngIf="!isSubmitted"> *</span>
              <span class="text-danger" *ngIf="isSubmitted && formControls.contrasena.errors?.required"> *</span>
              <div class="d-flex genPass">
                <input #password  formControlName="contrasena" [value]="isAdmin ? restauranteToEdit.password : ''" class="form-control col-md-6 d-flex mr-2 " placeholder="Introduce la contraseña" type="text">
                <button (click)="generatePassword()" class="btn btn-primary d-inline-block text-light" type="button">Generar contraseña</button>
              </div>

            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label>Nombre de la persona Responsable</label>
              <span *ngIf="!isSubmitted"> *</span>
              <span class="text-danger" *ngIf="isSubmitted && formControls.nombreResponsable.errors?.required"> *</span>
              <input #nombreResponsable formControlName="nombreResponsable" value="{{restauranteToEdit.nombreResponsable}}" class="form-control" type="text"
                placeholder="Introduce el nombre">
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label>Número de la persona Responsable</label>
              <span *ngIf="!isSubmitted"> *</span>
              <span class="text-danger" *ngIf="isSubmitted && formControls.numeroResponsable.errors?.required"> *</span>
              <input #numeroResponsable formControlName="numeroResponsable" value="{{restauranteToEdit.numeroResponsable}}" class="form-control" type="number"
                placeholder="Introduce el numero">
            </div>
          </div>
          <div class="col-md-6">
            <div class="position-relative form-group">
              <label>Comercial</label>
              <span *ngIf="!isSubmitted"> *</span>
              <span class="text-danger" *ngIf="isSubmitted && formControls.comercial.errors?.required"> *</span>
              <input #comercial formControlName="comercial" value="{{restauranteToEdit.comercial}}" class="form-control" type="text"
                placeholder="Introduce el nombre">
            </div>
          </div>
        </div>

      </div>
    </div>
  </form>

  <div   class="card card-box mb-5 bg-neutral-danger">
    
    <div class="card-body d-flex delete justify-content-between align-items-center">
      <h5 class="mb-0 py-2 font-weight-bold">
        Borrar {{restauranteToEdit.nombre}} 
      </h5>
      <button (click)="openModal(deleteModal)" class="btn m-2 btn-danger" >
        <span class="btn-wrapper--icon">
          <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
        </span>
        <span class="btn-wrapper--label ">BORRAR RESTAURANTE</span>
      </button>
    </div>
  </div>

  <ng-template #deleteModal>
    <div class="text-center p-5">
      <div class="avatar-icon-wrapper rounded-circle m-0">
        <div
          class="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-primary m-0 d-130">
          <fa-icon [icon]="['far', 'trash-alt']" class="d-flex align-self-center display-3"></fa-icon>
        </div>
      </div>
      <h4 class="font-weight-bold mt-4">¿Estas seguro?</h4>
      <!-- <p class="mb-0 font-size-lg text-muted">Selecciona tu opción</p> -->
      <div class="pt-4">
        <button *ngIf="!loadingDelete" (click)="deleteRestaurante()" class="btn btn-pill btn-danger mx-1" type="button">
          <span class="btn-wrapper--label">
            Borrar 
          </span>
        </button>
        <button *ngIf="loadingDelete" disabled class="btn btn-pill btn-danger mx-1" type="button">
          <span  aria-hidden="true" class="spinner-border spinner-border-sm mb-1 " role="status"></span>
        </button>
        <button (click)="closeModal()" class="btn btn-pill btn-neutral-dark mx-1" type="button">
          <span class="btn-wrapper--label">
            Cancelar
          </span>
        </button>
      </div>
    </div>
  </ng-template>
</div>