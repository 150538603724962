<app-page-title titleDescription='' titleHeading='Añadir Restaurante'></app-page-title>


<div class="mx-4">
  <form [formGroup]="nuevoRestaurante" (submit)="onSubmit(nuevoRestaurante.value)">

    <div class="card card-box mb-5 border-0">
      <div class="card-header border-0">
        <div class="card-header--title">
          <h5 class="mb-0 py-2 font-weight-bold">
            Nuevo Restaurante
          </h5>
        </div>
        <div [formGroup]="nuevoRestaurante" class="card-header--actions">
          <button *ngIf="loading" class="btn btn-sm m-2 btn-success d-flex  align-items-center" disabled type="button">
            <span aria-hidden="true" class="spinner-border spinner-border-sm " role="status"></span>
            <span class="ml-2">PROCESANDO...</span>
          </button>
          <button *ngIf="!loading" class="btn btn-sm m-2 btn-success" type="submit">
            <span class="btn-wrapper--icon">
              <fa-icon [icon]="['fas', 'plus']"></fa-icon>
            </span>
            <span class="btn-wrapper--label ">GUARDAR RESTAURANTE</span>
          </button>
        </div>
      </div>
      <div id="qrcode"></div>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label>Nombre del Restaurante</label>
              <span *ngIf="!isSubmitted"> *</span>
              <span class="text-danger" *ngIf="isSubmitted && formControls.nombre.errors?.required"> *</span>
              <input formControlName="nombre" class="form-control" placeholder="Introduce el nombre" type="text">
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label>Provincia</label>
              <app-selector collection="Provincias" [selectedFields]="selectedProvincia ? [selectedProvincia]: []" [maxInput]="1"
                [key]="provinciaSelectorKey" (addNewItem)="agregarProvinciaARestaurante($event)"
                (removeItem)="eliminarProvinciaDeRestaurante()" [isAdminOperation]="true" tag="Provincia">
              </app-selector>
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label>Localidad</label>
              <input formControlName="localidad" class="form-control" placeholder="Introduce la localidad" type="text">
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label>Dirección</label>
              <input formControlName="direccion" class="form-control" placeholder="Introduce la direccion" type="text">
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label for="exampleEmail11">Email</label>
              <span *ngIf="!isSubmitted"> *</span>
              <span class="text-danger" *ngIf="isSubmitted && formControls.email.errors?.required"> *</span>
              <span class="text-danger" *ngIf="isSubmitted && formControls.email.errors?.email"> Debe ser un email valido</span>
              <input formControlName="email" class="form-control" placeholder="Introduce el email" type="email">
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label>Número del Restaurante</label>
              <span *ngIf="!isSubmitted"> *</span>
              <span class="text-danger" *ngIf="isSubmitted && formControls.numeroRestaurante.errors?.required">
                *</span>
              <input formControlName="numeroRestaurante" class="form-control" placeholder="Introduce el número"
                type="number">
            </div>
          </div>
          <div class="col-md-6">
            <div class="position-relative form-group">
              <label>Contraseña</label>
              <span *ngIf="!isSubmitted"> *</span>
              <span class="text-danger" *ngIf="isSubmitted && formControls.contrasena.errors?.required"> *</span>
              <span class="text-danger" *ngIf="isSubmitted && formControls.contrasena.errors?.minlength"> Mínimo 6 caracteres</span>
              <div class="d-flex ">
                <input formControlName="contrasena" class="form-control col-md-6 d-flex mr-2 " placeholder="Introduce la contraseña" type="text">
                <button (click)="generatePassword()" class="btn btn-primary d-inline-block text-light" type="button">GENERAR CONTRASEÑA</button>
              </div>

            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <!-- <button class="btn m-2 btn-primary" type="button">Generar contraseña</button> -->
              
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label>Nombre de la persona Responsable</label>
              <span *ngIf="!isSubmitted"> *</span>
              <span class="text-danger" *ngIf="isSubmitted && formControls.nombreResponsable.errors?.required"> *</span>
              <input formControlName="nombreResponsable" class="form-control" type="text"
                placeholder="Introduce el nombre">
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <label>Número de la persona Responsable</label>
              <span *ngIf="!isSubmitted"> *</span>
              <span class="text-danger" *ngIf="isSubmitted && formControls.numeroResponsable.errors?.required"> *</span>
              <input formControlName="numeroResponsable" class="form-control" type="number"
                placeholder="Introduce el numero">
            </div>
          </div>
          <div class="col-md-6">
            <div class="position-relative form-group">
              <label>Comercial</label>
              <span *ngIf="!isSubmitted"> *</span>
              <span class="text-danger" *ngIf="isSubmitted && formControls.comercial.errors?.required"> *</span>
              <input #comercial formControlName="comercial" class="form-control" type="text"
                placeholder="Introduce el nombre">
            </div>
          </div>
        </div>

      </div>
    </div>



  </form>
</div>
