import { Component, OnInit, TemplateRef } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AngularFireFunctions } from "@angular/fire/functions";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FirestoreProviderService } from "src/app/shared/firestore-provider.service";

@Component({
  selector: "app-comercial",
  templateUrl: "./comercial.component.html",
  styleUrls: ["./comercial.component.scss"]
})
export class ComercialComponent implements OnInit {
  isSubmitted: boolean;

  nuevoUsuario = new FormGroup({
    nombre: new FormControl("", Validators.required),
    email: new FormControl(
      "",
      Validators.compose([Validators.email, Validators.required])
    ),
    contrasena: new FormControl(
      "",
      Validators.compose([Validators.minLength(6)])
    )
  });
  loading: boolean;
  public isAdmin: boolean;

  constructor(
    private _firestore: AngularFirestore,
    private _firestoreProvider: FirestoreProviderService,
    private _functions: AngularFireFunctions,
    private _router: Router,
    private notifier: NotifierService,
    private _route: ActivatedRoute,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.getParams();
    this.isAdmin = !this._firestoreProvider.isExtDist();
  }
  id = null;
  getParams() {
    this._route.paramMap.subscribe(params => {
      this.id = params.get("id");
      this.getUsuario();
    });
  }
  usuarioToEdit = {
    email: "",
    contrasena: "",
    nombre: ""
  };

  getUsuario() {
    if (this.id) {
      this._firestoreProvider
        .getCollection("comerciales")
        .doc(this.id)
        .valueChanges()
        .subscribe((usuario: any) => {
          this.usuarioToEdit = {
            email: usuario.email,
            contrasena: usuario.contrasena,
            nombre: usuario.nombre
          };

          this.nuevoUsuario.patchValue({ email: usuario.email });
          this.nuevoUsuario.patchValue({ contrasena: usuario.contrasena });
          this.nuevoUsuario.patchValue({ nombre: usuario.nombre });
        });
    }
  }

  generatePassword() {
    let generated = this._firestore.createId();
    let password = generated.substring(0, 7);
    this.nuevoUsuario.patchValue({ contrasena: password });
  }

  get formControls() {
    return this.nuevoUsuario["controls"];
  }

  showNotification(type, message) {
    this.notifier.notify(type, message);
  }

  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: "modal-dialog-centered"
    });
  }
  closeModal() {
    this.modalRef.hide();
  }
  loadingDelete: boolean;

  deleteUsuario() {
    this.loadingDelete = true;
    let deleteUser = this._functions.httpsCallable("deleteUserAccount");
    deleteUser({
      userUID: this.id,
      userClaim: this._firestoreProvider.isExtDist()
        ? "comercialExt"
        : "comercial"
    })
      .toPromise()
      .then(response => {
        if (response.errorInfo) {
          this.loading = false;
          this.showNotification("danger", response.errorInfo.code);
        } else {
          this._firestoreProvider
            .getCollection("comerciales")
            .doc(this.id)
            .delete()
            .then(() => {
              return this._firestoreProvider
                .getCollection("comercialesIndex")
                .doc(this.id)
                .delete()
            })
            .then(() => {
              this.loadingDelete = false;
              this.showNotification(
                "warning",
                `Se ha borrado el comercial ${this.usuarioToEdit.nombre} `
              );
              this.closeModal();
              this._router.navigateByUrl("/comerciales");
            })
            .catch(err=>{ 
              this.showNotification(
                "warning",
                `Ha ocurrido un error, por favor vuelva a intentar`
              );
              this.closeModal();
              this._router.navigateByUrl("/comerciales");
            });
        }
      }).catch(err=>{ 
        this.showNotification(
          "warning",
          `Ha ocurrido un error, por favor vuelva a intentar`
        );
        this.closeModal();
        this._router.navigateByUrl("/comerciales");
      });
  }

  onSubmit(formValue) {
    this.isSubmitted = true;

    if (!this.id) {
      let createAccount = this._functions.httpsCallable("createUserAccount");
      if (this.nuevoUsuario.valid) {
        this.loading = true;
        createAccount({
          userDetails: {
            email: formValue.email,
            password: formValue.contrasena,
            name: formValue.nombre
          },
          userClaim: this._firestoreProvider.isExtDist()
            ? "comercialExt"
            : "comercial"
        })
          .toPromise()
          .then(response => {
            if (response.errorInfo) {
              let msg = response.errorInfo.message;
              if (response.errorInfo.code === "auth/email-already-exists") {
                msg = "Error, email ya esta en uso por otra cuenta";
              }
              this.showNotification("warning", `${msg}`);
              this.loading = false;
              this._router.navigateByUrl("/comerciales");
            } else {
              this.loading = false;
              this._router.navigateByUrl("/comerciales");
              this.showNotification(
                "success",
                `El comercial ${formValue.nombre} fue creado exitosamente.`
              );
            }
          })
          .catch(err => {
            this.showNotification("warning", 'Ha ocurrido un error, por favor vuelta a intentar');
            this.loading = false;
          });
      }
    } else {
      let updateUser = this._functions.httpsCallable("updateUserAccount");

      if (this.nuevoUsuario.valid) {
        this.loading = true;
        let userDetails: any = {
          email: formValue.email,
          nombre: formValue.nombre
        };
        if (formValue.contrasena) {
          userDetails.password = formValue.contrasena;
        }
        updateUser({
          userUID: this.id,
          userDetails: {
            ...userDetails
          },
          userClaim: this._firestoreProvider.isExtDist()
            ? "comercialExt"
            : "comercial"
        })
          .toPromise()
          .then(response => {
            if (response.errorInfo) {
              let msg = response.errorInfo.message;
              if (response.errorInfo.code === "auth/email-already-exists") {
                msg = "Error, email ya esta en uso por otra cuenta";
              }
              this.showNotification("warning", `${msg}`);
              this.loading = false;
              this._router.navigateByUrl("/comerciales");
            } else {
              this.loading = false;
              this._router.navigateByUrl("/comerciales");
              this.showNotification(
                "success",
                `El comercial ${formValue.nombre} fue actualizado exitosamente.`
              );

              // this._firestoreProvider.getCollection('comerciales').doc(this.id).update({
              //   nombre: formValue.nombre,
              //   email: formValue.email,
              //   contrasena: formValue.contrasena
              // }).catch((error) => {
              //   this.loading = false
              //   this.showNotification('danger', error.code)
              //   // ...
              // }).then(() => {

              //   this._firestoreProvider.getCollection('comercialesIndex').doc(this.id).update({
              //     nombre: formValue.nombre,
              //     email: formValue.email,
              //   }).then(() => {

              //     this._router.navigateByUrl('/comerciales');
              //     this.showNotification('success', `El comercial ${formValue.nombre} fue actualizado exitosamente.`);
              //   })

              // })
            }
          })
          .catch(err => {
            this.loading = false;
            this._router.navigateByUrl("/comerciales");
            this.showNotification("danger", `${err.message}`);
          });
      }
    }
  }
}
