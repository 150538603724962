<app-page-title titleDescription='' titleHeading='Dashboard'></app-page-title>

<div *ngIf='director || directorExt' class="com">

  <div class="row">

    <div class="col-xl-6">
      <div class="card card-box mb-5 bg-premium-dark border-0 text-light">
        <div class="card-body">
          <div class="align-box-row align-items-start">
            <div class="font-weight-bold">
              <small class="text-white-50 d-block mb-1 text-uppercase">Restaurantes</small>
              <span [countUp]=restNumber class="font-size-xxl mt-1"> </span> <span class="font-size-xxl mt-1">
                Restaurantes</span>
            </div>
            <div class="ml-auto">
              <div
                class="bg-slick-carbon text-center text-success font-size-xl d-50 rounded-circle">
                <img class="restIcon" alt="..." src="assets/images/rest-icon.png">
              </div>
            </div>
          </div>
          <div *ngIf="restDelta != undefined" class="mt-3">
            <fa-icon *ngIf="restDelta > 0" [icon]="['fas', 'arrow-up']" class="text-success"></fa-icon>
            <fa-icon *ngIf="restDelta < 0" [icon]="['fas', 'arrow-down']" class="text-danger"></fa-icon>
            <span [class.text-success]="restDelta > 0" [class.text-warning]="restDelta == 0"
              [class.text-danger]="restDelta < 0" class=" px-1">{{restDelta}}</span>
            <span class="text-white-50">Incremento mensual</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6">
      <div class="card card-box mb-5 bg-premium-dark border-0 text-light">
        <div class="card-body">
          <div class="align-box-row align-items-start">
            <div class="font-weight-bold">
              <small class="text-white-50 d-block mb-1 text-uppercase">Vinos</small>
              <span [countUp]=wineNumber class="font-size-xxl mt-1"> </span><span class="font-size-xxl mt-1">
                Botellas</span>
            </div>
            <div class="ml-auto">
              <div class="bg-slick-carbon text-center text-success font-size-xl d-50 rounded-circle overflow-hidden">
                <img class="wineIcon" alt="..." src="assets/images/MenuBottle.png">
              </div>
            </div>
          </div>
          <div *ngIf="winesDelta != undefined" class="mt-3">

            <fa-icon *ngIf="winesDelta > 0" [icon]="['fas', 'arrow-up']" class="text-success"></fa-icon>
            <fa-icon *ngIf="winesDelta < 0" [icon]="['fas', 'arrow-down']" class="text-danger"></fa-icon>

            <span [class.text-success]="winesDelta > 0" [class.text-warning]="winesDelta == 0"
              [class.text-danger]="winesDelta < 0" class="text-success px-1">{{winesDelta}} Botellas </span>
            <span class="text-white-50">Incremento mensual</span>
          </div>
        </div>
      </div>
    </div>


  </div>

  <div class="row">


    <div class="col-xl-4">
      <div class="card card-box">
        <div class="card-header">
          <div class="card-header--title d-flex align-items-center justify-content-between">
            <h4 *ngIf='!details' class="font-size-lg mb-0 py-2 font-weight-bold">
              Tus comerciales con mas cartas
            </h4>
            <h4 *ngIf='details' class="font-size-lg mb-0 py-2 font-weight-bold">
              Actividad de {{comName}} en {{monthToString(getdate().month)}}
            </h4>
            <button *ngIf='details' (click)="goBack()" class="btn btn-sm m-2 btn-primary text-light"
              type="button">Volver</button>
          </div>
        </div>



        <div *ngIf='!details' class="card-body card-spacing item-container">
          <div *ngFor="let comercial of comercials; index as i" class="rest-container">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-center">
                <!-- <div class="bg-slick-carbon text-center text-success font-size-xl d-30 rounded-circle">
                  <img class="restIcon" alt="..." src="assets/images/rest-icon.png">
                </div> -->
                <div class="mx-4 justify-center">
                  <b>{{ comercial.nombre }}</b>
                  <div *ngIf="i == 0" class="text-black-50">Comercial con más cartas</div>
                </div>
              </div>
              <div class="d-flex align-center">
                <div [countUp]="comercial.restaurantes.length" class="font-weight-bold text-success "></div>
                &nbsp;&nbsp;
                <div class="font-weight-bold text-success ">Cartas</div>
                <button (click)="getComercialRestStats(comercial.id, comercial.nombre)"
                  class="btn btn-sm m-2 btn-primary text-light" type="button">info</button>
              </div>
            </div>
            <div class="divider my-1"></div>
          </div>
        </div>


        <div *ngIf='details' class="card-body card-spacing item-container">
          <div *ngFor="let rest of restsCom; index as i" class="rest-container">

            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-center">
                <div class="bg-slick-carbon text-center text-success font-size-xl d-30 rounded-circle">
                  <img class="restIcon" alt="..." src="assets/images/rest-icon.png">
                </div>
                <div class="mx-4 justify-center">
                  <b>{{ rest.nombre }}</b>
                  <div *ngIf="i == 0" class="text-black-50">Restaurante con mas actividad en
                    {{monthToString( getdate().month )}}</div>
                </div>
              </div>


              <div class="d-flex ">
                <div [countUp]="getMonthStat(rest)" class="font-weight-bold text-success "></div>
                &nbsp;&nbsp;
                <div *ngIf="getMonthStat(rest) != 1" class="font-weight-bold text-success ">Visitas</div>
                <div *ngIf="getMonthStat(rest) == 1" class="font-weight-bold text-success ">Visita</div>
              </div>
            </div>
            <div class="divider my-1"></div>

          </div>
        </div>



      </div>
    </div>

    <div class="col-xl-4">
      <div class="card card-box ">
        <div class="card-header">
          <div class="card-header--title">
            <h4 class="font-size-lg mb-0 py-2 font-weight-bold">
              Vinos mas vistos en {{monthToString( getdate().month )}}
            </h4>
          </div>
          <div class="card-header--actions">
            <!-- <div class="btn-group btn-group-sm">
            <button class="btn btn-sm no-caret btn-neutral-dark" type="button">
              Export
            </button>
          </div> -->
          </div>
        </div>
        <div class="card-body card-spacing item-container">
          <div *ngFor="let wine of wines | slice:0:100; index as i" class="wine-container">

            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-center">
                <div class="bg-slick-carbon text-center text-success font-size-xl d-30 rounded-circle overflow-hidden">
                  <img class="wineIcon" alt="..." src="assets/images/MenuBottle.png">
                </div>
                <div class="mx-4 justify-center">
                  <b>{{wine.name}}</b>
                  <div *ngIf="i == 0" class="text-black-50">Vino más visto en {{monthToString( getdate().month )}}
                  </div>
                </div>
              </div>
              <div class="d-flex ">
                <div [countUp]="getMonthStat(wine)" class="font-weight-bold text-success "></div>
                &nbsp;&nbsp;
                <div *ngIf="getMonthStat(wine) > 1" class="font-weight-bold text-success ">Vistas</div>
                <div *ngIf="getMonthStat(wine) == 1" class="font-weight-bold text-success ">Vista</div>
              </div>
            </div>
            <div class="divider my-1"></div>

          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-4">
      <div class="card card-box">
        <div class="card-header">
          <div class="card-header--title">
            <h4 class="font-size-lg mb-0 py-2 font-weight-bold">
              Comerciales con mas cartas
            </h4>
          </div>
        </div>
        <div class="card-body card-spacing item-container">
          <div *ngFor="let rest of comercialRests; index as i" class="rest-container">

            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-center">
                <!-- <div class="bg-slick-carbon text-center text-success font-size-xl d-30 rounded-circle">
                  <img class="restIcon" alt="..." src="assets/images/rest-icon.png">
                </div> -->
                <div class="mx-4 justify-center">
                  <b>{{ rest.nombre }}</b>
                </div>
              </div>


              <div class="d-flex ">
                <div [countUp]="rest.rests.length" class="font-weight-bold text-success "></div>
                &nbsp;&nbsp;
                <div class="font-weight-bold text-success ">Cartas</div>
              </div>
            </div>
            <div *ngIf="rest.id != 'z8OjcFHRdhfpyigqpK4bfrIa5wc2' && rest.id != 'yGPoDELExCUpS9AzZu4CMYkdmj92'"
              class="divider my-1"></div>

          </div>
        </div>
      </div>
    </div>

    



  </div>
</div>
<div *ngIf='comercial || comercialExt' class="com">

  
  <div class="row">

    <div class="col-xl-6">
      <div class="card card-box mb-5 bg-premium-dark border-0 text-light">
        <div class="card-body">
          <div class="align-box-row align-items-start">
            <div class="font-weight-bold">
              <small class="text-white-50 d-block mb-1 text-uppercase">Restaurantes</small>
              <span [countUp]=restNumber class="font-size-xxl mt-1"> </span> <span class="font-size-xxl mt-1">
                Restaurantes</span>
            </div>
            <div class="ml-auto">
              <div
                class="bg-slick-carbon text-center text-success font-size-xl d-50 rounded-circle">
                <img class="restIcon" alt="..." src="assets/images/rest-icon.png">
              </div>
            </div>
          </div>
          <div *ngIf="restDelta != undefined" class="mt-3">
            <fa-icon *ngIf="restDelta > 0" [icon]="['fas', 'arrow-up']" class="text-success"></fa-icon>
            <fa-icon *ngIf="restDelta < 0" [icon]="['fas', 'arrow-down']" class="text-danger"></fa-icon>
            <span [class.text-success]="restDelta > 0" [class.text-warning]="restDelta == 0"
              [class.text-danger]="restDelta < 0" class=" px-1">{{restDelta}}</span>
            <span class="text-white-50">Incremento mensual</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6">
      <div class="card card-box mb-5 bg-premium-dark border-0 text-light">
        <div class="card-body">
          <div class="align-box-row align-items-start">
            <div class="font-weight-bold">
              <small class="text-white-50 d-block mb-1 text-uppercase">Vinos</small>
              <span [countUp]=wineNumber class="font-size-xxl mt-1"> </span><span class="font-size-xxl mt-1">
                Botellas</span>
            </div>
            <div class="ml-auto">
              <div class="bg-slick-carbon text-center text-success font-size-xl d-50 rounded-circle overflow-hidden">
                <img class="wineIcon" alt="..." src="assets/images/MenuBottle.png">
              </div>
            </div>
          </div>
          <div *ngIf="winesDelta != undefined" class="mt-3">

            <fa-icon *ngIf="winesDelta > 0" [icon]="['fas', 'arrow-up']" class="text-success"></fa-icon>
            <fa-icon *ngIf="winesDelta < 0" [icon]="['fas', 'arrow-down']" class="text-danger"></fa-icon>

            <span [class.text-success]="winesDelta > 0" [class.text-warning]="winesDelta == 0"
              [class.text-danger]="winesDelta < 0" class="text-success px-1">{{winesDelta}} Botellas </span>
            <span class="text-white-50">Incremento mensual</span>
          </div>
        </div>
      </div>
    </div>


  </div>


  <div class="row">


    <div class="col-xl-6">
      <div class="card card-box">
        <div class="card-header">
          <div class="card-header--title">
            <h4 class="font-size-lg mb-0 py-2 font-weight-bold">
              Tus restaurantes con mas actividad en {{monthToString( getdate().month )}}
            </h4>
          </div>
        </div>
        <div class="card-body card-spacing item-container">
          <div *ngFor="let rest of restsCom; index as i" class="rest-container">

            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-center">
                <div class="bg-slick-carbon text-center text-success font-size-xl d-30 rounded-circle">
                  <img class="restIcon" alt="..." src="assets/images/rest-icon.png">
                </div>
                <div class="mx-4 justify-center">
                  <b>{{ rest.nombre }}</b>
                  <div *ngIf="i == 0" class="text-black-50">Restaurante con mas actividad en
                    {{monthToString( getdate().month )}}</div>
                </div>
              </div>


              <div class="d-flex ">
                <div [countUp]="getMonthStat(rest)" class="font-weight-bold text-success "></div>
                &nbsp;&nbsp;
                <div *ngIf="getMonthStat(rest) != 1" class="font-weight-bold text-success ">Visitas</div>
                <div *ngIf="getMonthStat(rest) == 1" class="font-weight-bold text-success ">Visita</div>
              </div>
            </div>
            <div class="divider my-1"></div>

          </div>
        </div>
      </div>
    </div>


    <div class="col-xl-6">
      <div class="card card-box">
        <div class="card-header">
          <div class="card-header--title">
            <h4 class="font-size-lg mb-0 py-2 font-weight-bold">
              Comerciales con mas cartas
            </h4>
          </div>
        </div>
        <div class="card-body card-spacing item-container">
          <div *ngFor="let rest of comercialRests; index as i" class="rest-container">

            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-center">
                <div class="bg-slick-carbon text-center text-success font-size-xl d-30 rounded-circle">
                  <img class="restIcon" alt="..." src="assets/images/rest-icon.png">
                </div>
                <div class="mx-4 justify-center">
                  <b>{{ rest.nombre }}</b>
                </div>
              </div>


              <div class="d-flex ">
                <div [countUp]="rest.rests.length" class="font-weight-bold text-success "></div>
                &nbsp;&nbsp;
                <div class="font-weight-bold text-success ">Cartas</div>
              </div>
            </div>
            <div *ngIf="rest.id != 'z8OjcFHRdhfpyigqpK4bfrIa5wc2' && rest.id != 'yGPoDELExCUpS9AzZu4CMYkdmj92'"
              class="divider my-1"></div>

          </div>
        </div>
      </div>
    </div>

  </div>


</div>

<div *ngIf='editor || distAdmin || editorExt' class="adm">
  <div class="row">

    <div class="col-xl-3">
      <div class="card card-box mb-5 bg-premium-dark border-0 text-light">
        <div class="card-body">
          <div class="align-box-row align-items-start">
            <div class="font-weight-bold">
              <small class="text-white-50 d-block mb-1 text-uppercase">Restaurantes</small>
              <span [countUp]=restNumber class="font-size-xl mt-1"> </span> <span class="font-size-xl mt-1">
                Restaurantes</span>
            </div>
          </div>
          <div *ngIf="restDelta != undefined" class="mt-3">
            <fa-icon *ngIf="restDelta > 0" [icon]="['fas', 'arrow-up']" class="text-success"></fa-icon>
            <fa-icon *ngIf="restDelta < 0" [icon]="['fas', 'arrow-down']" class="text-danger"></fa-icon>
            <span [class.text-success]="restDelta > 0" [class.text-warning]="restDelta == 0"
              [class.text-danger]="restDelta < 0" class=" px-1">{{restDelta}}</span>
            <span class="text-white-50">Incremento mensual</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-3">
      <div class="card card-box mb-5 bg-premium-dark border-0 text-light">
        <div class="card-body">
          <div class="align-box-row align-items-start">
            <div class="font-weight-bold">
              <small class="text-white-50 d-block mb-1 text-uppercase">Vinos</small>
              <span [countUp]=wineNumber class="font-size-xl mt-1"> </span><span class="font-size-xl mt-1">
                Botellas</span>
            </div>
          </div>
          <div *ngIf="winesDelta != undefined" class="mt-3">

            <fa-icon *ngIf="winesDelta > 0" [icon]="['fas', 'arrow-up']" class="text-success"></fa-icon>
            <fa-icon *ngIf="winesDelta < 0" [icon]="['fas', 'arrow-down']" class="text-danger"></fa-icon>

            <span [class.text-success]="winesDelta > 0" [class.text-warning]="winesDelta == 0"
              [class.text-danger]="winesDelta < 0" class="text-success px-1">{{winesDelta}}</span>
            <span class="text-white-50">Incremento mensual</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-3">
      <div class="card card-box mb-5 border-0 text-primary">
        <div class="card-body">
          <div class="align-box-row align-items-start">
            <div class="font-weight-bold">
              <small class="text-black-50 d-block mb-1 text-uppercase">Visitas diarias medias</small>
              <span [countUp]="viewNumber" class="font-size-xl mt-1"> </span> <span class="font-size-xl mt-1">
                Visitas Hoy</span>
            </div>
          </div>
          <div *ngIf="viewDelta != undefined" class="mt-3">
            <fa-icon *ngIf="viewDelta > 0" [icon]="['fas', 'arrow-up']" class="text-success"></fa-icon>
            <fa-icon *ngIf="viewDelta < 0" [icon]="['fas', 'arrow-down']" class="text-danger"></fa-icon>
            <span [class.text-success]="viewDelta > 0" [class.text-warning]="viewDelta == 0"
              [class.text-danger]="viewDelta < 0" class=" px-1">{{viewDelta}}</span>
            <span class="text-black-50">Incremento semanal</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-3">
      <div class="card card-box mb-5 border-0 text-primary card-height">
        <div class="card-body d-flex align-items-center">
          <div class="align-box-row align-items-start">
            <div class="font-weight-bold">
              <small class="text-black-50 d-block mb-1 text-uppercase">Visitas Activas</small>
              <span [countUp]="viewActive" class="font-size-xl mt-1"> </span><span class="font-size-xl mt-1">
                Visitas Activas</span>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
  <div class="row">
    <div class="col-xl-6">
      <app-graph-card chart='area'>
      </app-graph-card>
    </div>
    <div class="col-xl-6">
      <app-graph-card chart='bar' [comercialBarData]="comercialChart" [wineBarData]="winesChart">
      </app-graph-card>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-6">
      <app-card key="restaurantes" (onDataPropChange)="setDataProp($event)" cardTitle="Restaurantes con mas actividad en {{monthToString(getdate().month)}}">
        <app-detail-list
          [list]="rests"
          identProp='name'
          [valueProp]="dataProp"
          appendValue='Visitas'
          subtitle='Restaurante con mas actividad en {{monthToString(getdate().month)}}'
          icon='assets/images/rest-icon.png'
          key='restaurantes'
        >
        </app-detail-list>
      </app-card>
    </div>
    <div class="col-xl-6">
      <app-card cardTitle="Vinos mas vistos en {{monthToString( getdate().month )}}">
        <app-list
          [list]="wines"
          identProp='name'
          valueProp='monthView'
          appendValue='Vistas'
          subtitle="Vino más visto en {{monthToString(getdate().month)}}"
          icon='assets/images/MenuBottle.png'
        ></app-list>
      </app-card>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-6">
      <app-card cardTitle="Comerciales con mas cartas">
        <app-detail-list
          [list]="comercials"
          identProp='nombre'
          valueProp='numberOfRest'
          appendValue='Cartas'
          subtitle='Comercial con mas cartas'
          key='comerciales'
        >
        </app-detail-list>
      </app-card>
    </div>
    <div class="col-xl-6">
      <app-card cardTitle="Porcentaje de vino propios en restaurantes">
        <app-list
          [list]="ownedWinesOnRest"
          identProp='name'
          valueProp='winePercent'
          appendValue='%'
        ></app-list>
      </app-card>
    </div>
  </div>
</div>


<!-- <div *ngIf='editorExt' class="adm">

  <div class="row">

    <div class="col-xl-6">
      <div class="card card-box mb-5 bg-premium-dark border-0 text-light">
        <div class="card-body">
          <div class="align-box-row align-items-start">
            <div class="font-weight-bold">
              <small class="text-white-50 d-block mb-1 text-uppercase">Restaurantes</small>
              <span [countUp]=restNumber class="font-size-xxl mt-1"> </span> <span class="font-size-xxl mt-1">
                Restaurantes</span>
            </div>
            <div class="ml-auto">
              <div (click)="getCollections()"
                class="bg-slick-carbon text-center text-success font-size-xl d-50 rounded-circle">
                <img class="restIcon" alt="..." src="assets/images/rest-icon.png">
              </div>
            </div>
          </div>
          <div *ngIf="restDelta != undefined" class="mt-3">
            <fa-icon *ngIf="restDelta > 0" [icon]="['fas', 'arrow-up']" class="text-success"></fa-icon>
            <fa-icon *ngIf="restDelta < 0" [icon]="['fas', 'arrow-down']" class="text-danger"></fa-icon>
            <span [class.text-success]="restDelta > 0" [class.text-warning]="restDelta == 0"
              [class.text-danger]="restDelta < 0" class=" px-1">{{restDelta}}</span>
            <span class="text-white-50">Incremento mensual</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6">
      <div class="card card-box mb-5 bg-premium-dark border-0 text-light">
        <div class="card-body">
          <div class="align-box-row align-items-start">
            <div class="font-weight-bold">
              <small class="text-white-50 d-block mb-1 text-uppercase">Vinos</small>
              <span [countUp]=wineNumber class="font-size-xxl mt-1"> </span><span class="font-size-xxl mt-1">
                Botellas</span>
            </div>
            <div class="ml-auto">
              <div class="bg-slick-carbon text-center text-success font-size-xl d-50 rounded-circle overflow-hidden">
                <img class="wineIcon" alt="..." src="assets/images/MenuBottle.png">
              </div>
            </div>
          </div>
          <div *ngIf="winesDelta != undefined" class="mt-3">

            <fa-icon *ngIf="winesDelta > 0" [icon]="['fas', 'arrow-up']" class="text-success"></fa-icon>
            <fa-icon *ngIf="winesDelta < 0" [icon]="['fas', 'arrow-down']" class="text-danger"></fa-icon>

            <span [class.text-success]="winesDelta > 0" [class.text-warning]="winesDelta == 0"
              [class.text-danger]="winesDelta < 0" class="text-success px-1">{{winesDelta}} Botellas </span>
            <span class="text-white-50">Incremento mensual</span>
          </div>
        </div>
      </div>
    </div>


  </div>

  <div class="row">
    <div class="col-xl-4">
      <app-card cardTitle="Restaurantes con mas actividad en {{monthToString(getdate().month)}}">
        <app-detail-list
          [list]="rests"
          identProp='name'
          valueProp='visits'
          appendValue='Visitas'
          subtitle='Restaurante con mas actividad en {{monthToString(getdate().month)}}'
          icon='assets/images/rest-icon.png'
          key='restaurantes'
        >
        </app-detail-list>
      </app-card>
    </div>
    <div class="col-xl-4">
      <app-card cardTitle="Vinos mas vistos en {{monthToString( getdate().month )}}">
        <app-list
          [list]="wines"
          identProp='name'
          valueProp='monthView'
          appendValue='Vistas'
          subtitle="Vino más visto en {{monthToString(getdate().month)}}"
          icon='assets/images/MenuBottle.png'
        ></app-list>
      </app-card>
    </div>
    <div class="col-xl-4">
      <app-card cardTitle="Comerciales con mas cartas">
        <app-detail-list
          [list]="comercials"
          identProp='nombre'
          valueProp='numberOfRest'
          appendValue='Cartas'
          subtitle='Comercial con mas cartas'
          key='comerciales'
        >
        </app-detail-list>
      </app-card>
    </div>
  </div>
</div> -->
