import { Component, OnInit, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { SidebarMenuService } from './sidebar-menu.service';
import { ThemeOptions } from '../../theme-options';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  animations: [
    trigger('slide', [
      state('up', style({ height: 0, opacity: '0' })),
      state('down', style({ height: '*', opacity: '1' })),
      transition('up <=> down', animate(200))
    ])
  ]
})
export class SidebarMenuComponent implements OnInit {
  menus = [];
  admin
  editor
  comercial
  director
  editorExt
  comercialExt
  directorExt
  distAdmin: boolean;
  constructor(
    public globals: ThemeOptions,
    private sidebarMenuService: SidebarMenuService,
    private router: Router,
    private _auth: AuthService
  ) {

    this.admin = false
    this.distAdmin = false
    this.editor = false
    this.comercial = false
    this.director = false
    this.editorExt = false
    this.comercialExt = false
    this.directorExt = false

    this._auth.currentUser().getIdTokenResult(true).then((result) => {
      if (result.claims.admin) {
        this.admin = result.claims.admin
      }
      if (result.claims.distAdmin) {
        this.distAdmin = result.claims.distAdmin
      }
      if (result.claims.editor) {
        this.editor = result.claims.editor
      }
      if (result.claims.comercial) {
        this.comercial = result.claims.comercial
      }
      if (result.claims.director) {
        this.director = result.claims.director
      }
      if (result.claims.editorExt) {
        this.editorExt = result.claims.editorExt
      }
      if (result.claims.comercialExt) {
        this.comercialExt = result.claims.comercialExt
      }
      if (result.claims.directorExt) {
        this.directorExt = result.claims.directorExt
      }

      
      if (this.editor) {
        this.menus = [...sidebarMenuService.getMenuList().editorMenus];
      }
      if (this.admin) {
        this.menus = [...sidebarMenuService.getMenuList().adminMenus];
      }
      if (this.comercial || this.director ) {
        this.menus = [...sidebarMenuService.getMenuList().comercialMenus];
      }
      if (this.editorExt) {
        this.menus = [...sidebarMenuService.getMenuList().editorMenus];
      }
      if (this.distAdmin) {
        this.menus = [...sidebarMenuService.getMenuList().distAdminMenus];
      }
      if (this.comercial || this.director || this.comercialExt || this.directorExt ) {
        this.menus = [...sidebarMenuService.getMenuList().comercialMenus];
      }


    })
  }

  private innerWidth: number;


  ngOnInit() {
    const theActiveMenu = this.sidebarMenuService.getMenuItemByUrl(this.menus, this.router.url);
    if (theActiveMenu) {
      this.toggle(theActiveMenu);
    }
    this.innerWidth = window.innerWidth;
  }



  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  toggleSidebarMobileOpen() {
    if (this.innerWidth < 992) {
      this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
      this.globals.toggleSidebar = false;
    }
  }

  toggle(currentMenu) {
    this.menus = this.sidebarMenuService.toggleMenuItem(this.menus, currentMenu);
  }

  getState(currentMenu) {

    if (currentMenu.active) {
      return 'down';
    } else {
      return 'up';
    }
  }

}
