<app-page-title titleDescription='' titleHeading='Asignar vinos'></app-page-title>

<div class="card card-box mx-4 mb-5">
    <div class="card-header pr-2">
        <div class="card-header--title ">
            <h5  class="mb-0 py-2 font-weight-bold">
                {{restauranteToEdit.nombre}}
              </h5>
        </div>
        <div class="card-header--actions">
            <button *ngIf="loading" class="btn btn-sm m-2 btn-success" disabled type="button">
                <span aria-hidden="true" class="spinner-border spinner-border-sm " role="status"></span>
                <span class="ml-2">PROCESANDO...</span>
            </button>
            <button *ngIf="!loading" (click)="showResult()" class="btn btn-sm m-2 btn-success" type="button">
                <span class="btn-wrapper--icon">
                  <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                </span> 
                <span class="btn-wrapper--label ">GUARDAR CAMBIOS</span>
            </button>
        </div>
    </div>
    <div class="card-body ">
        <dual-list [format]="format" [filter]="true" [source]="source" [(destination)]="target" [key]="key" [display]="display" height="300px"></dual-list>
    </div>
</div>