import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { SelectMultipleControlValueAccessor } from "@angular/forms";
import { setMonth } from "ngx-bootstrap/chronos/utils/date-setters";
import { FirestoreProviderService } from "src/app/shared/firestore-provider.service";

@Component({
  selector: "app-graph-card",
  templateUrl: "./graph-card.component.html",
  styleUrls: ["./graph-card.component.scss"]
})
export class GraphCardComponent implements OnInit, OnChanges {
  private TITLES = {
    BAR_COM: "Comerciales con mas cartas",
    BAR_WINE: "Vinos mas vistos este mes",
    BAR_WINE_MONTH: "Vinos mas vistos ",
    AREA_VIEW: "Estadisticas Mensuales",
    AREA_REST: "Restaurantes",
    AREA_WINE: "Vinos"
  };

  private Months = {
    "01": ["Ene", "Enero"],
    "02": ["Feb", "Febrero"],
    "03": ["Mar", "Marzo"],
    "04": ["Abr", "Abril"],
    "05": ["May", "Mayo"],
    "06": ["Jun", "Junio"],
    "07": ["Jul", "Julio"],
    "08": ["Ago", "Agosto"],
    "09": ["Sep", "Septiembre"],
    "10": ["Oct", "Octubre"],
    "11": ["Nov", "Noviembre"],
    "12": ["Dic", "Diciembre"]
  };

  @Input() chart: string;
  @Input() comercialBarData: any = [];
  @Input() wineBarData: any;

  public cardTitle: string;
  public areaData: any = { data: [{ name: "", data: [] }], months: [] };
  public barData: any = { data: [], names: [] };
  public sortedWineBarData: any;

  public year: number;
  public month: number;
  public months: any;
  public viewsAreaData: any;
  public restAreaData: any;
  public wineAreaData: any;

  public selectedArea: string = "views";
  public selectedBar: string = "comercial";

  constructor(private _firestoreProvider: FirestoreProviderService) {}

  async getWineAreaData() {
    if (this.wineAreaData) {
      this.areaData = this.wineAreaData;
      return true;
    }
    const wineDoc = await this._firestoreProvider
      .getCollection("stats")
      .doc("wineAmmount")
      .get()
      .toPromise();
    const wines = await wineDoc.data();
    const wineCount = [];
    const Months = [];
    const monthsToView = this.getLastSixMonths().reverse();
    for (const monthObj of monthsToView) {
      Months.push(this.Months[monthObj.month][0]);
      wineCount.push(wines[monthObj.year][monthObj.month]);
    }
    this.wineAreaData = {
      data: [
        {
          name: "Vinos",
          data: wineCount
        }
      ],
      months: Months
    };
    this.areaData = this.wineAreaData;
    console.log(this.areaData);
    return true;
  }

  async getRestAreaData() {
    if (this.restAreaData) {
      this.areaData = this.restAreaData;
      return true;
    }
    const restDoc = await this._firestoreProvider
      .getCollection("stats")
      .doc("restAmmount")
      .get()
      .toPromise();
    const rests = await restDoc.data();
    const restCount = [];
    const Months = [];
    const monthsToView = this.getLastSixMonths().reverse();
    for (const monthObj of monthsToView) {
      Months.push(this.Months[monthObj.month][0]);
      restCount.push(rests[monthObj.year][monthObj.month]);
    }
    this.restAreaData = {
      data: [
        {
          name: "Restaurantes",
          data: restCount
        }
      ],
      months: Months
    };
    this.areaData = this.restAreaData;
    console.log(this.areaData);
    return true;
  }

  getLastSixMonths() {
    const { year, month } = this.getdate();
    const lastSixMonths = [];
    let monthtoAdd = month;
    let yearToAdd = year;
    let counter = 0;
    for (let i = 0; i < 13; i++) {
      const formattedMonth = (monthtoAdd - counter < 10
        ? "0" + (monthtoAdd - counter)
        : monthtoAdd - counter
      ).toString();
      lastSixMonths.push({ month: formattedMonth, year: yearToAdd });
      counter++;
      if (monthtoAdd - counter === 0) {
        counter = 0;
        monthtoAdd = 12;
        yearToAdd--;
      }
    }
    console.log(lastSixMonths);
    return lastSixMonths;
  }

  async getViewsAreaData() {
    if (this.viewsAreaData) {
      this.areaData = this.viewsAreaData;
      return true;
    }
    const viewsDoc = await this._firestoreProvider
      .getCollection("stats")
      .doc("views")
      .get()
      .toPromise();
    const views = await viewsDoc.data();
    const AppViews = [];
    const QRViews = [];
    const Months = [];
    const monthsToView = this.getLastSixMonths().reverse();
    for (const monthObj of monthsToView) {
      Months.push(this.Months[monthObj.month][0]);
      if (views[monthObj.year][monthObj.month]["App"])
        AppViews.push(views[monthObj.year][monthObj.month]["App"]["all"]);
      if (views[monthObj.year][monthObj.month]["QR"])
        QRViews.push(views[monthObj.year][monthObj.month]["QR"]["all"]);
    }
    this.viewsAreaData = {
      data: [
        {
          name: "QR",
          data: QRViews
        },
        {
          name: "App",
          data: AppViews
        }
      ],
      months: Months
    };
    this.areaData = this.viewsAreaData;
    return true;
  }
  getComercialCurrentMonthData(comercialesViews) {
    return comercialesViews.map(comercial => comercial[this.year][this.month]);
  }
  getComercialBarData() {
    const comData = {
      dataName: "Restaurantes",
      names: this.comercialBarData.names,
      data: this.comercialBarData.views
    };
    this.barData = comData;
  }

  getCurrentMonthWineData(wines) {
    return wines
      .sort(
        (a, b) =>
          b.views[this.year][this.month] - a.views[this.year][this.month]
      )
      .slice(0, 10)
      .reduce(
        (accu, current) => {
          return {
            names: [...accu.names, current.name],
            views: [...accu.views, current.views[this.year][this.month]]
          };
        },
        { names: [], views: [] }
      );
  }

  getSpecificMonthWineData(wines, month) {
    return wines
      .filter(wine => wine.views[this.year][month])
      .sort((a, b) => b.views[this.year][month] - a.views[this.year][month])
      .slice(0, 9)
      .reduce(
        (accu, current) => {
          return {
            names: [...accu.names, current.name],
            views: [...accu.views, current.views[this.year][month]]
          };
        },
        { names: [], views: [] }
      );
  }

  getWineBarData(sort, month) {
    let wines: any = {};
    let wineData: any = {};
    switch (sort) {
      case "current":
        wines = this.getCurrentMonthWineData(this.wineBarData);
        wineData = {
          dataName: "Vistas",
          colors: ["#d23078", "#d23078"],
          names: wines.names,
          data: wines.views
        };
        this.cardTitle = this.TITLES.BAR_WINE;
        break;
      case "month":
        wines = this.getSpecificMonthWineData(this.wineBarData, month);
        wineData = {
          dataName: "Vistas",
          colors: ["#d23078", "#d23078"],
          names: wines.names,
          data: wines.views
        };
        const formatMonth = month < 10 ? "0" + month : month.toString();
        this.cardTitle =
          this.TITLES.BAR_WINE_MONTH + this.Months[formatMonth][1];
        break;
      default:
        wines = this.getCurrentMonthWineData(this.wineBarData);
        wineData = {
          dataName: "Vistas",
          colors: ["#d23078", "#d23078"],
          names: wines.names,
          data: wines.views
        };
        this.cardTitle = this.TITLES.BAR_WINE;
        break;
    }
    this.barData = wineData;
  }

  getdate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();

    let date: any = {};

    date["day"] = dd;
    date["month"] = mm;
    date["year"] = yyyy;

    return date;
  }
  ngOnInit() {
    const date = this.getdate();
    this.year = date.year;
    this.month = date.month;
    if (this.chart === "bar") {
      this.cardTitle = this.TITLES.BAR_COM;
      const availableMonths = [];
      for (let i = 1; i <= this.month; i++) {
        const index = i < 10 ? "0" + i : i;
        availableMonths.push(this.Months[index][0]);
        this.months = Promise.resolve(availableMonths);
      }
    }
    if (this.chart === "area") {
      this.cardTitle = this.TITLES.AREA_VIEW;
      this.getViewsAreaData();
    }
  }

  ngOnChanges() {
    if (this.selectedBar === "comercial" && this.chart === "bar") {
      this.getComercialBarData();
    }
  }

  changeBarSelected(selection, selector = "current", month = this.month) {
    this.selectedBar = selection;
    if (selection === "comercial") {
      this.getComercialBarData();
      this.cardTitle = this.TITLES.BAR_COM;
    }
    if (selection === "wines") {
      this.getWineBarData(selector, month);
    }
  }

  changeAreaSelected(selection) {
    this.selectedArea = selection;
    if (selection === "views") {
      this.getViewsAreaData();
      this.cardTitle = this.TITLES.AREA_VIEW;
    }
    if (selection === "rest") {
      this.getRestAreaData();
      this.cardTitle = this.TITLES.AREA_REST;
    }
    if (selection === "wines") {
      this.getWineAreaData();
      this.cardTitle = this.TITLES.AREA_WINE;
    }
  }

  trackMonthList(index, item) {
    return item;
  }
}
