<app-page-title *ngIf='!id' titleDescription='' titleHeading='Nuevo Director Comercial'></app-page-title>
<app-page-title *ngIf='id' titleDescription='' titleHeading=' Editar Director Comercial'></app-page-title>

<div class="mx-4">
    <form [formGroup]="nuevoUsuario" (submit)="onSubmit(nuevoUsuario.value)">
  
      <div class="card card-box mb-5 border-0">
        <div class="card-header border-0">
          <div class="card-header--title">
            <h5 *ngIf='!id' class="mb-0 py-2 font-weight-bold">
              Nuevo Director Comercial
            </h5>
            <h5 *ngIf='id' class="mb-0 py-2 font-weight-bold">
              Editar {{usuarioToEdit.nombre}}
            </h5>
          </div>
          <div class="card-header--actions">
    
            <button *ngIf="loading" class="btn btn-sm m-2 btn-success d-flex  align-items-center" disabled type="button">
              <span aria-hidden="true" class="spinner-border spinner-border-sm " role="status"></span>
              <span class="ml-2">PROCESANDO...</span>
            </button>
            <button *ngIf="!loading" class="btn btn-sm m-2 btn-success" type="submit">
              <span class="btn-wrapper--icon">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>
              </span>
              <span *ngIf='!id' class="btn-wrapper--label ">CREAR DIRECTOR</span>
              <span *ngIf='id' class="btn-wrapper--label ">EDITAR DIRECTOR</span>
            </button>
          </div>
        </div>
        <div class="card-body">
  
          <div class="form-row">
            <div class="col-md-3">
              <div class="position-relative form-group">
                <label for="exampleEmail11">Email</label>
                <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.email.errors?.email"> Debe ser un email valido</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.email.errors?.required"> *</span>
                <input formControlName="email" class="form-control" placeholder="Introduce el email" type="email">
              </div>
            </div>
            <div class="col-md-3">
              <div class="position-relative form-group">
                <label>Nombre</label>
                <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.nombre.errors?.required">*</span>
                  
                <input formControlName="nombre" class="form-control" placeholder="Introduce el número"
                  type="text">
              </div>
            </div>
            <div class="col-md-6">
              <div class="position-relative form-group">
                <label>Contraseña</label>
                <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.contrasena.errors?.required"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.contrasena.errors?.minlength"> Mínimo 6 caracteres</span>
                <div class="d-flex ">
                  <input formControlName="contrasena" [value]="isAdmin ? usuarioToEdit.contrasena : ''" class="form-control col-md-6 d-flex mr-2 " placeholder="Introduce la contraseña" type="text">
                  <button (click)="generatePassword()" class="btn btn-primary d-inline-block text-light" type="button">Generar contraseña</button>
                </div>
  
              </div>
            </div>
            <div class="col-md-3">
              <div class="position-relative form-group">
                <!-- <button class="btn m-2 btn-primary" type="button">Generar contraseña</button> -->
                
              </div>
            </div>
          </div>
  
        </div>
      </div>
  
    </form>


    <div class="card card-box mb-5 bg-neutral-danger">
    
      <div class="card-body d-flex justify-content-between align-items-center">
        <h5 class="mb-0 py-2 font-weight-bold">
          Borrar {{usuarioToEdit.nombre}}
        </h5>
        <button (click)="openModal(deleteModal)" class="btn m-2 btn-danger" >
          <span class="btn-wrapper--icon">
            <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
          </span>
          <span class="btn-wrapper--label ">BORRAR USUARIO</span>
        </button>
      </div>
    </div>
  
    <ng-template #deleteModal>
      <div class="text-center p-5">
        <div class="avatar-icon-wrapper rounded-circle m-0">
          <div
            class="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-primary m-0 d-130">
            <fa-icon [icon]="['far', 'trash-alt']" class="d-flex align-self-center display-3"></fa-icon>
          </div>
        </div>
        <h4 class="font-weight-bold mt-4">¿Estas seguro?</h4>
        <!-- <p class="mb-0 font-size-lg text-muted">Selecciona tu opción</p> -->
        <div class="pt-4">
          <button *ngIf="!loadingDelete" (click)="deleteUsuario()" class="btn btn-pill btn-danger mx-1" type="button">
            <span class="btn-wrapper--label">
              Borrar 
            </span>
          </button>
          <button *ngIf="loadingDelete" disabled class="btn btn-pill btn-danger mx-1" type="button">
            <span  aria-hidden="true" class="spinner-border spinner-border-sm mb-1 " role="status"></span>
          </button>
          <button  (click)="closeModal()" class="btn btn-pill btn-neutral-dark mx-1" type="button">
            <span class="btn-wrapper--label">
              Cancelar
            </span>
          </button>
        </div>
      </div>
    </ng-template>



  </div>
