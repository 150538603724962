import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AngularFireStorage } from "@angular/fire/storage";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireFunctions } from "@angular/fire/functions";
import { NotifierService } from "angular-notifier";
import * as jsPDF from "jspdf";
import { finalize } from "rxjs/operators";
import { FirestoreProviderService } from "src/app/shared/firestore-provider.service";
import { DistribuidoresComponent } from "../distribuidores/distribuidores.component";

declare var QRCode: any;

@Component({
  selector: "app-anadir-restaurante",
  templateUrl: "./anadir-restaurante.component.html",
  styleUrls: ["./anadir-restaurante.component.scss"]
})
export class AnadirRestauranteComponent implements OnInit {
  public provinciaSelectorKey = "Nombre";
  public selectedProvincia: string = "";

  nuevoRestaurante = new FormGroup({
    nombre: new FormControl("", Validators.required),
    email: new FormControl(
      "",
      Validators.compose([Validators.email, Validators.required])
    ),
    contrasena: new FormControl(
      "",
      Validators.compose([Validators.minLength(6), Validators.required])
    ),
    nombreResponsable: new FormControl("", Validators.required),
    numeroResponsable: new FormControl("", Validators.required),
    // provincia: new FormControl('', Validators.required),
    localidad: new FormControl(""),
    direccion: new FormControl(""),
    comercial: new FormControl(""),
    numeroRestaurante: new FormControl("", Validators.required)
  });

  isSubmitted: boolean;
  passwordDifferent: boolean;
  loading: boolean;
  code: any;
  fakeuid: any = "hello";
  output: any;
  //output: any;

  constructor(
    private _functions: AngularFireFunctions,
    public _auth: AngularFireAuth,
    private _storage: AngularFireStorage,
    private _firestore: AngularFirestore,
    private _firestoreProvider: FirestoreProviderService,
    private _router: Router,
    private notifier: NotifierService
  ) {}

  ngOnInit() {}

  generatePassword() {
    //let password = this.password.nativeElement.value
    let generated = this._firestore.createId();
    let password = generated.substring(0, 7);
    this.nuevoRestaurante.patchValue({ contrasena: password });
  }

  showNotification(type, message) {
    this.notifier.notify(type, message);
  }

  resolveDate() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    const todayDate = dd + "/" + mm + "/" + yyyy;
    return todayDate;
  }

  getDate() {

    var today = new Date();
    var dd = today.getDate()
    var mm = today.getMonth() + 1
    var yyyy = today.getFullYear()

    let date: any = {}

    date['day'] = dd
    date['month'] = mm
    date['year'] = yyyy

    return date
  }

  formatMonth(month) {
    return month < 10 ? '0'+month : month.toString();
  }

  async addRestCount(){
    const {year, month, day} = this.getDate();
    const formattedMonth = month < 10 ? '0'+month : month.toString();
    const restAmmount = await this._firestoreProvider.getCollection('stats').doc('restAmmount').get().toPromise();
    const restAmmountX = await this._firestoreProvider.getCollection('restaurantesIndex').get().toPromise();
    const restObj = restAmmount.data();
    if (!restObj[year]) {
      restObj[year] = {};
      if (!restObj[year][formattedMonth]) {
        restObj[year][formattedMonth] = 0
      }
    }
    const previousAmmount = restObj[year][formattedMonth] ? restObj[year][formattedMonth] : restObj[year][this.formatMonth(month-1)]
    restObj[year][formattedMonth] = restAmmountX.docs.length;
    await this._firestoreProvider.getCollection('stats').doc('restAmmount').set({...restObj},{merge: true});
  }

  onSubmit(formValue) {
    this.isSubmitted = true;

    const createAccount = this._functions.httpsCallable("createNewRestaurant");
    if (this.nuevoRestaurante.valid) {
      const formValues = {
        nombre: formValue.nombre,
        email: formValue.email,
        password: formValue.contrasena,
        creado: this.resolveDate(),
        provincia: this.selectedProvincia,
        localidad: formValue.localidad,
        direccion: formValue.direccion,
        comercial: formValue.comercial,
        numeroRestaurante: formValue.numeroRestaurante,
        nombreResponsable: formValue.nombreResponsable,
        numeroResponsable: formValue.numeroResponsable
      };
      const formValuesIndex = {
        nombre: formValue.nombre,
        email: formValue.email,
        creado: this.resolveDate(),
        provincia: this.selectedProvincia,
        localidad: formValue.localidad,
        direccion: formValue.direccion,
        comercial: formValue.comercial
      };
      this.loading = true;
      createAccount({
        email: formValue.email,
        password: formValue.contrasena,
        displayName: formValue.nombre,
        distribuidorUID: this._firestoreProvider.getDistUID(),
        formValues,
        formValuesIndex
      })
        .toPromise()
        .then(response => {
          if (response.errorInfo) {
            this.loading = false;
            let msg = response.errorInfo.message;
            if (response.errorInfo.code === "auth/email-already-exists") {
              msg = "Error, email ya esta en uso por otra cuenta";
            }
            this.showNotification("warning", `${msg}`);
          } else {
            this.addRestCount();
            this.loading = false;
            this.showNotification(
              "success",
              `Se ha guardado el restaurante ${formValue.nombre} con exito`
            );
            this._router.navigateByUrl("/restaurantes");
          }
        })
        .catch(err => {
          console.log(err);
          this.showNotification(
            "danger",
            `Ocurrio un error, intente nuevamente`
          );
        });
    }
  }

  onSubmio(formValue) {
    this.isSubmitted = true;

    if (this.nuevoRestaurante.valid) {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0");
      let yyyy = today.getFullYear();
      let todayDate = dd + "/" + mm + "/" + yyyy;

      let id = this._firestore.createId();
      this._auth.auth
        .createUserWithEmailAndPassword(formValue.email, formValue.contrasena)
        .then(() => {
          this._firestoreProvider
            .getCollection("restaurantes")
            .doc(id)
            .set({
              id: id,
              nombre: formValue.nombre,
              email: formValue.email,
              password: formValue.contrasena,
              creado: todayDate,
              provincia: formValue.provincia,
              localidad: formValue.localidad,
              direccion: formValue.direccion,
              numeroRestaurante: formValue.numeroRestaurante,
              nombreResponsable: formValue.nombreResponsable,
              numeroResponsable: formValue.numeroResponsable
            })
            .catch(error => {
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
              // ...
            })
            .then(() => {
              this._firestoreProvider
                .getCollection(id)
                .doc("configs")
                .set({
                  pin: "0000"
                })
                .catch(error => {
                  // Handle Errors here.
                  var errorCode = error.code;
                  var errorMessage = error.message;
                  // ...
                })
                .then(() => {
                  this._firestoreProvider
                    .getCollection(id)
                    .doc("vinos")
                    .set({
                      vinos: [""]
                    })
                    .catch(error => {
                      // Handle Errors here.
                      var errorCode = error.code;
                      var errorMessage = error.message;
                      // ...
                    })
                    .then(() => {
                      this._router.navigateByUrl("/restaurantes");
                    });
                });
            });
        })
        .catch(error => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // ...
        });
    }
  }

  get formControls() {
    return this.nuevoRestaurante["controls"];
  }

  agregarProvinciaARestaurante(event) {
    if (this.selectedProvincia === "") {
      this.selectedProvincia = event[this.provinciaSelectorKey];
    }
  }

  eliminarProvinciaDeRestaurante() {
    this.selectedProvincia = "";
  }
}
