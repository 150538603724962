import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() cardTitle: string;
  @Input() key: string = '';

  @Output() onDataPropChange = new EventEmitter<{}>();
  
  public restFilterStatus: string = 'Visitas';
  public dataProp: string = ''

  constructor() { }

  ngOnInit() { }

  toggleRestView(view) {
    switch(view) {
      case 'visitas':
        this.dataProp = 'visits';
        this.restFilterStatus = 'Visitas'
        this.onDataPropChange.emit('visits')
        break;
        case 'lastuse': 
        this.dataProp = 'lastUse';
        this.restFilterStatus = 'Ultimo uso'
        this.onDataPropChange.emit('lastUse')
        break;
        default: 
        this.dataProp = 'visits';
        this.restFilterStatus = 'Visitas'
        this.onDataPropChange.emit('visits')
        break;
    }
  }

}
