import { Component, OnDestroy, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireFunctions } from "@angular/fire/functions";
import { NotifierService } from "angular-notifier";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/shared/auth.service";
import { FirestoreProviderService } from "src/app/shared/firestore-provider.service";

@Component({
  selector: "app-restaurantes",
  templateUrl: "./restaurantes.component.html",
  styleUrls: ["./restaurantes.component.scss"]
})
export class RestaurantesComponent implements OnInit, OnDestroy {
  public admin: boolean;
  public editor: boolean;
  public comercial: boolean;
  public director: boolean;
  public editorExt: boolean;
  public comercialExt: boolean;
  public directorExt: boolean;
  public distAdmin: boolean;

  isCollapsed = true;
  rotate = true;

  page: number = 1;
  query: any = [];
  loading: boolean = false;
  file: any;
  id;
  subs = new Subscription();
  constructor(
    private _firestore: AngularFirestore,
    private _firestoreProvider: FirestoreProviderService,
    private _functions: AngularFireFunctions,
    private notifier: NotifierService,
    private _auth: AuthService
  ) {
    this.admin = false;
    this.editor = false;
    this.comercial = false;
    this.director = false;
    this.editorExt = false;
    this.comercialExt = false;
    this.directorExt = false;
    this.distAdmin = false;

    this._auth
      .currentUser()
      .getIdTokenResult(true)
      .then(result => {
        if (result.claims.admin) {
          this.admin = result.claims.admin;
        }
        if (result.claims.distAdmin) {
          this.distAdmin = result.claims.distAdmin;
        }
        if (result.claims.editor) {
          this.editor = result.claims.editor;
        }
        if (result.claims.comercial) {
          this.comercial = result.claims.comercial;
        }
        if (result.claims.director) {
          this.director = result.claims.director;
        }
        if (result.claims.editorExt) {
          this.editorExt = result.claims.editorExt;
        }
        if (result.claims.comercialExt) {
          this.comercialExt = result.claims.comercialExt;
        }
        if (result.claims.directorExt) {
          this.directorExt = result.claims.directorExt;
        }

        if (this.admin || this.editor || this.distAdmin || this.editorExt) {
          this.getRestaurantes();
        }
        if (this.comercial || this.comercialExt) {
          this.id = result.claims.user_id;
          this.getComercialRestaurantes();
        }
        if (this.director || this.directorExt) {
          this.getDirectorComercials();
        }
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    // this.getRestaurantes();
  }

  showNotification(type, message) {
    this.notifier.notify(type, message);
  }
  comercials;
  getDirectorComercials() {
    this.comercials = [];
    let id = this._auth.currentUser().uid;

    this.subs.add(
      this._firestoreProvider
        .getCollection("directoresIndex")
        .doc(id)
        .snapshotChanges()
        .subscribe((doc: any) => {
          this.subs.add(
            this._firestoreProvider
              .getCollection("comercialesIndex")
              .snapshotChanges()
              .subscribe((doc2: any) => {
                // (doc.data().comerciales)
                let comercialesIndex = doc.payload.data().comerciales;

                let comercialesList = doc2.map(rest => rest.payload.doc.data());

                comercialesIndex.forEach((ele, i) => {
                  let id = ele.id;
                  let curComercial = comercialesList.filter(
                    filteredComercial => {
                      return filteredComercial.id == id;
                    }
                  );

                  if (curComercial[0]) {
                    // curRest[0].nombre = restList[i].nombre
                    this.comercials.push(curComercial[0].restaurantes);
                  }
                });
                this.comercials.forEach(element => {
                  element.forEach(element2 => {
                    this.query.push(element2);
                    this.restaurantes = this.query;
                  });
                });
                this.restaurantes.forEach((doc: any, i) => {
                  // let item: any
                  // item = doc.payload.doc.data()
                  doc.last = "click";
                  // })
                });
              })
          );
        })
    );
  }
  comName = "";
  details = false;
  getComercialRestaurantes(outerId?, comName?) {
    // (this.id);
    if (outerId) {
      this.id = outerId;
      this.comName = comName;
      this.details = true;
    }

    this._firestoreProvider
      .getCollection("comercialesIndex")
      .doc(this.id)
      .snapshotChanges()
      .subscribe((doc: any) => {
        this.query = doc.payload.data().restaurantes;
        this.restaurantes = this.query;
        this.restaurantes.forEach((doc: any, i) => {
          // let item: any
          // item = doc.payload.doc.data()
          doc.last = "click";
          // })
        });
      });
  }

  buscador(event) {
    this.restaurantes = this.query;
    let string_norm;
    const searchKeyword = event.target.value
      .toLowerCase()
      .replace(/á/, "a")
      .replace(/é/, "e")
      .replace(/í/, "i")
      .replace(/ó/, "o")
      .replace(/ú/, "u");

    let busqueda = this.restaurantes.filter(restaurante => {
      string_norm = restaurante.nombre
        .replace(/á/, "a")
        .replace(/é/, "e")
        .replace(/í/, "i")
        .replace(/ó/, "o")
        .replace(/ú/, "u");
      return string_norm.toLowerCase().includes(searchKeyword);
    });
    this.restaurantes = busqueda;
  }

  getLastLogin(restUid, index) {
    //(index)

    this.restaurantes[index + this.page * 10 - 10].last = "CARGANDO";

    let getLastLogin = this._functions.httpsCallable("listUsers");

    getLastLogin({ uid: restUid })
      .toPromise()
      .then(response => {
        if (response.errorInfo) {
          this.showNotification("danger", response.errorInfo.code);
          this.loading = false;
        } else {
          let date: string = response.metadata.lastSignInTime.substring(4, 16);

          let months = ["Jan", "Apr", "Aug"];
          let changeLang;

          months.forEach(element => {
            changeLang = date.indexOf(element);

            if (changeLang >= 0) {
              switch (element) {
                case "Jan":
                  date = date.replace("Jan", "ENE");

                  break;

                case "Apr":
                  date = date.replace("Apr", "ABR");
                  break;

                case "Aug":
                  date = date.replace("Aug", "AGO");
                  break;

                default:
                  break;
              }
            }
          });

          this.restaurantes[index + this.page * 10 - 10].last = date;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  restaurantes;

  getRestaurantes() {
    this.subs.add(
      this._firestoreProvider
        .getCollectionAndSort("restaurantes", "nombre")
        .snapshotChanges()
        .subscribe(doc => {
          this.query = [];
          doc.forEach((doc: any, i) => {
            let item: any;
            item = doc.payload.doc.data();
            item.last = "click";
            this.query.push(item);
            // let fileItem = ({
            //   Nombre: item.name,
            //   Provincia: item.provincia,
            //   Localidad: item.localidad,
            //   Comercial: item.comercial,
            //   Email: item.email,
            // })
          });
          this.restaurantes = this.query;

          this.file = this.restaurantes.map((element: any) => ({
            Nombre: element.nombre,
            Provincia: element.provincia,
            Localidad: element.localidad,
            Comercial: this.converter(element.comercial),
            Email: element.email
          }));
          //(this.file)
          //(this.restaurantes)
        })
    );
  }

  convertArrayOfObjectsToCSV(args) {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ",";
    lineDelimiter = args.lineDelimiter || "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function(item) {
      ctr = 0;
      keys.forEach(function(key) {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  downloadCSV() {
    var data, filename, link;
    var csv = this.convertArrayOfObjectsToCSV({
      data: this.file
    });
    var date = new Date();
    let day = date.getDay();
    let month = date.getMonth();
    let year = date.getFullYear();
    let hour = date.getHours();
    let minutes = date.getMinutes();

    if (csv == null) return;

    filename = `Restaurantes ${year +
      "/" +
      month +
      "/" +
      day +
      "-" +
      hour +
      ":" +
      minutes}.csv`;
    // if (!csv.match(/^data:text\/csv/i)) {
    //   csv =  "data:text/csv;charset=utf-8,%EF%BB%BF," + csv;
    // }
    data = encodeURI(csv);

    link = document.createElement("a");
    // link.setAttribute('href', data);
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv)
    );
    link.setAttribute("download", filename);
    link.click();
  }

  converter(string) {
    if (string == true) {
      return "Si";
    } else if (string == false || string == undefined) {
      return "N/A";
    } else if (string.includes(",")) {
      return string.replace(",", ".");
    } else {
      return string;
    }
  }
}
