import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreProviderService } from "src/app/shared/firestore-provider.service";

@Component({
  selector: "app-distribuidores",
  templateUrl: "./distribuidores.component.html",
  styleUrls: ["./distribuidores.component.scss"]
})
export class DistribuidoresComponent implements OnInit {
  isCollapsed = true;
  rotate = true;

  page: number = 1;
  distribuidores: any;

  constructor(
    private _firestore: AngularFirestore,
    private _firestoreProvider: FirestoreProviderService
  ) {}

  ngOnInit() {
    this.getComerciales();
  }

  buscador(event) {
    const searchKeyword = event.target.value
      .toLowerCase()
      .replace(/á/, "a")
      .replace(/é/, "e")
      .replace(/í/, "i")
      .replace(/ó/, "o")
      .replace(/ú/, "u");

    let busqueda = this.distribuidores.filter(distribuidor => {
      return distribuidor.name
        .toLowerCase()
        .replace(/á/, "a")
        .replace(/é/, "e")
        .replace(/í/, "i")
        .replace(/ó/, "o")
        .replace(/ú/, "u")
        .includes(searchKeyword);
    });
    this.distribuidores = busqueda;
  }
  getComerciales() {
    this._firestoreProvider
      .getCollection("NewDistribuidoresIndex")
      .snapshotChanges()
      .subscribe(distribuidores => {
        this.distribuidores = distribuidores.map(doc => doc.payload.doc.data());
      });
  }
}
