<app-page-title titleDescription='' titleHeading='Restaurantes'></app-page-title>

<div class="card card-box mb-5">
  <div class="card-header pr-2">
    <div class="card-header--title ">
      <div class="search-wrapper w-25">
        <span class="icon-wrapper text-black">
          <fa-icon [icon]="['fas', 'search']"></fa-icon>
        </span>
        <input (keyup)="buscador($event)" class="form-control form-control-sm rounded-pill" placeholder="Buscar..." type="search">
      </div>
    </div>
    <div class="card-header--actions">
      <button *ngIf="editor || editorExt || distAdmin" (click)='downloadCSV();' class="btn  btn-sm m-2 btn-success" type="button">
        <span class="btn-wrapper--icon">
          <fa-icon [icon]="['fas', 'plus']"></fa-icon>
        </span>
        <span class="btn-wrapper--label ">EXPORTAR RESTAURANTES</span>
      </button>

      <button *ngIf="editor || editorExt || distAdmin" [routerLink]="['/anadir-restaurante']" class="btn btn-sm m-2 btn-success" type="button">
        <span class="btn-wrapper--icon">
          <fa-icon [icon]="['fas', 'plus']"></fa-icon>
        </span>
        <span class="btn-wrapper--label ">AÑADIR RESTAURANTE</span>
      </button>
    </div>
  </div>
  <div class="table-responsive">

    <table *ngIf='editor || comercial || director || editorExt || comercialExt || directorExt' class="table table-hover text-nowrap mb-0 table-borderless">
      <thead class="bg-primary">
        <tr class="bg-primary">
          <!-- <th class="text-center bg-primary text-light">#</th> -->
          <!-- <th class="text-center bg-primary text-light">Localidad</th> -->
          <!-- <th class="text-center bg-primary text-light">Estado</th> -->
          <th class="text-center bg-primary text-light">Restaurante</th>
          <th class="text-center bg-primary text-light">Fecha de Creacion</th>
          <th class="text-center bg-primary text-light">Ultima actividad</th>
          <th *ngIf="editor || editorExt" class="text-center bg-primary text-light">Acceso a panel</th>
          <th class="text-center bg-primary text-light">Descargas</th>
          <th *ngIf="editor || editorExt" class="text-center bg-primary text-light">Ordenar Vinos</th>
          <th *ngIf="editor || editorExt" class="text-center bg-primary text-light">Añadir/Remover</th>
        </tr>
      </thead>
      <tbody>
        <tr 
          *ngFor="let restaurante of restaurantes | paginate: { itemsPerPage: 10, currentPage: page } ; index as i">
          <!-- <td>
            12
          </td> -->
          <td [routerLink]="['/editar-restaurante', restaurante.id]">
            <div class="d-flex align-items-center" >
              <div class="avatar-icon-wrapper mx-4">
                <div class="avatar-icon p-1 bg-slick-carbon"><img alt="..." src="assets/images/rest-icon.png" /></div>
              </div>
              <div>
                <a class="font-weight-bold text-black" title="...">
                  {{restaurante.nombre}}
                </a>
              </div>
            </div>
          </td>
          <td  [routerLink]="['/editar-restaurante', restaurante.id]" class="text-center">
            <div class=" text-black-50 d-block">{{restaurante.creado}}</div>
          </td>
          <td  class="text-center">
            <div (click)="getLastLogin(restaurante.id, i)" class="badge pointer bg-neutral-warning text-neutral px-4">{{restaurante.last}}</div>
          </td>
          <td  *ngIf="editor || editorExt" class="text-center">
            <a href="https://app.dvinum.es/d/{{restaurante.email}}/{{restaurante.password}}" target="_blank" class=" text-black-50 d-block">
              <button class="btn btn-sm btn-link " type="button">
                <fa-icon [icon]="['fas', 'ellipsis-h']" class="font-size-lg"></fa-icon>
              </button>
            </a>
          </td>
          <td *ngIf="!director || !directorExt" [routerLink]="['/fichas-vinos', restaurante.id]" class="text-center">
            <div class=" text-black-50 d-block">
              <button class="btn btn-sm btn-link " type="button">
                <fa-icon [icon]="['fas', 'ellipsis-h']" class="font-size-lg"></fa-icon>
              </button>
            </div>
          </td>
          <td *ngIf="director || directorExt" [routerLink]="['/fichas-vinos', restaurante.id, restaurante.nombre]" class="text-center">
            <div class=" text-black-50 d-block">
              <button class="btn btn-sm btn-link " type="button">
                <fa-icon [icon]="['fas', 'ellipsis-h']" class="font-size-lg"></fa-icon>
              </button>
            </div>
          </td>
          <td *ngIf="editor || editorExt" [routerLink]="['/ordenar-vinos', restaurante.id]" class="text-center">
            <div class=" text-black-50 d-block">
              <button [routerLink]="['/ordenar-vinos', restaurante.id]" class="btn btn-sm btn-link " type="button">
                <fa-icon [icon]="['fas', 'ellipsis-h']" class="font-size-lg"></fa-icon>
              </button>
            </div>
          </td>
          <td *ngIf="editor || editorExt" [routerLink]="['/asignar-vinos', restaurante.id]" class="text-center asign">
            <button [routerLink]="['/asignar-vinos', restaurante.id]" class="btn btn-sm btn-link " type="button">
              <fa-icon [icon]="['fas', 'ellipsis-h']" class="font-size-lg"></fa-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>


  </div>
  <div class="card-footer py-3 d-flex justify-content-between">
    <pagination-controls class="pagination" (pageChange)="page = $event"></pagination-controls>
  </div>
</div>
