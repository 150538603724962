<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground"
     [ngClass]="{
    'app-page-title--shadow' : globals.pageTitleShadow
    }">
    <div>
        <ol *ngIf="globals.pageTitleBreadcrumb" class="app-page-title--breadcrumb breadcrumb">
            <li class="breadcrumb-item">
                <a routerLink="/">
                    <fa-icon [icon]="['fas', 'tachometer-alt']"></fa-icon>
                </a>
            </li>
            <li class="breadcrumb-item"><a routerLink="/dashboard-default">Dashboards</a></li>
            <li class="breadcrumb-item active">
                <span>{{titleHeading}}</span>
            </li>
        </ol>
        <div class="app-page-title--first">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-umbrella display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>{{titleHeading}}</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">
                    {{titleDescription}}
                </div>
            </div>
        </div>
    </div>
</div>