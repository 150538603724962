import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { NotifierService } from 'angular-notifier';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FirestoreProviderService } from 'src/app/shared/firestore-provider.service';

@Component({
  selector: 'app-editar-restaurante',
  templateUrl: './editar-restaurante.component.html',
  styleUrls: ['./editar-restaurante.component.scss']
})
export class EditarRestauranteComponent implements OnInit {

  restauranteToEdit = {
    nombre: '',
    email: '',
    password: '',
    provincia: '',
    localidad: '',
    direccion: '',
    comercial: '',
    numeroRestaurante: '',
    nombreResponsable: '',
    numeroResponsable: '',
  };
  nuevoRestaurante = new FormGroup({
    nombre: new FormControl(''),
    email: new FormControl('', Validators.compose([
      Validators.email,
      Validators.required
    ])),
    contrasena: new FormControl(''),
    nombreResponsable: new FormControl(''),
    numeroResponsable: new FormControl(''),
    provincia: new FormControl(''),
    localidad: new FormControl(''),
    direccion: new FormControl(''),
    numeroRestaurante: new FormControl(''),
    comercial: new FormControl('')
  });

  @ViewChild('nombre', { static: true }) nombreInput: ElementRef;
  @ViewChild('email', { static: true }) emailInput: ElementRef;
  @ViewChild('password', { static: true }) passwordInput: ElementRef;
  @ViewChild('provincia', { static: true }) provinciaInput: ElementRef;
  @ViewChild('localidad', { static: true }) localidadInput: ElementRef;
  @ViewChild('direccion', { static: true }) direccionInput: ElementRef;
  @ViewChild('comercial', { static: true }) comercialInput: ElementRef;
  @ViewChild('numeroRestaurante', { static: true }) numeroRestauranteInput: ElementRef;
  @ViewChild('nombreResponsable', { static: true }) nombreResponsableInput: ElementRef;
  @ViewChild('numeroResponsable', { static: true }) numeroResponsableInput: ElementRef;

  isSubmitted: boolean;
  passwordDifferent: boolean;
  id: any;
  inputValue: any;
  modalRef: BsModalRef;
  loading: boolean;
  loadingDelete: boolean;

  public selectedProvincia = '';
  public provinciaSelectorKey = "Nombre";

  public isAdmin: boolean = false;

  constructor(
    private _functions: AngularFireFunctions,
    private notifier: NotifierService,
    private _auth: AngularFireAuth,
    private _storage: AngularFireStorage,
    private _firestore: AngularFirestore,
    private _firestoreProvider: FirestoreProviderService,
    private _router: Router,
    private _route: ActivatedRoute,
    private modalService: BsModalService,
  ) {
  }

  ngOnInit() {
    this._route.paramMap.subscribe(params => {
      this.id = params.get('id')

    });
    this.isAdmin = !this._firestoreProvider.isExtDist();
    this.getRestaurante();
  }

  showNotification(type, message) {
    this.notifier.notify(type, message);
  }

  getRestaurante() {
    this._firestoreProvider.getCollection('restaurantes').doc(this.id).valueChanges().subscribe((restaurante: any) =>{
    this.selectedProvincia = restaurante.provincia;
      this.restauranteToEdit = {
        nombre: restaurante.nombre,
        email: restaurante.email,
        password: restaurante.password,
        provincia: restaurante.provincia,
        localidad: restaurante.localidad,
        direccion: restaurante.direccion,
        comercial: restaurante.comercial,
        numeroRestaurante: restaurante.numeroRestaurante,
        nombreResponsable: restaurante.nombreResponsable,
        numeroResponsable: restaurante.numeroResponsable
      }
    });
  }

  generatePassword() {
    //let password = this.password.nativeElement.value
    let generated = this._firestore.createId();
    let password = generated.substring(0, 7);
    this.nuevoRestaurante.patchValue({ contrasena: password });

  }

  getInput() {
    this.inputValue = {
      nombre: this.nombreInput.nativeElement.value,
      email: this.emailInput.nativeElement.value,
      password: this.passwordInput.nativeElement.value || this.restauranteToEdit.password,
      provincia: this.selectedProvincia,
      localidad: this.localidadInput.nativeElement.value,
      direccion: this.direccionInput.nativeElement.value,
      comercial: this.comercialInput.nativeElement.value,
      numeroRestaurante: this.numeroRestauranteInput.nativeElement.value,
      nombreResponsable: this.nombreResponsableInput.nativeElement.value,
      numeroResponsable: this.numeroResponsableInput.nativeElement.value,
    }

  }


  onSubmit(formValue) {
    let updateUser = this._functions.httpsCallable('updateRestaurant');
    this.isSubmitted = true
    this.loading = true
    this.getInput();

    updateUser({
      userUID: this.id,
      restAccount: {
        email: this.inputValue.email,
        password: this.inputValue.password,
        displayName: this.inputValue.nombre
      },
      restaurantDetails: {
        nombre: this.inputValue.nombre,
        email: this.inputValue.email,
        password: this.inputValue.password,
        provincia: this.selectedProvincia,
        localidad: this.inputValue.localidad,
        direccion: this.inputValue.direccion,
        comercial: this.inputValue.comercial,
        numeroRestaurante: this.inputValue.numeroRestaurante,
        nombreResponsable: this.inputValue.nombreResponsable,
        numeroResponsable: this.inputValue.numeroResponsable
      },
      restaurantDetailsIndex: {
        nombre: this.inputValue.nombre,
        email: this.inputValue.email,
        provincia: this.selectedProvincia,
        localidad: this.inputValue.localidad,
        direccion: this.inputValue.direccion,
        comercial: this.inputValue.comercial
      }
    }).toPromise().then((response) => {
      if (response.errorInfo) {
        this.loading = false
        this.showNotification('danger', response.errorInfo.code)
      } else {
        this.loading = false
        this.notifier.notify("success", `Se ha actualizado el restaurante ${this.inputValue.nombre} con exito`)
        this._router.navigateByUrl('/restaurantes');

      }
    })
  }

  deleteRestaurante() {
    this.loadingDelete = true
    let deleteUser = this._functions.httpsCallable('deleteRestaurant');
    deleteUser({
      userUID: this.id
    }).toPromise().then((response) => { 

      if (response.errorInfo) {
        this.loading = false
        this.showNotification('danger', response.errorInfo.code)
      } else {
        this.loadingDelete = false
        this.showNotification('warning', `Se ha borrado el restaurante ${this.restauranteToEdit.nombre} `)
        this._router.navigateByUrl('/restaurantes');
      }
    })
    .finally(()=>{this.closeModal();})
  }



  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }


  closeModal() {
    this.modalRef.hide()
  }

  get formControls() {
    return this.nuevoRestaurante['controls'];
  }



  onAgregarProvincia(event) {
    if (this.selectedProvincia === ''){
      this.selectedProvincia = event[this.provinciaSelectorKey];
    }
  }
  onDeleteProvincia() {
    this.selectedProvincia = "";
  }

}
