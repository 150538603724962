import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreProviderService } from "src/app/shared/firestore-provider.service";

@Component({
  selector: "app-usuarios",
  templateUrl: "./usuarios.component.html",
  styleUrls: ["./usuarios.component.scss"]
})
export class UsuariosComponent implements OnInit {
  isCollapsed = true;
  rotate = true;

  page: number = 1;
  usuarios: any;
  query: any;

  constructor(
    private _firestore: AngularFirestore,
    private _firestoreProvider: FirestoreProviderService
  ) {}

  ngOnInit() {
    this.getUsuarios();
  }

  buscador(event) {
    const searchKeyword = event.target.value
      .toLowerCase()
      .replace(/á/, "a")
      .replace(/é/, "e")
      .replace(/í/, "i")
      .replace(/ó/, "o")
      .replace(/ú/, "u");
    this.usuarios = this.query;
    let busqueda = this.usuarios.filter(restaurante => {
      return restaurante.nombre
        .toLowerCase()
        .replace(/á/, "a")
        .replace(/é/, "e")
        .replace(/í/, "i")
        .replace(/ó/, "o")
        .replace(/ú/, "u")
        .includes(searchKeyword);
    });
    this.usuarios = busqueda;
  }
  getUsuarios() {
    this._firestoreProvider
      .getCollection("usuarios")
      .snapshotChanges()
      .subscribe(doc => {
        this.query = [];
        doc.forEach((doc, i) => {
          this.query.push(doc.payload.doc.data());
        });
        this.usuarios = this.query;
      });
  }
}
