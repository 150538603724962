import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { auth, User } from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // public authState: User = null;

  constructor(
    public _auth: AngularFireAuth,
    private _router: Router
  ) { 
    // this._auth.authState.subscribe(auth => {
    //   this.authState = auth;
    // });

  }


  currentUser() {
    let user = auth().currentUser;
    if (user) {
      return user;
    }
  }

  logout() {
    sessionStorage.clear();
    this._auth.auth.signOut().then(() => {
      this._router.navigate(['']);
      // Sign-out successful.
    }).catch(function (error) {
      // An error happened.
    });
  }

  getIdToken() {
    return this._auth.auth.currentUser.getIdTokenResult().then((idTokenResult) => {

      if (!!idTokenResult.claims.admin) {
        return true
      } else {
        return false
      }
    })
  }


}
