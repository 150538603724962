import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { PdfFontsService } from 'src/app/shared/pdf-fonts.service';
import { toPng } from 'html-to-image';
import * as jsPDF from 'jspdf';
import download from 'downloadjs';
import { AuthService } from 'src/app/shared/auth.service';
import { FirestoreProviderService } from 'src/app/shared/firestore-provider.service';

@Component({
  selector: 'app-fichas-vinos',
  templateUrl: './fichas-vinos.component.html',
  styleUrls: ['./fichas-vinos.component.scss']
})
export class FichasVinosComponent implements OnInit {

  id: string;
  restauranteToEdit = {
    nombre: ''
  };
  subs = new Subscription();
  generating: boolean = false;
  generating21: boolean = false;
  generating22: boolean = false;
  code: any;
  qrcode: string;
  //file: any;

  admin
  editor
  comercial
  director
  ComercialId

  constructor(
    private _router: Router,
    private _firestore: AngularFirestore,
    private _firestoreProvider: FirestoreProviderService,
    private _route: ActivatedRoute,
    private _auth: AuthService,
    private pdfFonts: PdfFontsService
  ) {


    this.admin = false
    this.editor = false
    this.comercial = false
    this.director = false
    this._auth.currentUser().getIdTokenResult(true).then((result) => {

      this.ComercialId = result.claims.user_id

      if (result.claims.admin) {
        this.admin = result.claims.admin
      }
      if (result.claims.editor) {
        this.editor = result.claims.editor
      }
      if (result.claims.comercial) {
        this.comercial = result.claims.comercial
      }
      if (result.claims.director) {
        this.director = result.claims.director
      }

      if (this.admin) {
        // this.getRestaurantes();
        this.getRestaurante()
      }
      if (this.comercial || this.director) {
        this.getRestauranteComercial()
        // this.id = result.claims.user_id
      }

    })


    this.subs.add(
      this._route.paramMap.subscribe(params => {
        this.id = params.get('id')
        this.restName = params.get('name')
      })
    )
    this.qrcode = `https://app.dvinum.es/id/${this.id}`;
  }


  ngOnInit() {
    // this.subs.add(
    //   this._route.paramMap.subscribe(params => {
    //     this.id = params.get('id')
    //   })
    // )
    // this.getRestaurante();
  }

  generatingqr
  generatingqr2

  restName
  getRestauranteComercial() {
    this._firestoreProvider.getCollection('comercialesIndex').doc(this.ComercialId).get().toPromise().then((rests: any) => {

      let restComercial = rests.data().restaurantes.filter((rest) => {
        return rest.id == this.id
      })


      this.restauranteToEdit = restComercial[0]
    })
  }

  getTxt(format) {

    if (format == 1) {
      this.generatingqr = true

      toPng(document.getElementById('card-image2'))
        .then((dataUrl) => {
          download(dataUrl, `QR-${this.restauranteToEdit.nombre}-horizontal.png`);

          this.generatingqr = false
        });

    } else if (format == 2) {
      this.generatingqr2 = true

      toPng(document.getElementById('card-image3'))
        .then((dataUrl) => {
          download(dataUrl, `QR-${this.restauranteToEdit.nombre}-vertical.png`);

          this.generatingqr2 = false
        });
    }



  }

  getRestaurante() {
    this._firestoreProvider.getCollection('restaurantes').doc(this.id).get().toPromise().then((restaurante: any) => {

      this.restauranteToEdit = {
        nombre: restaurante.data().nombre,
      }
    })
  }


  winesArray = []

  convertArrayOfObjectsToCSV(args) {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ',';
    lineDelimiter = args.lineDelimiter || '\n';

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  getWineList(caseNo, subCaseNo?) {
    if (caseNo == 1) {
      this.generating = true
    } else {
      if (subCaseNo == 1) {
        this.generating21 = true
      } else {
        this.generating22 = true
      }
    }

    this._firestoreProvider.getCollection(this.id).doc('vinos').get().toPromise().then((doc: any) => {
      let wines = doc.data().vinos.map((wine) => wine.id)


      this._firestoreProvider.getCollection(this.id).get().toPromise().then((restWines: any) => {
        let restWineData = []
        restWines.docs.forEach((element) => {
          restWineData.push(element.data())
        })
        let filteredWineData = []

        wines.forEach(element => {

          let item = restWineData.filter((wineData) => {
            return wineData.id == element
          })
          filteredWineData.push(item[0])
        });


        this._firestoreProvider.getCollection('vinosStock').get().toPromise().then((doc2) => {
          let wineStock = []
          doc2.docs.forEach((element) => {
            wineStock.push(element.data())
          })
          let wineRest = []
          wines.forEach(element => {

            let wine = wineStock.filter((currentwineRest) => {
              return currentwineRest.id == element
            })
            wineRest.push(wine[0])
          });

          let doSorted = wineRest.sort((a, b) => a.DO.localeCompare(b.DO))


          //////////////////////////////////////////////////////////////////////

          if (caseNo == 2) {
            let nameSorted = wineRest.sort((a, b) => a.name.localeCompare(b.name))
            if (subCaseNo == 1) {


              nameSorted.forEach(element => {

                let sortedWineData: any = filteredWineData.filter((wine) => {
                  if (wine) {
                    return wine.id == element.id
                  } else {
                    return undefined
                  }

                })
                if (sortedWineData[0]) {
                  if (sortedWineData[0].active) {
                    element.active = 'activo'
                  } else {
                    element.active = 'inactivo'
                  }
                } else {

                  element.active = 'inactivo'
                }
              })


              let fileDoSorted = nameSorted.sort((a, b) => a.DO.localeCompare(b.DO))
              let file = fileDoSorted.map((element: any) => ({

                nombre: element.name,
                DO: element.DO,
                tipo: element.color,
                activo: element.active

              }))
              this.getDoc(file, subCaseNo)

            } else if (subCaseNo == 2) {



              nameSorted.forEach(element => {

                let sortedWineData: any = filteredWineData.filter((wine) => {
                  if (wine) {
                    return wine.id == element.id
                  } else {
                    return undefined
                  }

                })
                if (sortedWineData[0]) {
                  if (sortedWineData[0].active) {
                    element.active = 'activo'
                  } else {
                    element.active = 'inactivo'
                  }
                } else {

                  element.active = 'inactivo'
                }
              })


              let fileDoSorted = nameSorted.sort((a, b) => a.color.localeCompare(b.color))
              let file = fileDoSorted.map((element: any) => ({

                nombre: element.name,
                DO: element.DO,
                tipo: element.color,
                activo: element.active

              }))
              this.getDoc(file, subCaseNo)



            }

          }

          //////////////////////////////////////////////////////////////////

          let types = ['Tinto', 'Blanco', 'Rosado', 'Espumoso', 'Generoso', 'Vermouth']
          let type

          types.forEach((element, i) => {

            switch (element) {
              case 'Tinto':
                type = 'Vinos Tintos'
                break;
              case 'Blanco':
                type = 'Vinos Blancos'
                break;
              case 'Rosado':
                type = 'Vinos Rosados'
                break;
              case 'Espumoso':
                type = 'Vinos Espumosos'
                break;
              case 'Generoso':
                type = 'Vinos Generosos'
                break;
              case 'Vermouth':
                type = 'Vinos Vermouth'
                break;
              default:
                break;
            }

            let typecheck = doSorted.filter((wineData) => {
              return wineData.color == element
            })
            if (typecheck[0]) {

              let lastDO = null
              let DOIndex = 0
              let typeObj
              typeObj = {}
              typeObj.nombre = type
              typeObj.dos = []

              let typeSorted = doSorted.filter((wineData) => {
                return wineData.color == element
              })

              typeSorted.forEach(element => {

                let currentWineData: any = filteredWineData.filter((wine) => {
                  if (wine) {
                    return wine.id == element.id
                  } else {
                    return undefined
                  }

                })
                if (currentWineData[0]) {
                  if (currentWineData[0].precioBotella == 0) {
                    element.price = ''
                  } else {
                    element.price = currentWineData[0].precioBotella + "€"
                  }
                } else {
                  element.price = ''
                }

                let currentDO = element.DO

                if (lastDO == null) {
                  typeObj.dos[DOIndex] = {}
                  typeObj.dos[DOIndex].nombre = currentDO
                  typeObj.dos[DOIndex].vinos = []
                  typeObj.dos[DOIndex].vinos.push(element)
                  //DOIndex++
                  lastDO = currentDO

                } else if (currentDO == lastDO) {

                  typeObj.dos[DOIndex].vinos.push(element)
                  lastDO = currentDO

                } else {
                  DOIndex++
                  typeObj.dos[DOIndex] = {}
                  typeObj.dos[DOIndex].nombre = currentDO
                  typeObj.dos[DOIndex].vinos = []
                  typeObj.dos[DOIndex].vinos.push(element)
                  lastDO = currentDO

                }

              });

              this.winesArray.push(typeObj)
            }

          });
          if (caseNo == 1) {


            this._firestoreProvider.getCollection(this.id).doc('configs').get().toPromise().then((docConfig: any) => {

              this._firestoreProvider.getCollection(this.id).doc('configs').collection("order").doc('cartas').get().toPromise().then((docCartas: any) => {
                this._firestoreProvider.getCollection(this.id).doc('configs').collection("carta").get().toPromise().then((itemDocCarta: any) => {
                  this._firestoreProvider.getCollection(this.id).doc('configs').collection("order").doc('menus').get().toPromise().then((docMenus: any) => {
                    this._firestoreProvider.getCollection(this.id).doc('configs').collection("menus").get().toPromise().then((itemDocMenus: any) => {
                      let foodMsg = false
                      if (docConfig.data().foodMsg) {
                        foodMsg = docConfig.data().foodMsg
                      }

                      let sortedCart = []
                      if (docCartas.data()) {

                        let indexCarta = docCartas.data().order

                        let cartasArray = itemDocCarta.docs.map((item) => { return item.data() })

                        indexCarta.forEach(element => {
                          let filteredItem = cartasArray.filter((item) => { return item.id == element })[0]
                          sortedCart.push(filteredItem)
                        });

                      }


                      let sortedMenu = []
                      if (docMenus.data()) {

                        let indexMenu = docMenus.data().order

                        let MenusArray = itemDocMenus.docs.map((item) => { return item.data() })


                        indexMenu.forEach(element => {
                          let filteredItem = MenusArray.filter((item) => { return item.id == element })[0]
                          sortedMenu.push(filteredItem)
                        });

                      }

                      toPng(document.getElementById('qrcode'))
                        .then(dataUrl => {

                          dataUrl.replace('data:image/png;base64,', '')


                          this.getDesign(sortedCart, sortedMenu, dataUrl, foodMsg)
                        });
                    })
                  })
                })
              })
            })
          }
        })
      })
    })
  }


  getDoc(file, caseNo) {



    var data, filename, link;
    var csv = this.convertArrayOfObjectsToCSV({
      data: file
    });
    var date = new Date();
    let day = date.getDay();
    let month = date.getMonth();
    let year = date.getFullYear();
    let hour = date.getHours();
    let minutes = date.getMinutes();

    if (csv == null) return;

    filename = `Vinos ${year + '/' + month + '/' + day + '-' + hour + ':' + minutes}.csv`;
    // if (!csv.match(/^data:text\/csv/i)) {
    //   csv =  "data:text/csv;charset=utf-8,%EF%BB%BF," + csv;
    // }
    data = encodeURI(csv);

    link = document.createElement('a');
    // link.setAttribute('href', data);
    link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv));
    link.setAttribute('download', filename);
    link.click()

    if (caseNo == 1) {
      this.generating21 = false
    } else if (caseNo == 2) {

      this.generating22 = false
    }
  }



  getDesign(carta, menus, qr, foodMsg) {
    //setTimeout(() => {



    let doc = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [592, 842]
    })


    doc.setFillColor(40, 40, 40);
    doc.rect(0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, 'F')

    let text = "CARTA DE COMIDAS Y MENÚS"
    let stringWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() * 0.75,
      textOffset = ((doc.internal.pageSize.width) - stringWidth - 70) / 2;

    this.pdfFonts.AddFonts(doc)


    let lightTextColor = doc.setTextColor(234, 234, 234);
    doc.setFont('DrukTextWide', 'Medium');
    doc.setFontSize(17)
    let lastHeight = ((doc.internal.pageSize.height) - 10) / 2
    doc.text(text, 94, lastHeight)

    let accentTextColor = doc.setTextColor(255, 72, 103);
    doc.setFont('Monserrat', 'Bold');
    doc.setFontSize(12)
    stringWidth = doc.getStringUnitWidth(this.restauranteToEdit.nombre) * doc.internal.getFontSize() * 0.75,
      textOffset = ((doc.internal.pageSize.width) - stringWidth) / 2;

    doc.text(this.restauranteToEdit.nombre.toUpperCase(), textOffset - 2, lastHeight + 10)

    lastHeight = doc.internal.pageSize.height - 60
    doc.addImage(this.pdfFonts.logo, 'pdf', ((doc.internal.pageSize.width) - 20) / 2, lastHeight, 20, 20)

    text = 'dvinum.es'

    lightTextColor
    doc.setTextColor(234, 234, 234);
    doc.setFontSize(7)
    stringWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() * 0.75,
      textOffset = ((doc.internal.pageSize.width) - stringWidth - 2) / 2;
    lastHeight = lastHeight + 30
    doc.text(text.toUpperCase(), textOffset, lastHeight)


    let allergenShown = (array: Array<any>, item) => {
      return array.includes(item)
    }


    //////////////////////////////////////CARTA///////////////////////////////////////////

    let displayCarta = () => {

      let columnOneSpace = 190
      let columnTwoSpace = 50
      let columnThreeSpace = 63


      let innerSpace = (doc.internal.pageSize.width) - 110

      let leftMargin
      let rightMargin
      let columnOne = leftMargin
      let columnTwo = columnOne + 190
      let columnThree = columnTwo + 50
      let columnFour = columnThree + 60
      let rows = 0
      let footerHeight





      let newPage = () => {
        lastHeight = 50

        doc.addPage([592, 842], 'p')

        if (this.isOdd(doc.internal.getCurrentPageInfo().pageNumber)) {
          leftMargin = 80
          rightMargin = (doc.internal.pageSize.width) - 30
          footerHeight = (doc.internal.pageSize.height) - 30

          columnOne = leftMargin
          columnTwo = columnOne + columnOneSpace
          columnThree = columnTwo + columnTwoSpace
          columnFour = columnThree + columnThreeSpace

          doc.setFontSize(7);
          doc.setTextColor(255, 72, 103);
          doc.text('DVINUM.ES', leftMargin, footerHeight)


          stringWidth = doc.getStringUnitWidth("IVA INCLUIDO") * doc.internal.getFontSize() * 0.75
          doc.text("IVA INCLUIDO", rightMargin - stringWidth, footerHeight)
          if (foodMsg) {

            stringWidth = doc.getStringUnitWidth(foodMsg) * doc.internal.getFontSize() * 0.75
            doc.text(foodMsg, rightMargin - stringWidth, footerHeight - 10)
          }

          doc.setFontSize(12);
          doc.setTextColor(255, 72, 103);
          doc.text(this.restauranteToEdit.nombre.toUpperCase(), leftMargin, lastHeight);

          doc.setFontSize(21)
          accentTextColor
          let darkTextColor = doc.setTextColor(56, 56, 56);
          stringWidth = doc.getStringUnitWidth('CARTA') * doc.internal.getFontSize() * 0.75
          doc.text('CARTA', rightMargin - stringWidth, lastHeight)

          lastHeight = lastHeight + 5

          doc.line(leftMargin, lastHeight, rightMargin, lastHeight)


        } else {

          leftMargin = 30
          rightMargin = (doc.internal.pageSize.width) - 80
          footerHeight = (doc.internal.pageSize.height) - 30


          columnOne = leftMargin
          columnTwo = columnOne + columnOneSpace
          columnThree = columnTwo + columnTwoSpace
          columnFour = columnThree + columnThreeSpace

          doc.setFontSize(7);
          doc.setTextColor(255, 72, 103);
          doc.text('DVINUM.ES', leftMargin, footerHeight)


          stringWidth = doc.getStringUnitWidth("IVA INCLUIDO") * doc.internal.getFontSize() * 0.75
          doc.text("IVA INCLUIDO", rightMargin - stringWidth, footerHeight)
          if (foodMsg) {

            stringWidth = doc.getStringUnitWidth(foodMsg) * doc.internal.getFontSize() * 0.75
            doc.text(foodMsg, rightMargin - stringWidth, footerHeight - 10)
          }
          doc.setFontSize(21)
          let darkTextColor = doc.setTextColor(56, 56, 56);

          doc.text('CARTA', leftMargin, lastHeight);

          doc.setFontSize(12);
          accentTextColor
          doc.setTextColor(255, 72, 103);
          stringWidth = doc.getStringUnitWidth(this.restauranteToEdit.nombre.toUpperCase()) * doc.internal.getFontSize() * 0.75

          doc.text(this.restauranteToEdit.nombre.toUpperCase(), rightMargin - stringWidth, lastHeight)

          lastHeight = lastHeight + 5

          doc.line(leftMargin, lastHeight, rightMargin, lastHeight)

        }
        doc.setTextColor(255, 72, 103);
        doc.setFontSize(7)
        lastHeight = lastHeight + 15
        doc.text("REFERENCIA", columnOne, lastHeight)
        doc.text("FORMATO", columnTwo, lastHeight)
        doc.text("ALERGENOS", columnThree, lastHeight)
        doc.text("PVP", columnFour, lastHeight)


      }
      newPage()


      carta.forEach((categoria) => {


        if (rows >= 35) {
          rows = 4

          newPage()

          lastHeight = lastHeight + 12

          /////////////////////////////////////////////////////////////////////////////////////////////////////

        } else {

          rows = rows + 4

        }

        doc.setFontSize(18)
        doc.setTextColor(56, 56, 56);
        doc.setFont('DrukTextWide', 'Medium');
        lastHeight = lastHeight + 20


        let nameArray = doc.splitTextToSize(categoria.name.toUpperCase(), innerSpace);

        nameArray.forEach((element, index) => {
          if (index != 0) {
            lastHeight = lastHeight + 14

            rows = rows + 4
          }
          doc.text(element, columnOne, lastHeight);
        });

        //doc.text(categoria.name, columnOne, lastHeight);
        lastHeight = lastHeight + 10

        categoria.optionsA.forEach((comida) => {

          if (rows >= 40) {

            rows = 1

            newPage()

            lastHeight = lastHeight + 12


          }

          ////////////////////////////////////////////NAME/////////////////////////////////////////////////////////



          let darkTextColor = doc.setTextColor(56, 56, 56);
          doc.setFontSize(9)
          let totalHeight = 8
          let startHeight = lastHeight
          lastHeight = lastHeight + 8
          doc.setFont('Monserrat', 'SemiBold');


          let nameArray = doc.splitTextToSize(comida.name.toUpperCase(), columnOneSpace);

          nameArray.forEach((element, index) => {

            if (index != 0) {
              lastHeight = lastHeight + 8
              totalHeight = totalHeight + 8
            }
            doc.text(element, columnOne + 2, lastHeight)


          });


          ////////////////////////////////////////////DESC/////////////////////////////////////////////////////////

          //doc.text(comida.name.toUpperCase(), columnOne + 2, lastHeight);
          doc.setFont('Monserrat', 'Regular');
          doc.setFontSize(7)
          //doc.text(comida.description, columnOne + 2, lastHeight, {maxWidth: columnOneSpace});

          let descArray = doc.splitTextToSize(comida.description, columnOneSpace);

          if (descArray[0] != '') {
            lastHeight = lastHeight + 6
            totalHeight = totalHeight + 6
          }

          descArray.forEach((element, index) => {

            if (element != '') {

              doc.text(element, columnOne + 2, lastHeight)

              if (index != (descArray.length - 1)) {

                totalHeight = totalHeight + 5
                lastHeight = lastHeight + 5

              }

            }

          });
          let heightOffset

          if (comida.allergens.length > 6) {

            if (14 > totalHeight) {
              totalHeight = 14
            }
          }
          if (totalHeight >= 14) {
            heightOffset = startHeight + (totalHeight / 2) + 4
          }


          ////////////////////////////////////////////FORMAT/////////////////////////////////////////////////////////


          doc.setFont('Monserrat', 'Regular');
          doc.setFontSize(7)


          let formatArray = doc.splitTextToSize(comida.comment, columnTwoSpace);

          let formatOffset

          if (totalHeight >= 14) {

            formatOffset = heightOffset

            if (formatArray.length == 1) {
              formatArray.forEach((element, index) => {
                if (index != 0) {
                  formatOffset = formatOffset + 4
                }
                doc.text(element, columnTwo + 2, formatOffset);
              });
            } else {
              formatArray.forEach((element, index) => {
                if (index != 0) {
                  formatOffset = formatOffset + 4
                }
                doc.text(element, columnTwo + 2, formatOffset - 3);
              });
            }


          } else {

            formatOffset = lastHeight

            if (formatArray.length == 1) {

              formatArray.forEach((element, index) => {
                if (index != 0) {
                  formatOffset = formatOffset
                }
                doc.text(element, columnTwo + 2, formatOffset);
              });
            } else {
              formatArray.forEach((element, index) => {
                if (index != 0) {
                  formatOffset = formatOffset + 4
                }
                doc.text(element, columnTwo + 2, formatOffset - 3);
              });
            }

          }
          doc.setFontSize(7)


          rows = rows + (totalHeight / 10)



          ////////////////////////////////////////////ALLERGEN/////////////////////////////////////////////////////////

          let allergenPosition
          let allergenDimention = 7
          let allergenSingleLineOffset


          if (totalHeight >= 14) {
            if (comida.allergens.length > 6) {
              allergenSingleLineOffset = heightOffset - 10
            } else {

              allergenSingleLineOffset = heightOffset - 6
            }

          } else {
            // if (comida.allergens.length >= 5) {
            //   allergenSingleLineOffset = lastHeight 
            // } 

            allergenSingleLineOffset = lastHeight - 6
          }


          comida.allergens.forEach((element, index) => {

            if (index == 0) {
              allergenPosition = columnThree + 2
            } else {
              allergenPosition = allergenPosition + allergenDimention + 2
            }
            if (index == 6) {
              allergenPosition = columnThree + 2
              allergenSingleLineOffset = allergenSingleLineOffset + allergenDimention + 1
            }

            let offset = allergenSingleLineOffset

            switch (element) {
              case 1:

                doc.addImage(this.pdfFonts.icons.icon1, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              case 2:

                doc.addImage(this.pdfFonts.icons.icon2, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              case 3:

                doc.addImage(this.pdfFonts.icons.icon3, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              case 4:

                doc.addImage(this.pdfFonts.icons.icon4, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              case 5:

                doc.addImage(this.pdfFonts.icons.icon5, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              case 6:

                doc.addImage(this.pdfFonts.icons.icon6, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              case 7:

                doc.addImage(this.pdfFonts.icons.icon7, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              case 8:

                doc.addImage(this.pdfFonts.icons.icon8, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              case 9:

                doc.addImage(this.pdfFonts.icons.icon9, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              case 10:

                doc.addImage(this.pdfFonts.icons.icon10, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              case 11:

                doc.addImage(this.pdfFonts.icons.icon11, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              case 12:

                doc.addImage(this.pdfFonts.icons.icon12, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              case 13:

                doc.addImage(this.pdfFonts.icons.icon13, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              case 14:

                doc.addImage(this.pdfFonts.icons.icon14, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              case 15:

                doc.addImage(this.pdfFonts.icons.icon15, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              case 16:

                doc.addImage(this.pdfFonts.icons.icon16, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              case 17:

                doc.addImage(this.pdfFonts.icons.icon17, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                break;
              default:
                break;
            }

          });




          doc.setFont('Monserrat', 'Bold');
          doc.setFontSize(10)
          if (comida.price == '' || comida.price == null) {

          } else {
            let price = comida.price.toString() + '€'
            if (totalHeight >= 14) {
              doc.text(price, columnFour + 2, heightOffset);
            } else {
              doc.text(price, columnFour + 2, lastHeight);
            }

          }
          lastHeight = lastHeight + 3
          doc.line(leftMargin, lastHeight, rightMargin, lastHeight)
        })

      })

    }

    displayCarta()

    ///////////////////////////////////////MENU//////////////////////////////////////////////


    if (menus.length >= 1) {



      let displayMenu = () => {

        let columnOneSpace = 195
        let columnTwoSpace = 50
        let columnThreeSpace = 60

        let leftMargin
        let rightMargin
        let columnOne = leftMargin
        let columnTwo = columnOne + 195
        let columnThree = columnTwo + 50
        let columnFour = columnThree + 60
        let rows = 0
        let footerHeight




        let newPage = () => {
          lastHeight = 50

          doc.addPage([592, 842], 'p')

          if (this.isOdd(doc.internal.getCurrentPageInfo().pageNumber)) {
            leftMargin = 80
            rightMargin = (doc.internal.pageSize.width) - 30
            footerHeight = (doc.internal.pageSize.height) - 30

            columnOne = leftMargin
            columnTwo = columnOne + columnOneSpace
            columnThree = columnTwo + columnTwoSpace
            columnFour = columnThree + columnThreeSpace

            doc.setFontSize(7);
            doc.setTextColor(255, 72, 103);
            doc.text('DVINUM.ES', leftMargin, footerHeight)


            stringWidth = doc.getStringUnitWidth("IVA INCLUIDO") * doc.internal.getFontSize() * 0.75
            doc.text("IVA INCLUIDO", rightMargin - stringWidth, footerHeight)
            if (foodMsg) {

              stringWidth = doc.getStringUnitWidth(foodMsg) * doc.internal.getFontSize() * 0.75
              doc.text(foodMsg, rightMargin - stringWidth, footerHeight - 10)
            }

            doc.setFontSize(12);
            doc.setTextColor(255, 72, 103);
            doc.text(this.restauranteToEdit.nombre.toUpperCase(), leftMargin, lastHeight);

            doc.setFontSize(21)
            accentTextColor
            let darkTextColor = doc.setTextColor(56, 56, 56);
            stringWidth = doc.getStringUnitWidth('MENÚS') * doc.internal.getFontSize() * 0.75
            doc.text('MENÚS', rightMargin - stringWidth, lastHeight)

            lastHeight = lastHeight + 5

            doc.line(leftMargin, lastHeight, rightMargin, lastHeight)


          } else {

            leftMargin = 30
            rightMargin = (doc.internal.pageSize.width) - 80
            footerHeight = (doc.internal.pageSize.height) - 30


            columnOne = leftMargin
            columnTwo = columnOne + columnOneSpace
            columnThree = columnTwo + columnTwoSpace
            columnFour = columnThree + columnThreeSpace

            doc.setFontSize(7);
            doc.setTextColor(255, 72, 103);
            doc.text('DVINUM.ES', leftMargin, footerHeight)


            stringWidth = doc.getStringUnitWidth("IVA INCLUIDO") * doc.internal.getFontSize() * 0.75
            doc.text("IVA INCLUIDO", rightMargin - stringWidth, footerHeight)
            if (foodMsg) {

              stringWidth = doc.getStringUnitWidth(foodMsg) * doc.internal.getFontSize() * 0.75
              doc.text(foodMsg, rightMargin - stringWidth, footerHeight - 10)
            }

            doc.setFontSize(21)
            let darkTextColor = doc.setTextColor(56, 56, 56);

            doc.text('MENÚS', leftMargin, lastHeight);

            doc.setFontSize(12);
            accentTextColor
            doc.setTextColor(255, 72, 103);
            stringWidth = doc.getStringUnitWidth(this.restauranteToEdit.nombre.toUpperCase()) * doc.internal.getFontSize() * 0.75

            doc.text(this.restauranteToEdit.nombre.toUpperCase(), rightMargin - stringWidth, lastHeight)

            lastHeight = lastHeight + 5

            doc.line(leftMargin, lastHeight, rightMargin, lastHeight)

          }
          doc.setTextColor(255, 72, 103);
          doc.setFontSize(7)
          lastHeight = lastHeight + 15
          doc.text("REFERENCIA", columnOne, lastHeight)
          doc.text("ALERGENOS", columnTwo, lastHeight)
          // doc.text("ALERGENOS", columnThree, lastHeight)
          doc.text("PVP", columnFour, lastHeight)


        }
        newPage()



        menus.forEach((menu) => {


          if (rows > 40) {
            rows = 1

            newPage()

            lastHeight = lastHeight + 12

            /////////////////////////////////////////////////////////////////////////////////////////////////////

          } else {

            rows = rows + 4

          }


          doc.setFontSize(18)
          doc.setTextColor(56, 56, 56);
          doc.setFont('DrukTextWide', 'Medium');
          lastHeight = lastHeight + 20
          doc.text(menu.name, columnOne, lastHeight);
          lastHeight = lastHeight + 10


          let categoryLoop = (category: string) => {



            if (rows > 45) {
              rows = 1

              newPage()

              lastHeight = lastHeight + 12

              /////////////////////////////////////////////////////////////////////////////////////////////////////

            } else {
              rows = rows + 1
            }

            if (menu.optionsA[category]) {

              let darkTextColor = doc.setTextColor(56, 56, 56);
              doc.setFontSize(9)
              let totalHeight = 8
              let startHeight = lastHeight
              lastHeight = lastHeight + 10
              doc.setFont('Monserrat', 'SemiBold');
              doc.text(category.toUpperCase(), columnOne + 2, lastHeight);

              menu.optionsA[category].forEach((comida) => {
                doc.setFont('Monserrat', 'Regular');
                doc.setFontSize(9)

                rows = rows + 1

                lastHeight = lastHeight + 9


                let capitalize = (string: string) => {

                  return string.charAt(0).toUpperCase() + string.slice(1);
                }

                let nameArray = doc.splitTextToSize(capitalize(comida.name.toLowerCase()), columnOneSpace);



                nameArray.forEach((element, index) => {
                  if (index != 0) {

                    lastHeight = lastHeight + 7
                  }


                  doc.text(element, columnOne + 2, lastHeight)



                });
                // let heightOffset

                // if (totalHeight >= 14) {
                //   heightOffset = startHeight + (totalHeight / 2) + 4
                // }



                //doc.text(comida.name.toLowerCase(), columnOne + 2, lastHeight, { maxWidth: columnOneSpace });

                let allergenPosition
                let allergenSingleLineOffset
                let allergenDimention = 7

                if (nameArray.length > 1) {
                  allergenSingleLineOffset = lastHeight - (nameArray.length * 4.5)
                } else {

                  allergenSingleLineOffset = lastHeight - 6
                }

                comida.allergens.forEach((element, index) => {

                  if (index == 0) {
                    allergenPosition = columnTwo + 2
                  } else {
                    allergenPosition = allergenPosition + allergenDimention + 2
                  }

                  let offset = allergenSingleLineOffset

                  switch (element) {
                    case 1:

                      doc.addImage(this.pdfFonts.icons.icon1, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    case 2:

                      doc.addImage(this.pdfFonts.icons.icon2, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    case 3:

                      doc.addImage(this.pdfFonts.icons.icon3, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    case 4:

                      doc.addImage(this.pdfFonts.icons.icon4, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    case 5:

                      doc.addImage(this.pdfFonts.icons.icon5, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    case 6:

                      doc.addImage(this.pdfFonts.icons.icon6, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    case 7:

                      doc.addImage(this.pdfFonts.icons.icon7, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    case 8:

                      doc.addImage(this.pdfFonts.icons.icon8, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    case 9:

                      doc.addImage(this.pdfFonts.icons.icon9, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    case 10:

                      doc.addImage(this.pdfFonts.icons.icon10, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    case 11:

                      doc.addImage(this.pdfFonts.icons.icon11, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    case 12:

                      doc.addImage(this.pdfFonts.icons.icon12, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    case 13:

                      doc.addImage(this.pdfFonts.icons.icon13, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    case 14:

                      doc.addImage(this.pdfFonts.icons.icon14, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    case 15:

                      doc.addImage(this.pdfFonts.icons.icon15, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    case 16:

                      doc.addImage(this.pdfFonts.icons.icon16, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    case 17:

                      doc.addImage(this.pdfFonts.icons.icon17, 'png', allergenPosition, offset, allergenDimention, allergenDimention)

                      break;
                    default:
                      break;
                  }

                });

              })
            }

          }
          categoryLoop("Entrantes")
          categoryLoop("Plato principal")
          categoryLoop("Postre")

          lastHeight = lastHeight + 3

          doc.line(leftMargin, lastHeight, rightMargin, lastHeight)


          lastHeight = lastHeight + 8

          if (menu.comment != '') {

            doc.setFont('Monserrat', 'Bold');
            doc.setFontSize(9)
            doc.text("INCLUIDO", columnOne + 2, lastHeight);
            doc.setFont('Monserrat', 'Regular');
            doc.setFontSize(9)
            doc.text(menu.comment, columnOne + 40, lastHeight);
          }


          doc.setFont('Monserrat', 'Bold');
          doc.setFontSize(10)
          if (menu.price == '' || menu.price == null) {

          } else {
            let price = menu.price.toString() + '€'
            doc.text(price, columnFour + 2, lastHeight);

          }




        })

      }

      displayMenu()


    }

    /////////////////////////////////////////VINOS///////////////////////////////////////////////////




    doc.addPage([592, 842], 'p')


    doc.setFillColor(40, 40, 40);
    doc.rect(0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, 'F')

    text = "CARTA DE VINOS"
    stringWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() * 0.75,
      textOffset = ((doc.internal.pageSize.width) - stringWidth) / 2;

    this.pdfFonts.AddFonts(doc)


    lightTextColor = doc.setTextColor(234, 234, 234);
    doc.setFont('DrukTextWide', 'Medium');
    doc.setFontSize(17)
    lastHeight = ((doc.internal.pageSize.height) - 10) / 2
    doc.text(text, textOffset - 39, lastHeight)

    accentTextColor = doc.setTextColor(255, 72, 103);
    doc.setFont('Monserrat', 'Bold');
    doc.setFontSize(12)
    stringWidth = doc.getStringUnitWidth(this.restauranteToEdit.nombre) * doc.internal.getFontSize() * 0.75,
      textOffset = ((doc.internal.pageSize.width) - stringWidth) / 2;

    doc.text(this.restauranteToEdit.nombre.toUpperCase(), textOffset - 2, lastHeight + 10)

    lastHeight = doc.internal.pageSize.height - 60
    doc.addImage(this.pdfFonts.logo, 'pdf', ((doc.internal.pageSize.width) - 20) / 2, lastHeight, 20, 20)

    text = 'dvinum.es'

    lightTextColor
    doc.setTextColor(234, 234, 234);
    doc.setFontSize(7)
    stringWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() * 0.75,
      textOffset = ((doc.internal.pageSize.width) - stringWidth - 2) / 2;
    lastHeight = lastHeight + 30
    doc.text(text.toUpperCase(), textOffset, lastHeight)

    let specials = []

    this.winesArray.forEach((tipo, index) => {
      let leftMargin
      let rightMargin
      let columnOne
      let columnTwo
      let columnThree
      let columnFour
      let rows = 0
      let footerHeight
      lastHeight = 50

      doc.addPage([592, 842], 'p')


      if (this.isOdd(doc.internal.getCurrentPageInfo().pageNumber)) {
        leftMargin = 80
        rightMargin = (doc.internal.pageSize.width) - 30
        footerHeight = (doc.internal.pageSize.height) - 30

        columnOne = leftMargin
        columnTwo = columnOne + 165
        columnThree = columnTwo + 50
        columnFour = columnThree + 90

        doc.setFontSize(7);
        doc.setTextColor(255, 72, 103);
        doc.text('DVINUM.ES', leftMargin, footerHeight)

        stringWidth = doc.getStringUnitWidth("IVA INCLUIDO") * doc.internal.getFontSize() * 0.75
        doc.text("IVA INCLUIDO", rightMargin - stringWidth, footerHeight)
        if (foodMsg) {

          stringWidth = doc.getStringUnitWidth(foodMsg) * doc.internal.getFontSize() * 0.75
          doc.text(foodMsg, rightMargin - stringWidth, footerHeight - 10)
        }

        doc.setFontSize(12);
        doc.setTextColor(255, 72, 103);
        doc.text(this.restauranteToEdit.nombre.toUpperCase(), leftMargin, lastHeight);

        doc.setFontSize(21)
        accentTextColor
        let darkTextColor = doc.setTextColor(56, 56, 56);
        stringWidth = doc.getStringUnitWidth(tipo.nombre.toUpperCase()) * doc.internal.getFontSize() * 0.75
        doc.text(tipo.nombre.toUpperCase(), rightMargin - stringWidth, lastHeight)

        lastHeight = lastHeight + 5

        doc.line(leftMargin, lastHeight, rightMargin, lastHeight)

      } else {

        leftMargin = 30
        rightMargin = (doc.internal.pageSize.width) - 80
        footerHeight = (doc.internal.pageSize.height) - 30

        columnOne = leftMargin
        columnTwo = columnOne + 165
        columnThree = columnTwo + 50
        columnFour = columnThree + 90

        doc.setFontSize(7);
        doc.setTextColor(255, 72, 103);
        doc.text('DVINUM.ES', leftMargin, footerHeight)

        stringWidth = doc.getStringUnitWidth("IVA INCLUIDO") * doc.internal.getFontSize() * 0.75
        doc.text("IVA INCLUIDO", rightMargin - stringWidth, footerHeight)
        if (foodMsg) {

          stringWidth = doc.getStringUnitWidth(foodMsg) * doc.internal.getFontSize() * 0.75
          doc.text(foodMsg, rightMargin - stringWidth, footerHeight - 10)
        }
        doc.setFontSize(21)
        let darkTextColor = doc.setTextColor(56, 56, 56);

        doc.text(tipo.nombre.toUpperCase(), leftMargin, lastHeight);

        doc.setFontSize(12);
        accentTextColor
        doc.setTextColor(255, 72, 103);
        stringWidth = doc.getStringUnitWidth(this.restauranteToEdit.nombre.toUpperCase()) * doc.internal.getFontSize() * 0.75
        doc.text(this.restauranteToEdit.nombre.toUpperCase(), rightMargin - stringWidth, lastHeight)

        lastHeight = lastHeight + 5

        doc.line(leftMargin, lastHeight, rightMargin, lastHeight)

      }

      doc.setTextColor(255, 72, 103);
      doc.setFontSize(7)
      lastHeight = lastHeight + 15
      doc.text("REFERENCIA", columnOne, lastHeight)
      doc.text("FORMATO", columnTwo, lastHeight)
      doc.text("VARIEDADES", columnThree, lastHeight)
      doc.text("PVP", columnFour, lastHeight)

      tipo.dos.forEach((denominacion) => {


        let vinosSorted
        let wines = denominacion.vinos


        let addPage = () => {

          doc.addPage([592, 842], 'p')

          //////////////////////////////////////////////////////////////////////////////////////////////////// 11 al nombre del vino

          if (this.isOdd(doc.internal.getCurrentPageInfo().pageNumber)) {
            lastHeight = 50
            leftMargin = 80
            rightMargin = (doc.internal.pageSize.width) - 30
            footerHeight = (doc.internal.pageSize.height) - 30

            columnOne = leftMargin
            columnTwo = columnOne + 165
            columnThree = columnTwo + 50
            columnFour = columnThree + 90

            doc.setFontSize(7);
            doc.setTextColor(255, 72, 103);
            doc.text('DVINUM.ES', leftMargin, footerHeight)

            stringWidth = doc.getStringUnitWidth("IVA INCLUIDO") * doc.internal.getFontSize() * 0.75
            doc.text("IVA INCLUIDO", rightMargin - stringWidth, footerHeight)
            if (foodMsg) {

              stringWidth = doc.getStringUnitWidth(foodMsg) * doc.internal.getFontSize() * 0.75
              doc.text(foodMsg, rightMargin - stringWidth, footerHeight - 10)
            }

            doc.setFontSize(12);
            doc.setTextColor(255, 72, 103);
            doc.text(this.restauranteToEdit.nombre.toUpperCase(), leftMargin, lastHeight);

            doc.setFontSize(21)
            accentTextColor
            let darkTextColor = doc.setTextColor(56, 56, 56);
            stringWidth = doc.getStringUnitWidth(tipo.nombre.toUpperCase()) * doc.internal.getFontSize() * 0.75
            doc.text(tipo.nombre.toUpperCase(), rightMargin - stringWidth, lastHeight)

            lastHeight = lastHeight + 5

            doc.line(leftMargin, lastHeight, rightMargin, lastHeight)

          } else {
            lastHeight = 50

            leftMargin = 30
            rightMargin = (doc.internal.pageSize.width) - 80
            footerHeight = (doc.internal.pageSize.height) - 30

            columnOne = leftMargin
            columnTwo = columnOne + 165
            columnThree = columnTwo + 50
            columnFour = columnThree + 90

            doc.setFontSize(7);
            doc.setTextColor(255, 72, 103);
            doc.text('DVINUM.ES', leftMargin, footerHeight)

            stringWidth = doc.getStringUnitWidth("IVA INCLUIDO") * doc.internal.getFontSize() * 0.75
            doc.text("IVA INCLUIDO", rightMargin - stringWidth, footerHeight)
            if (foodMsg) {

              stringWidth = doc.getStringUnitWidth(foodMsg) * doc.internal.getFontSize() * 0.75
              doc.text(foodMsg, rightMargin - stringWidth, footerHeight - 10)
            }


            doc.setFontSize(21)
            let darkTextColor = doc.setTextColor(56, 56, 56);

            doc.text(tipo.nombre.toUpperCase(), leftMargin, lastHeight);

            doc.setFontSize(12);
            accentTextColor
            doc.setTextColor(255, 72, 103);
            stringWidth = doc.getStringUnitWidth(this.restauranteToEdit.nombre.toUpperCase()) * doc.internal.getFontSize() * 0.75
            doc.text(this.restauranteToEdit.nombre.toUpperCase(), rightMargin - stringWidth, lastHeight)

            lastHeight = lastHeight + 5

            doc.line(leftMargin, lastHeight, rightMargin, lastHeight)

          }

          doc.setTextColor(255, 72, 103);
          doc.setFontSize(7)
          lastHeight = lastHeight + 15
          doc.text("REFERENCIA", columnOne, lastHeight)
          doc.text("FORMATO", columnTwo, lastHeight)
          doc.text("VARIEDADES", columnThree, lastHeight)
          doc.text("PVP", columnFour, lastHeight)


          lastHeight = lastHeight + 12

        }
        let checkEmptyDO = wines.every((element, index) => {
          return element.volumen != '750 ml'
        })

        if (!checkEmptyDO) {

          if (rows > 40) {

            rows = 4
            addPage()

          } else {

            rows = rows + 4

          }


          doc.setFontSize(18)
          doc.setTextColor(56, 56, 56);
          doc.setFont('DrukTextWide', 'Medium');
          lastHeight = lastHeight + 20
          doc.text(denominacion.nombre, columnOne, lastHeight);
          lastHeight = lastHeight + 10

        }
        wines.forEach((vino) => {

          if (vino.volumen == '750 ml') {



            if (rows > 45) {

              rows = 1

              addPage()

              /////////////////////////////////////////////////////////////////////////////////////////////////////

            } else {

              rows = rows + 1

            }

            let darkTextColor = doc.setTextColor(56, 56, 56);
            doc.setFontSize(9)
            lastHeight = lastHeight + 8
            doc.setFont('Monserrat', 'SemiBold');
            doc.text(vino.name.toUpperCase(), columnOne + 2, lastHeight);
            doc.setFont('Monserrat', 'Regular');
            doc.setFontSize(7)
            doc.text(vino.volumen, columnTwo + 2, lastHeight);
            doc.setFontSize(7)

            if (typeof (vino.uva) == 'string') {
              doc.text(vino.uva.toUpperCase(), columnThree + 2, lastHeight);
            } else {
              let array2string = ''
              vino.uva.forEach((element, i) => {

                if (i < 4) {

                  let res = element.slice(0, 5);

                  if (i == 0) {
                    array2string = res
                  } else {
                    array2string = array2string + ', ' + res
                  }
                }

              });
              doc.text(array2string.toUpperCase(), columnThree + 2, lastHeight);
            }

            doc.setFont('Monserrat', 'Bold');
            doc.setFontSize(10)
            doc.text(vino.price.toString(), columnFour + 2, lastHeight);


            lastHeight = lastHeight + 3
            doc.line(leftMargin, lastHeight, rightMargin, lastHeight)

          } else {

            specials.push(vino)
          }

        });




      })

    })

    let leftMargin
    let rightMargin
    if (this.isOdd(doc.internal.getCurrentPageInfo().pageNumber)) {
      leftMargin = 80
      rightMargin = (doc.internal.pageSize.width) - 30
    } else {
      leftMargin = 30
      rightMargin = (doc.internal.pageSize.width) - 80
    }

    let columnOne = leftMargin
    let columnTwo = columnOne + 165
    let columnThree = columnTwo + 50
    let columnFour = columnThree + 90


    if (specials.length >= 1) {

      doc.setFontSize(18)
      doc.setTextColor(56, 56, 56);
      doc.setFont('DrukTextWide', 'Medium');
      lastHeight = lastHeight + 20
      doc.text('Formatos Especiales', columnOne, lastHeight);
      lastHeight = lastHeight + 10
    }

    specials.forEach(vino => {


      let darkTextColor = doc.setTextColor(56, 56, 56);
      doc.setFontSize(9)
      lastHeight = lastHeight + 8
      doc.setFont('Monserrat', 'SemiBold');
      doc.text(vino.name.toUpperCase(), columnOne + 2, lastHeight);
      doc.setFont('Monserrat', 'Regular');
      doc.setFontSize(7)
      doc.text(vino.volumen, columnTwo + 2, lastHeight);
      doc.setFontSize(7)

      if (typeof (vino.uva) == 'string') {
        doc.text(vino.uva.toUpperCase(), columnThree + 2, lastHeight);
      } else {
        let array2string = ''
        vino.uva.forEach((element, i) => {

          if (i < 4) {

            let res = element.slice(0, 5);

            if (i == 0) {
              array2string = res
            } else {
              array2string = array2string + ', ' + res
            }
          }

        });
        doc.text(array2string.toUpperCase(), columnThree + 2, lastHeight);
      }

      doc.setFont('Monserrat', 'Bold');
      doc.setFontSize(10)
      doc.text(vino.price.toString(), columnFour + 2, lastHeight);


      lastHeight = lastHeight + 3
      doc.line(leftMargin, lastHeight, rightMargin, lastHeight)

    })


    doc.addPage([592, 842], 'p')

    doc.addImage(this.pdfFonts.lastPage, 'jpg', -2, 0, doc.internal.pageSize.width + 2, doc.internal.pageSize.height)
    doc.addImage(qr, 'png', 246, 374)

    doc.save(`Restaurant ${this.restauranteToEdit.nombre}.pdf`, { returnPromise: true }).then(() => this.generating = false).catch(() => this.generating = false)

  }

  isOdd(num) { return num % 2; }

}
