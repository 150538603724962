<app-page-title titleDescription='' titleHeading='Orden de los vinos'></app-page-title>



<div class="card card-box mx-4 mb-5 border-0">

  <div class="container">
    <div class="just-cont-center row py-4">
        <div class="col-md-1" >
            <div class=" text-center card card-box bg-secondary px-1 py-3 my-3 font-weight-bold" *ngFor="let vino of vinos; index as i">{{i+1}}</div>
        </div>
        <div class="col-md-10 px-0" dragula="WINES" [(dragulaModel)]="vinos">
            <div class="card card-box bg-secondary px-4 py-3 my-3 font-weight-bold" *ngFor="let vino of vinos; index as i">{{vino.nombre}}</div>
        </div>

    </div>
</div>

  <!-- <div class="table-responsive">
    <table class="table table-hover text-nowrap mb-0 table-borderless">
      <thead>
        <tr>
          <th class="text-center bg-primary text-light">#</th>
          <th class="text-center bg-primary text-light">Nombre</th>
        </tr>
      </thead>
      <tbody>
        <tr [routerLink]="['/editar-vino', vino.id]"  *ngFor="let vino of vinos ; index as i"> 
          <td class="text-center">
            {{i+1}} 
          </td>
          <td>
            <div class="d-flex align-items-center ">
              <div class="avatar-icon-wrapper mr-2">
                <div [ngStyle]="{'background-image': 'url(' + vino.img + ')'}" class="avatar-icon itemImg" ></div>
              </div>
              <div>
                <a class="font-weight-bold text-black" title="...">
                  {{vino.nombre}}
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div> -->

</div>
