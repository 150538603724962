<div class="div card card-box">
  <div class="card-header d-flex justify-content-between pr-5">
    <h4 class="font-size-md mb-0 py-2 font-weight-bold">
      {{cardTitle}}
    </h4>
    <div *ngIf="key === 'restaurantes'" class="d-flex align-items-center">
      <div class="d-flex align-items-center justify-content-end">
          <div class="btn-group m-2 ml-3 dropdown-index" dropdown>
              <button dropdownToggle type="button" class="btn btn-primary btn-sm dropdown-toggle text-light">
                  Filtrar
                  <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu" role="menu">
                  <li role="menuitem"><a class="dropdown-item" (click)="toggleRestView('visitas')">Visitas</a></li>
                  <li role="menuitem"><a class="dropdown-item" (click)="toggleRestView('lastuse')">Ultimo uso</a></li>
              </ul>   
          </div>
          <b *ngIf="restFilterStatus !== ''">{{restFilterStatus}}</b>
      </div>
  </div>
  </div>
  <div class="card-body card-spacing item-container card--height">
    <ng-content></ng-content>
  </div>
</div>