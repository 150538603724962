import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirestoreProviderService } from 'src/app/shared/firestore-provider.service';

@Component({
  selector: 'app-comerciales',
  templateUrl: './comerciales.component.html',
  styleUrls: ['./comerciales.component.scss']
})
export class ComercialesComponent implements OnInit {
  isCollapsed = true;
  rotate = true;

  page: number = 1;
  comerciales: any;
  query: any;

  constructor(
    private _firestore: AngularFirestore,
    private _firestoreProvider: FirestoreProviderService
    ) { }

  ngOnInit() {
    this.getComerciales()
  }

  buscador(event){
    this.comerciales = this.query

    const searchKeyword = event.target.value
      .toLowerCase()
      .replace(/á/, "a")
      .replace(/é/, "e")
      .replace(/í/, "i")
      .replace(/ó/, "o")
      .replace(/ú/, "u");

    let busqueda = this.comerciales.filter((restaurante) => {
      return restaurante.nombre.toLowerCase()
        .replace(/á/, 'a')
        .replace(/é/, 'e')
        .replace(/í/, 'i')
        .replace(/ó/, 'o')
        .replace(/ú/, 'u')
        .includes(searchKeyword)
    })
    this.comerciales = busqueda
  }
  getComerciales() {
    this._firestoreProvider.getCollection('comerciales').snapshotChanges().subscribe((doc) => {
      this.query = []
      doc.forEach((doc, i) => {
        this.query.push(doc.payload.doc.data())   
      });
      this.comerciales = this.query
    })
  }
}
