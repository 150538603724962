<app-page-title
  titleDescription=""
  titleHeading="Añadir Vinos"
></app-page-title>

<div class="mx-4">
  <div class="card card-box mb-5 border-0">
    <form
      class=" s12 m6"
      [formGroup]="nuevoVino"
      (submit)="onSubmit(nuevoVino.value)"
    >
      <div class="card-header border-0">
        <div class="card-header--title">
          <h5 class="mb-0 py-2 font-weight-bold">
            Nuevo vino
          </h5>
        </div>
        <div class="card-header--actions">
          <button
            *ngIf="loading"
            class="btn btn-sm m-2 btn-success"
            disabled
            type="button"
          >
            <span
              aria-hidden="true"
              class="spinner-border spinner-border-sm "
              role="status"
            ></span>
            <span class="ml-2">PROCESANDO...</span>
          </button>
          <button *ngIf="!loading" class="btn btn-sm btn-success" type="submit">
            <span class="btn-wrapper--icon">
              <fa-icon [icon]="['fas', 'plus']"></fa-icon>
            </span>
            <span class="btn-wrapper--label ">GUARDAR VINO</span>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-4">
            <div class="position-relative form-group">
              <div
                class="d-flex justify-content-between align-items-center minTitle-h"
              >
                <label>
                  Nombre
                  <span *ngIf="!isSubmitted"> *</span>
                  <span
                    class="text-danger"
                    *ngIf="isSubmitted && formControls.nombre.errors?.required"
                  >
                    *</span
                  >
                </label>
              </div>
              <input
                class="form-control"
                formControlName="nombre"
                placeholder="Introduce el nombre"
                type="text"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="position-relative form-group">
              <div
                class="d-flex justify-content-between align-items-center minTitle-h"
              >
                <label class="mb-0">
                  Bodega

                  <span *ngIf="!isSubmitted"> *</span>
                  <span
                    class="text-danger"
                    *ngIf="isSubmitted && formControls.bodega.errors?.required"
                  >
                    *</span
                  >
                </label>
                <button
                  (click)="openModal(bodegaModal)"
                  class="btn btn-sm m-2 btn-primary text-light"
                  type="button"
                >
                  <span class="btn-wrapper--icon">
                    <fa-icon [icon]="['fas', 'cog']"></fa-icon>
                  </span>
                </button>

                <ng-template #bodegaModal>
                  <div class="text-center p-5">
                    <div class="avatar-icon-wrapper rounded-circle m-0">
                      <div
                        class="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-primary m-0 d-130"
                      >
                        <fa-icon
                          [icon]="['fas', 'cog']"
                          class="d-flex align-self-center display-3"
                        ></fa-icon>
                      </div>
                    </div>
                    <h4 class="font-weight-bold mt-4">Que quieres hacer?</h4>
                    <p class="mb-0 font-size-lg text-muted">
                      Selecciona tu opción
                    </p>
                    <div class="pt-4">
                      <button
                        (click)="deleteBodega()"
                        class="btn btn-pill btn-danger mx-1"
                        type="button"
                      >
                        <span class="btn-wrapper--label">
                          Borrar
                        </span>
                      </button>
                      <button
                        (click)="editBodega()"
                        class="btn btn-pill btn-info mx-1"
                        type="button"
                      >
                        <span class="btn-wrapper--label">
                          Editar
                        </span>
                      </button>
                      <button
                        (click)="addBodega()"
                        class="btn btn-pill btn-success mx-1"
                        type="button"
                      >
                        <span class="btn-wrapper--label">
                          Añadir
                        </span>
                      </button>
                    </div>

                    <div *ngIf="showAddBodega" class="row mt-4">
                      <form
                        class="col"
                        [formGroup]="nuevaBodega"
                        (submit)="onBodegaSubmit(nuevaBodega.value)"
                      >
                        <div
                          class="position-relative row form-group  d-flex justify-content-center"
                        >
                          <div class="col-sm-9">
                            <input
                              class="form-control"
                              formControlName="nombre"
                              placeholder="Introduce nombre de la bodega"
                              type="text"
                            />
                          </div>
                          <button
                            type="submit"
                            class=" btn btn-sm btn-pill btn-success mx-1 d-inline-block"
                          >
                            <span class="btn-wrapper--label"> Añadir </span>
                          </button>
                        </div>
                      </form>
                    </div>

                    <div *ngIf="showEditBodega" class="row mt-4">
                      <div class="table-responsive">
                        <table
                          class="table table-hover table-striped table-bordered mb-5"
                        >
                          <thead class="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col" colspan="2">Bodega</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="let bodega of bodegas | async; index as i"
                            >
                              <th scope="row">{{ i + 1 }}</th>
                              <td
                                colspan="2"
                                class="d-flex justify-content-between align-items-center"
                              >
                                <span class="">{{ bodega.name }}</span>
                                <span
                                  (click)="
                                    openEditModal(
                                      editBodegaModal,
                                      bodega.name,
                                      bodega.id
                                    )
                                  "
                                  class="text-info pointer"
                                >
                                  <i-feather name="settings"></i-feather>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div *ngIf="showDeleteBodega" class="row mt-4">
                      <div class="table-responsive">
                        <table
                          class="table table-hover table-striped table-bordered mb-5"
                        >
                          <thead class="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col" colspan="2">Bodega</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="let bodega of bodegas | async; index as i"
                            >
                              <th scope="row">{{ i + 1 }}</th>
                              <td
                                colspan="2"
                                class="d-flex justify-content-between align-items-center"
                              >
                                <span class="">{{ bodega.name }}</span>
                                <span
                                  (click)="onBodegaDelete(bodega.id)"
                                  class="text-danger pointer"
                                >
                                  <fa-icon
                                    [icon]="['far', 'trash-alt']"
                                    class=""
                                  ></fa-icon>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <ng-template #editBodegaModal>
                  <div class="text-center p-5">
                    <h4 class="font-weight-bold mb-4">
                      Edita la Bodega {{ itemToEdit }}
                    </h4>
                    <div class="d-flex justify-content-center">
                      <form class="form-inline">
                        <div
                          class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group"
                        >
                          <input
                            class="form-control"
                            id="bodegaToEdit"
                            value="{{ itemToEdit }}"
                          />
                        </div>

                        <button
                          (click)="queryWinesByBodega(itemToEdit)"
                          class="btn btn-primary text-light"
                        >
                          Enviar
                        </button>
                      </form>
                    </div>
                  </div>
                </ng-template>
              </div>
              <select
                #bodega
                formControlName="bodega"
                class="form-control"
                id="bodega"
                name="select"
              >
                <option disabled selected value="">Selecciona</option>
                <option *ngFor="let bodega of bodegas | async; index as i">{{
                  bodega.name
                }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="position-relative form-group">
              <div
                class="d-flex justify-content-between align-items-center minTitle-h"
              >
                <label class="mb-0" for="DO"
                  >D.O.

                  <span *ngIf="!isSubmitted"> *</span>
                  <span
                    class="text-danger"
                    *ngIf="isSubmitted && formControls.do.errors?.required"
                  >
                    *</span
                  >
                </label>
                <button
                  (click)="openModal(doModal)"
                  class="btn btn-sm m-2 btn-primary text-light"
                  type="button"
                >
                  <span class="btn-wrapper--icon">
                    <fa-icon [icon]="['fas', 'cog']"></fa-icon>
                  </span>
                </button>

                <ng-template #doModal>
                  <div class="text-center p-5">
                    <div class="avatar-icon-wrapper rounded-circle m-0">
                      <div
                        class="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-primary m-0 d-130"
                      >
                        <fa-icon
                          [icon]="['fas', 'cog']"
                          class="d-flex align-self-center display-3"
                        ></fa-icon>
                      </div>
                    </div>
                    <h4 class="font-weight-bold mt-4">Que quieres hacer?</h4>
                    <p class="mb-0 font-size-lg text-muted">
                      Selecciona tu opción
                    </p>
                    <div class="pt-4">
                      <button
                        (click)="deleteDo()"
                        class="btn btn-pill btn-danger mx-1"
                        type="button"
                      >
                        <span class="btn-wrapper--label">
                          Borrar D.O.
                        </span>
                      </button>
                      <button
                        (click)="editDo()"
                        class="btn btn-pill btn-info mx-1"
                        type="button"
                      >
                        <span class="btn-wrapper--label">
                          Editar D.O.
                        </span>
                      </button>
                      <button
                        (click)="addDo()"
                        class="btn btn-pill btn-success mx-1"
                        type="button"
                      >
                        <span class="btn-wrapper--label">
                          Añadir D.O.
                        </span>
                      </button>
                    </div>

                    <div *ngIf="showAddDo" class="row mt-4">
                      <form
                        class="col"
                        [formGroup]="nuevoDo"
                        (submit)="onDoSubmit(nuevoDo.value)"
                      >
                        <div
                          class="position-relative row form-group  d-flex justify-content-center"
                        >
                          <div class="col-sm-9">
                            <input
                              class="form-control"
                              formControlName="nombre"
                              placeholder="Introduce nombre del DO"
                              type="text"
                            />
                          </div>
                          <button
                            type="submit"
                            class=" btn btn-sm btn-pill btn-success mx-1 d-inline-block"
                          >
                            <span class="btn-wrapper--label"> Añadir </span>
                          </button>
                        </div>
                      </form>
                    </div>

                    <div *ngIf="showDeleteDo" class="row mt-4">
                      <div class="table-responsive">
                        <table
                          class="table table-hover table-striped table-bordered mb-5"
                        >
                          <thead class="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col" colspan="2">D.O.</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let DO of dos | async; index as i">
                              <th scope="row">{{ i + 1 }}</th>
                              <td
                                colspan="2"
                                class="d-flex justify-content-between align-items-center"
                              >
                                <span class="">{{ DO.name }}</span>
                                <span
                                  (click)="onDoDelete(DO.id)"
                                  class="text-danger pointer"
                                >
                                  <fa-icon
                                    [icon]="['far', 'trash-alt']"
                                  ></fa-icon>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div *ngIf="showEditDo" class="row mt-4">
                      <div class="table-responsive">
                        <table
                          class="table table-hover table-striped table-bordered mb-5"
                        >
                          <thead class="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col" colspan="2">D.O.</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let DO of dos | async; index as i">
                              <th scope="row">{{ i + 1 }}</th>
                              <td
                                colspan="2"
                                class="d-flex justify-content-between align-items-center"
                              >
                                <span class="">{{ DO.name }}</span>
                                <span
                                  (click)="
                                    openEditModal(editDoModal, DO.name, DO.id)
                                  "
                                  class="text-info pointer"
                                >
                                  <i-feather name="settings"></i-feather>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <ng-template #editDoModal>
                  <div class="text-center p-5">
                    <h4 class="font-weight-bold mb-4">
                      Edita el D.O. {{ itemToEdit }}
                    </h4>
                    <div class="d-flex justify-content-center">
                      <form class="form-inline">
                        <div
                          class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group"
                        >
                          <input
                            class="form-control"
                            id="doToEdit"
                            value="{{ itemToEdit }}"
                          />
                        </div>

                        <button
                          (click)="queryWinesByDO(itemToEdit)"
                          class="btn btn-primary text-light"
                        >
                          Enviar
                        </button>
                      </form>
                    </div>
                  </div>
                </ng-template>
              </div>
              <select
                formControlName="do"
                class="form-control"
                id="DO"
                name="DO"
              >
                <option disabled selected value="">Selecciona</option>
                <option *ngFor="let DO of dos | async; index as i">{{
                  DO.name
                }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-3">
            <div class="position-relative form-group">
              <div
                class="d-flex justify-content-between align-items-center minTitle-h"
              >
                <label for="contienente"
                  >Continente
                  <span *ngIf="!isSubmitted"> *</span>
                  <span
                    class="text-danger"
                    *ngIf="
                      isSubmitted && formControls.continente.errors?.required
                    "
                  >
                    *</span
                  ></label
                >
              </div>
              <select
                formControlName="continente"
                class="form-control"
                id="contienente"
                name="contienente"
              >
                <option disabled value="">Selecciona</option>
                <option>Europa</option>
                <option>America</option>
                <option>Sudamerica</option>
                <option>Asia</option>
                <option>África</option>
                <option>Oceanía</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <div
                class="d-flex justify-content-between align-items-center minTitle-h"
              >
                <label class="mb-0"
                  >País
                  <span *ngIf="!isSubmitted"> *</span>
                  <span
                    class="text-danger"
                    *ngIf="isSubmitted && formControls.pais.errors?.required"
                  >
                    *</span
                  >
                </label>
                <button
                  (click)="openModal(paisModal)"
                  class="btn btn-sm m-2 btn-primary text-light"
                  type="button"
                >
                  <span class="btn-wrapper--icon">
                    <fa-icon [icon]="['fas', 'cog']"></fa-icon>
                  </span>
                </button>

                <ng-template #paisModal>
                  <div class="text-center p-5">
                    <div class="avatar-icon-wrapper rounded-circle m-0">
                      <div
                        class="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-primary m-0 d-130"
                      >
                        <fa-icon
                          [icon]="['fas', 'cog']"
                          class="d-flex align-self-center display-3"
                        ></fa-icon>
                      </div>
                    </div>
                    <h4 class="font-weight-bold mt-4">Que quieres hacer?</h4>
                    <p class="mb-0 font-size-lg text-muted">
                      Selecciona tu opción
                    </p>
                    <div class="pt-4">
                      <button
                        (click)="deletePais()"
                        class="btn btn-pill btn-danger mx-1"
                        type="button"
                      >
                        <span class="btn-wrapper--label">
                          Borrar
                        </span>
                      </button>
                      <button
                        (click)="editPais()"
                        class="btn btn-pill btn-info mx-1"
                        type="button"
                      >
                        <span class="btn-wrapper--label">
                          Editar
                        </span>
                      </button>
                      <button
                        (click)="addPais()"
                        class="btn btn-pill btn-success mx-1"
                        type="button"
                      >
                        <span class="btn-wrapper--label">
                          Añadir
                        </span>
                      </button>
                    </div>

                    <div *ngIf="showAddPais" class="row mt-4">
                      <form
                        class="col"
                        [formGroup]="nuevoPais"
                        (submit)="onPaisSubmit(nuevoPais.value)"
                      >
                        <div
                          class="position-relative row form-group  d-flex justify-content-center"
                        >
                          <div class="col-sm-9">
                            <input
                              class="form-control"
                              formControlName="nombre"
                              placeholder="Introduce nombre del país"
                              type="text"
                            />
                          </div>
                          <button
                            type="submit"
                            class=" btn btn-sm btn-pill btn-success mx-1 d-inline-block"
                          >
                            <span class="btn-wrapper--label"> Añadir </span>
                          </button>
                        </div>
                      </form>
                    </div>

                    <div *ngIf="showEditPais" class="row mt-4">
                      <div class="table-responsive">
                        <table
                          class="table table-hover table-striped table-bordered mb-5"
                        >
                          <thead class="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col" colspan="2">Pais</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let pais of paises | async; index as i">
                              <th scope="row">{{ i + 1 }}</th>
                              <td
                                colspan="2"
                                class="d-flex justify-content-between align-items-center"
                              >
                                <span class="">{{ pais.name }}</span>
                                <span
                                  (click)="
                                    openEditModal(
                                      editPaisModal,
                                      pais.name,
                                      pais.id
                                    )
                                  "
                                  class="text-info pointer"
                                >
                                  <i-feather name="settings"></i-feather>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div *ngIf="showDeletePais" class="row mt-4">
                      <div class="table-responsive">
                        <table
                          class="table table-hover table-striped table-bordered mb-5"
                        >
                          <thead class="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col" colspan="2">Pais</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let pais of paises | async; index as i">
                              <th scope="row">{{ i + 1 }}</th>
                              <td
                                colspan="2"
                                class="d-flex justify-content-between align-items-center"
                              >
                                <span class="">{{ pais.name }}</span>
                                <span
                                  (click)="onPaisDelete(pais.id)"
                                  class="text-danger pointer"
                                >
                                  <fa-icon
                                    [icon]="['far', 'trash-alt']"
                                  ></fa-icon>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <ng-template #editPaisModal>
                  <div class="text-center p-5">
                    <h4 class="font-weight-bold mb-4">
                      Edita el Pais {{ itemToEdit }}
                    </h4>
                    <div class="d-flex justify-content-center">
                      <form class="form-inline">
                        <div
                          class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group"
                        >
                          <input
                            class="form-control"
                            id="PaisToEdit"
                            value="{{ itemToEdit }}"
                          />
                        </div>

                        <button
                          (click)="queryWinesByPais(itemToEdit)"
                          class="btn btn-primary text-light"
                        >
                          Enviar
                        </button>
                      </form>
                    </div>
                  </div>
                </ng-template>
              </div>
              <select
                #pais
                formControlName="pais"
                class="form-control"
                id="pais"
                name="pais"
              >
                <option selected value="España">España</option>
                <option *ngFor="let pais of paises | async; index as i">{{
                  pais.name
                }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <div
                class="d-flex justify-content-between align-items-center minTitle-h"
              >
                <label for="anada">Añada</label>
              </div>
              <select
                formControlName="anada"
                class="form-control"
                id="anada"
                name="anada"
              >
                <option value="Sin añada">Sin añada</option>
                <option>2020</option>
                <option>2019</option>
                <option>2018</option>
                <option>2017</option>
                <option>2016</option>
                <option>2015</option>
                <option>2014</option>
                <option>2013</option>
                <option>2012</option>
                <option>2011</option>
                <option>2010</option>
                <option>2009</option>
                <option>2008</option>
                <option>2007</option>
                <option>2006</option>
                <option>2005</option>
                <option>2004</option>
                <option>2003</option>
                <option>2002</option>
                <option>2001</option>
                <option>2000</option>
                <option>1999</option>
                <option>1998</option>
                <option>1997</option>
                <option>1996</option>
                <option>1995</option>
                <option>1994</option>
                <option>1993</option>
                <option>1992</option>
                <option>1991</option>
                <option>1990</option>
                <option>1989</option>
                <option>1988</option>
                <option>1987</option>
                <option>1986</option>
                <option>1985</option>
                <option>1984</option>
                <option>1983</option>
                <option>1982</option>
                <option>1981</option>
                <option>1980</option>
                <option>1979</option>
                <option>1978</option>
                <option>1977</option>
                <option>1976</option>
                <option>1975</option>
                <option>1974</option>
                <option>1973</option>
                <option>1972</option>
                <option>1971</option>
                <option>1970</option>
                <option>1969</option>
                <option>1968</option>
                <option>1967</option>
                <option>1966</option>
                <option>1965</option>
                <option>1964</option>
                <option>1963</option>
                <option>1962</option>
                <option>1961</option>
                <option>1960</option>
                <option>1959</option>
                <option>1958</option>
                <option>1957</option>
                <option>1956</option>
                <option>1955</option>
                <option>1954</option>
                <option>1953</option>
                <option>1952</option>
                <option>1951</option>
                <option>1950</option>
                <option>1949</option>
                <option>1948</option>
                <option>1947</option>
                <option>1946</option>
                <option>1945</option>
                <option>1944</option>
                <option>1943</option>
                <option>1942</option>
                <option>1941</option>
                <option>1940</option>
                <option>1939</option>
                <option>1938</option>
                <option>1937</option>
                <option>1936</option>
                <option>1935</option>
                <option>1934</option>
                <option>1933</option>
                <option>1932</option>
                <option>1931</option>
                <option>1930</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <div
                class="d-flex justify-content-between align-items-center minTitle-h"
              >
                <label class="mb-0"
                  >Volumen

                  <span *ngIf="!isSubmitted"> *</span>
                  <span
                    class="text-danger"
                    *ngIf="isSubmitted && formControls.do.errors?.required"
                  >
                    *</span
                  >
                </label>
                <button
                  (click)="openModal(volumenModal)"
                  class="btn btn-sm m-2 btn-primary text-light"
                  type="button"
                >
                  <span class="btn-wrapper--icon">
                    <fa-icon [icon]="['fas', 'cog']"></fa-icon>
                  </span>
                </button>

                <ng-template #volumenModal>
                  <div class="text-center p-5">
                    <div class="avatar-icon-wrapper rounded-circle m-0">
                      <div
                        class="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-primary m-0 d-130"
                      >
                        <fa-icon
                          [icon]="['fas', 'cog']"
                          class="d-flex align-self-center display-3"
                        ></fa-icon>
                      </div>
                    </div>
                    <h4 class="font-weight-bold mt-4">Que quieres hacer?</h4>
                    <p class="mb-0 font-size-lg text-muted">
                      Selecciona tu opción
                    </p>
                    <div class="pt-4">
                      <button
                        (click)="deleteVolumen()"
                        class="btn btn-pill btn-danger mx-1"
                        type="button"
                      >
                        <span class="btn-wrapper--label">
                          Borrar
                        </span>
                      </button>
                      <button
                        (click)="editVolumen()"
                        class="btn btn-pill btn-info mx-1"
                        type="button"
                      >
                        <span class="btn-wrapper--label">
                          Editar
                        </span>
                      </button>
                      <button
                        (click)="addVolumen()"
                        class="btn btn-pill btn-success mx-1"
                        type="button"
                      >
                        <span class="btn-wrapper--label">
                          Añadir
                        </span>
                      </button>
                    </div>

                    <div *ngIf="showAddVolumen" class="row mt-4">
                      <form
                        class="col"
                        [formGroup]="nuevoVolumen"
                        (submit)="onVolumenSubmit(nuevoVolumen.value)"
                      >
                        <div
                          class="position-relative row form-group  d-flex justify-content-center"
                        >
                          <div class="col-sm-9">
                            <input
                              class="form-control"
                              formControlName="nombre"
                              placeholder="Introduce nombre del volumen"
                              type="text"
                            />
                          </div>
                          <button
                            type="submit"
                            class=" btn btn-sm btn-pill btn-success mx-1 d-inline-block"
                          >
                            <span class="btn-wrapper--label"> Añadir </span>
                          </button>
                        </div>
                      </form>
                    </div>

                    <div *ngIf="showEditVolumen" class="row mt-4">
                      <div class="table-responsive">
                        <table
                          class="table table-hover table-striped table-bordered mb-5"
                        >
                          <thead class="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col" colspan="2">Volumen</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let volumen of volumenes | async;
                                index as i
                              "
                            >
                              <th scope="row">{{ i + 1 }}</th>
                              <td
                                colspan="2"
                                class="d-flex justify-content-between align-items-center"
                              >
                                <span class="">{{ volumen.name }}</span>
                                <span
                                  (click)="
                                    openEditModal(
                                      editVolumenModal,
                                      volumen.name,
                                      volumen.id
                                    )
                                  "
                                  class="text-info pointer"
                                >
                                  <i-feather name="settings"></i-feather>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div *ngIf="showDeleteVolumen" class="row mt-4">
                      <div class="table-responsive">
                        <table
                          class="table table-hover table-striped table-bordered mb-5"
                        >
                          <thead class="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col" colspan="2">Volumen</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let volumen of volumenes | async;
                                index as i
                              "
                            >
                              <th scope="row">{{ i + 1 }}</th>
                              <td
                                colspan="2"
                                class="d-flex justify-content-between align-items-center"
                              >
                                <span class="">{{ volumen.name }}</span>
                                <span
                                  (click)="onVolumenDelete(volumen.id)"
                                  class="text-danger pointer"
                                >
                                  <fa-icon
                                    [icon]="['far', 'trash-alt']"
                                  ></fa-icon>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <ng-template #editVolumenModal>
                  <div class="text-center p-5">
                    <h4 class="font-weight-bold mb-4">
                      Edita el Volumen {{ itemToEdit }}
                    </h4>
                    <div class="d-flex justify-content-center">
                      <form class="form-inline">
                        <div
                          class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group"
                        >
                          <input
                            class="form-control"
                            id="volumenToEdit"
                            value="{{ itemToEdit }}"
                          />
                        </div>

                        <button
                          (click)="queryWinesByVolumen(itemToEdit)"
                          class="btn btn-primary text-light"
                        >
                          Enviar
                        </button>
                      </form>
                    </div>
                  </div>
                </ng-template>
              </div>
              <select
                #volumen
                formControlName="volumen"
                class="form-control"
                id="volumen"
                name="volumen"
              >
                <option selected value="750 ml">750 ml</option>
                <option *ngFor="let volumen of volumenes | async; index as i">{{
                  volumen.name
                }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-3">
            <div class="position-relative form-group">
              <div
                class="d-flex justify-content-between align-items-center minTitle-h"
              >
                <label for="tipoVino"
                  >Tipo de vino

                  <span *ngIf="!isSubmitted"> *</span>
                  <span
                    class="text-danger"
                    *ngIf="isSubmitted && formControls.tipo.errors?.required"
                  >
                    *</span
                  >
                </label>
              </div>
              <select
                formControlName="tipo"
                class="form-control"
                id="tipoVino"
                name="tipoVino"
              >
                <option disabled value="">Selecciona</option>
                <option>Tinto</option>
                <option>Blanco</option>
                <option>Rosado</option>
                <option>Espumoso</option>
                <option>Generoso</option>
                <option>Estuche</option>
                <option>Vermouth</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <div
                class="d-flex justify-content-between align-items-center minTitle-h"
              >
                <label class="mb-0">Variedad de uva </label>
                <button
                  (click)="openModal(uvaModal)"
                  class="btn btn-sm m-2 btn-primary text-light"
                  type="button"
                >
                  <span class="btn-wrapper--icon">
                    <fa-icon [icon]="['fas', 'cog']"></fa-icon>
                  </span>
                </button>

                <ng-template #uvaModal>
                  <div class="text-center p-5">
                    <div class="avatar-icon-wrapper rounded-circle m-0">
                      <div
                        class="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-primary m-0 d-130"
                      >
                        <fa-icon
                          [icon]="['fas', 'cog']"
                          class="d-flex align-self-center display-3"
                        ></fa-icon>
                      </div>
                    </div>
                    <h4 class="font-weight-bold mt-4">Que quieres hacer?</h4>
                    <p class="mb-0 font-size-lg text-muted">
                      Selecciona tu opción
                    </p>
                    <div class="pt-4">
                      <button
                        (click)="deleteUva()"
                        class="btn btn-pill btn-danger mx-1"
                        type="button"
                      >
                        <span class="btn-wrapper--label">
                          Borrar
                        </span>
                      </button>
                      <button
                        (click)="editUva()"
                        class="btn btn-pill btn-info mx-1"
                        type="button"
                      >
                        <span class="btn-wrapper--label">
                          Editar
                        </span>
                      </button>
                      <button
                        (click)="addUva()"
                        class="btn btn-pill btn-success mx-1"
                        type="button"
                      >
                        <span class="btn-wrapper--label">
                          Añadir
                        </span>
                      </button>
                    </div>

                    <div *ngIf="showAddUva" class="row mt-4">
                      <form
                        class="col"
                        [formGroup]="nuevaUva"
                        (submit)="onUvaSubmit(nuevaUva.value)"
                      >
                        <div
                          class="position-relative row form-group  d-flex justify-content-center"
                        >
                          <div class="col-sm-9">
                            <input
                              class="form-control"
                              formControlName="nombre"
                              placeholder="Introduce nombre de la uva"
                              type="text"
                            />
                          </div>
                          <button
                            type="submit"
                            class=" btn btn-sm btn-pill btn-success mx-1 d-inline-block"
                          >
                            <span class="btn-wrapper--label"> Añadir </span>
                          </button>
                        </div>
                      </form>
                    </div>

                    <div *ngIf="showEditUva" class="row mt-4">
                      <div class="table-responsive">
                        <table
                          class="table table-hover table-striped table-bordered mb-5"
                        >
                          <thead class="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col" colspan="2">Variedad de uva</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let uva of uvas | async; index as i">
                              <th scope="row">{{ i + 1 }}</th>
                              <td
                                colspan="2"
                                class="d-flex justify-content-between align-items-center"
                              >
                                <span class="">{{ uva.name }}</span>
                                <span
                                  (click)="
                                    openEditModal(
                                      editUvaModal,
                                      uva.name,
                                      uva.id
                                    )
                                  "
                                  class="text-info pointer"
                                >
                                  <i-feather name="settings"></i-feather>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div *ngIf="showDeleteUva" class="row mt-4">
                      <div class="table-responsive">
                        <table
                          class="table table-hover table-striped table-bordered mb-5"
                        >
                          <thead class="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col" colspan="2">Variedad de uva</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let uva of uvas | async; index as i">
                              <th scope="row">{{ i + 1 }}</th>
                              <td
                                colspan="2"
                                class="d-flex justify-content-between align-items-center"
                              >
                                <span class="">{{ uva.name }}</span>
                                <span
                                  (click)="onUvaDelete(uva.id)"
                                  class="text-danger pointer"
                                >
                                  <fa-icon
                                    [icon]="['far', 'trash-alt']"
                                  ></fa-icon>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <ng-template #editUvaModal>
                  <div class="text-center p-5">
                    <h4 class="font-weight-bold mb-4">
                      Edita el la variedad de uva {{ itemToEdit }}
                    </h4>
                    <div class="d-flex justify-content-center">
                      <form class="form-inline">
                        <div
                          class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group"
                        >
                          <input
                            class="form-control"
                            id="uvaToEdit"
                            value="{{ itemToEdit }}"
                          />
                        </div>

                        <button
                          (click)="queryWinesByUva(itemToEdit)"
                          class="btn btn-primary text-light"
                        >
                          Enviar
                        </button>
                      </form>
                    </div>
                  </div>
                </ng-template>
              </div>

              <div class="button-container">
                <ul *ngIf="checkType(variedades)">
                  <li *ngFor="let variedad of variedades; index as i">
                    {{ variedad }}
                  </li>
                </ul>
                <ul *ngIf="!checkType(variedades)">
                  <li>
                    {{ variedades }}
                  </li>
                </ul>

                <button
                  (click)="
                    openVariedadModal(
                      variedadesModal,
                      this.uvaspromise,
                      variedades
                    )
                  "
                  class="btn btn-sm m-2 btn-primary text-light"
                  type="button"
                >
                  <span class="btn-wrapper--icon">
                    <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                  </span>
                </button>
              </div>

              <ng-template #variedadesModal>
                <div class="text-center p-5">
                  <div class="avatar-icon-wrapper rounded-circle m-0">
                    <div
                      class="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-primary m-0 d-130"
                    >
                      <fa-icon
                        [icon]="['fas', 'cog']"
                        class="d-flex align-self-center display-3"
                      ></fa-icon>
                    </div>
                  </div>
                  <h4 class="font-weight-bold mt-4">
                    Selecciona las variedades y haga click en añadir
                  </h4>
                  <button
                    (click)="saveVariedades()"
                    class="btn btn-pill btn-success mx-1"
                    type="button"
                  >
                    <span class="btn-wrapper--label">
                      Añadir
                    </span>
                  </button>
                  <div class="pt-4"></div>
                  <div class="table-responsive">
                    <table
                      class="table table-hover table-striped table-bordered mb-5"
                    >
                      <thead class="thead-light">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Variedad de uva</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody #uvaTable>
                        <tr *ngFor="let uva of availableList; index as i">
                          <td scope="row">{{ i + 1 }}</td>
                          <td class="">
                            <span class="">{{ uva }}</span>
                          </td>
                          <td class="">
                            <div class="custom-checkbox custom-control">
                              <input
                                class="custom-control-input variedad"
                                value="{{ uva }}"
                                id="{{ uva }}"
                                type="checkbox"
                              />
                              <label
                                class="custom-control-label"
                                for="{{ uva }}"
                              ></label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-template>

              <!-- <select #uva formControlName="uva" class="form-control" id="uva" name="uva">
                <option disabled selected value="">Selecciona</option>
                <option *ngFor="let uva of uvas | async; index as i">{{uva.name}}</option>
              </select> -->
            </div>
          </div>

          <div class="col-md-3">
            <div class="position-relative form-group">
              <div
                class="d-flex justify-content-between align-items-center minTitle-h"
              >
                <label for="tipoVino">
                  Vino propio
                </label>
              </div>

              <div class="custom-checkbox custom-control">
                <input
                  class="custom-control-input"
                  id="ownCheck"
                  type="checkbox"
                  (change)="toggleOwn($event)"
                />
                <label class="custom-control-label" for="ownCheck"
                  >El vino no podrá ser ocultado por el cliente</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="position-relative form-group">
              <div
                class="d-flex justify-content-between align-items-center minTitle-h"
              >
                <label for="tipoVino">
                  Vino ecológico
                </label>
              </div>
              <div class="custom-checkbox custom-control">
                <input
                  class="custom-control-input"
                  id="ecoWine"
                  type="checkbox"
                />
                <label class="custom-control-label" for="ecoWine"
                  >El vino será marcado como vino ecológico.</label
                >
              </div>
            </div>
          </div>
        </div>
        <div
          [ngClass]="{ hidden: showProvinciaExclusiva === false }"
          class="form-row"
        >
          <div class="col-md-3 form-group">
            <label>Provincia Exclusiva</label>
            <app-selector
              collection="Provincias"
              [selectedFields]="selectedProvincias"
              [maxInput]="4"
              [key]="provinciaSelectorKey"
              (addNewItem)="agregarProvinciaAVino($event)"
              (removeItem)="eliminarProvinciaDeVino($event)"
              tag="Provincia"
            >
            </app-selector>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="position-relative row form-group">
            <label>Información</label>
            <textarea formControlName="desc" class="form-control"></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="row">
    <div class="col-xl-6">
      <div class="card card-box text-center mb-5 p-4 border-0">
        <h5 class="display-4 font-weight-bold">
          Subir imagen

          <span *ngIf="!isSubmitted"> *</span>
          <span
            class="text-danger"
            *ngIf="isSubmitted && formControls.image.errors?.required"
          >
            *</span
          >
        </h5>
        <p class="text-black-50 mb-4">
          Seleccionar la imagen para subirla y posteriormente ajustarla.
        </p>

        <div class="rounded-sm bg-light p-3">
          <div class="input-group text-left mb-0">
            <div [formGroup]="nuevoVino" class="custom-file">
              <input
                formControlName="image"
                type="file"
                accept="image/*"
                class="custom-file-input"
                (change)="fileChangeEvent($event)"
                id="inputGroupFile01"
                aria-describedby="inputGroupFileAddon01"
              />
              <label class="custom-file-label" for="inputGroupFile01"
                >Seleccionar archivo</label
              >
            </div>
          </div>
        </div>
        <div class="divider my-4"></div>

        <p class="text-black-50">
          Utiliza los siguientes botones para girar o invertir la imagen.
        </p>

        <div class="text-light">
          <button
            (click)="rotateLeft()"
            class="btn btn-primary btn-sm m-2 text-light p-0"
          >
            Girar izquierda
          </button>
          <button
            (click)="rotateRight()"
            class="btn btn-primary btn-sm m-2 text-light p-0"
          >
            Girar derecha
          </button>
          <button
            (click)="flipHorizontal()"
            class="btn btn-primary btn-sm m-2 text-light p-0"
          >
            Invertir Horizontal
          </button>
          <button
            (click)="flipVertical()"
            class="btn btn-primary btn-sm m-2 text-light p-0"
          >
            Invertir Vertical
          </button>
        </div>

        <div class="divider my-4"></div>
        <img
          [hidden]="!showCropper"
          [src]="croppedImage"
          class="croppedImage rounded-sm"
        />
      </div>
    </div>
    <div class="col-xl-6">
      <div class="card card-box mb-5 p-4 border-0">
        <h5 [hidden]="!showCropper" class="display-4 mb-3 font-weight-bold">
          Cropped image
        </h5>

        <image-cropper
          class="rounded-sm"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          [alignImage]="'center'"
          [aspectRatio]="3 / 4"
          [imageQuality]="80"
          [cropperMinWidth]="340"
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="false"
          [onlyScaleDown]="true"
          [resizeToHeight]="1300"
          [roundCropper]="false"
          [style.display]="showCropper ? null : 'none'"
          format="jpg"
          outputType="base64"
        ></image-cropper>
        <div
          class="font-size-lg text-center text-danger p-5"
          [hidden]="showCropper"
        >
          <h5 class="display-4 text-black mb-3 font-weight-bold">Imagen</h5>
          Aqui aparecerá la imagen para cortar cuando se seleccione.
        </div>
      </div>
    </div>
  </div>

  <!-- [resizeToWidth]="340"  -->

  <!-- <div class="col-xl-6">
        <div class="card card-box  ">
          <div class="card-body">
            <h4 class="font-size-xl font-weight-bold mb-2 text-center">Subir imagen</h4>
            <p class="opacity-6 font-size-md mb-3 text-center">
                Selecciona la imagen para subirla y posteriormente ajustarla.
            </p>
          </div>
        </div>
    </div>

    <div class="col-xl-6">
        <div class="card card-box  ">
          <div class="card-body">

          </div>
        </div>
    </div> -->
</div>
