import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { FirestoreProviderService } from 'src/app/shared/firestore-provider.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-pages-login-content',
  templateUrl: './pages-login-content.component.html',
  styleUrls: ['./pages-login-content.component.scss'],
  host: {'class': 'w-100 d-flex align-items-center'}
})
export class PagesLoginContentComponent implements OnInit {
 

  ngOnInit(){
  }


  login = new FormGroup({
    email: new FormControl(''),
    password: new FormControl('')
  });

  public loading: boolean = false;
  public wrongLogin: boolean = false;
  constructor(
    public _auth: AngularFireAuth,
    private _firestoreProvider: FirestoreProviderService,
    private _router: Router,
    private notifier: NotifierService
  ){

  }

  showNotification(type, message) {
    this.notifier.notify(type, message);
  }

  onSubmit() {

      let email = this.login.value.email
      let password = this.login.value.password
      this.loading = true;
      this._auth.auth.signInWithEmailAndPassword(email,password)
        .then(async (user)=> {
          this.wrongLogin = false;
          return this._firestoreProvider.setDistribuidorPath(user);
      }).then(()=> {
        this.showNotification('success', 'Bienvenido')
        this._router.navigate(['/dashboard']);
      }).catch((error)=> {
        this.showNotification('danger', 'Datos incorrectos');
        this.wrongLogin = true;
      }).finally(()=>{
        this.loading = false;
      });

  }

}
