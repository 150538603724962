<div class="d-flex flex-column justify-content-between align-items-left minTitle-h">
  <div class="d-flex">
    <input type="text" autocomplete='none' [value]="inputValue" class="input-item form-control" (keyup)="onInputChange($event)"
    (keydown)="onDataNavigation($event)" (blur)="onBlur()" (click)="onInputClick()" #mainInput />
  </div>
  <div class="collection-list__container">
    <div *ngIf="displayDataBox" class="collection-list__items">
      <div (mousedown)="onSelectOptionClick(i)" *ngFor="let item of displayData; index as i">
        <div class="d-flex flex-row justify-content-between align-items-center collection-list__items__item"
          [ngClass]="{'collection-list__items__item--background-grey': i === indexSelected}">
          <p *ngIf="i !== indexEditing; else editInput">
            {{ item.Nombre }}
          </p>
          <ng-template #editInput>
            <input type="text" (click)="stopPropagationHelper($event)" (keyup)="onEditInputEnter($event)" />
          </ng-template>
          <div *ngIf="i !== displayData.length-1"
            class="collection-list__items__item__icons d-flex flex-row justify-content-end align-items-center">
            <fa-icon [icon]="['fas', 'star']" [ngClass]="{'text-dark': !item.favorito, 'text-warning': item.favorito}" class="font-size-lg margin-right" (click)="setFavorite($event,i)"></fa-icon>
            <fa-icon *ngIf="isAdminOperation" [icon]="['far', 'edit']" class="font-size-lg margin-right" (click)="setEditing($event,i)"></fa-icon>
            <fa-icon *ngIf="isAdminOperation" [icon]="['far', 'trash-alt']" class="font-size-lg margin-right" (click)="setDeleting($event,i)"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="selected-box d-flex">
    <div (click)="onOptionClick(selectedItem)" *ngFor="let selectedItem of selectedFields; index as i"
      class="badge m-1 badge-primary selected">
      <p>{{ selectedItem }}</p>
    </div>
  </div>
</div>

<!-- MODALS -->

<ng-template #acceptModal>
  <div class="text-center p-5">
    <div class="avatar-icon-wrapper rounded-circle m-0">
      <div
        class="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-first text-first m-0 d-130">
        <fa-icon [icon]="['far', 'question-circle']" class="d-flex align-self-center display-3"></fa-icon>
      </div>
    </div>
    <h4 class="font-weight-bold mt-4">¿Estas seguro de que quieres agregar esta Provincia?</h4>
    <div class="pt-4">
      <button class="btn btn-pill btn-neutral-secondary text-danger mx-1" type="button">
        <span (click)="modalUploadDecline()" class="btn-wrapper--label">
          Cancelar
        </span>
      </button>
      <button (click)="modalUploadAcceptButton()" class="btn btn-pill btn-first mx-1" type="button">
        <span class="btn-wrapper--label">
          Agregar
        </span>
      </button>
    </div>
  </div>
</ng-template>
<ng-template #successEditModal>
  <div class="text-center p-5">
    <div class="avatar-icon-wrapper rounded-circle m-0">
      <div
        class="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-success text-success m-0 d-130">
        <fa-icon [icon]="['far', 'check-circle']" class="d-flex align-self-center display-3"></fa-icon>
      </div>
    </div>
    <h4 class="font-weight-bold mt-4">¡Item modificado!</h4>
    <div class="pt-4">
      <button (click)="modalSubmittedAcceptButton()" class="btn btn-pill btn-success mx-1" type="button">
        <span class="btn-wrapper--label">
          Cerrar
        </span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #successDeleteModal>
  <div class="text-center p-5">
    <div class="avatar-icon-wrapper rounded-circle m-0">
      <div
        class="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-warning text-warning m-0 d-130">
        <fa-icon [icon]="['far', 'trash-alt']" class="d-flex align-self-center display-3"></fa-icon>
      </div>
    </div>
    <h4 class="font-weight-bold mt-4">¡Item elminado!</h4>
    <div class="pt-4">
      <button (click)="modalSubmittedAcceptButton()" class="btn btn-pill btn-warning mx-1" type="button">
        <span class="btn-wrapper--label">
          Cerrar
        </span>
      </button>
    </div>
  </div>
</ng-template>