import { Component, Input, OnDestroy, OnInit, Output, OnChanges } from '@angular/core';
import { AuthService } from 'src/app/shared/auth.service';
import { FirestoreProviderService } from 'src/app/shared/firestore-provider.service';

@Component({
  selector: 'app-detail-list',
  templateUrl: './detail-list.component.html',
  styleUrls: ['./detail-list.component.scss']
})
export class DetailListComponent implements OnInit, OnDestroy, OnChanges {

  @Input() list: Array<unknown>;
  @Input() identProp: string;
  @Input() valueProp: string;
  @Input() appendValue: string = '';
  @Input() subtitle: string = '';
  @Input() icon: string;
  @Input() key: string;
  @Input() descIdentProp: string;
  @Input() descValueProp: string;
  @Input() restFilterStatus: string = 'Visitas';

  public details: boolean = false;
  public showWines: boolean = true;
  public showOnlyHiddenWine: boolean = false;
  public singleName: string;
  public restsCom: Array<any>;
  public wineFromRestStat: Array<any>;
  public wineFromRestList: Array<any>;

  public dataProp: any;
  public restStats: any;
  public statList: any;
  public restDataToShow: string;

  public filterStatus: string = 'Vinos';


  constructor(
    private _firestoreProvider: FirestoreProviderService,
    private _auth: AuthService
  ) { }

  ngOnInit() {
    this.dataProp = this.valueProp;
  }

  ngOnDestroy() {
  }

  ngOnChanges(){
    this.dataProp = this.valueProp;
  }

  closeDetail() {
    this.details = false;
    this.showOnlyHiddenWine = false;
    this.showAllWines();
  }

  formatDate(dateObject) {
    if (dateObject === undefined) {
      return '7, 2021';
    }
    const date = dateObject.toDate();
    const returnDate = `${date.getDate()}, ${date.getMonth() + 1}, ${date.getFullYear()}`;
    return returnDate;
  }

  toggleRestView(view) {
    switch(view) {
      case 'visitas':
        this.dataProp = 'visits';
        this.restFilterStatus = 'Visitas'
        break;
      case 'lastuse': 
        this.dataProp = 'lastUse';
        this.restFilterStatus = 'Ultimo uso'
        break;
      default: 
        this.dataProp = 'visits';
        this.restFilterStatus = 'Visitas'
        break;
    }
  }

  filterCategory() {
    const { month, year } = this.getdate();
    this.filterStatus = 'Categoria';
    this.showWines = false;
    this.statList = [];
    if (this.restStats.categoryView && this.restStats.categoryView[year] && this.restStats.categoryView[year][month]){
      for(const key in this.restStats.categoryView[year][month]) {
        this.statList.push({name: key, count: this.restStats.categoryView[year][month][key]})
      }
    }
    this.statList = this.statList.sort((a, b)=> b.count - a.count)
  }
  filterEntryChannel() {
    const { month, year } = this.getdate();
    this.filterStatus = 'Canal';
    this.showWines = false;
    this.statList = [];
    if (this.restStats.entryChannels && this.restStats.entryChannels[year] && this.restStats.entryChannels[year][month]){
      for(const key in this.restStats.entryChannels[year][month]) {
        this.statList.push({name: key, count: this.restStats.entryChannels[year][month][key]})
      }
    }
    this.statList = this.statList.sort((a, b)=> b.count - a.count)
  }
  filterType() {
    const { month, year } = this.getdate();
    this.filterStatus = 'Tipo';
    this.showWines = false;
    this.statList = [];
    if (this.restStats.typeViews && this.restStats.typeViews[year] && this.restStats.typeViews[year][month]){
      for(const key in this.restStats.typeViews[year][month]) {
        this.statList.push({name: key, count: this.restStats.typeViews[year][month][key]})
      }
    }
    this.statList = this.statList.sort((a, b)=> b.count - a.count)
  }

  filterHiddenWines() {
    this.filterStatus = 'Ocultos';
    this.showWines = true;
    this.wineFromRestList = this.wineFromRestStat.filter(wine => !wine.active)
    
  }
  filterRecommendedWines() {
    this.filterStatus = 'Recomendados';
    this.showWines = true;
    this.wineFromRestList = this.wineFromRestStat.filter(wine => wine.recommended)
  }

  showAllWines() {
    this.filterStatus = 'Vinos';
    this.showWines = true;
    this.wineFromRestList = this.wineFromRestStat;
  }

  handleInfo(id, name) {
    if (this.key === 'restaurantes') {
      this.getVinosFromRest(id, name);
    }
    if (this.key === 'comerciales') {
      this.getComercialRestStats(id, name)
    }
  }

  getdate() {
    var today = new Date();
    var dd = today.getDate()
    var mm = today.getMonth() + 1
    var yyyy = today.getFullYear()

    let date: any = {}

    date['day'] = dd
    date['month'] = mm
    date['year'] = yyyy

    return date
  }

  getMonthStat(data, from='wine') {

    var today = this.getdate()
    var dd = today.day
    var mm = today.month
    var yyyy = today.year
    let views;
    if (from === 'rest') {
      if (data.entryChannels && data.entryChannels[yyyy] && data.entryChannels[yyyy][mm]) {
        const viewQR = data.entryChannels[yyyy][mm]['QR'] || 0;
        const viewApp = data.entryChannels[yyyy][mm]['App'] || 0;
        views = viewQR + viewApp
      } else {
        views = 0
      }
    } else {
      views = data.views[yyyy][mm]
    }
    return views;

  }

  getComercialRestStats(outerId?, singleName?) {
    var today = this.getdate();
    var mm = today.month;
    var yyyy = today.year;
    let comercialRests = [];
    let id;

    if (outerId) {
      id = outerId;
    } else {
      id = this._auth.currentUser().uid;
    }

    this._firestoreProvider.getCollection('comercialesIndex').doc(id).get().toPromise().then((doc: any) => {
      this._firestoreProvider.getCollection('statsRests').get().toPromise().then((doc2: any) => {
        let restList = doc.data().restaurantes;
        let stats = doc2.docs.map((ele) => {
          return ele.data();
        })
        stats.forEach((ele) => {
          if(ele.views === undefined) {
            ele.views = {
              [yyyy]: {
                [mm] : 0
              }
            }
          }
          if (ele.views[yyyy] === undefined) {
            ele.views[yyyy] = {}
          }
          if (ele.views[yyyy][mm] === undefined) {
            (ele.views[yyyy][mm] = 0);
          }
          ele.visits = this.getMonthStat(ele,'rest');
        })
        restList.forEach((rest, i) => {
          let id = rest.id
          let curRest = stats.filter((filteredRest) => {
            return filteredRest.id == id
          })
          if (curRest[0]) {
            curRest[0].nombre = restList[i].nombre
            comercialRests.push(curRest[0])
          }
        });
        comercialRests.sort((a: any, b: any) => {
          return b.views[yyyy][mm] - a.views[yyyy][mm]
        });
        this.restsCom = comercialRests;
      });
    }).then(()=> {
      this.details = true;
      this.singleName = singleName;
    });
  }

  getVinosFromRest(restId, restName) {
    this._firestoreProvider.getCollection('vinosStock').get().toPromise().then(winesStock=>{
      this._firestoreProvider.getCollection(restId).get().toPromise().then(col=>{
        const {year, month} = this.getdate();
        const wineOnStock = winesStock.docs
          .map(wine => wine.data())
          .reduce((prev: any, current:any)=>{
            return {...prev, [current.id]: {name: current.name}}
          }, {});
        const wines = []
        for (const wine of col.docs) {
          if (wine.id === 'configs' || wine.id === 'vinos'){
            break;
          } 
          const data = wine.data();
          if (wineOnStock[wine.id]) {
            wines.push({
              id: wine.id,
              active: data.active || false,
              count: data.views ? data.views[year] ? data.views[year][month] : 0 : 0,
              own: data.recommended !== undefined,
              name: wineOnStock[wine.id].name,
              recommended: !!data.recommended,
              entryChannels: data.entryChannels,
            })
          }
        }
        this.wineFromRestStat = wines.sort((wineA, wineB) => wineB.count - wineA.count);
        this.wineFromRestList = this.wineFromRestStat
      }).then(()=> {
        this.details = true;
        this.singleName = restName;
        this._firestoreProvider.getCollection('statsRests').doc(restId).get().toPromise().then(rest=>{
          this.restStats = rest.data();
        })
      })
    })
  }

  trackCount(index, item) {
    return item.count
  }
  trackCountVisits(index, item) {
    return item.visits
  }
}
