import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NotifierService } from 'angular-notifier';
import { FirestoreProviderService } from 'src/app/shared/firestore-provider.service';

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html',
  styleUrls: ['./editar-usuario.component.scss']
})
export class EditarUsuarioComponent implements OnInit {
  id: string;
  modalRef: BsModalRef;
  isSubmitted: boolean;
  inputValue: { nombre: any; email: any; password?: any; };

  editarUsuario = new FormGroup({
    nombre: new FormControl('', Validators.required),
    email: new FormControl('', Validators.compose([
      Validators.email,
      Validators.required
    ])),
    contrasena: new FormControl(''),
  });
  usuarioToEdit = {
    email: '', contrasena: '', nombre: ''
  };


  @ViewChild('nombre', { static: true }) nombreInput: ElementRef;
  @ViewChild('email', { static: true }) emailInput: ElementRef;
  @ViewChild('password', { static: true }) passwordInput: ElementRef;
  loading: boolean;
  loadingDelete: boolean;

  public isAdmin: boolean;

  constructor(
    private notifier: NotifierService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _firestore: AngularFirestore,
    private _firestoreProvider: FirestoreProviderService,
    private _functions: AngularFireFunctions,
    private modalService: BsModalService, ) { }

  ngOnInit() {
    this._route.paramMap.subscribe(params => {
      this.id = params.get('id')
    })
    this.isAdmin = !this._firestoreProvider.isExtDist();
    this.getUsuario()
  }

  showNotification(type, message) {
    this.notifier.notify(type, message);
  }

  generatePassword() {
    let generated = this._firestore.createId();
    let password = generated.substring(0, 7)
    this.editarUsuario.patchValue({ contrasena: password });
  }

  getUsuario() {
    this._firestoreProvider.getCollection('usuarios').doc(this.id).valueChanges().subscribe((usuario: any) =>
      this.usuarioToEdit = {
        email: usuario.email,
        contrasena: usuario.contrasena,
        nombre: usuario.nombre
      })
  }

  getInput() {
    this.inputValue = {
      nombre: this.nombreInput.nativeElement.value,
      email: this.emailInput.nativeElement.value,
    }
    if (this.passwordInput.nativeElement.value) {
      this.inputValue.password = this.passwordInput.nativeElement.value;
    }

  }

  onSubmit(formValue) {
    this.getInput()
    this.isSubmitted = true;
    this.loading = true;
    let updateUser = this._functions.httpsCallable('updateUserAccount');

    updateUser({
      userUID: this.id,
      userDetails: {
        ...this.inputValue
      },
      userClaim: this._firestoreProvider.isExtDist() ? 'editorExt' : 'editor'
    }).toPromise()
      .then((response) => {
        if (response.errorInfo) {
          this.loading = false;
          this.showNotification('danger', response.errorInfo.code)
          this._router.navigateByUrl('/usuarios');
        } else {
          this.loading = false;
          this._router.navigateByUrl('/usuarios');
          this.showNotification('success', `El usuario ${this.inputValue.email} fue actualizado exitosamente.`);
        }
      })
      .catch(err=>{
        this.loading = false
        this.showNotification('danger', err.message)
      })

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }
  closeModal() {
    this.modalRef.hide()
  }

  get formControls() {
    return this.editarUsuario['controls'];
  }

  deleteUsuario() {
    this.loadingDelete = true
    let deleteUser = this._functions.httpsCallable('deleteUserAccount');
    deleteUser({
      userUID: this.id,
      userClaim: this._firestoreProvider.isExtDist() ? 'editorExt' : 'editor'
    }).toPromise().then((response) => {
      if (response.errorInfo) {
        this.loading = false;
        this.showNotification('danger', response.errorInfo.code)
      } else {
        this.closeModal();
        this.loading = false;
        this._router.navigateByUrl('/usuarios');
      }
    })
  }
}
