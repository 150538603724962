import { NgModule, ɵAPP_ID_RANDOM_PROVIDER } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { customClaims } from '@angular/fire/auth-guard';

// Pages

import { DashboardDefaultComponent } from './example-pages/dashboard-default/dashboard-default.component';
//import { ApplicationsChatComponent } from './example-pages/applications-chat/applications-chat.component';
import { PagesLoginComponent } from './example-pages/pages-login/pages-login.component';
import { PagesRegisterComponent } from './example-pages/pages-register/pages-register.component';
import { PagesRecoverPasswordComponent } from './example-pages/pages-recover-password/pages-recover-password.component';
import { PagesError404Component } from './example-pages/pages-error-404/pages-error-404.component';
import { ButtonsComponent } from './example-pages/buttons/buttons.component';
import { UtilitiesHelpersComponent } from './example-pages/utilities-helpers/utilities-helpers.component';

// Layouts

import { LeftSidebarComponent } from './layout-blueprints/left-sidebar/left-sidebar.component';
import { CollapsedSidebarComponent } from './layout-blueprints/collapsed-sidebar/collapsed-sidebar.component';
import { MinimalLayoutComponent } from './layout-blueprints/minimal-layout/minimal-layout.component';
import { PresentationLayoutComponent } from './layout-blueprints/presentation-layout/presentation-layout.component';
import { VinosComponent } from './dvinum/vinos/vinos.component';
import { AnadirVinoComponent } from './dvinum/anadir-vino/anadir-vino.component';
import { RestaurantesComponent } from './dvinum/restaurantes/restaurantes.component';
import { DashboardComponent } from './dvinum/dashboard/dashboard.component';
import { EditarVinoComponent } from './dvinum/editar-vino/editar-vino.component';
import { AngularFireAuthGuard, canActivate, redirectLoggedInTo, redirectUnauthorizedTo, hasCustomClaim } from "@angular/fire/auth-guard";
import { AnadirRestauranteComponent } from './dvinum/anadir-restaurante/anadir-restaurante.component';
import { EditarRestauranteComponent } from './dvinum/editar-restaurante/editar-restaurante.component';
import { AsignarVinosComponent } from './dvinum/asignar-vinos/asignar-vinos.component';
import { UsuariosComponent } from './dvinum/usuarios/usuarios.component';

import { AnadirUsuarioComponent } from './dvinum/anadir-usuario/anadir-usuario.component';
import { EditarUsuarioComponent } from './dvinum/editar-usuario/editar-usuario.component';
import { OrdenarVinosComponent } from './dvinum/ordenar-vinos/ordenar-vinos.component';
import { FichasVinosComponent } from './dvinum/fichas-vinos/fichas-vinos.component';
import { ConfigurarCartaComponent } from './dvinum/configurar-carta/configurar-carta.component';
import { ComercialesComponent } from './dvinum/comerciales/comerciales.component';
import { ComercialComponent } from './dvinum/comercial/comercial.component';
import { AsignarRestaurantesComponent } from './dvinum/asignar-restaurantes/asignar-restaurantes.component';
import { DirectoresComponent } from './dvinum/directores/directores.component';
import { DirectorComponent } from './dvinum/director/director.component';
import { DistribuidoresComponent } from './dvinum/distribuidores/distribuidores.component';
import { DistribuidorComponent } from './dvinum/distribuidor/distribuidor.component';
import { AsignarComercialesComponent } from './dvinum/asignar-comerciales/asignar-comerciales.component';
import { AuthGuardGuard } from './shared/auth-guard.guard';


const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToDash = () => redirectLoggedInTo(['dashboard']);

const appAdmin = 'admin';
const appEditor = 'editor';
const appComercial = 'comercial';
const appDirector = 'director';
const appExtAdmin = 'distAdmin';
const appExtEditor = 'editorExt';
const appExtComercial = 'comercialExt';
const appExtDirector = 'directorExt';

const adminOnly = [appAdmin];
const allAdmins = [appAdmin, appExtAdmin];
const allEditors = [appExtEditor, appExtAdmin, appAdmin, appEditor];
const allUsers = [appAdmin, appEditor, appComercial, appDirector, appExtAdmin, appExtDirector, appExtComercial, appExtEditor]

const routes: Routes = [
  {
    path: '',
    component: MinimalLayoutComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full', canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDash } },
      { path: 'login', component: PagesLoginComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDash } },
    ]
  },
  {
    path: '',
    component: LeftSidebarComponent,
    children: [

      { path: 'vinos', component: VinosComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allUsers } },
      { path: 'restaurantes', component: RestaurantesComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allUsers } },
      { path: 'anadir-restaurante', component: AnadirRestauranteComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allEditors } },
      { path: 'editar-restaurante/:id', component: EditarRestauranteComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allEditors } },
      { path: 'asignar-vinos/:id', component: AsignarVinosComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allEditors } },
      { path: 'anadir-vino', component: AnadirVinoComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allEditors } },
      { path: 'editar-vino/:id', component: EditarVinoComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allEditors } },
      { path: 'fichas-vinos/:id', component: FichasVinosComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allUsers } },
      { path: 'fichas-vinos/:id/:name', component: FichasVinosComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allUsers } },

      { path: 'configurar-carta/:id', component: ConfigurarCartaComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allUsers } },
      { path: 'configurar-carta/:id/:name', component: ConfigurarCartaComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allUsers } },

      { path: 'ordenar-vinos/:id', component: OrdenarVinosComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allEditors } },

      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allUsers } },
      { path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allAdmins } },
      { path: 'anadir-usuario', component: AnadirUsuarioComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allAdmins } },
      { path: 'editar-usuario/:id', component: EditarUsuarioComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allAdmins } },

      { path: 'comerciales', component: ComercialesComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allEditors } },
      { path: 'comercial', component: ComercialComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allEditors } },
      { path: 'comercial/:id', component: ComercialComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allEditors } },
      { path: 'asignar-restaurantes/:id', component: AsignarRestaurantesComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allEditors } },

      { path: 'directores', component: DirectoresComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allEditors } },
      { path: 'director', component: DirectorComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allEditors } },
      { path: 'director/:id', component: DirectorComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allEditors } },
      { path: 'asignar-comerciales/:id', component: AsignarComercialesComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: allEditors } },

      { path: 'distribuidores', component: DistribuidoresComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: adminOnly }},
      { path: 'distribuidor', component: DistribuidorComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: adminOnly }},
      { path: 'distribuidor/:id', component: DistribuidorComponent, canActivate: [AuthGuardGuard], data: { admittedClaims: adminOnly }}

    ]
  },

  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
