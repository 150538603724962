import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { addMilliseconds } from 'date-fns';
import { User } from 'firebase';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {

  constructor (
    private _auth: AuthService,
    private router: Router,
    ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const admittedClaims = next.data['admittedClaims'];
    const currentUser = this._auth.currentUser();
    if (!currentUser){
      this.router.navigate(['/login']);
      return true;
    }
    // Obtiene los claims del usuario
    return currentUser.getIdTokenResult().then(result => {
      for (const claim of admittedClaims) {
        // Revisa que los claims del usuario esten entre los permitidos en la ruta.
        if (result.claims[claim]) {
          return true;
        }
      }
      this.router.navigate(['/dashboard']);
    });
  }
}
