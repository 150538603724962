import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from 'src/app/shared/auth.service';
import { Observable, Subscription } from 'rxjs';
import { FirestoreProviderService } from 'src/app/shared/firestore-provider.service';
import { FaLayersTextBaseComponent } from '@fortawesome/angular-fontawesome/layers/layers-text-base.component';
import { format } from 'url';
import { staticViewQueryIds } from '@angular/compiler';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  admin: boolean = false;
  editor: boolean = false;
  comercial: boolean = false;
  director: boolean = false;
  editorExt: boolean = false;
  comercialExt: boolean = false;
  directorExt: boolean = false;
  distAdmin: boolean = false;

  restNumber: number
  wineNumber: number
  viewNumber: number
  viewNumberOld: number
  viewDelta: number
  viewActive: number

  public ownedWinesOnRest = [];

  public wineSubs: Subscription;
  public restSubs: Subscription;

  public dataProp: string = 'visits';

  public winesChart;
  public comercialChart;
  
  private interval;
  private viewOldInterval;

  constructor(
    private _firestore: AngularFirestore,
    private _firestoreProvider: FirestoreProviderService,
    private _analytics: AngularFireAnalytics,
    private _auth: AuthService
  ) {
  }

  ngOnInit() {

    // this._auth.currentUser().getIdTokenResult(true).then((result) => {
    this._firestoreProvider.setDistribuidorPath(this._auth.currentUser()).then((result) => {
      if (result.claims.admin) {
        this.admin = result.claims.admin
      }
      if (result.claims.editor) {
        this.editor = result.claims.editor
      }
      if (result.claims.comercial) {
        this.comercial = result.claims.comercial
      }
      if (result.claims.director) {
        this.director = result.claims.director
      }
      if (result.claims.distAdmin) {
        this.distAdmin = result.claims.distAdmin
      }
      if (result.claims.editorExt) {
        this.editorExt = result.claims.editorExt
      }
      if (result.claims.comercialExt) {
        this.comercialExt = result.claims.comercialExt
      }
      if (result.claims.directorExt) {
        this.directorExt = result.claims.directorExt
      }

      this.getCurrent()
      if (this.editor || this.editorExt) {
        this.getWinesStats()
        this.getRestStats()
        this.getAllComercialsInfo()
        this.getPercentageStats()
      }
      if (this.comercial || this.comercialExt) {
        this.getComercialRestStats()
        this.getOtherComercialsRests()
      }
      if (this.director || this.directorExt) {
        this.getDirectorComercials()
        this.getOtherComercialsRests()
        this.getWinesStats()
      }
      if (this.distAdmin) {
        this.getWinesStats()
        this.getRestStats()
        this.getAllComercialsInfo()
      }
    })
    .then(()=>{
      this.interval = setInterval(()=>{
        if (this.viewNumberOld === undefined) this.viewNumberOld = this.viewNumber;
        this.viewActive = this.viewNumber - this.viewNumberOld < 0 ? 0 : this.viewNumber - this.viewNumberOld;
      },5000);
      this.viewOldInterval = setInterval(()=>{
        this.viewNumberOld = this.viewNumber;
      },300000);
    })
    .catch(err=>{
    });
  }
  subs = new Subscription();
  ngOnDestroy() {
    this.subs.unsubscribe();
    clearInterval(this.interval)
    clearInterval(this.viewOldInterval)
  }

  setDataProp(data) {
    this.dataProp = data;
  }

  getCurrent() {
    const {day, month, year} = this.getdate();
    const formatMonth = month < 10 ? '0'+month : month;
    this.subs.add(
      this._firestoreProvider.getCollection('restaurantesIndex').snapshotChanges().subscribe((docrest) => {
        this.subs.add(
          this._firestoreProvider.getCollection('vinosStock').snapshotChanges().subscribe((docwine) => {
            let restArray = docrest.map((ele) => {
              return ele.payload.doc.data()
            })

            let wineArray = docwine.map((ele) => {
              return ele.payload.doc.data()
            })

            this.restNumber = restArray.length
            this.wineNumber = wineArray.length

            this.checkNewMonth()

          })
        )
      })
    )
    this.subs.add(
      this._firestoreProvider.getCollection('stats').doc('views').snapshotChanges().subscribe((docCurViews)=> {
        let document = docCurViews.payload.data();
        if (!document[year]) {
          document[year] = {}
        }
        if (!document[year][formatMonth]) {
          document[year][formatMonth] = {}
        }
        if (!document[year][formatMonth][day]){
          document[year][formatMonth][day] = 0
        }
        // this.viewActive = document['active'] > 0 ? document['active'] : 0 ;

        const viewTodayApp = document[year][formatMonth]['App'][day] || 0;
        const viewTodayQR = document[year][formatMonth]['QR'][day] || 0;
        this.viewNumber = viewTodayApp + viewTodayQR;

        const lastDayAppViews = document[year][formatMonth]['App'][day-1] || 0;
        const lastDayQRViews = document[year][formatMonth]['QR'][day-1] || 0;
        const lastDayAppViewsB = document[year][formatMonth]['App'][day-8] || 0;
        const lastDayQRViewsB = document[year][formatMonth]['QR'][day-8] || 0;
        this.viewDelta = (lastDayAppViews + lastDayQRViews) - (lastDayAppViewsB + lastDayQRViewsB);

        console.log(year, formatMonth, day)
      })
    )
  }

  monthToString(ele) {

    switch (ele) {
      case 1:
        return 'Enero'
        break;
      case 2:
        return 'Febrero'

        break;
      case 3:
        return 'Marzo'

        break;
      case 4:
        return 'Abril'

        break;
      case 5:
        return 'Mayo'

        break;
      case 6:
        return 'Junio'

        break;
      case 7:
        return 'Julio'

        break;
      case 8:
        return 'Agosto'

        break;
      case 9:
        return 'Septiembre'
        break;

      case 10:
        return 'Octubre'
        break;

      case 11:
        return 'Noviembre'
        break;

      case 12:
        return 'Diciembre'
        break;

      default:
        break;
    }

  }


  getdate() {

    var today = new Date();
    var dd = today.getDate()
    var mm = today.getMonth() + 1
    var yyyy = today.getFullYear()

    let date: any = {}

    date['day'] = dd
    date['month'] = mm
    date['year'] = yyyy



    return date

  }


  restDelta: number
  winesDelta: number
  compare() {
    this.subs.add(

      this._firestoreProvider.getCollection('stats').doc('lastMonth').snapshotChanges().subscribe((doc2: any) => {

        let lastMonth = doc2.payload.data().month
        let lastRests = doc2.payload.data().restaurants
        let lastWines = doc2.payload.data().wines

        this.restDelta = this.restNumber - lastRests
        this.winesDelta = this.wineNumber - lastWines

      })
    )
  }

  wines
  getWinesStats() {

    var today = this.getdate()
    var dd = today.day
    var mm = today.month
    var yyyy = today.year

    this.subs.add(

      this._firestoreProvider.getCollection('statsWines').snapshotChanges().subscribe((doc2: any) => {

        let result = doc2.map((ele) => {
          return ele.payload.doc.data()
        })

        let filteredResult = result.filter((ele) => {
          if (ele.views[yyyy]) {
            return ((ele.views[yyyy][mm] != undefined) && (ele.views[yyyy][mm] > 0))
          }
        })

        filteredResult.sort((a: any, b: any) => {
          return b.views[yyyy][mm] - a.views[yyyy][mm]
        })

        const filteredResultB = filteredResult.map(wine=>{return {...wine, monthView: this.getMonthStat(wine)}}).slice(0,99)
        const topTenWinesNames = [];
        const topTenWinesViews = [];
        const wineList = []
        for (let i = 0; i < filteredResultB.length; i++) {
          // topTenWinesNames.push(filteredResultB[i].name)
          // topTenWinesViews.push(filteredResultB[i].views)
          wineList.push({
            name:filteredResultB[i].name,
            views: filteredResultB[i].views
          })
        }
        this.winesChart = wineList
        this.wines = filteredResultB
      })
    )
  }

  async getPercentageStats() {
    const rests = await this._firestoreProvider.getCollection('restaurantesIndex').get().toPromise();
    const stats = await this._firestoreProvider.getCollection('statsRests').get().toPromise();
    const formattedList = rests.docs
      .reduce((accum, current) => {
        return {...accum, [current.id]:{name: current.data().nombre}}
      }, []);

    stats.docs.forEach(stat=>{
      if (formattedList[stat.id]){
        formattedList[stat.id].winePercent = stat.data().ownedWinePercentage;
      }
    });
    const winesToPush = []
    for (const rest in formattedList) {
      if (formattedList[rest].winePercent > 0) {
        winesToPush.push({name: formattedList[rest].name, winePercent: formattedList[rest].winePercent});
        winesToPush.sort((wineA, wineB) => wineB.winePercent - wineA.winePercent);
        this.ownedWinesOnRest = winesToPush;
      }
    }
  }

  rests
  
  getRestStats() {

    var today = this.getdate()
    var dd = today.day
    var mm = today.month
    var yyyy = today.year

    this.subs.add(
      this._firestoreProvider.getCollection('restaurantesIndex').snapshotChanges().subscribe((doc: any) => {
        this.subs.add(
          this._firestoreProvider.getCollection('statsRests').snapshotChanges().subscribe((doc2: any) => {
            let restList = doc.map((ele) => {
              const data = ele.payload.doc.data();
              return data
            }).reduce((prev: any, current: any) => {
              return {...prev, [current.id]: current}
            },{})

            let stats = doc2.map((ele) => {
              return ele.payload.doc.data()
            })

            let filteredResult = stats.filter((ele) => {
              if (ele.entryChannels && ele.entryChannels[yyyy]) {
                return true
              }
            })

            this.rests = filteredResult
              .filter((stat) => {
                if(restList[stat.id]) {
                  stat['name'] = restList[stat.id].nombre;
                  stat['visits'] = this.getMonthStat(stat, 'rest');
                  return true;
                }
                return false
              })
              .sort((a,b)=>b.visits - a.visits)

            console.log(this.rests)
          })
        )
      })
    )
  }

  comercials
  getDirectorComercials() {
    this.comercials = []
    let id = this._auth.currentUser().uid

    this.subs.add(

      this._firestoreProvider.getCollection('directoresIndex').doc(id).snapshotChanges().subscribe((doc: any) => {
        this.subs.add(

          this._firestoreProvider.getCollection('comercialesIndex').snapshotChanges().subscribe((doc2) => {
            let comercialesIndex = doc.payload.data().comerciales


            let comercialesList = doc2.map((rest) => rest.payload.doc.data())

            comercialesIndex.forEach((ele, i) => {

              let id = ele.id
              let curComercial = comercialesList.filter((filteredComercial: any) => {
                return filteredComercial.id == id
              }) 

              if (curComercial[0]) {
                // curRest[0].nombre = restList[i].nombre
                this.comercials.push(curComercial[0])
              }
            });

            this.comercials.sort((a: any, b: any) => {
              return b.restaurantes.length - a.restaurantes.length
            });

            
          })
          )
        })
        )
        
  }
  getAllComercialsInfo() {
    this.comercials = []
    this.subs.add(
      this._firestoreProvider.getCollection('comercialesIndex').snapshotChanges().subscribe((doc2) => {
          let comercialesList = doc2.map((rest) => rest.payload.doc.data())
          
          const comerciales = comercialesList
            .sort((a: any, b: any) => {
              if (a.restaurantes && b.restaurantes){
                return b.restaurantes.length - a.restaurantes.length
              }
              return -1;
            })
            .map((comercial: any) => {
              return {...comercial, numberOfRest: comercial.restaurantes ? comercial.restaurantes.length : 0}
            })

          this.comercials = comerciales
          const topTenComercialesNames = [];
          const topTenComercialesRest = [];
          for (let i = 0; i < 10; i++) {
            topTenComercialesNames.push(comerciales[i].nombre)
            topTenComercialesRest.push(comerciales[i].numberOfRest)
          }
          this.comercialChart = {
            views: topTenComercialesRest,
            names: topTenComercialesNames
          }
        })
      )
  }
  comercialRests
  getOtherComercialsRests() {


    this.subs.add( 

      this._firestoreProvider.getCollection('comercialesIndex').snapshotChanges().subscribe((doc: any) => {
        this.comercialRests = []

        let Names = doc.map((rest) => rest.payload.doc.data())
        let Rests = doc.map((rest) => rest.payload.doc.data().restaurantes)



        Names.forEach((e, i) => {
          let rest = {}
          rest['nombre'] = e.nombre
          rest['rests'] = Rests[i]

          this.comercialRests.push(rest)
        })

        this.comercialRests.sort((a: any, b: any) => {
          return b.rests.length - a.rests.length
        })

      })
    )

  }
  goBack() {

    this.details = false
    this.restsCom = []
  }
  details = false
  comName = ''
  restsCom
  getComercialRestStats(outerId?, comName?) {

    var today = this.getdate()
    var dd = today.day
    var mm = today.month
    var yyyy = today.year
    let comercialRests = []
    let id

    if (outerId) {
      id = outerId
      this.details = true
      this.comName = comName
    } else {
      id = this._auth.currentUser().uid
    }

    this.subs.add(

      this._firestoreProvider.getCollection('comercialesIndex').doc(id).snapshotChanges().subscribe((doc: any) => {
        this.subs.add(

          this._firestoreProvider.getCollection('statsRests').snapshotChanges().subscribe((doc2: any) => {
            let restList = doc.payload.data().restaurantes

            let stats = doc2.map((ele) => {
              return ele.payload.doc.data()
            })

            // let filteredResult = stats.filter((ele) => {
            //   if (ele.views[yyyy]) {
            //     return (ele.views[yyyy][mm] != undefined);
            //     ((ele.views[yyyy][mm] != undefined) && (ele.views[yyyy][mm] > 0))
            //   }
            // })
            stats.forEach((ele) => {

                if(ele.views == undefined) {
                  ele.views = {
                    [yyyy]: {
                      [mm] : 0
                    }
                  }
                }
                if (ele.views[yyyy] == undefined) {
                  ele.views[yyyy] = {}
                }
                if (ele.views[yyyy][mm] == undefined) {
                  (ele.views[yyyy][mm] = 0);
                }
            })
            restList.forEach((rest, i) => {
              let id = rest.id
              let curRest = stats.filter((filteredRest) => {
                return filteredRest.id == id
              })
              if (curRest[0]) {
                curRest[0].nombre = restList[i].nombre
                comercialRests.push(curRest[0])
              }

            });


            comercialRests.sort((a: any, b: any) => {
              return b.views[yyyy][mm] - a.views[yyyy][mm]
            })

            this.restsCom = comercialRests

          })
        )

      })
    )
  }

  getMonthStat(data, from='wine') {

    var today = this.getdate()
    var dd = today.day
    var mm = today.month
    var yyyy = today.year
    let views;
    if (from === 'rest') {
      if (data.entryChannels && data.entryChannels[yyyy] && data.entryChannels[yyyy][mm]) {
        const viewQR = data.entryChannels[yyyy][mm]['QR'] || 0;
        const viewApp = data.entryChannels[yyyy][mm]['App'] || 0;
        views = viewQR + viewApp
      } else {
        views = 0
      }
    } else {
      views = data.views[yyyy][mm]
    }
    return views;

  }


  updateLastDate(ele) {
    return this._firestoreProvider.getCollection('stats').doc('lastDate').set({
      day: ele.day,
      month: ele.month,
      year: ele.year,
      restaurants: ele.restaurants,
      wines: ele.wines

    }, { merge: true })
  }

  checkNewMonth() {

    this.subs.add(

      this._firestoreProvider.getCollection('stats').doc('lastDate').snapshotChanges().subscribe((doc2: any) => {

        let dayToCheck = doc2.payload.data().day
        let monthToCheck = doc2.payload.data().month
        let yearToCheck = doc2.payload.data().year

        var today = this.getdate()
        var dd = today.day
        var mm = today.month
        var yyyy = today.year

        let todayDate = {
          day: dd,
          month: mm,
          year: yyyy,
          restaurants: this.restNumber,
          wines: this.wineNumber
        }

        if (monthToCheck != mm) {



          this._firestoreProvider.getCollection('stats').doc('lastMonth').set({
            month: todayDate.month,
            year: todayDate.year,
            restaurants: todayDate.restaurants,
            wines: todayDate.wines

          }, { merge: true }).then(() => {
            this.updateLastDate(todayDate).then(() => { this.compare() })
          })
        } else if (dayToCheck != dd) {
          this.updateLastDate(todayDate).then(() => { this.compare() })
        } else {
          this.compare()
        }

      })
    )

  }




  time = 0

  getCollections() {

    this.time = this.time + 1

    if (this.time == 3) {

      this.subs.add(

        this._firestoreProvider.getCollection("restaurantes").snapshotChanges().subscribe((snapshot: any) => {
          let idArray = snapshot.map(doc => doc.payload.doc.data().id)

          let date = new Date();
          let year = date.getFullYear();
          let month = (date.getMonth() + 1)
          let day = date.getDate()
          let hour = (date.getHours() + 1)
          let minutes = date.getMinutes()

          this._firestoreProvider.getCollection("backups").doc('00date').set({ date: `${year}-${month}-${day}:${hour}:${minutes}` }, { merge: true })
            .then(() => {

              idArray.forEach((id) => {

                let fromId = this._firestoreProvider.getCollection(id).doc("configs");

                let toBackup = this._firestoreProvider.getCollection("backups").doc(id);

                this.subs.add(

                  fromId.collection("carta").snapshotChanges().subscribe((snapshot) => {
                    let cartaArray = snapshot.map(doc => doc.payload.doc.data())
                    toBackup.set({ carta: cartaArray }, { merge: true })
                      .then(() => {

                        this.subs.add(

                          fromId.collection("menus").snapshotChanges().subscribe((snapshot) => {
                            let menusArray = snapshot.map(doc => doc.payload.doc.data())
                            toBackup.set({ menus: menusArray }, { merge: true })
                              .then(() => {

                                this.subs.add(

                                  fromId.collection("order").doc('cartas').snapshotChanges().subscribe((snapshot) => {
                                    let cartasArray = snapshot.payload.data();
                                    this.subs.add(

                                      fromId.collection("order").doc('menus').snapshotChanges().subscribe((snapshot) => {
                                        let menusArray = snapshot.payload.data();

                                        if (cartasArray) {

                                          toBackup.set({ cartasOrder: cartasArray }, { merge: true }).then(() => {

                                            if (menusArray) {

                                              toBackup.set({ menusOrder: menusArray }, { merge: true })
                                            }
                                          })
                                        }
                                      })
                                    )
                                  })
                                )
                              })

                          })
                        )
                      })

                  })
                )
              })
            })

        })
      )

    }


  }

}
