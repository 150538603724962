<app-page-title titleDescription='' titleHeading='Añadir usuario'></app-page-title>

<div class="mx-4">
    <form [formGroup]="nuevoUsuario" (submit)="onSubmit(nuevoUsuario.value)">
  
      <div class="card card-box mb-5 border-0">
        <div class="card-header border-0">
          <div class="card-header--title">
            <h5 class="mb-0 py-2 font-weight-bold">
              Nuevo Usuario
            </h5>
          </div>
          <div class="card-header--actions">
    
            <button *ngIf="loading" class="btn btn-sm m-2 btn-success d-flex  align-items-center" disabled type="button">
              <span aria-hidden="true" class="spinner-border spinner-border-sm " role="status"></span>
              <span class="ml-2">PROCESANDO...</span>
            </button>
            <button *ngIf="!loading" class="btn btn-sm m-2 btn-success" type="submit">
              <span class="btn-wrapper--icon">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>
              </span>
              <span class="btn-wrapper--label ">CREAR USAURIO</span>
            </button>
          </div>
        </div>
        <div class="card-body">
  
          <div class="form-row">
            <div class="col-md-3">
              <div class="position-relative form-group">
                <label for="exampleEmail11">Email</label>
                <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.email.errors?.email"> Debe ser un email valido</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.email.errors?.required"> *</span>
                <input formControlName="email" class="form-control" placeholder="Introduce el email" type="email">
              </div>
            </div>
            <div class="col-md-3">
              <div class="position-relative form-group">
                <label>Nombre</label>
                <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.nombre.errors?.required">*</span>
                  
                <input formControlName="nombre" class="form-control" placeholder="Introduce el número"
                  type="text">
              </div>
            </div>
            <div class="col-md-6">
              <div class="position-relative form-group">
                <label>Contraseña</label>
                <span *ngIf="!isSubmitted"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.contrasena.errors?.required"> *</span>
                <span class="text-danger" *ngIf="isSubmitted && formControls.contrasena.errors?.minlength"> Mínimo 6 caracteres</span>
                <div class="d-flex ">
                  <input formControlName="contrasena" class="form-control col-md-6 d-flex mr-2 " placeholder="Introduce la contraseña" type="text">
                  <button (click)="generatePassword()" class="btn btn-primary d-inline-block text-light" type="button">Generar contraseña</button>
                </div>
  
              </div>
            </div>
            <div class="col-md-3">
              <div class="position-relative form-group">
                <!-- <button class="btn m-2 btn-primary" type="button">Generar contraseña</button> -->
                
              </div>
            </div>
          </div>
  
        </div>
      </div>
  
  
  
    </form>
  </div>
  