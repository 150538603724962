import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { auth } from 'firebase';

@Injectable()
export class FirestoreProviderService {

  private distPath: string;
  private distUID: string;
  private isExtDistAccount: boolean = false;

  constructor(private _firestore: AngularFirestore) {
    if (auth().currentUser){
      this.setDistribuidorPath(auth().currentUser)    
    }
  }

  whereQueryHelper(query) {
    return query.split(' ');
  }

  async getCollectionDocuments(collection: string, orderResultsBy?: string) {
    const documentsFromCollection = [];
    const provinciasRef = await this._firestore.collection(`${this.getDistPath()}${collection}`, orderResultsBy ? ref => ref.orderBy(orderResultsBy) : ref => ref);
    const snapShot = await provinciasRef.get().toPromise();
    snapShot.docs.forEach(document => documentsFromCollection.push({ ...document.data(), _id: document.id }));
    return documentsFromCollection;
  }

  async updateOne(collection, documentId, data) {
    return await this._firestore.doc(`${this.getDistPath()}${collection}/${documentId}`).update({ ...data })
  }

  async createOneDoc(collection, data) {
    const newdoc = await this._firestore.collection(`${this.getDistPath()}${collection}`).add({ ...data });
    return newdoc.id;
  }

  async deleteOneDoc(collection, documentId) {
    return await this._firestore.doc(`${this.getDistPath()}${collection}/${documentId}`).delete()
  }

  getOneDoc(collection: string, doc: string) {
    return this._firestore.doc(`${this.getDistPath()}${collection}/${doc}`).get();
  }

  getCollection(collection: string) {
    return this._firestore.collection(`${this.getDistPath()}${collection}`);
  }

  getCollectionAndSort(collection: string, orderBy?: string, asc: boolean = true) {
    const sortOrder = asc ? 'asc' : 'desc';
    const returnCollection = this._firestore.collection(`${this.getDistPath()}${collection}`, ref => ref.orderBy(orderBy, sortOrder));
    return returnCollection;
  }

  getCollectionWhere(collection: string, where: string) {
    const whereQuery = this.whereQueryHelper(where);
    return this._firestore.collection(`${this.getDistPath()}${collection}`, ref => ref.where(whereQuery[0],whereQuery[1],whereQuery[2]))
  }

  async setDistribuidorPath(distribuidorCredential) {
    try {
      const distUser = distribuidorCredential.user ? distribuidorCredential.user : distribuidorCredential;
      const userUID = distUser.uid;
      const distribuidorToken = await distUser.getIdTokenResult();
      if (distribuidorToken.claims.distAdmin) {
        const distDoc = await this._firestore
        .doc(`NewDistribuidoresIndex/${userUID}`)
        .get()
        .toPromise();
        if (distDoc) {
          this.distUID = distDoc.id;
          this.distPath = `Distribuidores/${this.distUID}/`;
          this.isExtDistAccount = true;
        }
        return distribuidorToken;
      }
      if (distribuidorToken.claims.editorExt || distribuidorToken.claims.comercialExt || distribuidorToken.claims.directorExt) {
        const userDoc = await this._firestore
          .doc(`NewUserIndex/${userUID}`)
          .get()
          .toPromise();
        if (userDoc) {
          this.distUID = await userDoc.data().distribuidorUID;
          this.distPath = `Distribuidores/${this.distUID}/`;
          this.isExtDistAccount = true;
        }
        return distribuidorToken;
      }
      this.distPath = '';
      return distribuidorToken;
    } catch(err) {
      return {error: true, msg: err.message}
    }
  }
  
  getDistUID() {
    return this.distUID || '';
  }

  getDistPath() {
    return this.distPath;
  }

  isExtDist() {
    return this.isExtDistAccount;
  }
}
