import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import { FirestoreProviderService } from 'src/app/shared/firestore-provider.service';

@Component({
  selector: 'app-ordenar-vinos',
  templateUrl: './ordenar-vinos.component.html',
  styleUrls: ['./ordenar-vinos.component.scss']
})
export class OrdenarVinosComponent implements OnInit, OnDestroy {
  id: string;
  vinos: any;
  subs = new Subscription();

  constructor(
    private _db: AngularFirestore,
    private _firestoreProvider: FirestoreProviderService,
    private _route: ActivatedRoute,
    private _dragula: DragulaService,
  ) {
    this._dragula.createGroup("WINES", {
      // ...
    });

    this.subs.add(this._dragula.dropModel("WINES")
      .subscribe(({ targetModel }) => {
        this.updateArray(targetModel);
      })
    );

  }
  ngOnDestroy() {
    this.subs.unsubscribe();
    this._dragula.destroy("WINES")
  }

  ngOnInit() {

    this.subs.add(
      this._route.paramMap.subscribe(params => {
        this.id = params.get('id')

      })
    )
    this.getWineBottlesByIndex()
  }

  updateArray(items) {
    this._firestoreProvider.getCollection(this.id).doc('vinos').set({ vinos: items }, { merge: true })
  }

  getWineBottlesByIndex(fcn?, fcn1?) {
    let bottleSubscription

    bottleSubscription = this._firestoreProvider.getCollection(this.id).doc('vinos').get().toPromise().then((doc: any) => {
      this.vinos = doc.data().vinos
    })

  }

}
